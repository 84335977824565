<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="content">
            <h1>Projects Details</h1>
            <a routerLink="/" class="link-btn">
                Back to Home
            </a>
        </div>
    </div>
</div>

<!-- Projects Details -->
<div class="projects-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="projects-details-desc">
                    <h2>Project Management Software</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas facilisis metus vitae mattis velit ac amet, mattis aenean. Quam eu aliquam quisque commodo feugiatedetti placerat elit. Eget mi, morbi tincidunt dolor.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas facilisis metus vitae mattis velit ac amet, mattis aenean. Quam eu aliquam quisqu. Quam eu aliquam quisq commodo feugiatedetti placerat elit. Eget mi, morbi.</p>
                    <img src="assets/images/personal-portfolio/project-img1.jpg" alt="services-image">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas facilisis metus vitae mattis velit ac amet, mattis aenean. Quam eu aliquam quisque <strong>commodo</strong> feugiat placerat elit. Eget mi, morbi tincidunt dolor. Placerat enim rid iculus id feugiat faucibus non pulvinar tincidunt. Vulputate tincidunt sed interdum interdum porta enim.</p>
                    <h2>Project Information</h2>
                    <ul class="projects-information">
                        <li>
                            <span>Date:</span>
                            January 23, 2022
                        </li>
                        <li>
                            <span>Category:</span>
                            Design, Development
                        </li>
                        <li>
                            <span>Client:</span>
                            Envytheme.com
                        </li>
                        <li>
                            <span>Duration:</span>
                            1 Month
                        </li>
                        <li>
                            <span>Budget:</span>
                            $5000
                        </li>
                    </ul>
                    <h2>Problem That We Faced</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas facilisis metus vitae mattis velit ac amet, mattis aenean. Quam eu aliquam quisque commodo <strong>feugiat</strong> placerat elit. Eget mi, morbi tincidunt dolor. Placerat enim rid iculus id feugiat faucibus non pulvinar tincidunt. Vulputate tincidunt sed interdum interdum porta enim.</p>
                    <h2>How We Overcome</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas facilisis metus vitae mattis velit ac amet, mattis aenean. Quam eu aliquam quisque commodo feugiat placerat elit. Eget mi, morbi tincidunt dolor.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas facilisis metus vitae mattis velit ac amet, mattis aenean. Quam eu aliquam quisque <a routerLink="/"></a> feugiat placerat elit. Eget mi, morbi tincidunt dolor. Placerat enim rid iculus id feugiat faucibus non pulvinar tincidunt. Vulputate tincidunt sed interdum interdum porta enim.</p>
                    <h2>Key Takeways</h2>
                    <ul class="mb-0">
                        <li>
                            <i class="fa-regular fa-circle-check"></i>
                            Don’t try to create a project without proper research.
                        </li>
                        <li>
                            <i class="fa-regular fa-circle-check"></i>
                            Create appropriate Timeframe for the Task
                        </li>
                        <li>
                            <i class="fa-regular fa-circle-check"></i>
                            Get notified when task completed &amp; handover the project in time.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>