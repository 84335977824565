declare var $: any;
//system
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//services
import { LogService } from 'src/app/services/common/log.service';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { FacilityService } from 'src/app/services/facility.service';
import { MetaService } from 'src/app/services/meta.service';

//components
import { AuthBaseComponent } from 'src/app/components/auth-base/auth-base.component';
import { ChoosePlanComponent } from 'src/app/components/popups/my-drop/choose-plan/choose-plan.component';
import { CheckoutComponent } from 'src/app/components/popups/my-drop/checkout/checkout.component';

//interfaces
import { Benefit, CurrencyMeta, Pack, MenuPack } from 'src/app/models/meta';
import { MemberCurrency, Purchase, Item, MyDrop } from 'src/app/models/member';

//utils
import { Utils } from 'src/app/services/common/utils';

@Component({
  selector: 'df-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss']
})
export class BenefitsComponent extends AuthBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  
  private readonly _unsubscribe: Subject<any> = new Subject();
  @Input() public currencyMeta! : CurrencyMeta;
  @Input() public purchase! : Purchase;
  @Input() public myDrop! : MyDrop;
  @Output() passEntry:      EventEmitter<boolean> = new EventEmitter();
  submitted:                boolean  = false;
  initialized:              boolean  = false;
  memberCurrency!:          MemberCurrency;
  subscriptionMenuIds!:     number[];
  benefits!:                Benefit[];
  filteredMenuPacks!:       MenuPack[];
  pack!:                    Pack | undefined;
  selectedMenuPack!:        MenuPack | undefined;
  modalBodyHeight:          number = 0;

  constructor(
    private router: Router
    , private logService: LogService
    , private alertService: AlertService
    , private facilityService: FacilityService
    , private metaService: MetaService
    ,  public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , private userService: UserService
    , private elRef: ElementRef
    , private renderer: Renderer2
  ) {
    super(router, logService, alertService, facilityService);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    
    const filteredMenuPackIds = this.currencyMeta.DiscountMenuPacks.map(dmp => dmp.MenuPackId);
    this.subscriptionMenuIds = this.currencyMeta.Menus.filter(m => m.IsSubscription).map(m => m.Id);
    this.filteredMenuPacks = [];
    
    for (const menu of this.currencyMeta.Menus) {
      for (const menuPack of menu.MenuPacks) {
        if (filteredMenuPackIds.includes(menuPack.Id)) {
          this.filteredMenuPacks.push(menuPack);
        }
      }
    }

    if(this.purchase && this.purchase.Items.length > 0)
    {
      for(const item of this.purchase.Items)
      {
        this.fetchPackBenefits(item);
      }
    }

    this.initialized = true;
  }

  ngAfterViewInit() {
    // Find the pack-image element using ElementRef
    const packImage = this.elRef.nativeElement.querySelector('.pack-image img');

    if (packImage) {
      // Listen for the 'load' event on the image element
      packImage.addEventListener('load', () => {
        // Find the container element
        const container = document.querySelector('.modal-dialog');

        if (container) {
          // Calculate the dynamic height of modal-body
          const containerHeight = container.clientHeight;
          const headerHeight = document.querySelector('.modal-header')?.clientHeight || 0;
          const packImageHeight = document.querySelector('.pack-image')?.clientHeight || 0; 
          const footerHeight = document.querySelector('.modal-footer')?.clientHeight || 0;
          this.modalBodyHeight = containerHeight - headerHeight - footerHeight;
        }
        // You can also set the modal-body height using Renderer2
        this.renderer.setStyle(this.elRef.nativeElement.querySelector('.modal-body'), 'height', this.modalBodyHeight + 'px');
      });
    }
  }

  fetchPackBenefits(item: Item)
  {
    const menuPack = this.filteredMenuPacks.find(mp => mp.Id === item.Id);
    if(menuPack)
    {
      this.selectedMenuPack = menuPack;
      this.pack = this.currencyMeta.Packs.find(p => p.Id === menuPack?.PackId);

      if(this.pack?.Benefits && this.pack?.Benefits.length > 0)
      {
        const benefitIds = this.pack?.Benefits.map(dmp => dmp.BenefitId);
        this.benefits = this.currencyMeta.Benefits.filter(cat => benefitIds.includes(cat.Id));
      }
    } else {
      this.benefits = [];
    }
  }

  openChoosePlan() {
    var itemId : number = 0;
    if(this.purchase && this.purchase.Items.length > 0)
    {
      for(const item of this.purchase.Items)
      {
        itemId = item.Id;
      }
    }

    const menuPack = this.filteredMenuPacks.find(mp => mp.Id === itemId);
    if(menuPack)
    {
      const isSubscription = this.subscriptionMenuIds.includes(menuPack.MenuId);
      const pack = this.currencyMeta.Packs.find(mp => mp.Id === menuPack.PackId);
      var showChoosePlan: boolean = false;
      if(pack)
      {
        if(isSubscription)
        {
          var menuPacks: number[] = [];
          for (const menu of this.currencyMeta.Menus) {
            for (const menuPack1 of menu.MenuPacks) {
              if (menuPack1.Id == menuPack.ParentId || menuPack1.ParentId == menuPack.Id ) {
                menuPacks.push(menuPack.Id);
              }
            }
          }
          
          if(menuPack.ParentId > 0 || menuPacks.length > 0)
            showChoosePlan = true;
        }
        // console.log("showChoosePlan", menuPack, this.filteredMenuPacks, itemId, isSubscription, showChoosePlan);
        if(showChoosePlan)
        {
          setTimeout(() => {
            //open class map popup
            const modalRef = this.modalService.open(ChoosePlanComponent, {
              ariaLabelledBy: 'modal-basic-title',
              size: 'lg',
              centered: false,
              windowClass: 'checkout-popup-wrapper',
              modalDialogClass: 'checkout-popup',
              fullscreen: window.innerWidth <= 768,
            });
      
            //Passing itemIds to popup
            modalRef.componentInstance.purchase = this.purchase;
            modalRef.componentInstance.currencyMeta = this.currencyMeta;
            modalRef.componentInstance.myDrop = this.myDrop;
        
            //Retrieving MemberCurrency from popup
            modalRef.result.then((result: boolean) => {
              this.logService.debug("benefits:", result);
              this.closeModal(result);
            });
          }, 1000);
        } else {
          this.openCheckoutPopup();
        }
      }
    }
  }

  openCheckoutPopup() {
    setTimeout(() => {
      //open class map popup
      const modalRef = this.modalService.open(CheckoutComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        centered: false,
        windowClass: 'checkout-popup-wrapper',
        modalDialogClass: 'checkout-popup',
        fullscreen: window.innerWidth <= 768,
      });

      //Passing itemIds to popup
      modalRef.componentInstance.purchase = this.purchase;
      modalRef.componentInstance.currencyMeta = this.currencyMeta;
      modalRef.componentInstance.myDrop = this.myDrop;
  
      //Retrieving MemberCurrency from popup
      modalRef.result.then((result: boolean) => {
        this.logService.debug("benefits:", result);
        this.closeModal(result);
      });
    }, 1000);
  }

  closeModal(result: boolean) {
    this.passEntry.emit(result);
    this.activeModal.close(result);
  }

  toUpperCase(text: string | undefined)
  {
    return text?.toUpperCase();
  }

  ngOnDestroy(): void {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
  }
}

