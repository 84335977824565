//system
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService, HttpServiceConfig } from './common/http.service';
import { HttpHeaders } from '@angular/common/http';

//services
import { LogService } from './common/log.service';

//environment
import { environment } from 'src/environments/environment';

//models
import { Person } from 'src/app/models/common-models';
import { MemberBasicInfo, MemberModel, MemberDatum, CreditMemberCurrencyRequest, MemberCurrency, Registration, MemberSetting, MyDrop } from 'src/app/models/member';
import { Discount } from '../models/meta';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  
  constructor(private logService: LogService, private httpService: HttpService) { 
    const config: HttpServiceConfig = {
      baseUrl: `${environment.facility[environment.submerchant].facilityUrl}api/v${environment.facility[environment.submerchant].facilityApiVersion}`
    };
    this.httpService.configure(config);
  }

  getMember(): Promise<MemberModel> {
    return this.httpService.getPromise<MemberModel>(`Member/Info`);
  }

  getMemberSetting(memberId:number, key: string): Promise<MemberSetting> {
    return this.httpService.getPromise<MemberSetting>(`MemberSetting/ByMemberAndKey/${memberId}/${key}`);
  }

  getPerson(): Promise<Person> {
    return this.httpService.getPromise<Person>(`Person/me`);
  }

  getCompeletedRegistrationSteps(): Promise<Registration> {
    return this.httpService.getPromise<Registration>(`Registration`);
  }

  addMemberDatum(memberDatumRequest: MemberDatum): Observable<MemberDatum> {
    return this.httpService.post<MemberDatum>(`MemberDatum`, memberDatumRequest);
  }

  creditMemberCurrency(creditMemberCurrencyRequest: CreditMemberCurrencyRequest): Observable<MemberCurrency> {
    return this.httpService.put<MemberCurrency>(`MemberCurrency/Credit`, creditMemberCurrencyRequest);
  }

  getMutual(memberId: number): Observable<MemberBasicInfo[]> {
    return this.httpService.get<MemberBasicInfo[]>(`MemberContact/Mutual/${memberId}/0/1000`);
  }

  getMyDrop(memberId: number): Promise<MyDrop> {
    return this.httpService.getPromise<MyDrop>(`Member/MyDrop/${memberId}`);
  }
  
  getDiscount(memberId: number): Promise<Discount> {
    return this.httpService.getPromise<Discount>(`Currency/Discount/ByMember/${memberId}`);
  }
}