declare var $: any;
//system
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//services
import { LogService } from 'src/app/services/common/log.service';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import { MetaService } from 'src/app/services/meta.service';

//components
import { AuthBaseComponent } from 'src/app/components/auth-base/auth-base.component';
import { UpgradeTimeComponent } from 'src/app/components/popups/my-drop/upgrade-time/upgrade-time.component';
import { CheckoutComponent } from 'src/app/components/popups/my-drop/checkout/checkout.component';

//interfaces
import { CurrencyMeta, Pack, MenuPack } from 'src/app/models/meta';
import { MemberCurrency, Purchase, Item, MyDrop, Booking } from 'src/app/models/member';
import { MembershipAction, TermUnit } from 'src/app/models/enums';

//utils
import { Utils } from 'src/app/services/common/utils';

@Component({
  selector: 'df-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.scss']
})
export class ChoosePlanComponent extends AuthBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  
  private readonly _unsubscribe: Subject<any> = new Subject();
  @Input() public currencyMeta! : CurrencyMeta;
  @Input() public purchase! : Purchase;
  @Input() public myDrop! : MyDrop;
  @Output() passEntry:      EventEmitter<boolean> = new EventEmitter();
  submitted:                boolean  = false;
  initialized:              boolean  = false;
  memberCurrency!:          MemberCurrency;
  subscriptionMenuIds!:     number[];
  filteredMenuPacks!:       MenuPack[];
  pack!:                    Pack | undefined;
  modalBodyHeight:          number = 0;
  planMenuPacks:            MenuPack[] = [];
  selectedMenuPack!:        MenuPack | undefined;
  selectedMenuPackId:       number = 0;
  selectedMenuPackIdStr:    string = "";
  currentMember!:           any;
  memberId:                 number = 0;

  constructor(
    private router: Router
    , private logService: LogService
    , private alertService: AlertService
    , private facilityService: FacilityService
    , private metaService: MetaService
    , public activeModal: NgbActiveModal
    , private authService: AuthService
    , private modalService: NgbModal
    , private userService: UserService
    , private elRef: ElementRef
    , private renderer: Renderer2
  ) {
    super(router, logService, alertService, facilityService);
  }

  override async ngOnInit() {
    await super.ngOnInit();

    this.currentMember = this.authService.getLoggedInMember();
    this.memberId = this.currentMember?.Id || 0;
    
    const filteredMenuPackIds = this.currencyMeta.DiscountMenuPacks.map(dmp => dmp.MenuPackId);
    this.subscriptionMenuIds = this.currencyMeta.Menus.filter(m => m.IsSubscription).map(m => m.Id);
    this.filteredMenuPacks = [];
    
    for (const menu of this.currencyMeta.Menus) {
      for (const menuPack of menu.MenuPacks) {
        if (filteredMenuPackIds.includes(menuPack.Id)) {
          this.filteredMenuPacks.push(menuPack);
        }
      }
    }

    if(this.purchase && this.purchase.Items.length > 0)
    {
      for(const item of this.purchase.Items)
      {
        this.fetchPlans(item);
      }
    }

    this.initialized = true;
  }

  ngAfterViewInit() {
    // Find the pack-image element using ElementRef
    const packImage = this.elRef.nativeElement.querySelector('.pack-image img');

    if (packImage) {
      // Listen for the 'load' event on the image element
      packImage.addEventListener('load', () => {
        // Find the container element
        const container = document.querySelector('.modal-dialog');

        if (container) {
          // Calculate the dynamic height of modal-body
          const containerHeight = container.clientHeight;
          const headerHeight = document.querySelector('.modal-header')?.clientHeight || 0;
          const packImageHeight = document.querySelector('.pack-image')?.clientHeight || 0; 
          const footerHeight = document.querySelector('.modal-footer')?.clientHeight || 0;
          this.modalBodyHeight = containerHeight - headerHeight - footerHeight;
        }
        // You can also set the modal-body height using Renderer2
        this.renderer.setStyle(this.elRef.nativeElement.querySelector('.modal-body'), 'height', this.modalBodyHeight + 'px');
      });
    }
  }

  fetchPlans(item: Item)
  {
    const menuPack = this.filteredMenuPacks.find(mp => mp.Id === item.Id);
    if(menuPack)
    {
      this.selectedMenuPack = menuPack;
      this.selectedMenuPackId = menuPack.Id;
      this.selectedMenuPackIdStr = this.selectedMenuPackId + "";

      this.pack = this.currencyMeta.Packs.find(p => p.Id === menuPack?.PackId);

      this.planMenuPacks.push(menuPack);
      for (const menu of this.currencyMeta.Menus) {
        const menuPacks = menu.MenuPacks.filter(cc => cc.ParentId === menuPack.Id);
        this.planMenuPacks.push(...menuPacks);
      }

      this.planMenuPacks.sort((a, b) => a.Order - b.Order);
    }
  }

  openNextPopup() {
   var booking: Booking = this.myDrop.Subscriptions.find(c => c.CurrencyId == this.selectedMenuPack?.CurrencyId && c.Action != MembershipAction.Remove) as Booking;
   if(booking && booking.Id > 0)
   {
    this.openUpgradeTimePopup();
   } else {
    this.openCheckoutPopup();
   }
  }

  openUpgradeTimePopup() {
    setTimeout(() => {
      //open class map popup
      const modalRef = this.modalService.open(UpgradeTimeComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        centered: false,
        windowClass: 'checkout-popup-wrapper',
        modalDialogClass: 'checkout-popup',
        fullscreen: window.innerWidth <= 768,
      });

      //Passing itemIds to popup
      modalRef.componentInstance.purchase = this.purchase;
      modalRef.componentInstance.currencyMeta = this.currencyMeta;
      modalRef.componentInstance.myDrop = this.myDrop;
  
      //Retrieving boolean from popup
      modalRef.result.then((result: boolean) => {
        this.logService.debug("upgrade time:", result);
        this.closeModal(result);
      });
    }, 1000);
  }

  openCheckoutPopup() {
    setTimeout(() => {
      //open class map popup
      const modalRef = this.modalService.open(CheckoutComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        centered: false,
        windowClass: 'checkout-popup-wrapper',
        modalDialogClass: 'checkout-popup',
        fullscreen: window.innerWidth <= 768,
      });

      //Passing itemIds to popup
      modalRef.componentInstance.purchase = this.purchase;
      modalRef.componentInstance.currencyMeta = this.currencyMeta;
  
      //Retrieving boolean from popup
      modalRef.result.then((result: boolean) => {
        this.logService.debug("choose plan:", result);
        this.closeModal(result);
      });
    }, 1000);
  }

  getTermDuration(menuPackId: number) {
    const menuPack = this.filteredMenuPacks.find(mp => mp.Id === menuPackId);
    if(menuPack)
    {
      return "per " + Utils.getEnumNameFromValue(TermUnit, menuPack.TermUnit).toLowerCase();
    }
    return "";
  }

  getTermUnitText(menuPackId: number) {
    const menuPack = this.filteredMenuPacks.find(mp => mp.Id === menuPackId);
    if(menuPack)
    {
      return Utils.getEnumNameFromValue(TermUnit, menuPack.TermUnit).toUpperCase() + "LY";
    }
    return "";
  }

  changePlan(menuPackId: number) {
    this.selectedMenuPackId = menuPackId;
    this.selectedMenuPackIdStr = this.selectedMenuPackId + "";
    this.purchase.Items[0].Id = this.selectedMenuPackId;
  }

  closeModal(result: boolean) {
    this.passEntry.emit(result);
    this.activeModal.close(result);
  }

  toUpperCase(text: string | undefined)
  {
    return text?.toUpperCase();
  }

  ngOnDestroy(): void {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
  }
}