<div class="modal-header">
    <span class="btn-back" data-bs-dismiss="modal" aria-label="Back" (click)="goBack()">
        &#8249;
    </span>
    <h4 class="modal-title drop-modal-title" id="modal-basic-title">CONFIRM BOOKING?</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
    <div class="banner-wrapper">
        <div class="banner-image" [ngStyle]="{'background-image': 'url(' + sessionDetails?.ClassPhoto + ')'}" [title]="sessionDetails?.ClassName"></div>
    </div>
    <div class="content-wrapper">
        <div class="row m-0">
            <div class="heading-wrapper col px-0">
                <p>YOU'RE GOING TO</p>
                <h4 class="class-name-title">{{sessionDetails?.Class?.Name}} at {{sessionDetails?.Studio?.Name}}</h4>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-sm-6 info-wrapper mt-4 px-0">
                <p class="info-title">LOCATION</p>
                <p class="info-details">{{address}}</p>
                <a [href]="directionUrl" target="_blank" class="info-link">Get directions</a>
            </div>
            <div class="col-sm-6 info-wrapper mt-4 px-0">
                <p class="info-title">RESERVATION DETAILS</p>
                <p class="info-details">{{sessionTiming}}</p>
                <p class="info-details">Spot {{selectedSpots[0]}}</p>
                <a href="javascript:void(0)" (click)="generateICS()" class="info-link">Add to calendar</a>
            </div>
        </div>
        <div class="row m-0">
            <div class="col info-wrapper mt-4 px-0">
                <p class="info-title">CONFIRMATION WILL BE SENT TO</p>
                <p class="info-details" *ngIf="person">{{person.Email}}</p>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="row">
        <div class="col-6 col-lg-6 px-0">
            <button type="button" class="btn btn-outline-dark invite-friend-button" [disabled]="inviteSubmitted" (click)="inviteFriends()">
                <div *ngIf="inviteSubmitted" class="spinner-border" role="status">
                    <span class="visually-hidden">Cancelling Spot...</span>
                </div>
                <span *ngIf="!inviteSubmitted">INVITE FRIENDS</span>
            </button>
        </div>
        <div class="col-6 col-lg-6 cancel-button-wrapper px-0">
            <button type="button" class="btn btn-dark reserve-spot-button" [disabled]="submitted" (click)="reserveSpot()">
                <div *ngIf="submitted" class="spinner-border" role="status">
                    <span class="visually-hidden">Cancelling Spot...</span>
                </div>
                <span *ngIf="!submitted">CONFIRM</span>
            </button>
        </div>
    </div>
</div>