import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MemberModel } from 'src/app/models/member';

declare var segment: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly authKey: string = 'df-auth-key';

  constructor(private _http: HttpClient) { }

  login(phoneNumber: string, dialCode: string = ''): Observable<Object> {
    segment.track(segment.TRACK_EVENTS.login, { phone: dialCode + phoneNumber });
    return this._http.post(
      environment.facility[environment.submerchant].corporateUrl + 'api/v1/User/member-signin',
      {
        phoneNumber,
        dialCode
      }
    );
  }

  signup(phoneNumber: string, dialCode: string = ''): Observable<Object> {
    segment.track(segment.TRACK_EVENTS.signup, { phone: dialCode + phoneNumber });
    return this._http.post(
      environment.facility[environment.submerchant].corporateUrl + 'api/v1/User/member-signup',
      {
        phoneNumber,
        dialCode
      }
    );
  }

  verify(phoneNumber: string, code: string, dialCode: string = ''): Observable<Object> {
    segment.track(segment.TRACK_EVENTS.verify, { phone: dialCode + phoneNumber });
    var mfaToken = phoneNumber.startsWith('555') ? 'Members' : 'passwordless';
    return this._http.post(
      environment.facility[environment.submerchant].corporateUrl + 'api/v1/User/verify',
      {
        phoneNumber,
        dialCode,
        code,
        mfaToken: mfaToken
      }
    );
  }

  getProp(name: string) {
    let propsString = window.localStorage.getItem(this.authKey);
    let props = propsString ? JSON.parse(propsString) : {};
    return props[name];
  }

  getProperty<T>(name: string): T {
      let propsString = window.localStorage.getItem(this.authKey);
      let props = propsString ? JSON.parse(propsString) : {};
      return props[name] as T;
  }

  setProp(name: string, value: any) {
    let propsString = window.localStorage.getItem(this.authKey);
    let props = propsString ? JSON.parse(propsString) : {};
    props[name] = value;
    window.localStorage.setItem(this.authKey, JSON.stringify(props));
  }
  
  getLoggedInMember() : MemberModel
  {
    return this.getProp("member");
  }
  
  logout() {
    segment.track(segment.TRACK_EVENTS.logout);
    window.localStorage.removeItem(this.authKey);
  }

  getToken(): string | null {
    return this.getProp('token');
  }
  
  isLoggedIn(): boolean {

    let token : string | null = this.getToken();
    if (token == null) return false;

    let decoded: any = jwt_decode(token);
    if (/*!this.getProp('user') || */decoded.exp * 1000 < Date.now()) {
      this.logout();
      return false;
    }
    return true;
  }

  getHeaders() {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.getToken()}`
    });

    return { headers };
  }

  async registerPhone(phoneNumber: string, dialCode: string): Promise<void> {
    return firstValueFrom(
      this._http.post(environment.facility[environment.submerchant].facilityUrl + 'api/v1/Registration/phone', {
        phoneNumber,
        dialCode
      })
    ).then(() => {});
  }

  async registerProfile(profile: any): Promise<any> {
    return firstValueFrom(
      this._http.post(environment.facility[environment.submerchant].facilityUrl + 'api/v1/Registration/profile',
        profile
      )
    ).then(() => {});
  }

  updateUser(profile: any): Observable<Object> {
    return this._http.put(
      environment.facility[environment.submerchant].corporateUrl + 'api/v1/Person/me',
      profile
    );
  }

  // updateMember(profile: any): Observable<Object> {
  //   return this._http.put(
  //     environment.facility[environment.submerchant].facilityUrl + 'api/v1/Person/me',
  //     profile
  //   );
  // }
}
