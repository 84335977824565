declare var $: any;
//system
import { Component, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
//environment
import { environment } from 'src/environments/environment';

@Component({
  selector: 'df-stripe-checkout',
  templateUrl: './stripe-checkout.component.html',
  styleUrls: ['./stripe-checkout.component.scss']
})
export class StripeCheckoutComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly _unsubscribe: Subject<any> = new Subject();
  @Input() public productName! : string;
  @Input() public productDescription! : string;
  @Input() public productImage! : string;
  @Input() public productPrice! : number;
  safeUrl: SafeResourceUrl | undefined;
  iFrameHeight: string = '100%';

  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    const env = environment.facility[environment.submerchant];
    let url = `${env.checkoutUrl}checkout.html?stripe_key=${env.stripe.publishedKey}&stripe_account=${env.stripe.accountId}&product_name=${encodeURI(this.productName)}&product_description=${encodeURI(this.productDescription)}&product_image=${encodeURI(this.productImage)}&product_price=${this.productPrice}`;
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

    let height = Math.round(window.innerHeight * 0.8);
    if (height > 880) {
      height = 880;
    }
    this.iFrameHeight = height + 'px';
  }

  ngAfterViewInit(): void {
    const modalContent = document.querySelector('.modal-content');
    if (modalContent && modalContent.firstChild instanceof HTMLElement && modalContent.firstChild.style) {
      modalContent.firstChild.style.height='100%';
    }
  }

  closeModal(result: boolean) {
    this.activeModal.close(result);
  }

  ngOnDestroy(): void {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
  }
}

