<div class="modal-header">
    <h4 class="modal-title drop-modal-title" id="modal-basic-title" *ngIf="initialized">{{toUpperCase(selectedMenuPack.Name)}}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal(false)"></button>
</div>
<div class="modal-body" [style.height.px]="modalBodyHeight">
    <div class="pack-image" [style.background-image]="'url(' + pack?.Image +')'">
        <img [src]="pack?.Image" />
    </div>
    <p class="text" *ngIf="initialized">Upgrade to {{selectedMenuPack.Name}}</p>
    <div class="timings" *ngIf="initialized">
        <div class="timing" *ngIf="selectedMenuPack" [class.selected]="selectedUpgradeNow" id="timing-now" (click)="changeTiming(true)">
            <input type="radio" name="upgrade_timing" class="timing-input" [(ngModel)]="selectedUpgradeNowStr" value="true"/>
            <p class="term-duration">UPGRADE NOW</p>
            <p class="price"><span class="decoy-price" *ngIf="selectedMenuPack.DecoyPrice > 0 && selectedMenuPack.DecoyPrice != selectedMenuPack.Price">${{selectedMenuPack.DecoyPrice/100}}</span><span *ngIf="selectedMenuPack.DecoyPrice > 0 && selectedMenuPack.DecoyPrice != selectedMenuPack.Price">&nbsp; | &nbsp;</span><span class="actual-price">${{selectedMenuPack.Price/100}}</span> {{getTermDuration(selectedMenuPack.Id)}}</p>
            <p *ngIf="selectedMenuPack.DecoyPrice > 0 && selectedMenuPack.DecoyPrice != selectedMenuPack.Price" class="tag-wrapper"><span class="tag">Save ${{(selectedMenuPack.DecoyPrice - selectedMenuPack.Price)/100}}</span></p>
            <p class="upgrade-description">Purchase now and get upgraded immediately</p>
        </div>  
        <div class="timing" *ngIf="selectedMenuPack" [class.selected]="!selectedUpgradeNow" id="timing-later" (click)="changeTiming(false)">
            <input type="radio" name="upgrade_timing" class="timing-input" [(ngModel)]="selectedUpgradeNowStr" value="false"/>
            <p class="term-duration">UPGRADE NEXT CYCLE</p>
            <p class="price"><span class="decoy-price" *ngIf="selectedMenuPack.DecoyPrice > 0 && selectedMenuPack.DecoyPrice != selectedMenuPack.Price">${{selectedMenuPack.DecoyPrice/100}}</span><span *ngIf="selectedMenuPack.DecoyPrice > 0 && selectedMenuPack.DecoyPrice != selectedMenuPack.Price">&nbsp; | &nbsp;</span><span class="actual-price">${{selectedMenuPack.Price/100}}</span> {{getTermDuration(selectedMenuPack.Id)}}</p>
            <p *ngIf="selectedMenuPack.DecoyPrice > 0 && selectedMenuPack.DecoyPrice != selectedMenuPack.Price" class="tag-wrapper"><span class="tag">Save ${{(selectedMenuPack.DecoyPrice - selectedMenuPack.Price)/100}}</span></p>
            <p class="upgrade-description">Purchase now and get upgraded on {{getNextChargableDate(nextChargableDate)}}</p>
        </div>  
    </div>
</div>
<div class="modal-footer">
    <div class="row">
        <div class="col-lg-12">
            <button type="button" class="btn btn-dark checkout-button" [disabled]="submitted" (click)="openCheckoutPopup()">
                <div *ngIf="submitted" class="spinner-border" role="status">
                    <span class="visually-hidden">Checking Out...</span>
                    </div>
                    <span *ngIf="!submitted">CHECKOUT</span>
            </button>
        </div>
    </div>
</div>
  