import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {Title, Meta} from "@angular/platform-browser";
import { DownloadAppComponent } from 'src/app/components/download-app/download-app.component';

import { Banner } from "src/app/models/common-models";

import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
    bannerObj!: Banner;
    @ViewChild('download') downloadPopup!: DownloadAppComponent;
    hideTimeout: number = 0;
    constructor(private titleService:Title
        , private meta: Meta
        , private alertService: AlertService
        , private auth: AuthService
    ) { 
        this.titleService.setTitle("Drop Fitness - Luxury Fitness. For All.");
        this.meta.updateTag({ name: "description", content: "We partnered with the best boutique fitness brands and offer their studio experience inside Drop Fitness. Use class packages across all studios. Enjoy our luxe amenities, the open gym, and bring you child to our Kids Club for free on any day you book a class." });

        this.meta.updateTag({ name: "canonical", content: "http://dropfitness.com/" });
        
        this.meta.updateTag({ name: "og:locale", content: "en_US" });
        this.meta.updateTag({ name: "og:type", content: "website" });
        this.meta.updateTag({ name: "og:title", content: "Drop Fitness - Luxury Fitness. For All." });
        this.meta.updateTag({ name: "og:description", content: "We partnered with the best boutique fitness brands and offer their studio experience inside Drop Fitness. Use class packages across all studios. Enjoy our luxe amenities, the open gym, and bring you child to our Kids Club for free on any day you book a class." });
        this.meta.updateTag({ name: "og:url", content: "http://dropfitness.com/" });
        this.meta.updateTag({ name: "og:site_name", content: "Drop Fitness" });
        
        this.meta.updateTag({ name: "twitter:card", content: "summary_large_image" });
        this.meta.updateTag({ name: "twitter:description", content: "We partnered with the best boutique fitness brands and offer their studio experience inside Drop Fitness. Use class packages across all studios. Enjoy our luxe amenities, the open gym, and bring you child to our Kids Club for free on any day you book a class." });
        this.meta.updateTag({ name: "twitter:title", content: "Drop Fitness - Luxury Fitness. For All." });
        
        this.meta.updateTag({ name: "facebook-domain-verification", content: "blo5mngf1jj25op8z6fzae8vlfz1in" });

        this.bannerObj = {
            Text : 'New members save up to $50 on memberships',
            LinkText : 'GET STARTED',
            Link : '/get-started'
        };
      }

    ngOnInit(): void {
        this.alertService.readPersistentAlerts();
    }

    ngAfterViewInit(): void {
        if (!this.auth.isLoggedIn()) {
            window.setTimeout(() => this.downloadPopup.open());
            this.hideTimeout = window.setTimeout(() => this.downloadPopup.dismiss(), 10000);
        }
    }

    dlPopupClicked() {
        if (this.hideTimeout) {
            window.clearTimeout(this.hideTimeout);
            this.hideTimeout = 0;
        }
    }

    isLoggedIn(): boolean {
        return this.auth.isLoggedIn();
    }

}