<ul class="df-toast-wrapper">
    <li *ngFor="let alert of alerts" class="toast df-toast align-items-center border-0" [ngClass]="getClass(alert.type)" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
        <div class="toast-body">
            {{ alert.message }}
        </div>
        <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" (click)="dismiss(alert.id)"></button>
        </div>
    </li>
</ul>
