<div class="newsletter-area ptb-100">
    <div class="container">
        <div class="newsletter-inner-area">
            <div class="newsletter-content">
                <span class="sub-title">Get Started Instantly!</span>
                <h2>Get the only new update from this newsletter</h2>
                <form class="newsletter-form">
                    <input type="email" class="form-control" placeholder="Enter your email" name="email">
                    <button type="submit">Subscribe!</button>
                </form>
            </div>
        </div>
    </div>
</div>