import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ConnectableObservable } from 'rxjs';
import { SmsService } from './sms.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { LoginComponent } from 'src/app/components/df-login/df-login.component';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {
  phoneForm: FormGroup<{ phone: FormControl<any>; }>;
  isSent: boolean = false;
  isDisabled: boolean = true;
  @ViewChild('login') loginPopup!: LoginComponent;

  constructor(private sms: SmsService, private auth: AuthService, private router: Router) {
    this.phoneForm = new FormGroup({
      phone: new FormControl()
    });
  }

  ngOnInit() {
  }

  onPhoneEnter(e: any) {
    let phone = this.phoneForm?.get('phone')?.value;
    if (!phone || phone.length < 14) {
      this.isDisabled = true;
      return;
    }
    this.isDisabled = false;
    if (e.code === 'Enter') {
      if (this.auth.isLoggedIn()) {
        this.sms.sendPhoneLinkSms(phone)?.pipe().subscribe(() => {
          this.router.navigateByUrl('/class-schedule');
        });
        this.isSent = true;
        return;
      }
      this.loginPopup.open(null, phone.replace(/[^0-9]/g, ''));
    }
  }

  inputChanged(evt: any) {
    // if auto-fill happened and value didn't get entered
    window.setTimeout(() => {
      if (this.phoneForm?.get('phone') && this.phoneForm.get('phone')?.value !== evt.target.value) {
        this.phoneForm.get('phone')?.setValue(evt.target.value);
        this.isDisabled = false;
      }
    }, 100);
  }

}
