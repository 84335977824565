import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { FeatureSupportService } from 'src/app/services/feature-support.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    collapseHeaderExpanded: boolean = true;
    menus: any = {};
    menuTimers: any = {};

    constructor(
        public router: Router,
        private viewportScroller: ViewportScroller,
        private _user: UserService,
        private _featureSupport: FeatureSupportService,
        private _auth: AuthService
    ) { }

    public onClick(elementId: string): void { 
        this.classApplied = false;
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit(): void {}

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    avatar() {
        if (this._auth.isLoggedIn())
            return this._user.getPhotoUrl();
        return null;
    }

    isLoggedIn() {
        return this._auth.isLoggedIn();
    }

    loginSupported(): boolean {
        return this._featureSupport.isSupported('login');
    }

    logout() {
        this.showMenu({ type: 'mouseout' }, 'acct');
        this._auth.logout();
    }

    showing() {
        this.collapseHeaderExpanded = true;
    }

    collapsing() {
        this.collapseHeaderExpanded = false;
    }

    collapseHeaderClasses() {
        return `side-nav-container mb-2${this.collapseHeaderExpanded?' expanded':' collapsed'}`;
    }

    showMenu(evt: any, menuName: string) {
        if (evt.type == 'mouseover') {
            this.menus[menuName] = 'show';
            if (this.menuTimers[menuName]) {
                clearTimeout(this.menuTimers[menuName]);
                this.menuTimers[menuName] = 0;
            }
        } else if (evt.type == 'mouseout' && this.menus[menuName]) {
            this.menuTimers[menuName] = setTimeout(() => {
                this.menus[menuName] = 'fading';
                setTimeout(() => delete this.menus[menuName], 250);
            }, 2000);
        }
    }

    menuClass(menuName: string) {
        return {
            'show': !!this.menus[menuName],
            'fadeOutMenu': this.menus[menuName] === 'fading'
        }
    }

    loggedIn() {
        window.location.reload();
    }
}