import { UserType, InviteCodeType, InviteCodeRedemptionUnit, InviteCodeIntent} from "./enums";
import { TimeStampModel } from "./common-models";

export interface InviteCode extends TimeStampModel {
    CodeType: InviteCodeType;
    UserType: UserType;
    UserId: number;
    Code: string;
    Intent: InviteCodeIntent;
    IntentId: number;
    RedemptionLimit: number;
    RedemptionUnit: InviteCodeRedemptionUnit;
}

export interface InviteCodeAccessHistory extends TimeStampModel {
    MemberId: number;
    InviteCodeId: number;
}

export interface OpenGraphMetadata {
    Title: string;
    Description: string;
    Url: string;
    ImageUrl: string;
    SiteName: string;
    Type: string;
    Locale: string;
    Keywords: string[];
    CustomMetaProperties: { [key: string]: string; };
    CustomMetaNames: { [key: string]: string; };
}

export class InviteDialogModel {
    Title: string;
    Description: string;
    ImageUrl: string;
    Url: string;
    EmailTitle: string;
    EmailBody: string;

    // Constructor
    constructor(title: string, description: string, imageUrl: string, url: string, emailTitle: string, emailBody: string) {
        this.Title = title;
        this.Description = description;
        this.ImageUrl = imageUrl;
        this.Url = url;
        this.EmailTitle = emailTitle;
        this.EmailBody = emailBody;
    }
}

export class InviteCodeGenerateRequest {
    UserType: UserType;
    UserId: number;
    Intent: InviteCodeIntent;
    IntentId: number;
    RedemptionLimit: number;

    // Constructor
    constructor(userType: UserType, userId: number, intent: InviteCodeIntent, intentId: number, redemptionLimit: number) {
        this.UserType = userType;
        this.UserId = userId;
        this.Intent = intent;
        this.IntentId = intentId;
        this.RedemptionLimit= redemptionLimit;
    }
}

export class InviteCodeRedeemRequest {
    MemberId: number;
    Code: string;

    // Constructor
    constructor(memberId: number, code: string) {
        this.MemberId = memberId;
        this.Code = code;
    }
}

export interface ShortLinkMeta {
    Id: number;
    ShortLinkId: number;
    Key: string;
    Value: string;
    ShortLink: string;
}

export interface ShortLink
{
    Id: number;
    Name: string;
    ShortCode: string;
    LongUrl: string;
    MetaData: ShortLinkMeta[];
}