//system
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService, HttpServiceConfig } from './common/http.service';
import { HttpHeaders } from '@angular/common/http';

//services
import { LogService } from './common/log.service';

//environment
import { environment } from 'src/environments/environment';

//models
import { Instructor, SessionDetails, Studio } from 'src/app/models/studio/session-details';
import { StudioDetails, InstructorDetails } from 'src/app/models/meta';
import { ClassMemberModel, ClassBookingRequest, CancelBookingRequest } from 'src/app/models/studio/class-booking';

@Injectable({
  providedIn: 'root'
})
export class StudioService {
  
  constructor(private logService: LogService, private httpService: HttpService) { 
    const config: HttpServiceConfig = {
      baseUrl: `${environment.facility[environment.submerchant].facilityUrl}api/v${environment.facility[environment.submerchant].facilityApiVersion}`
    };
    this.httpService.configure(config);
  }

  getStudios(): Observable<Studio[] | undefined> {
    return this.httpService.get<Studio[] | undefined>(`Room/Studio`);
  }

  getStudioDetails(studioId: number): Observable<StudioDetails> {
    return this.httpService.get<StudioDetails>(`Room/Studio/Details/${studioId}`);
  }

  getInstructors(): Observable<Instructor[] | undefined> {
    return this.httpService.get<Instructor[] | undefined>(`Employee/Instructor`);
  }

  getInstructorDetails(instructorId: number): Observable<InstructorDetails> {
    return this.httpService.get<InstructorDetails>(`Employee/Instructor/Details/${instructorId}`);
  }

  getClassDetails(sessionId: number): Observable<SessionDetails> {
    return this.httpService.get<SessionDetails>(`Class/Course/Session/Details/${sessionId}`);
  }

  bookSpot(classBookingRequest: ClassBookingRequest): Observable<ClassMemberModel | string> {
    return this.httpService.post<ClassMemberModel | string>(`ClassMember/Book`, classBookingRequest, new HttpHeaders(), true);
  }

  cancelSpot(classBookingRequest: CancelBookingRequest): Observable<void> {
    return this.httpService.post<void>(`ClassMember/Cancel`, classBookingRequest);
  }
}