import { Gender } from "./enums";

export interface BaseModel {
    Id:                 number;
}

export interface GuidModel extends BaseModel {
    Guid:               string;
}

export interface TimeStampModel extends GuidModel {
    CreatedAt:          Date;
    CreatedBy:          string;
}

export interface TimeStampedModel extends TimeStampModel {
    LastModified:       Date;
    LastModifiedBy:     string;
}

export interface UtcTimeModel {
    UtcTime:       Date;
    TimeStamp:     number;
}

export interface ResponseWrapper {
    StatusCode:     number;
    Message:        string;
    Details:        string;
}

export interface Person extends TimeStampedModel {
    FirstName: string;
    LastName: string;
    PreferredName: string;
    Gender: string;
    BirthDate: string;
    DialCode: string;
    PhoneNumber: string;
    Email: string;
    ZipCode: string;
    FullName: string;
    FullDetail: string;
    Initials: string;
    FirstInitial: string;
    LastInitial: string;
    PhotoUrl: string;
    GenderStr: string;
}

export class PersonModel implements Person {
    Id: number = 0;
    Guid: string = '00000000-0000-0000-0000-000000000000';
    CreatedAt: Date = new Date('0001-01-01 00:00:00.000000');
    CreatedBy: string = '00000000-0000-0000-0000-000000000000';
    LastModified: Date = new Date('0001-01-01 00:00:00.000000');
    LastModifiedBy: string = '00000000-0000-0000-0000-000000000000';
    FirstName: string = '';
    LastName: string = '';
    PreferredName: string = '';
    Gender: string = '';
    BirthDate: string = '';
    DialCode: string = '';
    PhoneNumber: string = '';
    Email: string = '';
    ZipCode: string = '';
    FullName: string = '';
    FullDetail: string = '';
    Initials: string = '';
    FirstInitial: string = '';
    LastInitial: string = '';
    PhotoUrl: string = '';
    GenderStr: string = '';
}

export class Banner {
    Text: string = '';
    LinkText: string = '';
    Link: string = '';
}

export interface Card {
    id: string;
    brand: string;
    last4: string;
    exp_month: number;
    exp_year: number;
    country: string;
    currency: string;
    name: string;
    funding: string;
    fingerprint: string;
    is_default: boolean;
}

export interface Location extends TimeStampModel {
    FacilityId: number;
    Name: string;
    Description: string;
    AddressLine1: string;
    AddressLine2: string;
    City: string;
    State: string;
    ZipCode: string;
    Country: string;
    Latitude: number;
    Longitude: number;
    TimeZone: string;
    TaxRate: number;
}

