//system
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService, HttpServiceConfig } from './common/http.service';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';

//services
import { LogService } from './common/log.service';

//environment
import { environment } from 'src/environments/environment';

//models
import { OpenGraphMetadata, InviteCode, InviteCodeGenerateRequest, InviteCodeRedeemRequest } from 'src/app/models/invite-code';

@Injectable({
  providedIn: 'root'
})
export class InviteCodeService {

  constructor(private logService: LogService, private httpService: HttpService) { 
    const config: HttpServiceConfig = {
      baseUrl: `${environment.facility[environment.submerchant].facilityUrl}api/v${environment.facility[environment.submerchant].facilityApiVersion}`
    };
    this.httpService.configure(config);
  }

  async getInviteCode(inviteCode: string): Promise<InviteCode> {
    return await this.httpService.getPromise<InviteCode>(`InviteCode/ByCode/${inviteCode}`);
  }

  async getOpenGraphMetadata(inviteCode: string): Promise<OpenGraphMetadata> {
    return await this.httpService.getPromise<OpenGraphMetadata>(`InviteCode/OpenGraphMetadata/${inviteCode}`);
  }

  generateInviteCode(inviteCodeGenerateRequest: InviteCodeGenerateRequest): Observable<InviteCode> {
    return this.httpService.post<InviteCode>(`InviteCode/Generate`, inviteCodeGenerateRequest);
  }

  redeemInviteCode(inviteCodeRedeemRequest: InviteCodeRedeemRequest): Observable<InviteCode | string> {
    return this.httpService.post<InviteCode | string>(`InviteCode/Redeem`, inviteCodeRedeemRequest, new HttpHeaders(), true);
  }
}
