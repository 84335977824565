import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  carouselSlides: OwlOptions = {
    // items: 1,
    // nav: true,
    // margin: 10,
    loop: true,
    dots: false,
    autoplay: true,
    smartSpeed: 500,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    autoplayHoverPause: false,
    // navText: [
    //   "<i class='fa-solid fa-chevron-left'></i>",
    //   "<i class='fa-solid fa-chevron-right'></i>"
    // ],
    responsive: {
      0:{
          items:1
      },
      600:{
          items:2
      },
      1000:{
          items:3
      }
    }
  }

  carouselSource = [
    {
      url: '/assets/images/df/pricing/Picture_01.jpg',
    },
    {
      url: '/assets/images/df/pricing/Picture_02.jpg',
    },
    {
      url: '/assets/images/df/pricing/Picture_03.jpg',
    },
    {
      url: '/assets/images/df/pricing/Picture_04.jpg',
    },
    {
      url: '/assets/images/df/pricing/Picture_05.jpg',
    },
    {
      url: '/assets/images/df/pricing/Picture_06.jpg',
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

  onClick(navPage: string) {

  }

}
