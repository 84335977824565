<!-- Default Navbar -->
<nav
    class="navbar-area navbar navbar-expand-lg bg-light"
    ngStickyNav
    stickyClass="sticky-box-shadow"
    [class.active]="classApplied"
>
    <div class="container">
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#leftMenu" aria-controls="leftMenu">
            <span class="burger-menu">
                <!-- <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span> -->
                <img src="/assets/images/svg/icon_mobile_drawer.svg" alt="Menu" title="Menu" />
            </span>
        </button>
        <a
            class="navbar-brand d-none"
            routerLink="/"
            [ngClass]="{'d-inline-block': true}"
        >
            <img class="logo-img" onerror="this.onerror=null; this.src='/assets/images/logo-sm.png'">
        </a>
        
        <div *ngIf="classApplied" class="others-options ms-auto">
            <a *ngIf="!isLoggedIn() && loginSupported()" class="btn btn-link login d-none d-sm-inline-block" (click)="login.open()">
                Log In
            </a>
            <a *ngIf="!isLoggedIn()" href="/get-started" class="btn default-btn-blu">
                Join Drop
            </a>
            <a *ngIf="isLoggedIn()" class="btn btn-link account-btn" href="/account">
                <img *ngIf="avatar().length != 2" [src]="avatar()" alt="account">
                <div *ngIf="avatar().length == 2" class="avatar" alt="account">{{avatar()}}</div>
            </a>
        </div>

        <div
            class="collapse navbar-collapse"
            [ngClass]="{'nav-center': true}"
        >
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" href="/purchase">Pricing</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="/class-schedule">Classes</a>
                </li>
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" href="#" (mouseover)="showMenu($event, 'classes')" (mouseout)="showMenu($event, 'classes')">Classes</a>
                    <ul class="dropdown-menu mt-4" [ngClass]="menuClass('classes')" (mouseover)="showMenu($event, 'classes')" (mouseout)="showMenu($event, 'classes')">
                        <li><a class="dropdown-item nav-link" href="/class-schedule">Class Schedule</a></li>
                        <li><a class="dropdown-item nav-link" href="/studios">Studios</a></li>
                        <li><a class="dropdown-item nav-link" href="/instructors">Instructors</a></li>
                    </ul>
                </li> -->
                <li *ngIf="!isLoggedIn() && loginSupported()" class="nav-item d-block d-sm-none">
                    <a class="nav-link" (click)="login.open()">Log In</a>
                </li>
            </ul>
        </div>
        <div *ngIf="!classApplied" class="others-options ms-auto">
            <a *ngIf="!isLoggedIn() && loginSupported()" class="btn btn-link login d-none d-sm-inline-block" (click)="login.open()">
                Log In
            </a>
            <a *ngIf="!isLoggedIn()" href="/get-started" class="btn default-btn-blu">
                Join Drop
            </a>
            <ul *ngIf="avatar()" class="navbar-nav">
                <li class="nav-item dropdown">
                    <a class="btn btn-link account-btn account-btn-mobile" data-bs-toggle="offcanvas" data-bs-target="#rightMenu" aria-controls="rightMenu" href="#">
                        <img *ngIf="avatar().length != 2" [src]="avatar()" alt="account">
                        <div *ngIf="avatar().length == 2" class="avatar" alt="account">{{avatar()}}</div>
                    </a>
                    <a class="btn btn-link account-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" href="#">
                        <!-- <img [src]="avatar()" alt="account" (mouseover)="showMenu($event, 'acct')" (mouseout)="showMenu($event, 'acct')"> -->
                        <img *ngIf="avatar().length != 2" [src]="avatar()" alt="account" (mouseover)="showMenu($event, 'acct')" (mouseout)="showMenu($event, 'acct')">
                        <div *ngIf="avatar().length == 2" class="avatar" alt="account" (mouseover)="showMenu($event, 'acct')" (mouseout)="showMenu($event, 'acct')">{{avatar()}}</div>
                    </a>
                    <ul class="dropdown-menu acct-dropdown" [ngClass]="menuClass('acct')" (mouseover)="showMenu($event, 'acct')" (mouseout)="showMenu($event, 'acct')">
                        <li><a class="dropdown-item nav-link" href="/account">Account</a></li>
                        <li><a class="dropdown-item nav-link" href="#" (click)="logout()">Log Out</a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>

<div class="offcanvas offcanvas-start w-75" data-bs-scroll="true" tabindex="-1" id="leftMenu" aria-labelledby="leftMenuLabel">
    <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasWithBothOptionsLabel"></h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body p-0">
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="side-nav nav-link" href="/purchase">Pricing</a>
            </li>
            <li class="nav-item">
                <a class="side-nav nav-link" href="/class-schedule">Class Schedule</a>
            </li>
            <!-- <li class="side-nav nav-item">
                <div [class]="collapseHeaderClasses()" data-bs-toggle="collapse" data-bs-target="#subClassesMenu" aria-expanded="true" aria-controls="subClassesMenu">
                    Classes
                </div>
                <div class="collapse show" id="subClassesMenu" (hide.bs.collapse)="collapsing()" (show.bs.collapse)="showing()">
                    <a class="side-sub-nav nav-link" href="/class-schedule">Class Schedule</a>
                    <a class="side-sub-nav nav-link" href="/studios">Studios</a>
                    <a class="side-sub-nav nav-link" href="/instructors">Instructors</a>
                </div>
            </li> -->
            <li class="side-nav">
                <div class="container p-0">
                    <div class="row">
                        <div class="col-6" *ngIf="loginSupported()">
                            <a *ngIf="!isLoggedIn()" class="side-sub-nav nav-link" (click)="login.open()" data-bs-toggle="offcanvas" data-bs-target="#leftMenu" aria-controls="leftMenu">Log In</a>
                            <a *ngIf="isLoggedIn()" class="side-sub-nav nav-link" (click)="logout()" href="/">Log Out</a>
                        </div>
                        <div class="col-6">
                            <a class="side-sub-nav nav-link" href="/privacy" target="_blank">Privacy Policy</a>
                        </div>
                        <div class="col-6">
                            <a class="side-sub-nav nav-link" href="/terms" target="_blank">Terms & Conditions</a>
                        </div>
                        <div class="col-6">
                            <a class="side-sub-nav nav-link" href="/faq">FAQ</a>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div class="container text-center">
                    <div class="row pt-4">
                        <div class="col">
                            <a href="https://www.instagram.com/dropfitness" target="_blank">
                                <i class="fa-brands fa-instagram"style="font-size:24px"></i>
                            </a>
                        </div>
                        <div class="col">
                            <a href="https://www.facebook.com/dropfitnessmontvale" target="_blank">
                                <i class="fa-brands fa-facebook-f"style="font-size:24px"></i>
                            </a>
                        </div>
                        <div class="col">
                            <a href="https://www.tiktok.com/@dropfitnessnj" target="_blank">
                                <i class="fa-brands fa-tiktok"style="font-size:24px"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>

<div class="offcanvas offcanvas-end w-75" data-bs-scroll="true" tabindex="-1" id="rightMenu" aria-labelledby="rightMenuLabel">
    <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasWithBothOptionsLabel"></h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body p-0">
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="side-nav nav-link" href="/account">Account</a>
            </li>
            <li class="nav-item">
                <a class="side-nav nav-link" (click)="logout()" href="/">Log Out</a>
            </li>
        </ul>
    </div>
</div>

<df-login #login title="Log into your account" (loggedIn)="loggedIn()"></df-login>

<div class="navbar-height">&nbsp;</div>