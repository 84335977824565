import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment-link',
  templateUrl: './payment-link.component.html',
  styleUrls: ['./payment-link.component.scss']
})
export class PaymentLinkComponent implements OnInit, OnDestroy {

  constructor(private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    let code = this.activeRoute.snapshot.queryParams['code'];
    if (!code) {
      window.location.href = '/';
      return;
    }

    window.location.href = `/purchase?short_code=${code}`;
    return;
  }

  ngOnDestroy(): void {
  }
}