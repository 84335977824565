import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { ViewportScroller } from '@angular/common';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { CarouselService } from 'ngx-owl-carousel-o/lib/services/carousel.service';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnDestroy {
	
	resizeObservable$!: Observable<Event>;
	resizeSubscription$!: Subscription;

	@ViewChild('carousel', { static: true }) carousel!: CarouselComponent;

	homeSlides: OwlOptions = {
		items: 1,
		nav: false,
		loop: true,
		autoWidth: true,
		responsive: {},
		dots: true,
		autoplay: true,
		smartSpeed: 500,
		animateIn: 'fadeIn',
		animateOut: 'fadeOut',
		autoplayHoverPause: false,
		navText: [
			"<i class='fa-solid fa-chevron-left'></i>",
			"<i class='fa-solid fa-chevron-right'></i>"
		]
	}
	
	constructor(
			private viewportScroller: ViewportScroller
	) { }

	public onClick(elementId: string): void { 
			this.viewportScroller.scrollToAnchor(elementId);
	}

	ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
			this.onResize(evt);
    })
	}
	
	ngOnDestroy() {
    this.resizeSubscription$.unsubscribe()
	}

	onResize(evt: any) {
		const anyService = this.carousel as any;
		const carouselService = anyService.carouselService as CarouselService;
		carouselService.setCarouselWidth(evt.target.innerWidth);
		// carouselService.onResize(evt.target.innerWidth);

    // carouselService.refresh();
    // carouselService.update();
		this.homeSlides = {...this.homeSlides};
	}

}