export class CountryOptions {
  static flagsUrl: string = 'https://dropfitness-static.nyc3.digitaloceanspaces.com/static/svg/';
  static countries: any[] = [
    {
        "capital": "Kabul",
        "code": "af",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/af.svg",
        "flag_4x3": "flags/4x3/af.svg",
        "iso": true,
        "name": "Afghanistan",
        "dialCode": "+93"
    },
    {
        "capital": "Tirana",
        "code": "al",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/al.svg",
        "flag_4x3": "flags/4x3/al.svg",
        "iso": true,
        "name": "Albania",
        "dialCode": "+355"
    },
    {
        "capital": "Algiers",
        "code": "dz",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/dz.svg",
        "flag_4x3": "flags/4x3/dz.svg",
        "iso": true,
        "name": "Algeria",
        "dialCode": "+213"
    },
    {
        "capital": "Pago Pago",
        "code": "as",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/as.svg",
        "flag_4x3": "flags/4x3/as.svg",
        "iso": true,
        "name": "American Samoa",
        "dialCode": "+1684"
    },
    {
        "capital": "Andorra la Vella",
        "code": "ad",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/ad.svg",
        "flag_4x3": "flags/4x3/ad.svg",
        "iso": true,
        "name": "Andorra",
        "dialCode": "+376"
    },
    {
        "capital": "Luanda",
        "code": "ao",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/ao.svg",
        "flag_4x3": "flags/4x3/ao.svg",
        "iso": true,
        "name": "Angola",
        "dialCode": "+244"
    },
    {
        "capital": "The Valley",
        "code": "ai",
        "continent": "North America",
        "flag_1x1": "flags/1x1/ai.svg",
        "flag_4x3": "flags/4x3/ai.svg",
        "iso": true,
        "name": "Anguilla",
        "dialCode": "+1264"
    },
    {
        "capital": "St. John's",
        "code": "ag",
        "continent": "North America",
        "flag_1x1": "flags/1x1/ag.svg",
        "flag_4x3": "flags/4x3/ag.svg",
        "iso": true,
        "name": "Antigua and Barbuda",
        "dialCode": "+1268"
    },
    {
        "capital": "Buenos Aires",
        "code": "ar",
        "continent": "South America",
        "flag_1x1": "flags/1x1/ar.svg",
        "flag_4x3": "flags/4x3/ar.svg",
        "iso": true,
        "name": "Argentina",
        "dialCode": "+54"
    },
    {
        "capital": "Yerevan",
        "code": "am",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/am.svg",
        "flag_4x3": "flags/4x3/am.svg",
        "iso": true,
        "name": "Armenia",
        "dialCode": "+374"
    },
    {
        "capital": "Oranjestad",
        "code": "aw",
        "continent": "South America",
        "flag_1x1": "flags/1x1/aw.svg",
        "flag_4x3": "flags/4x3/aw.svg",
        "iso": true,
        "name": "Aruba",
        "dialCode": "+297"
    },
    {
        "capital": "Canberra",
        "code": "au",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/au.svg",
        "flag_4x3": "flags/4x3/au.svg",
        "iso": true,
        "name": "Australia",
        "dialCode": "+61"
    },
    {
        "capital": "Vienna",
        "code": "at",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/at.svg",
        "flag_4x3": "flags/4x3/at.svg",
        "iso": true,
        "name": "Austria",
        "dialCode": "+43"
    },
    {
        "capital": "Baku",
        "code": "az",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/az.svg",
        "flag_4x3": "flags/4x3/az.svg",
        "iso": true,
        "name": "Azerbaijan",
        "dialCode": "+994"
    },
    {
        "capital": "Nassau",
        "code": "bs",
        "continent": "North America",
        "flag_1x1": "flags/1x1/bs.svg",
        "flag_4x3": "flags/4x3/bs.svg",
        "iso": true,
        "name": "Bahamas",
        "dialCode": "+1242"
    },
    {
        "capital": "Manama",
        "code": "bh",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/bh.svg",
        "flag_4x3": "flags/4x3/bh.svg",
        "iso": true,
        "name": "Bahrain",
        "dialCode": "+973"
    },
    {
        "capital": "Dhaka",
        "code": "bd",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/bd.svg",
        "flag_4x3": "flags/4x3/bd.svg",
        "iso": true,
        "name": "Bangladesh",
        "dialCode": "+880"
    },
    {
        "capital": "Bridgetown",
        "code": "bb",
        "continent": "North America",
        "flag_1x1": "flags/1x1/bb.svg",
        "flag_4x3": "flags/4x3/bb.svg",
        "iso": true,
        "name": "Barbados",
        "dialCode": "+1246"
    },
    {
        "capital": "Minsk",
        "code": "by",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/by.svg",
        "flag_4x3": "flags/4x3/by.svg",
        "iso": true,
        "name": "Belarus",
        "dialCode": "+375"
    },
    {
        "capital": "Brussels",
        "code": "be",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/be.svg",
        "flag_4x3": "flags/4x3/be.svg",
        "iso": true,
        "name": "Belgium",
        "dialCode": "+32"
    },
    {
        "capital": "Belmopan",
        "code": "bz",
        "continent": "North America",
        "flag_1x1": "flags/1x1/bz.svg",
        "flag_4x3": "flags/4x3/bz.svg",
        "iso": true,
        "name": "Belize",
        "dialCode": "+501"
    },
    {
        "capital": "Porto-Novo",
        "code": "bj",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/bj.svg",
        "flag_4x3": "flags/4x3/bj.svg",
        "iso": true,
        "name": "Benin",
        "dialCode": "+229"
    },
    {
        "capital": "Hamilton",
        "code": "bm",
        "continent": "North America",
        "flag_1x1": "flags/1x1/bm.svg",
        "flag_4x3": "flags/4x3/bm.svg",
        "iso": true,
        "name": "Bermuda",
        "dialCode": "+1441"
    },
    {
        "capital": "Thimphu",
        "code": "bt",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/bt.svg",
        "flag_4x3": "flags/4x3/bt.svg",
        "iso": true,
        "name": "Bhutan",
        "dialCode": "+975"
    },
    {
        "capital": "Sucre",
        "code": "bo",
        "continent": "South America",
        "flag_1x1": "flags/1x1/bo.svg",
        "flag_4x3": "flags/4x3/bo.svg",
        "iso": true,
        "name": "Bolivia",
        "dialCode": "+591"
    },
    {
        "capital": "Sarajevo",
        "code": "ba",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/ba.svg",
        "flag_4x3": "flags/4x3/ba.svg",
        "iso": true,
        "name": "Bosnia and Herzegovina",
        "dialCode": "+387"
    },
    {
        "capital": "Gaborone",
        "code": "bw",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/bw.svg",
        "flag_4x3": "flags/4x3/bw.svg",
        "iso": true,
        "name": "Botswana",
        "dialCode": "+267"
    },
    {
        "capital": "Brasília",
        "code": "br",
        "continent": "South America",
        "flag_1x1": "flags/1x1/br.svg",
        "flag_4x3": "flags/4x3/br.svg",
        "iso": true,
        "name": "Brazil",
        "dialCode": "+55"
    },
    {
        "capital": "Diego Garcia",
        "code": "io",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/io.svg",
        "flag_4x3": "flags/4x3/io.svg",
        "iso": true,
        "name": "British Indian Ocean Territory",
        "dialCode": "+246"
    },
    {
        "capital": "Bandar Seri Begawan",
        "code": "bn",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/bn.svg",
        "flag_4x3": "flags/4x3/bn.svg",
        "iso": true,
        "name": "Brunei Darussalam",
        "dialCode": "+673"
    },
    {
        "capital": "Sofia",
        "code": "bg",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/bg.svg",
        "flag_4x3": "flags/4x3/bg.svg",
        "iso": true,
        "name": "Bulgaria",
        "dialCode": "+359"
    },
    {
        "capital": "Ouagadougou",
        "code": "bf",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/bf.svg",
        "flag_4x3": "flags/4x3/bf.svg",
        "iso": true,
        "name": "Burkina Faso",
        "dialCode": "+226"
    },
    {
        "capital": "Bujumbura",
        "code": "bi",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/bi.svg",
        "flag_4x3": "flags/4x3/bi.svg",
        "iso": true,
        "name": "Burundi",
        "dialCode": "+257"
    },
    {
        "capital": "Phnom Penh",
        "code": "kh",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/kh.svg",
        "flag_4x3": "flags/4x3/kh.svg",
        "iso": true,
        "name": "Cambodia",
        "dialCode": "+855"
    },
    {
        "capital": "Yaoundé",
        "code": "cm",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/cm.svg",
        "flag_4x3": "flags/4x3/cm.svg",
        "iso": true,
        "name": "Cameroon",
        "dialCode": "+237"
    },
    {
        "capital": "Ottawa",
        "code": "ca",
        "continent": "North America",
        "flag_1x1": "flags/1x1/ca.svg",
        "flag_4x3": "flags/4x3/ca.svg",
        "iso": true,
        "name": "Canada",
        "dialCode": "+1"
    },
    {
        "capital": "Praia",
        "code": "cv",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/cv.svg",
        "flag_4x3": "flags/4x3/cv.svg",
        "iso": true,
        "name": "Cabo Verde",
        "dialCode": "+238"
    },
    {
        "capital": "George Town",
        "code": "ky",
        "continent": "North America",
        "flag_1x1": "flags/1x1/ky.svg",
        "flag_4x3": "flags/4x3/ky.svg",
        "iso": true,
        "name": "Cayman Islands",
        "dialCode": "+1345"
    },
    {
        "capital": "Bangui",
        "code": "cf",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/cf.svg",
        "flag_4x3": "flags/4x3/cf.svg",
        "iso": true,
        "name": "Central African Republic",
        "dialCode": "+236"
    },
    {
        "capital": "N'Djamena",
        "code": "td",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/td.svg",
        "flag_4x3": "flags/4x3/td.svg",
        "iso": true,
        "name": "Chad",
        "dialCode": "+235"
    },
    {
        "capital": "Santiago",
        "code": "cl",
        "continent": "South America",
        "flag_1x1": "flags/1x1/cl.svg",
        "flag_4x3": "flags/4x3/cl.svg",
        "iso": true,
        "name": "Chile",
        "dialCode": "+56"
    },
    {
        "capital": "Beijing",
        "code": "cn",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/cn.svg",
        "flag_4x3": "flags/4x3/cn.svg",
        "iso": true,
        "name": "China",
        "dialCode": "+86"
    },
    {
        "capital": "Flying Fish Cove",
        "code": "cx",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/cx.svg",
        "flag_4x3": "flags/4x3/cx.svg",
        "iso": true,
        "name": "Christmas Island",
        "dialCode": "+61"
    },
    {
        "capital": "West Island",
        "code": "cc",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/cc.svg",
        "flag_4x3": "flags/4x3/cc.svg",
        "iso": true,
        "name": "Cocos (Keeling) Islands",
        "dialCode": "+672"
    },
    {
        "capital": "Bogotá",
        "code": "co",
        "continent": "South America",
        "flag_1x1": "flags/1x1/co.svg",
        "flag_4x3": "flags/4x3/co.svg",
        "iso": true,
        "name": "Colombia",
        "dialCode": "+57"
    },
    {
        "capital": "Moroni",
        "code": "km",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/km.svg",
        "flag_4x3": "flags/4x3/km.svg",
        "iso": true,
        "name": "Comoros",
        "dialCode": "+269"
    },
    {
        "capital": "Brazzaville",
        "code": "cg",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/cg.svg",
        "flag_4x3": "flags/4x3/cg.svg",
        "iso": true,
        "name": "Republic of the Congo",
        "dialCode": "+242"
    },
    {
        "capital": "Kinshasa",
        "code": "cd",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/cd.svg",
        "flag_4x3": "flags/4x3/cd.svg",
        "iso": true,
        "name": "Democratic Republic of the Congo",
        "dialCode": "+242"
    },
    {
        "capital": "Avarua",
        "code": "ck",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/ck.svg",
        "flag_4x3": "flags/4x3/ck.svg",
        "iso": true,
        "name": "Cook Islands",
        "dialCode": "+682"
    },
    {
        "capital": "San José",
        "code": "cr",
        "continent": "North America",
        "flag_1x1": "flags/1x1/cr.svg",
        "flag_4x3": "flags/4x3/cr.svg",
        "iso": true,
        "name": "Costa Rica",
        "dialCode": "+506"
    },
    {
        "capital": "Yamoussoukro",
        "code": "ci",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/ci.svg",
        "flag_4x3": "flags/4x3/ci.svg",
        "iso": true,
        "name": "Côte d'Ivoire",
        "dialCode": "+225"
    },
    {
        "capital": "Zagreb",
        "code": "hr",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/hr.svg",
        "flag_4x3": "flags/4x3/hr.svg",
        "iso": true,
        "name": "Croatia",
        "dialCode": "+385"
    },
    {
        "capital": "Havana",
        "code": "cu",
        "continent": "North America",
        "flag_1x1": "flags/1x1/cu.svg",
        "flag_4x3": "flags/4x3/cu.svg",
        "iso": true,
        "name": "Cuba",
        "dialCode": "+53"
    },
    {
        "capital": "Nicosia",
        "code": "cy",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/cy.svg",
        "flag_4x3": "flags/4x3/cy.svg",
        "iso": true,
        "name": "Cyprus",
        "dialCode": "+357"
    },
    {
        "capital": "Prague",
        "code": "cz",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/cz.svg",
        "flag_4x3": "flags/4x3/cz.svg",
        "iso": true,
        "name": "Czech Republic",
        "dialCode": "+420"
    },
    {
        "capital": "Copenhagen",
        "code": "dk",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/dk.svg",
        "flag_4x3": "flags/4x3/dk.svg",
        "iso": true,
        "name": "Denmark",
        "dialCode": "+45"
    },
    {
        "capital": "Djibouti",
        "code": "dj",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/dj.svg",
        "flag_4x3": "flags/4x3/dj.svg",
        "iso": true,
        "name": "Djibouti",
        "dialCode": "+253"
    },
    {
        "capital": "Roseau",
        "code": "dm",
        "continent": "North America",
        "flag_1x1": "flags/1x1/dm.svg",
        "flag_4x3": "flags/4x3/dm.svg",
        "iso": true,
        "name": "Dominica",
        "dialCode": "+1767"
    },
    {
        "capital": "Santo Domingo",
        "code": "do",
        "continent": "North America",
        "flag_1x1": "flags/1x1/do.svg",
        "flag_4x3": "flags/4x3/do.svg",
        "iso": true,
        "name": "Dominican Republic",
        "dialCode": "+1809"
    },
    {
        "capital": "Quito",
        "code": "ec",
        "continent": "South America",
        "flag_1x1": "flags/1x1/ec.svg",
        "flag_4x3": "flags/4x3/ec.svg",
        "iso": true,
        "name": "Ecuador",
        "dialCode": "+593"
    },
    {
        "capital": "Cairo",
        "code": "eg",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/eg.svg",
        "flag_4x3": "flags/4x3/eg.svg",
        "iso": true,
        "name": "Egypt",
        "dialCode": "+20"
    },
    {
        "capital": "San Salvador",
        "code": "sv",
        "continent": "North America",
        "flag_1x1": "flags/1x1/sv.svg",
        "flag_4x3": "flags/4x3/sv.svg",
        "iso": true,
        "name": "El Salvador",
        "dialCode": "+503"
    },
    {
        "capital": "Malabo",
        "code": "gq",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/gq.svg",
        "flag_4x3": "flags/4x3/gq.svg",
        "iso": true,
        "name": "Equatorial Guinea",
        "dialCode": "+240"
    },
    {
        "capital": "Asmara",
        "code": "er",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/er.svg",
        "flag_4x3": "flags/4x3/er.svg",
        "iso": true,
        "name": "Eritrea",
        "dialCode": "+291"
    },
    {
        "capital": "Tallinn",
        "code": "ee",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/ee.svg",
        "flag_4x3": "flags/4x3/ee.svg",
        "iso": true,
        "name": "Estonia",
        "dialCode": "+372"
    },
    {
        "capital": "Addis Ababa",
        "code": "et",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/et.svg",
        "flag_4x3": "flags/4x3/et.svg",
        "iso": true,
        "name": "Ethiopia",
        "dialCode": "+251"
    },
    {
        "capital": "Stanley",
        "code": "fk",
        "continent": "South America",
        "flag_1x1": "flags/1x1/fk.svg",
        "flag_4x3": "flags/4x3/fk.svg",
        "iso": true,
        "name": "Falkland Islands",
        "dialCode": "+500"
    },
    {
        "capital": "Tórshavn",
        "code": "fo",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/fo.svg",
        "flag_4x3": "flags/4x3/fo.svg",
        "iso": true,
        "name": "Faroe Islands",
        "dialCode": "+298"
    },
    {
        "capital": "Suva",
        "code": "fj",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/fj.svg",
        "flag_4x3": "flags/4x3/fj.svg",
        "iso": true,
        "name": "Fiji",
        "dialCode": "+679"
    },
    {
        "capital": "Helsinki",
        "code": "fi",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/fi.svg",
        "flag_4x3": "flags/4x3/fi.svg",
        "iso": true,
        "name": "Finland",
        "dialCode": "+358"
    },
    {
        "capital": "Paris",
        "code": "fr",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/fr.svg",
        "flag_4x3": "flags/4x3/fr.svg",
        "iso": true,
        "name": "France",
        "dialCode": "+33"
    },
    {
        "capital": "Cayenne",
        "code": "gf",
        "continent": "South America",
        "flag_1x1": "flags/1x1/gf.svg",
        "flag_4x3": "flags/4x3/gf.svg",
        "iso": true,
        "name": "French Guiana",
        "dialCode": "+594"
    },
    {
        "capital": "Papeete",
        "code": "pf",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/pf.svg",
        "flag_4x3": "flags/4x3/pf.svg",
        "iso": true,
        "name": "French Polynesia",
        "dialCode": "+689"
    },
    {
        "capital": "Libreville",
        "code": "ga",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/ga.svg",
        "flag_4x3": "flags/4x3/ga.svg",
        "iso": true,
        "name": "Gabon",
        "dialCode": "+241"
    },
    {
        "capital": "Banjul",
        "code": "gm",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/gm.svg",
        "flag_4x3": "flags/4x3/gm.svg",
        "iso": true,
        "name": "Gambia",
        "dialCode": "+220"
    },
    {
        "capital": "Tbilisi",
        "code": "ge",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/ge.svg",
        "flag_4x3": "flags/4x3/ge.svg",
        "iso": true,
        "name": "Georgia",
        "dialCode": "+995"
    },
    {
        "capital": "Berlin",
        "code": "de",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/de.svg",
        "flag_4x3": "flags/4x3/de.svg",
        "iso": true,
        "name": "Germany",
        "dialCode": "+49"
    },
    {
        "capital": "Accra",
        "code": "gh",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/gh.svg",
        "flag_4x3": "flags/4x3/gh.svg",
        "iso": true,
        "name": "Ghana",
        "dialCode": "+233"
    },
    {
        "capital": "Gibraltar",
        "code": "gi",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/gi.svg",
        "flag_4x3": "flags/4x3/gi.svg",
        "iso": true,
        "name": "Gibraltar",
        "dialCode": "+350"
    },
    {
        "capital": "Athens",
        "code": "gr",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/gr.svg",
        "flag_4x3": "flags/4x3/gr.svg",
        "iso": true,
        "name": "Greece",
        "dialCode": "+30"
    },
    {
        "capital": "Nuuk",
        "code": "gl",
        "continent": "North America",
        "flag_1x1": "flags/1x1/gl.svg",
        "flag_4x3": "flags/4x3/gl.svg",
        "iso": true,
        "name": "Greenland",
        "dialCode": "+299"
    },
    {
        "capital": "St. George's",
        "code": "gd",
        "continent": "North America",
        "flag_1x1": "flags/1x1/gd.svg",
        "flag_4x3": "flags/4x3/gd.svg",
        "iso": true,
        "name": "Grenada",
        "dialCode": "+1473"
    },
    {
        "capital": "Basse-Terre",
        "code": "gp",
        "continent": "North America",
        "flag_1x1": "flags/1x1/gp.svg",
        "flag_4x3": "flags/4x3/gp.svg",
        "iso": true,
        "name": "Guadeloupe",
        "dialCode": "+590"
    },
    {
        "capital": "Hagåtña",
        "code": "gu",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/gu.svg",
        "flag_4x3": "flags/4x3/gu.svg",
        "iso": true,
        "name": "Guam",
        "dialCode": "+1671"
    },
    {
        "capital": "Guatemala City",
        "code": "gt",
        "continent": "North America",
        "flag_1x1": "flags/1x1/gt.svg",
        "flag_4x3": "flags/4x3/gt.svg",
        "iso": true,
        "name": "Guatemala",
        "dialCode": "+502"
    },
    {
        "capital": "Conakry",
        "code": "gn",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/gn.svg",
        "flag_4x3": "flags/4x3/gn.svg",
        "iso": true,
        "name": "Guinea",
        "dialCode": "+224"
    },
    {
        "capital": "Bissau",
        "code": "gw",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/gw.svg",
        "flag_4x3": "flags/4x3/gw.svg",
        "iso": true,
        "name": "Guinea-Bissau",
        "dialCode": "+245"
    },
    {
        "capital": "Georgetown",
        "code": "gy",
        "continent": "South America",
        "flag_1x1": "flags/1x1/gy.svg",
        "flag_4x3": "flags/4x3/gy.svg",
        "iso": true,
        "name": "Guyana",
        "dialCode": "+592"
    },
    {
        "capital": "Port-au-Prince",
        "code": "ht",
        "continent": "North America",
        "flag_1x1": "flags/1x1/ht.svg",
        "flag_4x3": "flags/4x3/ht.svg",
        "iso": true,
        "name": "Haiti",
        "dialCode": "+509"
    },
    {
        "capital": "Vatican City",
        "code": "va",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/va.svg",
        "flag_4x3": "flags/4x3/va.svg",
        "iso": true,
        "name": "Holy See",
        "dialCode": "+39"
    },
    {
        "capital": "Tegucigalpa",
        "code": "hn",
        "continent": "North America",
        "flag_1x1": "flags/1x1/hn.svg",
        "flag_4x3": "flags/4x3/hn.svg",
        "iso": true,
        "name": "Honduras",
        "dialCode": "+504"
    },
    {
        "capital": "Hong Kong",
        "code": "hk",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/hk.svg",
        "flag_4x3": "flags/4x3/hk.svg",
        "iso": true,
        "name": "Hong Kong",
        "dialCode": "+852"
    },
    {
        "capital": "Budapest",
        "code": "hu",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/hu.svg",
        "flag_4x3": "flags/4x3/hu.svg",
        "iso": true,
        "name": "Hungary",
        "dialCode": "+36"
    },
    {
        "capital": "Reykjavik",
        "code": "is",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/is.svg",
        "flag_4x3": "flags/4x3/is.svg",
        "iso": true,
        "name": "Iceland",
        "dialCode": "+354"
    },
    {
        "capital": "New Delhi",
        "code": "in",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/in.svg",
        "flag_4x3": "flags/4x3/in.svg",
        "iso": true,
        "name": "India",
        "dialCode": "+91"
    },
    {
        "capital": "Jakarta",
        "code": "id",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/id.svg",
        "flag_4x3": "flags/4x3/id.svg",
        "iso": true,
        "name": "Indonesia",
        "dialCode": "+62"
    },
    {
        "capital": "Tehran",
        "code": "ir",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/ir.svg",
        "flag_4x3": "flags/4x3/ir.svg",
        "iso": true,
        "name": "Iran",
        "dialCode": "+98"
    },
    {
        "capital": "Baghdad",
        "code": "iq",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/iq.svg",
        "flag_4x3": "flags/4x3/iq.svg",
        "iso": true,
        "name": "Iraq",
        "dialCode": "+964"
    },
    {
        "capital": "Dublin",
        "code": "ie",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/ie.svg",
        "flag_4x3": "flags/4x3/ie.svg",
        "iso": true,
        "name": "Ireland",
        "dialCode": "+353"
    },
    {
        "capital": "Jerusalem",
        "code": "il",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/il.svg",
        "flag_4x3": "flags/4x3/il.svg",
        "iso": true,
        "name": "Israel",
        "dialCode": "+972"
    },
    {
        "capital": "Rome",
        "code": "it",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/it.svg",
        "flag_4x3": "flags/4x3/it.svg",
        "iso": true,
        "name": "Italy",
        "dialCode": "+39"
    },
    {
        "capital": "Kingston",
        "code": "jm",
        "continent": "North America",
        "flag_1x1": "flags/1x1/jm.svg",
        "flag_4x3": "flags/4x3/jm.svg",
        "iso": true,
        "name": "Jamaica",
        "dialCode": "+1876"
    },
    {
        "capital": "Tokyo",
        "code": "jp",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/jp.svg",
        "flag_4x3": "flags/4x3/jp.svg",
        "iso": true,
        "name": "Japan",
        "dialCode": "+81"
    },
    {
        "capital": "Amman",
        "code": "jo",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/jo.svg",
        "flag_4x3": "flags/4x3/jo.svg",
        "iso": true,
        "name": "Jordan",
        "dialCode": "+962"
    },
    {
        "capital": "Astana",
        "code": "kz",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/kz.svg",
        "flag_4x3": "flags/4x3/kz.svg",
        "iso": true,
        "name": "Kazakhstan",
        "dialCode": "+7"
    },
    {
        "capital": "Nairobi",
        "code": "ke",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/ke.svg",
        "flag_4x3": "flags/4x3/ke.svg",
        "iso": true,
        "name": "Kenya",
        "dialCode": "+254"
    },
    {
        "capital": "South Tarawa",
        "code": "ki",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/ki.svg",
        "flag_4x3": "flags/4x3/ki.svg",
        "iso": true,
        "name": "Kiribati",
        "dialCode": "+686"
    },
    {
        "capital": "Pyongyang",
        "code": "kp",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/kp.svg",
        "flag_4x3": "flags/4x3/kp.svg",
        "iso": true,
        "name": "North Korea",
        "dialCode": "+850"
    },
    {
        "capital": "Seoul",
        "code": "kr",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/kr.svg",
        "flag_4x3": "flags/4x3/kr.svg",
        "iso": true,
        "name": "South Korea",
        "dialCode": "+82"
    },
    {
        "capital": "Kuwait City",
        "code": "kw",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/kw.svg",
        "flag_4x3": "flags/4x3/kw.svg",
        "iso": true,
        "name": "Kuwait",
        "dialCode": "+965"
    },
    {
        "capital": "Bishkek",
        "code": "kg",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/kg.svg",
        "flag_4x3": "flags/4x3/kg.svg",
        "iso": true,
        "name": "Kyrgyzstan",
        "dialCode": "+996"
    },
    {
        "capital": "Vientiane",
        "code": "la",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/la.svg",
        "flag_4x3": "flags/4x3/la.svg",
        "iso": true,
        "name": "Laos",
        "dialCode": "+856"
    },
    {
        "capital": "Riga",
        "code": "lv",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/lv.svg",
        "flag_4x3": "flags/4x3/lv.svg",
        "iso": true,
        "name": "Latvia",
        "dialCode": "+371"
    },
    {
        "capital": "Beirut",
        "code": "lb",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/lb.svg",
        "flag_4x3": "flags/4x3/lb.svg",
        "iso": true,
        "name": "Lebanon",
        "dialCode": "+961"
    },
    {
        "capital": "Maseru",
        "code": "ls",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/ls.svg",
        "flag_4x3": "flags/4x3/ls.svg",
        "iso": true,
        "name": "Lesotho",
        "dialCode": "+266"
    },
    {
        "capital": "Monrovia",
        "code": "lr",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/lr.svg",
        "flag_4x3": "flags/4x3/lr.svg",
        "iso": true,
        "name": "Liberia",
        "dialCode": "+231"
    },
    {
        "capital": "Tripoli",
        "code": "ly",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/ly.svg",
        "flag_4x3": "flags/4x3/ly.svg",
        "iso": true,
        "name": "Libya",
        "dialCode": "+218"
    },
    {
        "capital": "Vaduz",
        "code": "li",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/li.svg",
        "flag_4x3": "flags/4x3/li.svg",
        "iso": true,
        "name": "Liechtenstein",
        "dialCode": "+423"
    },
    {
        "capital": "Vilnius",
        "code": "lt",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/lt.svg",
        "flag_4x3": "flags/4x3/lt.svg",
        "iso": true,
        "name": "Lithuania",
        "dialCode": "+370"
    },
    {
        "capital": "Luxembourg City",
        "code": "lu",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/lu.svg",
        "flag_4x3": "flags/4x3/lu.svg",
        "iso": true,
        "name": "Luxembourg",
        "dialCode": "+352"
    },
    {
        "capital": "Macau",
        "code": "mo",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/mo.svg",
        "flag_4x3": "flags/4x3/mo.svg",
        "iso": true,
        "name": "Macau",
        "dialCode": "+853"
    },
    {
        "capital": "Skopje",
        "code": "mk",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/mk.svg",
        "flag_4x3": "flags/4x3/mk.svg",
        "iso": true,
        "name": "North Macedonia",
        "dialCode": "+389"
    },
    {
        "capital": "Antananarivo",
        "code": "mg",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/mg.svg",
        "flag_4x3": "flags/4x3/mg.svg",
        "iso": true,
        "name": "Madagascar",
        "dialCode": "+261"
    },
    {
        "capital": "Lilongwe",
        "code": "mw",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/mw.svg",
        "flag_4x3": "flags/4x3/mw.svg",
        "iso": true,
        "name": "Malawi",
        "dialCode": "+265"
    },
    {
        "capital": "Kuala Lumpur",
        "code": "my",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/my.svg",
        "flag_4x3": "flags/4x3/my.svg",
        "iso": true,
        "name": "Malaysia",
        "dialCode": "+60"
    },
    {
        "capital": "Malé",
        "code": "mv",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/mv.svg",
        "flag_4x3": "flags/4x3/mv.svg",
        "iso": true,
        "name": "Maldives",
        "dialCode": "+960"
    },
    {
        "capital": "Bamako",
        "code": "ml",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/ml.svg",
        "flag_4x3": "flags/4x3/ml.svg",
        "iso": true,
        "name": "Mali",
        "dialCode": "+223"
    },
    {
        "capital": "Valletta",
        "code": "mt",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/mt.svg",
        "flag_4x3": "flags/4x3/mt.svg",
        "iso": true,
        "name": "Malta",
        "dialCode": "+356"
    },
    {
        "capital": "Majuro",
        "code": "mh",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/mh.svg",
        "flag_4x3": "flags/4x3/mh.svg",
        "iso": true,
        "name": "Marshall Islands",
        "dialCode": "+692"
    },
    {
        "capital": "Fort-de-France",
        "code": "mq",
        "continent": "North America",
        "flag_1x1": "flags/1x1/mq.svg",
        "flag_4x3": "flags/4x3/mq.svg",
        "iso": true,
        "name": "Martinique",
        "dialCode": "+596"
    },
    {
        "capital": "Nouakchott",
        "code": "mr",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/mr.svg",
        "flag_4x3": "flags/4x3/mr.svg",
        "iso": true,
        "name": "Mauritania",
        "dialCode": "+222"
    },
    {
        "capital": "Port Louis",
        "code": "mu",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/mu.svg",
        "flag_4x3": "flags/4x3/mu.svg",
        "iso": true,
        "name": "Mauritius",
        "dialCode": "+230"
    },
    {
        "capital": "Mamoudzou",
        "code": "yt",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/yt.svg",
        "flag_4x3": "flags/4x3/yt.svg",
        "iso": true,
        "name": "Mayotte",
        "dialCode": "+269"
    },
    {
        "capital": "Mexico City",
        "code": "mx",
        "continent": "North America",
        "flag_1x1": "flags/1x1/mx.svg",
        "flag_4x3": "flags/4x3/mx.svg",
        "iso": true,
        "name": "Mexico",
        "dialCode": "+52"
    },
    {
        "capital": "Palikir",
        "code": "fm",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/fm.svg",
        "flag_4x3": "flags/4x3/fm.svg",
        "iso": true,
        "name": "Federated States of Micronesia",
        "dialCode": "+691"
    },
    {
        "capital": "Chișinău",
        "code": "md",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/md.svg",
        "flag_4x3": "flags/4x3/md.svg",
        "iso": true,
        "name": "Moldova",
        "dialCode": "+373"
    },
    {
        "capital": "Monaco",
        "code": "mc",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/mc.svg",
        "flag_4x3": "flags/4x3/mc.svg",
        "iso": true,
        "name": "Monaco",
        "dialCode": "+377"
    },
    {
        "capital": "Ulaanbaatar",
        "code": "mn",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/mn.svg",
        "flag_4x3": "flags/4x3/mn.svg",
        "iso": true,
        "name": "Mongolia",
        "dialCode": "+976"
    },
    {
        "capital": "Little Bay, Brades, Plymouth",
        "code": "ms",
        "continent": "North America",
        "flag_1x1": "flags/1x1/ms.svg",
        "flag_4x3": "flags/4x3/ms.svg",
        "iso": true,
        "name": "Montserrat",
        "dialCode": "+1664"
    },
    {
        "capital": "Rabat",
        "code": "ma",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/ma.svg",
        "flag_4x3": "flags/4x3/ma.svg",
        "iso": true,
        "name": "Morocco",
        "dialCode": "+212"
    },
    {
        "capital": "Maputo",
        "code": "mz",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/mz.svg",
        "flag_4x3": "flags/4x3/mz.svg",
        "iso": true,
        "name": "Mozambique",
        "dialCode": "+258"
    },
    {
        "capital": "Naypyidaw",
        "code": "mm",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/mm.svg",
        "flag_4x3": "flags/4x3/mm.svg",
        "iso": true,
        "name": "Myanmar",
        "dialCode": "+95"
    },
    {
        "capital": "Windhoek",
        "code": "na",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/na.svg",
        "flag_4x3": "flags/4x3/na.svg",
        "iso": true,
        "name": "Namibia",
        "dialCode": "+264"
    },
    {
        "capital": "Yaren District",
        "code": "nr",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/nr.svg",
        "flag_4x3": "flags/4x3/nr.svg",
        "iso": true,
        "name": "Nauru",
        "dialCode": "+674"
    },
    {
        "capital": "Kathmandu",
        "code": "np",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/np.svg",
        "flag_4x3": "flags/4x3/np.svg",
        "iso": true,
        "name": "Nepal",
        "dialCode": "+977"
    },
    {
        "capital": "Amsterdam",
        "code": "nl",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/nl.svg",
        "flag_4x3": "flags/4x3/nl.svg",
        "iso": true,
        "name": "Netherlands",
        "dialCode": "+31"
    },
    {
        "capital": "Nouméa",
        "code": "nc",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/nc.svg",
        "flag_4x3": "flags/4x3/nc.svg",
        "iso": true,
        "name": "New Caledonia",
        "dialCode": "+687"
    },
    {
        "capital": "Wellington",
        "code": "nz",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/nz.svg",
        "flag_4x3": "flags/4x3/nz.svg",
        "iso": true,
        "name": "New Zealand",
        "dialCode": "+64"
    },
    {
        "capital": "Managua",
        "code": "ni",
        "continent": "North America",
        "flag_1x1": "flags/1x1/ni.svg",
        "flag_4x3": "flags/4x3/ni.svg",
        "iso": true,
        "name": "Nicaragua",
        "dialCode": "+505"
    },
    {
        "capital": "Niamey",
        "code": "ne",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/ne.svg",
        "flag_4x3": "flags/4x3/ne.svg",
        "iso": true,
        "name": "Niger",
        "dialCode": "+227"
    },
    {
        "capital": "Abuja",
        "code": "ng",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/ng.svg",
        "flag_4x3": "flags/4x3/ng.svg",
        "iso": true,
        "name": "Nigeria",
        "dialCode": "+234"
    },
    {
        "capital": "Alofi",
        "code": "nu",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/nu.svg",
        "flag_4x3": "flags/4x3/nu.svg",
        "iso": true,
        "name": "Niue",
        "dialCode": "+683"
    },
    {
        "capital": "Kingston",
        "code": "nf",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/nf.svg",
        "flag_4x3": "flags/4x3/nf.svg",
        "iso": true,
        "name": "Norfolk Island",
        "dialCode": "+672"
    },
    {
        "capital": "Saipan",
        "code": "mp",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/mp.svg",
        "flag_4x3": "flags/4x3/mp.svg",
        "iso": true,
        "name": "Northern Mariana Islands",
        "dialCode": "+1670"
    },
    {
        "capital": "Oslo",
        "code": "no",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/no.svg",
        "flag_4x3": "flags/4x3/no.svg",
        "iso": true,
        "name": "Norway",
        "dialCode": "+47"
    },
    {
        "capital": "Muscat",
        "code": "om",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/om.svg",
        "flag_4x3": "flags/4x3/om.svg",
        "iso": true,
        "name": "Oman",
        "dialCode": "+968"
    },
    {
        "capital": "Islamabad",
        "code": "pk",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/pk.svg",
        "flag_4x3": "flags/4x3/pk.svg",
        "iso": true,
        "name": "Pakistan",
        "dialCode": "+92"
    },
    {
        "capital": "Ngerulmud",
        "code": "pw",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/pw.svg",
        "flag_4x3": "flags/4x3/pw.svg",
        "iso": true,
        "name": "Palau",
        "dialCode": "+680"
    },
    {
        "capital": "Ramallah",
        "code": "ps",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/ps.svg",
        "flag_4x3": "flags/4x3/ps.svg",
        "iso": true,
        "name": "State of Palestine",
        "dialCode": "+970"
    },
    {
        "capital": "Panama City",
        "code": "pa",
        "continent": "North America",
        "flag_1x1": "flags/1x1/pa.svg",
        "flag_4x3": "flags/4x3/pa.svg",
        "iso": true,
        "name": "Panama",
        "dialCode": "+507"
    },
    {
        "capital": "Port Moresby",
        "code": "pg",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/pg.svg",
        "flag_4x3": "flags/4x3/pg.svg",
        "iso": true,
        "name": "Papua New Guinea",
        "dialCode": "+675"
    },
    {
        "capital": "Asunción",
        "code": "py",
        "continent": "South America",
        "flag_1x1": "flags/1x1/py.svg",
        "flag_4x3": "flags/4x3/py.svg",
        "iso": true,
        "name": "Paraguay",
        "dialCode": "+595"
    },
    {
        "capital": "Lima",
        "code": "pe",
        "continent": "South America",
        "flag_1x1": "flags/1x1/pe.svg",
        "flag_4x3": "flags/4x3/pe.svg",
        "iso": true,
        "name": "Peru",
        "dialCode": "+51"
    },
    {
        "capital": "Manila",
        "code": "ph",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/ph.svg",
        "flag_4x3": "flags/4x3/ph.svg",
        "iso": true,
        "name": "Philippines",
        "dialCode": "+63"
    },
    {
        "capital": "Warsaw",
        "code": "pl",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/pl.svg",
        "flag_4x3": "flags/4x3/pl.svg",
        "iso": true,
        "name": "Poland",
        "dialCode": "+48"
    },
    {
        "capital": "Lisbon",
        "code": "pt",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/pt.svg",
        "flag_4x3": "flags/4x3/pt.svg",
        "iso": true,
        "name": "Portugal",
        "dialCode": "+351"
    },
    {
        "capital": "San Juan",
        "code": "pr",
        "continent": "North America",
        "flag_1x1": "flags/1x1/pr.svg",
        "flag_4x3": "flags/4x3/pr.svg",
        "iso": true,
        "name": "Puerto Rico",
        "dialCode": "+1787"
    },
    {
        "capital": "Doha",
        "code": "qa",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/qa.svg",
        "flag_4x3": "flags/4x3/qa.svg",
        "iso": true,
        "name": "Qatar",
        "dialCode": "+974"
    },
    {
        "capital": "Saint-Denis",
        "code": "re",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/re.svg",
        "flag_4x3": "flags/4x3/re.svg",
        "iso": true,
        "name": "Réunion",
        "dialCode": "+262"
    },
    {
        "capital": "Bucharest",
        "code": "ro",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/ro.svg",
        "flag_4x3": "flags/4x3/ro.svg",
        "iso": true,
        "name": "Romania",
        "dialCode": "+40"
    },
    {
        "capital": "Moscow",
        "code": "ru",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/ru.svg",
        "flag_4x3": "flags/4x3/ru.svg",
        "iso": true,
        "name": "Russia",
        "dialCode": "+7"
    },
    {
        "capital": "Kigali",
        "code": "rw",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/rw.svg",
        "flag_4x3": "flags/4x3/rw.svg",
        "iso": true,
        "name": "Rwanda",
        "dialCode": "+250"
    },
    {
        "capital": "Jamestown",
        "code": "sh",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/sh.svg",
        "flag_4x3": "flags/4x3/sh.svg",
        "iso": true,
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        "dialCode": "+290"
    },
    {
        "capital": "Basseterre",
        "code": "kn",
        "continent": "North America",
        "flag_1x1": "flags/1x1/kn.svg",
        "flag_4x3": "flags/4x3/kn.svg",
        "iso": true,
        "name": "Saint Kitts and Nevis",
        "dialCode": "+1869"
    },
    {
        "capital": "Castries",
        "code": "lc",
        "continent": "North America",
        "flag_1x1": "flags/1x1/lc.svg",
        "flag_4x3": "flags/4x3/lc.svg",
        "iso": true,
        "name": "Saint Lucia",
        "dialCode": "+1758"
    },
    {
        "capital": "Saint-Pierre",
        "code": "pm",
        "continent": "North America",
        "flag_1x1": "flags/1x1/pm.svg",
        "flag_4x3": "flags/4x3/pm.svg",
        "iso": true,
        "name": "Saint Pierre and Miquelon",
        "dialCode": "+508"
    },
    {
        "capital": "Kingstown",
        "code": "vc",
        "continent": "North America",
        "flag_1x1": "flags/1x1/vc.svg",
        "flag_4x3": "flags/4x3/vc.svg",
        "iso": true,
        "name": "Saint Vincent and the Grenadines",
        "dialCode": "+1784"
    },
    {
        "capital": "Apia",
        "code": "ws",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/ws.svg",
        "flag_4x3": "flags/4x3/ws.svg",
        "iso": true,
        "name": "Samoa",
        "dialCode": "+684"
    },
    {
        "capital": "San Marino",
        "code": "sm",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/sm.svg",
        "flag_4x3": "flags/4x3/sm.svg",
        "iso": true,
        "name": "San Marino",
        "dialCode": "+378"
    },
    {
        "capital": "São Tomé",
        "code": "st",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/st.svg",
        "flag_4x3": "flags/4x3/st.svg",
        "iso": true,
        "name": "Sao Tome and Principe",
        "dialCode": "+239"
    },
    {
        "capital": "Riyadh",
        "code": "sa",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/sa.svg",
        "flag_4x3": "flags/4x3/sa.svg",
        "iso": true,
        "name": "Saudi Arabia",
        "dialCode": "+966"
    },
    {
        "capital": "Dakar",
        "code": "sn",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/sn.svg",
        "flag_4x3": "flags/4x3/sn.svg",
        "iso": true,
        "name": "Senegal",
        "dialCode": "+221"
    },
    {
        "capital": "Victoria",
        "code": "sc",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/sc.svg",
        "flag_4x3": "flags/4x3/sc.svg",
        "iso": true,
        "name": "Seychelles",
        "dialCode": "+248"
    },
    {
        "capital": "Freetown",
        "code": "sl",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/sl.svg",
        "flag_4x3": "flags/4x3/sl.svg",
        "iso": true,
        "name": "Sierra Leone",
        "dialCode": "+232"
    },
    {
        "capital": "Singapore",
        "code": "sg",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/sg.svg",
        "flag_4x3": "flags/4x3/sg.svg",
        "iso": true,
        "name": "Singapore",
        "dialCode": "+65"
    },
    {
        "capital": "Bratislava",
        "code": "sk",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/sk.svg",
        "flag_4x3": "flags/4x3/sk.svg",
        "iso": true,
        "name": "Slovakia",
        "dialCode": "+421"
    },
    {
        "capital": "Ljubljana",
        "code": "si",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/si.svg",
        "flag_4x3": "flags/4x3/si.svg",
        "iso": true,
        "name": "Slovenia",
        "dialCode": "+386"
    },
    {
        "capital": "Honiara",
        "code": "sb",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/sb.svg",
        "flag_4x3": "flags/4x3/sb.svg",
        "iso": true,
        "name": "Solomon Islands",
        "dialCode": "+677"
    },
    {
        "capital": "Mogadishu",
        "code": "so",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/so.svg",
        "flag_4x3": "flags/4x3/so.svg",
        "iso": true,
        "name": "Somalia",
        "dialCode": "+252"
    },
    {
        "capital": "Pretoria",
        "code": "za",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/za.svg",
        "flag_4x3": "flags/4x3/za.svg",
        "iso": true,
        "name": "South Africa",
        "dialCode": "+27"
    },
    {
        "capital": "Madrid",
        "code": "es",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/es.svg",
        "flag_4x3": "flags/4x3/es.svg",
        "iso": true,
        "name": "Spain",
        "dialCode": "+34"
    },
    {
        "capital": "Sri Jayawardenepura Kotte, Colombo",
        "code": "lk",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/lk.svg",
        "flag_4x3": "flags/4x3/lk.svg",
        "iso": true,
        "name": "Sri Lanka",
        "dialCode": "+94"
    },
    {
        "capital": "Khartoum",
        "code": "sd",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/sd.svg",
        "flag_4x3": "flags/4x3/sd.svg",
        "iso": true,
        "name": "Sudan",
        "dialCode": "+249"
    },
    {
        "capital": "Paramaribo",
        "code": "sr",
        "continent": "South America",
        "flag_1x1": "flags/1x1/sr.svg",
        "flag_4x3": "flags/4x3/sr.svg",
        "iso": true,
        "name": "Suriname",
        "dialCode": "+597"
    },
    {
        "capital": "Longyearbyen",
        "code": "sj",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/sj.svg",
        "flag_4x3": "flags/4x3/sj.svg",
        "iso": true,
        "name": "Svalbard and Jan Mayen",
        "dialCode": "+47"
    },
    {
        "capital": "Lobamba, Mbabane",
        "code": "sz",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/sz.svg",
        "flag_4x3": "flags/4x3/sz.svg",
        "iso": true,
        "name": "Eswatini",
        "dialCode": "+268"
    },
    {
        "capital": "Stockholm",
        "code": "se",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/se.svg",
        "flag_4x3": "flags/4x3/se.svg",
        "iso": true,
        "name": "Sweden",
        "dialCode": "+46"
    },
    {
        "capital": "Bern",
        "code": "ch",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/ch.svg",
        "flag_4x3": "flags/4x3/ch.svg",
        "iso": true,
        "name": "Switzerland",
        "dialCode": "+41"
    },
    {
        "capital": "Damascus",
        "code": "sy",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/sy.svg",
        "flag_4x3": "flags/4x3/sy.svg",
        "iso": true,
        "name": "Syria",
        "dialCode": "+963"
    },
    {
        "capital": "Taipei",
        "code": "tw",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/tw.svg",
        "flag_4x3": "flags/4x3/tw.svg",
        "iso": true,
        "name": "Taiwan",
        "dialCode": "+886"
    },
    {
        "capital": "Dushanbe",
        "code": "tj",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/tj.svg",
        "flag_4x3": "flags/4x3/tj.svg",
        "iso": true,
        "name": "Tajikistan",
        "dialCode": "+992"
    },
    {
        "capital": "Dodoma",
        "code": "tz",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/tz.svg",
        "flag_4x3": "flags/4x3/tz.svg",
        "iso": true,
        "name": "Tanzania",
        "dialCode": "+255"
    },
    {
        "capital": "Bangkok",
        "code": "th",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/th.svg",
        "flag_4x3": "flags/4x3/th.svg",
        "iso": true,
        "name": "Thailand",
        "dialCode": "+66"
    },
    {
        "capital": "Dili",
        "code": "tl",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/tl.svg",
        "flag_4x3": "flags/4x3/tl.svg",
        "iso": true,
        "name": "Timor-Leste",
        "dialCode": "+670"
    },
    {
        "capital": "Lomé",
        "code": "tg",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/tg.svg",
        "flag_4x3": "flags/4x3/tg.svg",
        "iso": true,
        "name": "Togo",
        "dialCode": "+228"
    },
    {
        "capital": "Nukunonu, Atafu,Tokelau",
        "code": "tk",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/tk.svg",
        "flag_4x3": "flags/4x3/tk.svg",
        "iso": true,
        "name": "Tokelau",
        "dialCode": "+690"
    },
    {
        "capital": "Nukuʻalofa",
        "code": "to",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/to.svg",
        "flag_4x3": "flags/4x3/to.svg",
        "iso": true,
        "name": "Tonga",
        "dialCode": "+676"
    },
    {
        "capital": "Port of Spain",
        "code": "tt",
        "continent": "South America",
        "flag_1x1": "flags/1x1/tt.svg",
        "flag_4x3": "flags/4x3/tt.svg",
        "iso": true,
        "name": "Trinidad and Tobago",
        "dialCode": "+1868"
    },
    {
        "capital": "Tunis",
        "code": "tn",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/tn.svg",
        "flag_4x3": "flags/4x3/tn.svg",
        "iso": true,
        "name": "Tunisia",
        "dialCode": "+216"
    },
    {
        "capital": "Ankara",
        "code": "tr",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/tr.svg",
        "flag_4x3": "flags/4x3/tr.svg",
        "iso": true,
        "name": "Turkey",
        "dialCode": "+90"
    },
    {
        "capital": "Ashgabat",
        "code": "tm",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/tm.svg",
        "flag_4x3": "flags/4x3/tm.svg",
        "iso": true,
        "name": "Turkmenistan",
        "dialCode": "+7370"
    },
    {
        "capital": "Cockburn Town",
        "code": "tc",
        "continent": "North America",
        "flag_1x1": "flags/1x1/tc.svg",
        "flag_4x3": "flags/4x3/tc.svg",
        "iso": true,
        "name": "Turks and Caicos Islands",
        "dialCode": "+1649"
    },
    {
        "capital": "Funafuti",
        "code": "tv",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/tv.svg",
        "flag_4x3": "flags/4x3/tv.svg",
        "iso": true,
        "name": "Tuvalu",
        "dialCode": "+688"
    },
    {
        "capital": "Kampala",
        "code": "ug",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/ug.svg",
        "flag_4x3": "flags/4x3/ug.svg",
        "iso": true,
        "name": "Uganda",
        "dialCode": "+256"
    },
    {
        "capital": "Kiev",
        "code": "ua",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/ua.svg",
        "flag_4x3": "flags/4x3/ua.svg",
        "iso": true,
        "name": "Ukraine",
        "dialCode": "+380"
    },
    {
        "capital": "Abu Dhabi",
        "code": "ae",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/ae.svg",
        "flag_4x3": "flags/4x3/ae.svg",
        "iso": true,
        "name": "United Arab Emirates",
        "dialCode": "+971"
    },
    {
        "capital": "London",
        "code": "gb",
        "continent": "Europe",
        "flag_1x1": "flags/1x1/gb.svg",
        "flag_4x3": "flags/4x3/gb.svg",
        "iso": true,
        "name": "United Kingdom",
        "dialCode": "+44"
    },
    {
        "capital": "Washington, D.C.",
        "code": "us",
        "continent": "North America",
        "flag_1x1": "flags/1x1/us.svg",
        "flag_4x3": "flags/4x3/us.svg",
        "iso": true,
        "name": "United States of America",
        "dialCode": "+1"
    },
    {
        "capital": "Montevideo",
        "code": "uy",
        "continent": "South America",
        "flag_1x1": "flags/1x1/uy.svg",
        "flag_4x3": "flags/4x3/uy.svg",
        "iso": true,
        "name": "Uruguay",
        "dialCode": "+598"
    },
    {
        "capital": "Tashkent",
        "code": "uz",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/uz.svg",
        "flag_4x3": "flags/4x3/uz.svg",
        "iso": true,
        "name": "Uzbekistan",
        "dialCode": "+998"
    },
    {
        "capital": "Port Vila",
        "code": "vu",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/vu.svg",
        "flag_4x3": "flags/4x3/vu.svg",
        "iso": true,
        "name": "Vanuatu",
        "dialCode": "+678"
    },
    {
        "capital": "Caracas",
        "code": "ve",
        "continent": "South America",
        "flag_1x1": "flags/1x1/ve.svg",
        "flag_4x3": "flags/4x3/ve.svg",
        "iso": true,
        "name": "Venezuela",
        "dialCode": "+58"
    },
    {
        "capital": "Hanoi",
        "code": "vn",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/vn.svg",
        "flag_4x3": "flags/4x3/vn.svg",
        "iso": true,
        "name": "Vietnam",
        "dialCode": "+84"
    },
    {
        "capital": "Road Town",
        "code": "vg",
        "continent": "North America",
        "flag_1x1": "flags/1x1/vg.svg",
        "flag_4x3": "flags/4x3/vg.svg",
        "iso": true,
        "name": "Virgin Islands (British)",
        "dialCode": "+1284"
    },
    {
        "capital": "Charlotte Amalie",
        "code": "vi",
        "continent": "North America",
        "flag_1x1": "flags/1x1/vi.svg",
        "flag_4x3": "flags/4x3/vi.svg",
        "iso": true,
        "name": "Virgin Islands (U.S.)",
        "dialCode": "+1340"
    },
    {
        "capital": "Mata-Utu",
        "code": "wf",
        "continent": "Oceania",
        "flag_1x1": "flags/1x1/wf.svg",
        "flag_4x3": "flags/4x3/wf.svg",
        "iso": true,
        "name": "Wallis and Futuna",
        "dialCode": "+681"
    },
    {
        "capital": "Laayoune",
        "code": "eh",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/eh.svg",
        "flag_4x3": "flags/4x3/eh.svg",
        "iso": true,
        "name": "Western Sahara",
        "dialCode": "+212"
    },
    {
        "capital": "Sana'a",
        "code": "ye",
        "continent": "Asia",
        "flag_1x1": "flags/1x1/ye.svg",
        "flag_4x3": "flags/4x3/ye.svg",
        "iso": true,
        "name": "Yemen",
        "dialCode": "+967"
    },
    {
        "capital": "Lusaka",
        "code": "zm",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/zm.svg",
        "flag_4x3": "flags/4x3/zm.svg",
        "iso": true,
        "name": "Zambia",
        "dialCode": "+260"
    },
    {
        "capital": "Harare",
        "code": "zw",
        "continent": "Africa",
        "flag_1x1": "flags/1x1/zw.svg",
        "flag_4x3": "flags/4x3/zw.svg",
        "iso": true,
        "name": "Zimbabwe",
        "dialCode": "+263"
    }
  ];
}
