declare var $: any;
//system
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//services
import { LogService } from 'src/app/services/common/log.service';
import { StudioService } from 'src/app/services/studio.service';
import { UserService } from 'src/app/services/user.service';

//components
import { ConfirmReservationComponent } from 'src/app/components/studio/confirm-reservation/confirm-reservation.component';

//interfaces
import { SessionDetails } from 'src/app/models/studio/session-details';
import { ClassMemberModel, ClassBookingRequest } from 'src/app/models/studio/class-booking';
import { Subject, catchError, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-spot-grid',
  templateUrl: './spot-grid.component.html',
  styleUrls: ['./spot-grid.component.scss']
})
export class SpotGridComponent implements OnInit, OnDestroy {
  
  private readonly _unsubscribe: Subject<any> = new Subject();
  @Input() public sessionDetails! : SessionDetails;
  @Input() public friendIds : number[] = [];
  @Output() passEntry: EventEmitter<ClassMemberModel> = new EventEmitter();

  allowMultiSpotBooking:  boolean  = false;
  cellSquareSide:         number   = 50;
  instructorFirstName:    string   = "";
  rows:                   number[] = new Array();
  cols:                   number[] = new Array();
  spots:                  number[] = new Array();
  selected:               number[] = new Array();
  booked:                 number[] = new Array();
  submitted:              boolean  = false;
  classMember!:           ClassMemberModel;
  
  memberAccount: any | null = null;

  constructor(
      public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , private logService: LogService
    , private studioService: StudioService
    , private userService: UserService
  ) {
    
  }

  ngOnInit(): void {
    this.logService.debug(this.sessionDetails.SpotConfig);
    this.spots = this.sessionDetails.SpotConfig.Spots.split(",").map((value) => parseInt(value));

    this.rows = this.getRange(this.sessionDetails.SpotConfig.SpotRows);
    this.cols = this.getRange(this.sessionDetails.SpotConfig.SpotColumns);

    this.logService.debug(this.rows);
    this.logService.debug(this.cols);

    for (const obj of this.sessionDetails.ClassMembers) {
      this.booked.push(obj.Spot);
    }

    this.instructorFirstName = this.sessionDetails.InstructorName.split(' ')[0];

    this.userService.fetchMemberAccount(false)?.pipe(
      takeUntil(this._unsubscribe),
      tap((memberAccount: any) => {
        this.memberAccount = memberAccount;
      }),
      catchError((err: any) => {
        return err;
      })
    )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
  }

  getInstructorLayerStyle() {
    return {
      'left': (this.sessionDetails.SpotConfig.InstructorOffsetX * this.cellSquareSide)+ 'px',
      'top': (this.sessionDetails.SpotConfig.InstructorOffsetY * this.cellSquareSide) + 'px',
      'width': (this.sessionDetails.SpotConfig.InstructorWidth * this.cellSquareSide) + 'px',
      'height': (this.sessionDetails.SpotConfig.InstructorHeight * this.cellSquareSide) + 'px',
    };
  }

  selectToggle(spot: number) {
    if(this.booked.includes(spot))
      return;

    if(this.allowMultiSpotBooking)
    {
      if(this.selected.includes(spot))
      {
        const index = this.selected.indexOf(spot);
        if (index > -1) {
          this.selected.splice(index, 1);
        }
      } else {
        this.selected.push(spot);
      }
    } else {
      this.selected = [];
      this.selected.push(spot);
    }
  }

  isFriend(spot: number): boolean {
    const classMember = this.sessionDetails.ClassMembers.find(c => c.Spot === spot);
    var memberId: number = classMember ? classMember.MemberId : -1;
    return this.friendIds.includes(memberId);
  }

  getFriendAvatar(spot: number): string {
    const classMember = this.sessionDetails.ClassMembers.find(c => c.Spot === spot);
    return classMember ? classMember.MemberInHouseAvatar : "";
  }

  getRange(n: number): number[] {
    return Array.from({length: n}, (_, i) => i);
  }

  closeModal() {
    this.passEntry.emit(this.classMember);
    this.activeModal.close(this.classMember);
  }

  confirmSpot() {
    if(this.selected.length == 0)
    {
      window.alert("Please select spot first.");
      return;
    }

    this.submitted = true;
    
    //open confirm reservation popup
    const modalRef =this.modalService.open(ConfirmReservationComponent, {
      ariaLabelledBy: 'modal-basic-title', 
      // size: 'lg',
      centered: true,
      fullscreen: window.innerWidth <= 768,
      // windowClass: 'custom-class'
    });

    //Passing sessionDetails to popup
    modalRef.componentInstance.sessionDetails = this.sessionDetails;
    modalRef.componentInstance.selectedSpots = this.selected;

    //Retrieving ClassMember from popup
    modalRef.result.then((result: ClassMemberModel | undefined | null) => {
      if(typeof result == "undefined")
      {
        this.closeModal();
        return;
      }
      
      if (result === null) {
        this.submitted = false;
        return;
      }
      if (result) {
        this.logService.debug("class booking details:", result);
        this.classMember = result;
        this.closeModal();
      }
    });
  }

  creditsRemaining(): string {
    if (this.memberAccount && this.sessionDetails.Class.Cost > 0) {
      return `Classes remaining: ${this.balance()}`;
    }
    if (this.sessionDetails.Class.Cost === 0) {
      return 'Free class';
    }
    return '--';
  }

  balance(): string | undefined {
    if (!this.memberAccount) {
      return '--';
    }
    return this.userService.classesRemaining(
      this.userService.getMemberSubscriptions(this.memberAccount),
      this.userService.getMemberPacks(this.memberAccount)
    );
  }
}
