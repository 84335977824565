import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClassesService {

  constructor(private _auth: AuthService, private _http: HttpClient) { }

  getPublicSessions = (studioIds: number[] = [], instructorIds: number[] = []) => { 
    let studioIdParam = studioIds.join(',');
    let instructorIdParam = instructorIds.join(',');
    if(studioIdParam == "")
      studioIdParam = " ";
    if(instructorIdParam == "")
      instructorIdParam = " ";
    return this._http.get(`${environment.facility[environment.submerchant].facilityUrl}api/v1/Public/ClassSessions/${studioIdParam}/${instructorIdParam}`);
  }

  fetchSession(sessionId: number) {
    if (this._auth.isLoggedIn()) {
      return this._http.get(`${environment.facility[environment.submerchant].facilityUrl}api/v1/Class/Course/Session/${sessionId}`);
    }
    return null;
  }

  fetchClass(classId: number) {
    if (this._auth.isLoggedIn()) {
      return this._http.get(`${environment.facility[environment.submerchant].facilityUrl}api/v1/Class/${classId}`);
    }
    return null;
  }
}
