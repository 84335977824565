<div class="modal-header">
    <h4 class="modal-title drop-modal-title" id="modal-basic-title">CANCEL RESERVATION?</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
    <div class="banner-wrapper">
        <div class="banner-image" [ngStyle]="{'background-image': 'url(' + sessionDetails?.ClassPhoto + ')'}" [title]="sessionDetails?.ClassName"></div>
    </div>
    <div class="content-wrapper">
        <div class="heading-wrapper">
            <p>YOU'RE GOING TO</p>
            <h4 class="class-name-title">{{sessionDetails?.Class?.Name}} at {{sessionDetails?.Studio?.Name}}</h4>
        </div>
        <div class="row">
            <div class="col-sm-6 info-wrapper mt-4">
                <p class="info-title">LOCATION</p>
                <p class="info-details">{{address}}</p>
                <a [href]="directionUrl" target="_blank" class="info-link">Get directions</a>
            </div>
            <div class="col-sm-6 info-wrapper mt-4">
                <p class="info-title">RESERVATION DETAILS</p>
                <p class="info-details">{{sessionTiming}}</p>
                <p class="info-details">Spot {{classMember?.Spot}}</p>
                <a href="javascript:void(0)" (click)="generateICS()" class="info-link">Add to calendar</a>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="row">
        <div class="col-lg-6 col-4 px-0 py-1">
            <p *ngIf="sessionDetails.Class.Cost > 0" class="note">We will refund your {{sessionDetails.Class.Cost}} credit(s)</p>
            <p *ngIf="sessionDetails.Class.Cost === 0" class="note">Free class</p>
        </div>
        <div class="col-lg-6 col-8 cancel-button-wrapper">
            <button type="button" class="btn btn-dark cancel-reservation-button" [disabled]="submitted" (click)="cancelSpot()">
                <div *ngIf="submitted" class="spinner-border" role="status">
                    <span class="visually-hidden">Cancelling Spot...</span>
                </div>
                <span *ngIf="!submitted">CANCEL RESERVATION</span>
            </button>
        </div>
    </div>
</div>