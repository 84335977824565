import { Component, OnInit } from '@angular/core';
import {Meta} from "@angular/platform-browser";
import { ActivatedRoute } from '@angular/router';
import { getMobilePlatform } from '../get-started/sms.service';

@Component({
  selector: 'app-redirect-to-store',
  templateUrl: './redirect-to-store.component.html',
  styleUrls: ['./redirect-to-store.component.scss']
})
export class RedirectToStoreComponent implements OnInit {

  constructor(private meta: Meta, private activeRoute: ActivatedRoute) {
    this.meta.addTag({ name: 'og:title', content: "Download to join!" })
    this.meta.addTag({ name: 'og:image', content: "/assets/images/poster.png" })
  }

  ngOnInit() {
    let store = this.activeRoute.snapshot.queryParams['store'];
    if (!store) {
      store = getMobilePlatform() === 'android' ? 'google' : 'apple';
    }
    
    if (store === 'google') {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.dropfitness.member.montvale';
    } else {
      window.location.href = 'https://apps.apple.com/us/app/drop-fitness-montvale-nj/id1543808700';
    }
    window.setTimeout(() => window.location.href = '/', 1000);
  }
}
