<div class="error-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 text-end">
                <img src="assets/images/error.png" alt="error-image">
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="error-content">
                    <h3>Page Not Found</h3>
                    <p>We're sorry, the page you requested could not be found please go back the homepage.</p>
                    <a routerLink="/" class="default-btn">
                        Back To Home
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>