<div class="instructor-area pt-4 pb-4 ptb-lg-100" >
	<div class="container">
        <app-alert></app-alert>
        <div class="ph-item" *ngIf="!instructorDetails">
            <div class="ph-col-8">
                <div class="ph-picture"></div>
                <div class="ph-row">
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
                </div>
            </div>
        </div>
		<div class="row" *ngIf="instructorDetails">
			<div class="col-lg-7 col-sm-12 offset-lg-1 p-lg-2 ">
				<div class="banner-wrapper">
                    <div class="carousel-container">
                        <ngx-slick-carousel 
                        #slickModal="slick-carousel"
                        class="studio-image-carousel carousel" 
                        [config]="instructorImagesSlideConfig"
                        >
                          <div ngxSlickItem *ngFor="let slide of instructorImagesSlides; let i = index" class="slick-slide">
                            <img [src]="slide.UploadedUrl" alt="">
                          </div>
                        </ngx-slick-carousel>
                        <div class="carousel-navigation">
                          <button (click)="instructorCarousel.slickPrev()"><img src="/assets/images/icon/icon_arrow_left.png" /></button>
                          <button (click)="instructorCarousel.slickNext()"><img src="/assets/images/icon/icon_arrow_right.png" /></button>
                        </div>
                    </div>
                </div>
                
                <h3 class="mb-3 mt-3 section-heading">About {{instructorDetails?.Person?.FirstName}}</h3>
                
                <div class="instructor-description">
                    <p>
                        <span>{{instructorDescription}}</span>
                    </p>
                    <div class="social-handles">
                        <ul>
                            <li *ngIf="instagramLink">
                                <a [href]="instagramLink">
                                    <img src="/assets/images/icon/icon_bw_instagram.png" alt="Instagram" title="Instagram" />
                                </a>
                            </li>
                            <li *ngIf="facebookLink">
                                <a [href]="facebookLink">
                                    <img src="/assets/images/icon/icon_bw_facebook.png" alt="Facebook" title="Facebook" />
                                </a>
                            </li>
                            <li *ngIf="tiktokLink">
                                <a [href]="tiktokLink">
                                    <img src="/assets/images/icon/icon_bw_tiktok.png" alt="Tiktok" title="Tiktok" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <h3 class="mb-3 mt-3 section-heading">Upcoming Classes</h3>
                <div class="upcoming-classes">
                    <cs-sessions-list 
                        [filterDate]="dateStr" 
                        [filterStudio]="studioId" 
                        [filterInstructor]="instructorId" 
                        template="instructorView"
                        (onDataChanged)="handleDataChanged($event)"
                    >
                    </cs-sessions-list>
                </div>
                <div class="floating-footer">
                    <div class="container">
                        <div class="row" *ngIf="instructorDetails">
                            <div class="col-lg-7 col-sm-12 offset-lg-1 p-lg-2 " style="position: relative;">
                                <p class="spots-left-label">{{upcomingClassesCount}} upcoming classes</p>
                                <button class="btn btn-dark reserve-spot-button" (click)="goToClassSchedule()">
                                    VIEW CLASS SCHEDULE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</div>
	</div>
    
</div>
