<ng-template #country let-modal>
  <div class="modal-header">
    <div class="modal-title fs-5">SELECT COUNTRY</div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body text-center country-container">
    <div class="country-search">
      <form class="form-floating" autocomplete="off">
        <input #filter type="text" class="form-control" id="filterValue" placeholder="Search" value="" (input)="search(filter.value)">
        <label for="filterValue">Search</label>
      </form>
    </div>
    <div class="country-list mt-2">
      <ul class="list-group text-start">
        <li *ngFor="let c of countries" id="ctry-{{c.code}}" class="list-group-item" [ngClass]="itemClass(c)" (click)="selectItem(c)">
          <img [src]="options.flagsUrl + flag(c)" class="flag">
          <div class="country-code">{{ c.dialCode }}</div>
          <span>{{ c.name }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer">
    <div class="country-name">{{selectedCountryName()}}</div>
    <button type="button" [disabled]="!selectedCountry" class="btn btn-dark" data-bs-dismiss="modal" (click)="closeSuccess();modal.dismiss('Success')">Select</button>
  </div>
</ng-template>