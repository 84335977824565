<div class="page-faq-wrapper container pt-5">
    <div class="row g-0">
        <div class="col-lg-12 col-md-7 col mx-auto">
        	<h2 class="faq-section-title">About Drop Fitness</h2>
			<accordion [closeOthers]="false" class="faq-accordion">
				<accordion-group *ngFor="let item of aboutUs; let index = index;" heading="{{item.title}}">
					<div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12 d-flex flex-column justify-content-center" [innerHTML]="item.content">
                                
                            </div>
                        </div>
                    </div>
				</accordion-group>
			</accordion>
        </div>
    </div>

	<div class="row g-0">
        <div class="col-lg-12 col-md-7 col mx-auto">
        	<h2 class="faq-section-title">Studios</h2>
			<accordion [closeOthers]="false" class="faq-accordion">
				<accordion-group *ngFor="let item of studios; let index = index;" heading="{{item.title}}">
					<div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12 d-flex flex-column justify-content-center" [innerHTML]="item.content">
                                
                            </div>
                        </div>
                    </div>
				</accordion-group>
			</accordion>
        </div>
    </div>

	<div class="row g-0">
        <div class="col-lg-12 col-md-7 col mx-auto">
        	<h2 class="faq-section-title">Personal Training</h2>
			<accordion [closeOthers]="false" class="faq-accordion">
				<accordion-group *ngFor="let item of personalTraining; let index = index;" heading="{{item.title}}">
					<div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12 d-flex flex-column justify-content-center" [innerHTML]="item.content">
                                
                            </div>
                        </div>
                    </div>
				</accordion-group>
			</accordion>
        </div>
    </div>

	<div class="row g-0">
        <div class="col-lg-12 col-md-7 col mx-auto">
        	<h2 class="faq-section-title">Cardio Machines + Free Weights</h2>
			<accordion [closeOthers]="false" class="faq-accordion">
				<accordion-group *ngFor="let item of cardioWeights; let index = index;" heading="{{item.title}}">
					<div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12 d-flex flex-column justify-content-center" [innerHTML]="item.content">
                                
                            </div>
                        </div>
                    </div>
				</accordion-group>
			</accordion>
        </div>
    </div>

	<div class="row g-0 pb-5">
        <div class="col-lg-12 col-md-7 col mx-auto">
        	<h2 class="faq-section-title">Kids Club</h2>
			<accordion [closeOthers]="false" class="faq-accordion">
				<accordion-group *ngFor="let item of kidsClub; let index = index;" heading="{{item.title}}">
					<div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12 d-flex flex-column justify-content-center" [innerHTML]="item.content">
                                
                            </div>
                        </div>
                    </div>
				</accordion-group>
			</accordion>
        </div>
    </div>
</div>

