import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'df-carousel',
  templateUrl: './df-carousel.component.html',
  styleUrls: ['./df-carousel.component.scss']
})
export class DfCarouselComponent implements OnInit, AfterViewInit {


  @ViewChild('scrollContainer')
  public scrollContainer!: ElementRef<HTMLDivElement>;

  @Input()
  public images: { url: string }[] = [];
  
  @Input()
  public imageWidth: string = '300px';
  
  @Input()
  public imageHeight: string = '400px';

  public scrollOffset: string = '';
  public scrollWidth: string = '';

  constructor() { }

  ngOnInit() {
  }
  
  ngAfterViewInit(): void {
    // this.onScroll({ target: this.scrollContainer?.nativeElement });
  }

  public onScroll(evt: any) {
    if (evt?.target?.scrollWidth) {
      this.scrollOffset = `${Math.round((evt.target.scrollLeft / evt.target.scrollWidth) * 100)}%`;
      this.scrollWidth = `${Math.round((evt.target.clientWidth / evt.target.scrollWidth) * 100)}%`;
    }
  }

}
