<div class="session-list-wrapper">
    <div class="row g-0">
        <div class="col-lg-4 col-md-4 col mx-auto">
            <div class="sessions-wrapper" id="sessionsWrapper">
                <h3 class="heading">UPCOMING CLASSES</h3>
                <ng-container *ngIf="!filteredSessions">
                    <div class="ph-item">
                        <div class="ph-col-12">
                            <div class="ph-row">
                                <div class="ph-col-2 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-6 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-2 big"></div>
                                <div class="ph-col-2"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-6"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-2"></div>
                                <div class="ph-col-2 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-6 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-2 big"></div>
                                <div class="ph-col-2"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-6"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-2"></div>
                            </div>
                        </div>
                    </div>
                    <div class="ph-item">
                        <div class="ph-col-12">
                            <div class="ph-row">
                                <div class="ph-col-2 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-6 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-2 big"></div>
                                <div class="ph-col-2"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-6"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-2"></div>
                                <div class="ph-col-2 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-6 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-2 big"></div>
                                <div class="ph-col-2"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-6"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-2"></div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            
                <ng-container *ngIf="filteredSessions && template === 'scheduleView'">
                    <div *ngFor='let session of filteredSessions; let i = index'  
                    [attr.data-index]="i" 
                    class="g-0 session-wrapper justify-content-center sessionId-{{session.Id}} session-{{getMilitaryDateText(session.StartDate)}}"  
                    [ngClass]="session.Id == 0 ? 'header' : ''"
                    >
                        <div *ngIf="session.Id > 0" class="row">
                            <div class="col-3 time-slot">
                                <span *ngIf="isLiveSession(session.Id)" class="live"></span>
                                <p class="session-text">{{getTimeText(session.StartTime)}}</p>
                                <p class="session-text gray">{{session.Duration}} min</p>
                            </div>
                            <div class="col-9">
                                <img src="{{session.StudioImageUrl}}" alt="{{session.StudioName}}" class="session-studio-image"/>
                                <p class="session-text gray">{{session.ClassName}} | {{screenAdoptedName(session.InstructorName)}}</p>
                            </div>
                        </div>
                        <div *ngIf="session.Id === 0">
                            <p class="session-text">{{getDateText(session.StartDate)}}</p>
                        </div>
                    </div>
                    <div *ngIf="filteredSessions && !filteredSessions.length" class="no-more-classes">No more classes</div>
                </ng-container>
            </div>
        </div>
    </div>
</div>