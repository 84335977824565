import { Component, Input } from '@angular/core';

import { Banner } from "src/app/models/common-models";

@Component({
  selector: 'df-promo-banner',
  templateUrl: './promo-banner.component.html',
  styleUrls: ['./promo-banner.component.scss']
})
export class PromoBannerComponent {
  @Input() banner!: Banner;

  ngOnInit(): void {

  }

  closeBanner() : void {
    // Remove the component from the DOM
    const element = document.getElementById('promoBanner');
    if (element) {
      element.remove();
    }
  }
}
