<div id="pricing" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
        <div class="section-title col-lg-4 col-sm-12 text-left d-flex align-items-end mb-4 ml-0">
            <div>
                <h2>Fitness & Wellness Reimagined</h2>
                <p>Freedom. Flexibility. Fun. Drop Montvale features a premium, state-of-the-art gym and luxury amenities.</p>
                <a routerLink="/pricing" class="btn btn-primary rounded-pill px-4 py-2 rounded-pill-btn">
                    PRICING
                </a>
            </div>
        </div>
        <div class="col-lg-8 col-sm-12">
            <df-carousel>
                <img *ngFor="let item of carouselSource" [src]="item.url" alt="IMG" class="imgClass">
            </df-carousel>
        </div>
    </div>
</div>
