<h1>Delete account</h1>

<p>We value your privacy. If you need to permanently close your account and request that we delete the personal information associated with it (name, email, phone number, etc.), then follow these steps:</p>
<ul>
  <li>Open the Drop app on your phone</li>
  <li>Tap on your avatar in the top-left corner</li>
  <li>Tap on <i>Edit Profile</i></li>
  <li>Tap on <i>Member Status</i></li>
  <li>Tap on <i>DELETE ACCOUNT</i> and follow the prompts</li>
</ul>

<p>Completing this process will immediately log you out of the app, and you won't be able to log back in to access your account.</p>

<p>At this point, you won't be able to access any of the services that you would normally enjoy as a Drop Fitness member. That includes checking in to any already-booked classes and training sessions, or using the open gym.</p>

<p>Any active subscriptions will be cancelled, so that you won't continue to be charged for them.</p>

<p>Access to the Drop Café is and will continue to be available to you as a member of the general public.</p>

<p>We will initiate account deletion 7 days after we receive your request. At that point, any unused credits will be permanently lost. We will send you a confirmation text message and an email, once this process is completed.</p>

<p></p>