import { Component } from '@angular/core';

@Component({
  selector: 'app-support-request-page',
  templateUrl: './support-request-page.component.html',
  styleUrls: ['./support-request-page.component.scss']
})
export class SupportRequestPageComponent {

}
