<div class="banner-wrapper">
    <div class="banner-image" [style]="{'background-image': 'url(' + classPhoto + ')'}"></div>
</div>
<div class="modal-body">
    <h5>{{headingStr}}</h5>
    <div>{{subHeadingStr}}</div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-dark reserve-spot-button" (click)="closeModal()">
        <span>SOUNDS GOOD</span>
    </button>
</div>