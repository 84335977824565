<!-- Page Title -->
<div class="page-title-area bg-f9f9f9">
  <!-- <div class="container">
      <div class="content text-center"> -->
        <!-- <img [src]="'/assets/images/df/prices/prices01.jpg'" width="100%"> -->
      <!-- </div>
  </div> -->
</div>
<div class="menu-area pt-4 pb-4">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 p-lg-5">
        <div class="cafe-menu">
          <img src="/assets/images/df/cafe-menu/menu.png" class="cafe-menu-img" alt="Drop Cafe Menu">
        </div>
        <!-- <pdf-viewer [src]="'/assets/pdf/Zest-Menu.pdf'"
            [render-text]="true"
            [original-size]="false"
            [autoresize]="true"
            style="width: 100%;height: 3200px;"
        ></pdf-viewer> -->
      </div>
    </div>
  </div>
</div>
