declare var $: any;
//system
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Options, LabelType } from "@angular-slider/ngx-slider";

//services
import { LogService } from 'src/app/services/common/log.service';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { FacilityService } from 'src/app/services/facility.service';
import { MetaService } from 'src/app/services/meta.service';

//components
import { AuthBaseComponent } from 'src/app/components/auth-base/auth-base.component';

//interfaces
import { InstructorDetails, Session } from 'src/app/models/meta';
import { FilterModel } from 'src/app/models/studio/classes';
import { Studio } from 'src/app/models/studio/session-details';

//Third Party
import { OwlOptions } from 'ngx-owl-carousel-o';

//utils
import { Utils } from 'src/app/services/common/utils';

@Component({
  selector: 'df-session-filter',
  templateUrl: './session-filter.component.html',
  styleUrls: ['./session-filter.component.scss']
})
export class SessionFilterComponent extends AuthBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  
  private readonly _unsubscribe: Subject<any> = new Subject();
  @Input() public filterModel! : FilterModel;
  @Input() public studios : Studio[] = [];
  @Input() public instructors : InstructorDetails[] = [];
  @Output() passEntry:      EventEmitter<FilterModel|boolean> = new EventEmitter();

  carouselSlides: OwlOptions = {
    // items: 1,
    nav: false,
    // margin: 10,
    loop: true,
    dots: false,
    autoplay: false,
    smartSpeed: 500,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    autoplayHoverPause: false,
    // navText: [
    //   "<i class='fa-solid fa-chevron-left'></i>",
    //   "<i class='fa-solid fa-chevron-right'></i>"
    // ],
    responsive: {
      0:{
          items:1
      },
      600:{
          items:2
      },
      1000:{
          items:3
      }
    }
  }

  timingMinValue: number = 5;
  timingMaxValue: number = 23;
  timingOptions: Options = {
    floor: 5,
    ceil: 23,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return this.formatTime(value);
        case LabelType.High:
          return this.formatTime(value);
        default:
          return "";
      }
    }
  };
  
  submitted:                boolean  = false;
  initialized:              boolean  = false;

  selectedInstructorIds: number[] = [];
  selectedStudioIds: number[] = [];
  
  constructor(
    private router: Router
    , private logService: LogService
    , private alertService: AlertService
    , private facilityService: FacilityService
    , private metaService: MetaService
    ,  public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , private userService: UserService
    , private elRef: ElementRef
    , private renderer: Renderer2
  ) {
    super(router, logService, alertService, facilityService);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.initialized = true;
  }

  ngAfterViewInit() {
    if(this.filterModel == null)
    {
      this.filterModel = {
        StudioIds: [],
        InstructorIds: [],
        StartTime: 5,
        EndTime: 23
      };
    }

    setTimeout(() => {
      this.updateCheckboxes();
    }, 1000);
  }

  instructorToggle(instructorId: number) {
    const index = this.filterModel.InstructorIds.indexOf(instructorId);

    if (index === -1) {
        this.filterModel.InstructorIds.push(instructorId);
    } else {
        this.filterModel.InstructorIds.splice(index, 1);
    }

    this.updateCheckboxes();
  }

  studioToggle(studioId: number) {
    const index = this.filterModel.StudioIds.indexOf(studioId);

    if (index === -1) {
        this.filterModel.StudioIds.push(studioId);
    } else {
        this.filterModel.StudioIds.splice(index, 1);
    }

    this.updateCheckboxes();
  }

  onTimingSliderChange() {
    
  }

  formatTime(hour: number): string {
    // Helper function to format the hour as AM/PM
    if (hour < 12) {
      return `${hour}am`;
    } else if (hour === 12) {
      return `${hour}pm`;
    } else {
      return `${hour - 12}pm`;
    }
  }

  updateCheckboxes()
  {
    // Select all div elements with class 'instructor-wrapper'
    const instructorWrappers = document.querySelectorAll('div.instructor-wrapper');

    // Loop through each element and remove the 'active' class
    instructorWrappers.forEach((instructorWrapper) => {
        instructorWrapper.classList.remove('active');
    });

    this.filterModel.InstructorIds.forEach(value => {
      const instructorDiv = document.getElementById(`instructor-${value}`) as HTMLInputElement;
      const checkbox = document.getElementById(`instructor-input-${value}`) as HTMLInputElement;
      if (checkbox) {
          checkbox.checked = true;
          instructorDiv.classList.add('active');
      }
    });

    // Select all div elements with class 'instructor-wrapper'
    const studioWrappers = document.querySelectorAll('div.studio-wrapper');

    // Loop through each element and remove the 'active' class
    studioWrappers.forEach((studioWrapper) => {
      studioWrapper.classList.remove('active');
    });

    this.filterModel.StudioIds.forEach(value => {
      const studioDiv = document.getElementById(`studio-${value}`) as HTMLInputElement;
      const checkbox = document.getElementById(`studio-input-${value}`) as HTMLInputElement;
      if (checkbox) {
          checkbox.checked = true;
          studioDiv.classList.add('active');
      }
    });
  }

  clearFilter(): void {
    this.filterModel = {
      StudioIds: [],
      InstructorIds: [],
      StartTime: 5,
      EndTime: 23
    };

    this.updateCheckboxes();
  }

  filterSessions(): void {
    this.closeModal(this.filterModel);
  }

  closeModal(result: FilterModel|boolean) {
    this.passEntry.emit(result);
    this.activeModal.close(result);
  }

  ngOnDestroy(): void {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
  }
}

