<div class="modal-header">
    <h4 class="modal-title drop-modal-title" id="modal-basic-title">FILTERS</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal(true)"></button>
</div>
<div class="modal-body">
    <div class="instructors-wrapper filter" *ngIf="initialized">
        <h3 class="heading">Instructors</h3>
        <df-carousel>
            <ng-container *ngFor="let instructor of instructors">
                <div *ngIf="instructor.State == 1" class="instructor-wrapper" id="instructor-{{instructor.Id}}" (click)="instructorToggle(instructor.Id)" [style.background-image]="'url(' + instructor.Avatar.UploadedUrl +')'">
                    <div class="circle-ticked">
                        <div class="tickmark"></div>
                    </div>
                    <div class="circle-unticked"></div>
                    <div class="placeholder">&nbsp;</div>
                    <input type="checkbox" id="instructor-input-{{instructor.Id}}" class="instructor-input hidden" value="{{instructor.Id}}" />
                    <p class="instructor-name">{{instructor.Person.FirstName}}</p>
                </div>
            </ng-container>
        </df-carousel>
    </div>
    <div class="studios-wrapper filter" *ngIf="initialized">
        <h3 class="heading">Studios</h3>
        <div class="row">
            <div *ngFor="let studio of studios" class="col-lg-6 col-sm-12">
                <div class="studio-wrapper" id="studio-{{studio.Id}}" (click)="studioToggle(studio.Id)">
                    <img [src]="studio.Image.UploadedUrl" [alt]="studio.Name" class="studio-image" />
                    <div class="circle-ticked">
                        <div class="tickmark"></div>
                    </div>
                    <div class="circle-unticked"></div>
                    <input type="checkbox" id="studio-input-{{studio.Id}}" class="studio-input hidden" value="{{studio.Id}}" />
                </div>
            </div>
        </div>
    </div>
    <div class="timing-wrapper filter" *ngIf="initialized">
        <h3 class="heading">Start Time</h3>
        <div class="filter-timing-slider-container">
            <ngx-slider 
                [(value)]="filterModel.StartTime" 
                [(highValue)]="filterModel.EndTime" 
                [options]="timingOptions" 
                (userChange)="onTimingSliderChange()"
                class="filter-timing-slider"
            >
            </ngx-slider>
          </div>
    </div>
</div>
<div class="modal-footer">
    <div class="row">
        <div class="col-lg-12">
            <button type="button" class="btn btn-dark choose-plan-button" [disabled]="submitted" (click)="filterSessions()">
                <div *ngIf="submitted" class="spinner-border" role="status">
                    <span class="visually-hidden">Showing Classes...</span>
                    </div>
                    <span *ngIf="!submitted">SHOW CLASSES</span>
            </button>
            <a href="javascript:void(0)" (click)="clearFilter()" class="clear-btn">clear</a>
        </div>
    </div>
</div>
  