<div class="studio-area pt-4 pb-4 ptb-lg-100" >
	<div class="container">
        <app-alert></app-alert>
        <div class="ph-item" *ngIf="!studioDetails">
            <div class="ph-col-8">
                <div class="ph-picture"></div>
                <div class="ph-row">
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-4">
                <div class="ph-picture"></div>
                <div class="ph-row">
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                </div>
            </div>
        </div>
		<div class="row" *ngIf="studioDetails">
			<div class="col-lg-7 col-sm-12 offset-lg-1 p-lg-2 ">
				<div class="banner-wrapper">
                    <div class="carousel-container">
                        <ngx-slick-carousel 
                        #slickModal="slick-carousel"
                        class="studio-image-carousel carousel" 
                        [config]="studioImagesSlideConfig"
                        >
                          <div ngxSlickItem *ngFor="let slide of studioImagesSlides; let i = index" class="slick-slide">
                            <img [src]="slide.UploadedUrl" alt="">
                          </div>
                        </ngx-slick-carousel>
                        <div class="carousel-navigation">
                          <button (click)="studioCarousel.slickPrev()"><img src="/assets/images/icon/icon_arrow_left.png" /></button>
                          <button (click)="studioCarousel.slickNext()"><img src="/assets/images/icon/icon_arrow_right.png" /></button>
                        </div>
                    </div>
                </div>
                <div class="studio-name-photo-wrapper">
                    <div class="row">
                        <div class="col-lg-9 col-sm-12">
                            <img [src]="studioDetails?.Image?.UploadedUrl" [alt]="studioDetails?.Name" [title]="studioDetails?.Name" />
                        </div>
                    </div>
                </div>
                
                <h3 class="mb-3 mt-3 section-heading">About the Studio</h3>
                
                <div class="studio-description">
                    <p>
                        <span>{{studioDetails?.Name}}</span> - 
                        <span>{{studioDetails?.Description}}</span>
                    </p>
                    <div class="social-handles">
                        <ul>
                            <li *ngIf="instagramLink">
                                <a [href]="instagramLink">
                                    <img src="/assets/images/icon/icon_bw_instagram.png" alt="Instagram" title="Instagram" />
                                </a>
                            </li>
                            <li *ngIf="facebookLink">
                                <a [href]="facebookLink">
                                    <img src="/assets/images/icon/icon_bw_facebook.png" alt="Facebook" title="Facebook" />
                                </a>
                            </li>
                            <li *ngIf="tiktokLink">
                                <a [href]="tiktokLink">
                                    <img src="/assets/images/icon/icon_bw_tiktok.png" alt="Tiktok" title="Tiktok" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <h3 class="mb-3 mt-3 section-heading">Amenities</h3>
                <div class="amenities-wrapper">
                    <div class="row">
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_shower.svg" alt="Showers" title="Showers" />
                                <p>Showers</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_locker_rooms.svg" alt="Locker Rooms" title="Locker Rooms" />
                                <p>Locker Rooms</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_sauna.svg" alt="Sauna" title="Sauna" />
                                <p>Sauna</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_juice_bar.svg" alt="Juice Bar" title="Juice Bar" />
                                <p>Juice Bar</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_towel_service.svg" alt="Towel Service" title="Towel Service" />
                                <p>Towel Service</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_blow_dryer.svg" alt="Blow Dryers" title="Blow Dryers" />
                                <p>Blow Dryers</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_beauty_products.svg" alt="Beauty Products" title="Beauty Products" />
                                <p>Beauty Products</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_kids_club.svg" alt="Kids Club" title="Kids Club" />
                                <p>Kids Club</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_locker_chargers.svg" alt="Locker Chargers" title="Locker Chargers" />
                                <p>Locker Chargers</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_cold_plunge_shower.svg" alt="Cold Plunge Showers" title="Cold Plunge Showers" />
                                <p>Cold Plunge Showers</p>
                            </div>
                        </div>
                    </div>  
                </div>
                <h3 class="mb-3 mt-3 section-heading">Upcoming Classes</h3>
                <div class="upcoming-classes">
                    <cs-sessions-list 
                        [filterDate]="dateStr" 
                        [filterStudio]="studioId" 
                        template="studioView"
                        (onDataChanged)="handleDataChanged($event)"
                    >
                    </cs-sessions-list>
                </div>
                <h3 class="mb-3 mt-3 section-heading">Instructors</h3>
                <div class="instructors-wrapper">
                    <div class="carousel-container">
                        <ngx-slick-carousel 
                        #slickModal="slick-carousel"
                        class="instructor-image-carousel carousel" 
                        [config]="instructorImagesSlideConfig"
                        >
                          <div ngxSlickItem *ngFor="let slide of instructorImagesSlides; let i = index" class="slick-slide instructor-slide">
                            <img [src]="slide.Avatar.UploadedUrl" [alt]="slide.Person?.FirstName">
                            <p class="instructor-name">{{slide.Person?.FirstName.toUpperCase()}}</p>
                          </div>
                        </ngx-slick-carousel>
                        <div class="carousel-navigation">
                          <button (click)="instructorCarousel.slickPrev()"><img src="/assets/images/icon/icon_arrow_left.png" /></button>
                          <button (click)="instructorCarousel.slickNext()"><img src="/assets/images/icon/icon_arrow_right.png" /></button>
                        </div>
                    </div>
                </div>
                <div class="floating-footer">
                    <div class="container">
                        <div class="row" *ngIf="studioDetails">
                            <div class="col-lg-7 col-sm-12 offset-lg-1 p-lg-2 " style="position: relative;">
                                <p class="spots-left-label">{{upcomingClassesCount}} upcoming classes</p>
                                <button class="btn btn-dark reserve-spot-button" (click)="goToClassSchedule()">
                                    VIEW CLASS SCHEDULE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</div>
	</div>
    
</div>
