import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  constructor(private _http: HttpClient) { }

  getTrainers(): Observable<Object> {
    return this._http.get(environment.facility[environment.submerchant].facilityUrl + 'api/v1/Public/Trainers');
  }
}
