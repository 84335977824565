<div *ngIf="isLoading" class="loader my-5"></div>

<div *ngIf="!isLoading" class="classes-area pt-4 ptb-lg-100">
	<div class="container">
		<h5 class="account-heading">Personal Information</h5>
		<form class="form-floating form-personal-info needs-validation" autocomplete="off" (ngSubmit)="savePersonalInfo()" [formGroup]="profileForm" novalidate >
			<div class="row">
				<div class="col-lg-6 col-sm-12">
					<div class="form-floating">
						<input type="text" id="FirstName" name="firstName" formControlName="FirstName" [ngClass]="{'form-control':true, 'is-invalid':isInvalid('FirstName')}" placeholder="First Name" required [readOnly]="!isUpdateSupported">
						<label class="form-label" for="FirstName">First Name</label>
						<div *ngIf="isInvalid('FirstName')" class="invalid-feedback">
							First name is required
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12">
					<div class="form-floating">
						<input type="text" id="LastName" name="lastName" formControlName="LastName" [ngClass]="{'form-control':true, 'is-invalid':isInvalid('LastName')}" placeholder="Last Name" required [readOnly]="!isUpdateSupported">
						<label for="LastName">Last Name</label>
						<div *ngIf="isInvalid('LastName')" class="invalid-feedback">
							Last name is required
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12 col-sm-12">
					<div class="form-floating">
						<input type="email" id="Email" name="email" formControlName="Email" [ngClass]="{'form-control':true, 'is-invalid':isInvalid('Email')}" placeholder="Enter your email" required [readOnly]="!isUpdateSupported">
						<label class="form-label" for="Email">Email</label>
						<div *ngIf="isInvalid('Email')" class="invalid-feedback">
							Email is required
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-6 col-sm-12">
					<div class="form-floating">
						<div class="input-group has-validation">
							<span class="input-group-text pi-phone-dialcode" (click)="isUpdateSupported && country.open(countryCode)">
								<img src="{{flag()}}" class="flag">
								<span class="px-1">{{personInfo.DialCode}}</span>
							</span>
							<div class="form-floating mb-0">
								<input type="tel" id="PhoneNumber" name="phoneNumber" formControlName="PhoneNumber" [ngClass]="{'form-control':true, 'is-invalid':isInvalid('PhoneNumber')}"  placeholder="Mobile Number" required [readOnly]="!isUpdateSupported">
								<label for="PhoneNumber">Mobile Number</label>
								<div *ngIf="isInvalid('PhoneNumber')" class="invalid-feedback">
									Please enter a valid phone number.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12">
					<div class="form-floating">
						<input type="date" id="BirthDate" name="birthDate" formControlName="BirthDate" [ngClass]="{'form-control':true, 'is-invalid':isInvalid('BirthDate')}" placeholder="Date of Birth" required min="{{minDOB()}}" max="{{maxDOB()}}" [readOnly]="!isUpdateSupported">
						<label class="form-label" for="dobValue">Date of Birth</label>
						<div *ngIf="isInvalid('BirthDate')" class="invalid-feedback">
							Birth date is required
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-6 col-sm-12">
					<div class="form-floating">
						<select #gender id="Gender" name="gender" formControlName="Gender" [ngClass]="{'form-select':true, 'is-invalid':isInvalid('Gender')}" placeholder="Gender" required [disabled]="!isUpdateSupported">
						<option selected disabled value="">Choose...</option>
						<option value="Female">Female</option>
						<option value="Male">Male</option>
						<option value="Non-Binary">Non-Binary</option>
						<option value="Other">Other</option>
						</select>
						<label class="form-label" for="Gender">Gender</label>
						<div *ngIf="isInvalid('Gender')" class="invalid-feedback">
						Gender is required
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12">
					<div class="form-floating">
						<input type="text" id="ZipCode" name="zipCode" type="number" maxlength="5" minlength="5" formControlName="ZipCode" [ngClass]="{'form-control':true, 'is-invalid':isInvalid('ZipCode')}" placeholder="Zip Code" [readOnly]="!isUpdateSupported">
						<label class="form-label" for="ZipCode">Zip Code</label>
						<div *ngIf="isInvalid('ZipCode')" class="invalid-feedback">
							Zip code is required
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-6 col-sm-12">
					<button *ngIf="isUpdateSupported" type="submit" class="btn btn-dark w-50" [disabled]="submitted">
						<div *ngIf="submitted" class="spinner-border" role="status">
							<span class="visually-hidden">Submitting...</span>
						</div>
						<span *ngIf="!submitted">SAVE</span>
					</button>
				</div>
			</div>
			
		</form>
	</div>
</div>
<df-country-select #country (select)="changeCountry($event)"></df-country-select>