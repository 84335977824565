<!-- <owl-carousel-o [options]="carouselSlides">
  <ng-template carouselSlide *ngFor="let item of carouselSource">
      <img [src]="item.url" alt="">
  </ng-template>
</owl-carousel-o> -->

<div #scrollContainer class="df-carousel" (scroll)="onScroll($event)">
  <div class="d-flex">
    <ng-content></ng-content>
  </div>
</div>
<div class="text-center">
  Scroll
  <div class="df-carousel-scroll">
    <div class="df-scroll-position" [ngStyle]="{'margin-left': scrollOffset, 'width': scrollWidth}"></div>
  </div>
</div>