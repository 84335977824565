//system
import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { HttpService, HttpServiceConfig } from './common/http.service';

//services
import { LogService } from './common/log.service';

//environment
import { environment } from 'src/environments/environment';

//models
import { AppConfigurations, ClassesTab, CurrencyMeta } from 'src/app/models/meta';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  cachedClassTab$!: Observable<ClassesTab> | null;
  cachedAppConfiguration$!: Promise<AppConfigurations>;
  
  constructor(private logService: LogService, private httpService: HttpService) { 
    this.cachedClassTab$ = null;
    const config: HttpServiceConfig = {
      baseUrl: `${environment.facility[environment.submerchant].facilityUrl}api/v${environment.facility[environment.submerchant].facilityApiVersion}`
    };
    this.httpService.configure(config);
  }

  // to get cached last request data pass false
  getClassesMeta(force: boolean = true): Observable<ClassesTab> {
    if (!this.cachedClassTab$ || force) {
      this.cachedClassTab$ = this.httpService.get<ClassesTab>(`Meta/ClassesTab/Public`).pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
    }
    return this.cachedClassTab$;
  }

  getAppConfigurations(force: boolean = true): Promise<AppConfigurations> {
    if (!this.cachedAppConfiguration$ || force) {
      this.cachedAppConfiguration$ = this.httpService.getPromise<AppConfigurations>(`Meta/AppConfigurations/Public`);
    }
    return this.cachedAppConfiguration$;
  }

  getCurrencyMetaByMember(memberId:number): Promise<CurrencyMeta> {
    return this.httpService.getPromise<CurrencyMeta>(`Currency/Meta/ByMember/${memberId}`);
  }

  getCurrencyMetaByLocation(locationId:number): Promise<CurrencyMeta> {
    return this.httpService.getPromise<CurrencyMeta>(`Currency/Meta/ByLocation/${locationId}`);
  }
}