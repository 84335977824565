import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
    selector: 'app-classes',
    templateUrl: './classes.component.html',
    styleUrls: ['./classes.component.scss']
})
export class ClassesComponent implements OnInit {

    hpVideoOpen = false;
    p57VideoOpen = false;
    frVideoOpen = false;
    mhrcVideoOpen = false;

    constructor(
        public ngxSmartModalService: NgxSmartModalService,
        private _router: Router
    ) { }

    ngOnInit(): void {}

    onClick(navPage: string) {
        this._router.navigateByUrl('/class-schedule');
    }
}