// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from './environment.interface';

export const environment: Environment = {
    production: false,
    logLevel: 4,
    submerchant: 'dropfitness',
    facility: {
        dropfitness: {
            timezone: 'America/New_York',
            name: "Drop Fitness",
            links: {
                website: {
                    name: "Drop Fitness",
                    url: "https://www.dropfitness.com/"
                },
                domain: {
                    name: "Drop Fitness",
                    url: "dropfitness.com"
                },
                shareImage: {
                    name: "Drop Fitness",
                    url: "https://dropfitness.com/assets/images/poster.png"
                },
                smallLogo: {
                    name: "Drop Fitness",
                    url: "https://dropfitness.com/images/wordmark.png"
                },
                bigLogo: {
                    name: "Drop Fitness",
                    url: "https://dropfitness.com/images/wordmark.png"
                },
                privacyPolicy: {
                    name: "Privacy Policy",
                    url: "https://www.dropfitness.com/privacy/"
                },
                facebook: {
                    name: "Facebook",
                    url: "https://www.facebook.com/drop_fitness/"
                },
                twitter: {
                    name: "Twitter",
                    url: "https://www.twitter.com/drop_fitness/"
                },
                instagram: {
                    name: "Instagram",
                    url: "https://www.instagram.com/dropfitness/"
                },
                helpCenter: {
                    name: "Help Center",
                    url: "https://dropfitness.zendesk.com/hc/en-us/"
                },
                faq: {
                    name: "Help Center",
                    url: "https://www.dropfitness.com/faq/"
                },
                support: {
                    name: "Help Center",
                    url: "https://www.dropfitness.com/support/"
                },
                appStore: {
                    name: "App Store",
                    url: "https://apps.apple.com/us/app/drop-fitness-montvale-nj/id1543808700?ls=1"
                },
                playStore: {
                    name: "Play Store",
                    url: "https://play.google.com/store/apps/details?id=com.dropfitness.member.montvale"
                },
                googleMap: {
                    name: "Location",
                    url: "https://goo.gl/maps/rfR37EvcRkwPgczo7"
                }
            },
            corporateUrl: 'https://dropfitness-api.fitsvc.dev/',
            corporateApiVersion: '1.0',
            facilityUrl: 'https://dropfitness-api.fitsvc.dev/',
            checkoutUrl: 'https://dropfitness-checkout.fitsvc.dev/',
            facilityApiVersion: '1.1',
            sharerBaseUrl: 'https://beta-invite.dropfitness.com/',
            supportEmail: 'montvale@dropfitness.com',
            recaptcha: {
                siteKey: '6Ld_Hr4dAAAAAOZAzh5H7oGU-67Jo2C8nDwESmq3',
            },
            stripe: {
                isSandbox: true,
                publishedKey: "pk_test_51J5fuiEU0U350CJaIl7RzgqsNFTtdNSHgT8aOIfFgeYtya1E3Ym80NOsmWwHv9qp4gTcKVT4DpaDVMbghhHXdVh000WH079NDy",
                accountId: "acct_1KilFXRQVA1RL9Q1",
            },
            googleMap:
            {
                apiKey: 'AIzaSyADqzKNbTLmI7DK6SHdkaKYXqL7okETZzo',
                latitude: 41.0471936,
                longitude: -74.0723291
            }
        },
    }
};

/*
 * logLevel 1 = DEBUG
 * logLevel 2 = INFO
 * logLevel 3 = WARNING
 * logLevel 4 = ERROR
 * logLevel 5 = FATAL/CRITICAL
 */
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
