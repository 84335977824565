<div *ngIf="isLoading" class="loader my-5"></div>

<div *ngIf="!isLoading" class="col-sm-12 py-5 px-4">
    <h4 class="fs-1">Account</h4>
    <div class="pt-3"></div>

    <div *ngIf="bookedClasses && bookedClasses.length">
      <h4>Up Next</h4>
      <div *ngFor="let c of bookedClasses" class="row">
        <df-booked-class [source]="c" [classesTab]="classesTab" />
      </div>

      <div class="pt-3"></div>
    </div>

    <h4>My Drop</h4>
    <div *ngIf="subscriptions.length" class="row">
      <div class="col-xl-8 col col-sm-12">
          <ul class="mydrop-list">
              <li class="mydrop-list-item" *ngFor="let s of subscriptions">
                  <div class="card mb-3 py-0">
                      <div class="row g-0 d-table">
                        <div class="col-2 d-table-cell">
                          <img [src]="'/assets/images/df/mydrop/'+imageForPackId(s.PackId)" [alt]="s.Description" class="card-image"/>
                        </div>
                        <div class="col-7 d-table-cell">
                          <div class="card-body">
                            <h5 class="card-title">{{s.Description}}</h5>
                            <p class="card-text">{{subDescription(s)}}</p>
                            <p class="card-text mb-0"><small class="text-body-secondary text-muted">{{daysLeftSub(s)}}</small></p>
                            <p *ngIf="renewAvailable(s)" class="card-text"><small class="text-body-secondary text-muted">Next cycle : {{nextCycleDates(s)}}</small></p>
                            <a *ngIf="renewAvailable(s)" href="javascript:void(0)" (click)="renew(s)" style="text-decoration: underline;">Renew</a>
                          </div>
                        </div>
                        <!-- <div class="col-1 d-table-cell align-middle">
                          <i class="bi bi-three-dots-vertical three-dots"></i>
                        </div> -->
                      </div>
                    </div>
              </li>
          </ul>
      </div>
    </div>
    <!-- <div *ngFor="let s of subscriptions" class="row">
      <div class="col-sm-12">
        <div class="card mb-3 py-0">
          <div class="row g-0 d-table">
            <div class="col-4 d-table-cell">
              <img [src]="'/assets/images/df/mydrop/'+s.PackId+'.jpg'" class="img-fluid rounded-start" [alt]="s.Description">
            </div>
            <div class="col-7 d-table-cell">
              <div class="card-body">
                <h5 class="card-title">{{s.Description}}</h5>
                <p class="card-text"><small class="text-body-secondary text-muted">{{daysLeftSub(s)}}</small></p>
                <p class="card-text">{{subDescription(s)}}</p>
              </div>
            </div>
            <div class="col-1 d-table-cell align-middle">
              <i class="bi bi-three-dots-vertical three-dots"></i>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div *ngIf="packs.length" class="row">
      <div class="col-xl-8 col col-sm-12">
          <ul class="mydrop-list">
              <li class="mydrop-list-item" *ngFor="let p of packs">
                  <div class="card mb-3 py-0">
                      <div class="row g-0 d-table">
                        <div class="col-2 d-table-cell">
                          <img [src]="'/assets/images/df/mydrop/'+imageForPackId(p.PackId)" [alt]="p.Description" class="card-image"/>
                        </div>
                        <div class="col-7 d-table-cell">
                          <div class="card-body">
                            <h5 class="card-title">{{descriptionPack(p)}}</h5>
                            <p class="card-text"><small class="text-body-secondary text-muted">{{packBalance(p)}}</small></p>
                            <p class="card-text">{{subDescription(p.PackId)}}</p>
                          </div>
                        </div>
                        <!-- <div class="col-1 d-table-cell align-middle">
                          <i class="bi bi-three-dots-vertical three-dots"></i>
                        </div> -->
                      </div>
                    </div>
              </li>
          </ul>
      </div>
    </div>
    <!-- <div *ngFor="let p of packs" class="row">
      <div class="col-sm-12">
        <div class="card mb-3 py-0">
          <div class="row g-0 d-table">
            <div class="col-4 d-table-cell">
              <img [src]="'/assets/images/df/mydrop/'+p.PackId+'.jpg'" class="img-fluid rounded-start" [alt]="p.Description">
            </div>
            <div class="col-7 d-table-cell">
              <div class="card-body">
                <h5 class="card-title">{{descriptionPack(p)}}</h5>
                <p class="card-text"><small class="text-body-secondary">Expires in {{daysLeftPack(p)}} days</small></p>
                <p class="card-text">{{subDescription(p.PackId)}}</p>
              </div>
            </div>
            <div class="col-1 d-table-cell align-middle">
              <i class="bi bi-three-dots-vertical three-dots"></i>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div *ngIf="subscriptions.length || packs.length">
      You have {{classesRemaining()}} class credits remaining on this cycle. Have any questions? <a href="/support" class="link-btn blue">Reach out to us.</a>
    </div>
    <div *ngIf="!subscriptions.length && !packs.length" class="card p-4">
      No class credits are available
    </div>
    <div>
      To delete your account, follow instructions here: <a href="/account/delete" class="link-btn blue">Delete account</a>
    </div>
</div>