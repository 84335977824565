<!-- <div class="page-title-area bg-f9f9f9">
    <div class="container">
        <div class="content text-center"> -->
          <!-- <img [src]="'/assets/images/df/prices/prices01.png'" width="100%"> -->
        <!-- </div>
    </div>
</div> -->
<div class="page-paginator-wrapper page-paginator-sticky" *ngIf="filteredSessions">
    <div class="row g-0">
        <div class="col-lg-6 col-md-8 col mx-auto">
            <div class="date-paginator-area">
                <div class="date-paginator-controls">
                    <p>
                        <button class="arrow left" (click)="slickCarousel.slickPrev()"></button>
                        <span>{{rangeStart}}</span>
                        <span> - </span>
                        <span>{{rangeEnd}}</span>
                        <button class="arrow right" (click)="slickCarousel.slickNext()"></button>
                    </p>
                </div>
                <div class="date-paginator-slider">
                    
                    <!-- You can now use your library component in app.component.html -->
                    <ngx-slick-carousel class="carousel" 
                        #slickCarousel="slick-carousel"
                        [config]="slideConfig" 
                        (init)="slickInit($event)"
                        (afterChange)="afterChange($event)"
                    >
                        <div 
                            ngxSlickItem 
                            *ngFor="let slide of slides" 
                            class="slide" 
                            [ngClass]="{'disabled': slide.IsDisabled == true}"
                            [attr.data-month]="getMonth(slide.DateTime)"
                            [attr.data-date]="getDate(slide.DateTime)"
                        >
                            <div class="day">{{ getDay(slide.DateTime) }}</div>
                            <button 
                                class="date" 
                                (click)="onDateSelect(slide.DateTime)" 
                                [disabled]="slide.IsDisabled" 
                                [ngClass]="{'current' : slide.IsCurrent == true}"
                                [attr.data-dateTime]="slide.DateTime"
                            >
                                {{ getDate(slide.DateTime) }}
                            </button>
                        </div>
                        
                    </ngx-slick-carousel>
                    <div class="selected-date">{{currentDateStr}}</div>
                </div>
            </div>            
        </div>
    </div>
</div>
<div class="session-list-wrapper">
    <div class="row g-0">
        <div class="col-lg-6 col-md-8 col mx-auto">
            <div class="sessions-wrapper" id="sessionsWrapper">
                <ng-container *ngIf="!filteredSessions">
                    <div class="ph-item">
                        <div class="ph-col-12">
                            <div class="ph-row">
                                <div class="ph-col-2 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-6 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-2 big"></div>
                                <div class="ph-col-2"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-6"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-2"></div>
                                <div class="ph-col-2 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-6 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-2 big"></div>
                                <div class="ph-col-2"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-6"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-2"></div>
                            </div>
                        </div>
                    </div>
                    <div class="ph-item">
                        <div class="ph-col-12">
                            <div class="ph-row">
                                <div class="ph-col-2 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-6 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-2 big"></div>
                                <div class="ph-col-2"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-6"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-2"></div>
                                <div class="ph-col-2 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-6 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-2 big"></div>
                                <div class="ph-col-2"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-6"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-2"></div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            
                <ng-container *ngIf="filteredSessions && template === 'scheduleView'">
                    <div *ngFor='let session of filteredSessions; let i = index'  [attr.data-index]="i" (click)="goToSession(session.Id)" class="g-0 session-wrapper justify-content-center sessionId-{{session.Id}} session-{{getMilitaryDateText(session.StartDate)}}"  [ngClass]="session.Id == 0 ? 'header' : ''">
                        <div *ngIf="session.Id > 0" class="row">
                            <div class="col-3 time-slot">
                                <p class="session-text">{{getTimeText(session.StartTime)}}</p>
                                <p class="session-text">{{session.Duration}} min</p>
                            </div>
                            <div class="col-9">
                                <img src="{{session.StudioImageUrl}}" alt="{{session.StudioName}}" class="session-studio-image"/>
                                <p class="session-text">{{session.ClassName}}</p>
                                <p class="session-text">{{screenAdoptedName(session.InstructorName)}}</p>
                                <button *ngIf="isSupported() && isInFuture(session) && !isReserved(session)"
                                        [ngClass]="{'btn':true, 'btn-dark': session.SpotsLeft!==0, 'btn-outline-dark': session.SpotsLeft===0, 'class-book-button':true}"
                                        (click)="goToSession(session.Id)">
                                    {{session.SpotsLeft === 0 ? 'join waitlist' : 'reserve'}}
                                </button>
                                <button *ngIf="isReserved(session)"  class="btn btn-outline-dark class-book-button" (click)="goToSession(session.Id)">
                                    cancel
                                </button>
                                <p *ngIf="session.SpotsLeft > 0 && session.SpotsLeft <= 5" class="spots-left-text">{{spotsLeftText(session)}}</p>
                            </div>
                        </div>
                        <div *ngIf="session.Id === 0">
                            <p class="session-text">{{getDateText(session.StartDate)}}</p>
                        </div>
                    </div>
                    <div *ngIf="filteredSessions && !filteredSessions.length" class="no-more-classes">No more classes</div>
                </ng-container>
            
                <ng-container *ngIf="filteredSessions && (template === 'studioView' || template === 'instructorView')">
                    <div *ngFor='let session of filteredSessions' (click)="goToSession(session.Id)" class="g-0 session-wrapper justify-content-center sessionId-{{session.Id}} session-{{getMilitaryDateText(session.StartDate)}}">
                        <div class="row">
                            <div class="col-3">
                                <p class="session-text">{{getDayText(session.StartDate)}}</p>
                                <p class="session-text">{{getDateText(session.StartDate)}}</p>
                                <p class="session-text">{{getTimeText(session.StartTime)}}</p>
                                <p class="session-text">{{session.Duration}} min</p>
                            </div>
                            <div class="col-9">
                                <img src="{{session.StudioImageUrl}}" alt="{{session.StudioName}}" class="session-studio-image"/>
                                <p class="session-text">{{session.ClassName}}</p>
                                <p class="session-text">{{session.InstructorName}}</p>
                                <button *ngIf="isSupported() && isInFuture(session)"  class="btn btn-dark class-book-button">
                                    reserve
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="filteredSessions && !filteredSessions.length" class="no-more-classes">No more classes</div>
                </ng-container>
                <button *ngIf="showLoadMore && filteredSessions"
                    [ngClass]="{'btn':true, 'btn-dark': true, 'btn-outline-dark': true, 'load-more-button':true}"
                    (click)="onLoadMore()">
                    Load More
                </button>
                <a href="javascript:void(0)" (click)="filterSessions()" class="filter-button" [style.position]="filteredSessions && filteredSessions.length > 0 ? 'fixed' : 'absolute'">
                    <img src="assets/images/icon/icon_tool_filter.png" />
                    <span class="name">FILTERS</span>
                    <span *ngIf="filterCount > 0" class="filter-count">{{filterCount}}</span>
                </a>
            </div>
        </div>
    </div>
</div>