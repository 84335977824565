//system
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService, HttpServiceConfig } from './common/http.service';
import { HttpHeaders } from '@angular/common/http';

//services
import { LogService } from './common/log.service';

//environment
import { environment } from 'src/environments/environment';

//models
import { PurchaseRequest } from '../models/currency';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  
  constructor(private logService: LogService, private httpService: HttpService) { 
    const config: HttpServiceConfig = {
      baseUrl: `${environment.facility[environment.submerchant].facilityUrl}api/v${environment.facility[environment.submerchant].facilityApiVersion}`
    };
    this.httpService.configure(config);
  }

  purchase(purchaseRequest: PurchaseRequest): Observable<boolean | string> {
    return this.httpService.post<boolean | string>(`Currency/MenuPack/Purchase`, purchaseRequest, new HttpHeaders(), true);
  }

  renew(memberId: number, bookingId: number): Observable<boolean | string> {
    return this.httpService.post<boolean | string>(`Currency/MenuPack/Renew/${memberId}/${bookingId}`, null, new HttpHeaders(), true);
  }
}