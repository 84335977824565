//system
import { Injectable } from '@angular/core';
import { HttpService, HttpServiceConfig } from './common/http.service';

//services
import { LogService } from './common/log.service';

//environment
import { environment } from 'src/environments/environment';

//models
import { Location, UtcTimeModel } from 'src/app/models/common-models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FacilityService {
  utcTime!: UtcTimeModel;

  constructor(private logService: LogService, private httpService: HttpService) { 
    const config: HttpServiceConfig = {
      baseUrl: `${environment.facility[environment.submerchant].facilityUrl}api/v${environment.facility[environment.submerchant].facilityApiVersion}`
    };
    this.httpService.configure(config);
  }

  async getUtcDateTime(): Promise<UtcTimeModel> {
    return await this.httpService.getPromise<UtcTimeModel>(`Facility/Time`);
  }

  async isClockSynced(): Promise<boolean> {
    this.utcTime = await this.getUtcDateTime();
    this.utcTime.UtcTime = new Date(this.utcTime.UtcTime);

    const browserTimestamp = new Date();
    const timeDifference = Math.abs(browserTimestamp.getTime() - this.utcTime.UtcTime.getTime());
    const threashold = 5 * 60 * 1000;
    return timeDifference < threashold;
  }

  getLocations(): Promise<Location[]> {
    return this.httpService.getPromise<Location[]>(`Location`);
  }

  getLocationById(locationId: number): Promise<Location> {
    return this.httpService.getPromise<Location>(`Location/${locationId}`);
  }
}