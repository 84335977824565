//System
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Title} from "@angular/platform-browser";
import { FormGroup, FormControl, AbstractControl, ValidationErrors, Validators } from '@angular/forms';

//Services
import { LogService } from 'src/app/services/common/log.service';
import { AlertService } from 'src/app/services/alert.service';
import { FacilityService } from 'src/app/services/facility.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { MemberService } from 'src/app/services/member.service';
import { MemberModel } from 'src/app/models/member';
import { Person } from 'src/app/models/common-models';

//Components
import { AuthBaseComponent } from 'src/app/components/auth-base/auth-base.component';

//Environment
import { environment } from 'src/environments/environment';

//Third party
import * as moment from 'moment';
import { CountryOptions } from 'src/app/models/country-codes';
import { FeatureSupportService } from 'src/app/services/feature-support.service';

@Component({
  selector: 'df-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent extends AuthBaseComponent implements OnInit {
  profileForm : FormGroup;
  submitted: boolean = false;
  memberInfo!: MemberModel;
  personInfo!: Person;

  countryCode = 'us';
  
  isLoading = false;

  context: any = undefined;

  isUpdateSupported = true;

  constructor(private router: Router
    , private logService: LogService
    , private alertService: AlertService
    , private facilityService: FacilityService
    , private _authService: AuthService
    , private _userService: UserService
    , private _memberService: MemberService
    , private titleService: Title
    , private featureService: FeatureSupportService
  ) {
    super(router, logService, alertService, facilityService);
    
    this.personInfo = { FirstName: '', LastName: '', PreferredName: '', Gender: '', BirthDate: '', DialCode: '', PhoneNumber: '', Email: '', ZipCode: '', FullName: '', FullDetail: '', Initials: '', FirstInitial: '', LastInitial: '', PhotoUrl: '', GenderStr: '', LastModified: new Date(), LastModifiedBy: '', CreatedAt: new Date(), CreatedBy: '', Guid: '', Id: 0 };
    
    this.profileForm = new FormGroup({
      FirstName: new FormControl('', Validators.required),
      LastName: new FormControl('', Validators.required),
      DialCode: new FormControl('', Validators.required),
      PhoneNumber: new FormControl('', [Validators.required, this.phoneNumberValidator]),
      Email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$')]),
      BirthDate: new FormControl('', Validators.required),
      Gender: new FormControl('', Validators.required),
      ZipCode: new FormControl('', Validators.required),
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();

    //title
    this.titleService.setTitle(environment.facility[environment.submerchant].name + " Account -> Personal Information");

    this.isLoading = true;
    this.memberInfo = await this._memberService.getMember();
    this.personInfo = await this._memberService.getPerson();

    this.profileForm.setValue({
      FirstName: this.personInfo.FirstName,
      LastName: this.personInfo.LastName,
      DialCode: this.personInfo.DialCode,
      PhoneNumber: this.personInfo.PhoneNumber,
      Email: this.personInfo.Email,
      BirthDate:  moment(this.personInfo.BirthDate).format('YYYY-MM-DD'),
      Gender: this.personInfo.GenderStr,
      ZipCode: this.personInfo.ZipCode,
    });

    this.isLoading = false;
    this.isUpdateSupported = this.featureService.isSupported('pi');
  }

  // Custom validation function for PhoneNumber
  phoneNumberValidator(control: AbstractControl): ValidationErrors | null {
    const phoneNumber = control.value;

    if (Validators.required(control)) {
      return { required: true };
    }

    if (phoneNumber.length !== 10 || isNaN(+phoneNumber)) {
      return { invalidPhoneNumber: true };
    }

    return null;
  }

  async savePersonalInfo() {
    this.submitted = true;

    this.personInfo.FirstName = this.profileForm.controls['FirstName'].value;
    this.personInfo.LastName = this.profileForm.controls['LastName'].value;
    this.personInfo.Email = this.profileForm.controls['Email'].value;
    this.personInfo.PhoneNumber = this.profileForm.controls['PhoneNumber'].value;
    this.personInfo.GenderStr =this.personInfo.Gender = this.profileForm.controls['Gender'].value;
    this.personInfo.BirthDate = this.profileForm.controls['BirthDate'].value;
    this.personInfo.ZipCode = this.profileForm.controls['ZipCode'].value;

    console.log("Here", this.personInfo);
    this.alertService.error("Implementation is remaining.");
    this.submitted = false;
  }

  public open(metaData: any = null) {
    this.context = metaData;
    setTimeout(() => {
      // preload country flags
      CountryOptions.countries.forEach(c => {
        let img = new Image();
        img.src = `${CountryOptions.flagsUrl}${c.flag_4x3}`;
      })
    })
  }

  flag(): string {
    return `https://dropfitness-static.nyc3.digitaloceanspaces.com/static/svg/flags/4x3/${this.countryCode}.svg`;
  }

  changeCountry(ctry: any) {
    this.countryCode = ctry.country;
    this.personInfo.DialCode = ctry.phoneCode;
  }

  isInvalid(controlName: string): boolean {
    const control = this.profileForm.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  minDOB(): string {
    let today = new Date();
    return `${today.getFullYear() - 100}-${(today.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}-${today.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`;
  }

  maxDOB(): string {
    let today = new Date();
    return `${today.getFullYear() - 15}-${(today.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}-${today.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`;
  }
}
