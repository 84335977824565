<ng-template #download let-modal>
  <div class="modal-body text-center p-0" (mousedown)="clicked($event)" (touchstart)="clicked($event)">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"><img src="/assets/images/svg/exit_x.svg" alt="" /></button>

      <div class="row g-0">
        <div class="d-lg-none col-12 right-pane right-pane-narrow px-4" [ngClass]="rightPaneClassTop">
          <div class="slide" [ngClass]="slideClass"></div>
          <div *ngIf="slideNo === 2" class="attending bg-white mx-3">
            <img src="/assets/images/df/download/attending_3.png" alt="">
            Join 3 friends
          </div>
        </div>

        <div class="col-12 col-lg-6 left-pane pt-4 pt-md-5 pb-3 px-4">
          <owl-carousel-o #carousel [options]="downloadSlides" (changed)="slideChanged($event)" class="dl-carousel">
            <ng-template carouselSlide>
              <div class="bg-white p-1 dl-slide mx-md-auto">
                <h4 class="fs-2">Download the app and get a free week</h4>
                <div class="mt-3 mt-md-4">Unlock unlimited classes and workouts</div>

                <div class="mt-3 mt-md-4"></div>
                <df-download-options></df-download-options>
                <div class="mt-3 mt-md-4"></div>
              </div>
            </ng-template>

            <ng-template carouselSlide>
              <div class="bg-white p-1 dl-slide mx-md-auto">
                <h4 class="fs-2">Unlock a free week of unlimited classes</h4>
                <div class="mt-3 mt-md-4">Book classes at our premium boutique studios!</div>

                <div class="mt-3 mt-md-4"></div>
                <df-download-options></df-download-options>
                <div class="mt-3 mt-md-4"></div>
              </div>
            </ng-template>

            <ng-template carouselSlide>
              <div class="bg-white p-1 dl-slide mx-md-auto">
                <h4 class="fs-2">Easily invite and join your friends</h4>
                <div class="mt-3 mt-md-4">Don’t sweat solo! Invite friends to classes!</div>

                <div class="mt-3 mt-md-4"></div>
                <df-download-options></df-download-options>
                <div class="mt-3 mt-md-4"></div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>

        <div class="d-none d-lg-block col-lg-6 right-pane right-pane-wide px-4" [ngClass]="rightPaneClass">
          <div class="slide" [ngClass]="slideClass"></div>
          <div *ngIf="slideNo === 2" class="attending bg-white mx-3">
            <img src="/assets/images/df/download/attending_3.png" alt="">
            Join 3 friends
          </div>
        </div>
      </div>
  </div>
</ng-template>
