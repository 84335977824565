import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthBaseComponent } from 'src/app/components/auth-base/auth-base.component';
import { LoginComponent } from 'src/app/components/df-login/df-login.component';
import { ResponseWrapper } from 'src/app/models/common-models';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { LogService } from 'src/app/services/common/log.service';
import { CurrencyService } from 'src/app/services/currency.service';
import { FacilityService } from 'src/app/services/facility.service';

@Component({
  selector: 'app-renew-link',
  templateUrl: './renew-link.component.html',
  styleUrls: ['./renew-link.component.scss']
})
export class RenewLinkComponent extends AuthBaseComponent implements OnInit, OnDestroy {
  @ViewChild('login') loginPopup!: LoginComponent;
  memberId: number = 0;
  bookingId: number = 0;
  currentMember!: any;
  constructor(
    private router: Router,
    private logService: LogService,
    private facilityService: FacilityService,
    private activeRoute: ActivatedRoute,
    private alertService: AlertService,
    private authService: AuthService,
    private currencyService: CurrencyService,
    ) { 
      super(router, logService, alertService, facilityService);
    }

  override async ngOnInit() {
    await super.ngOnInit();
    this.memberId = this.activeRoute.snapshot.queryParams['memberId'];
    if (!this.memberId) {
      this.alertService.error("Invalid URL", true, true);
      window.location.href = '/';
      return;
    }

    this.bookingId = this.activeRoute.snapshot.queryParams['bookingId'];
    if (!this.bookingId) {
      this.alertService.error("Invalid URL", true, true);
      window.location.href = '/';
      return;
    }

    if(this.isLoggedIn())
    {
      this.renew();
    } else {
      this.loginPopup.open();
    }
  }

  loggedIn(isLoggedIn: boolean) {
    if (isLoggedIn)
    {
      this.currentMember = this.authService.getLoggedInMember();
      this.renew();
    }
  }

  renew()
  {
    window.location.href = '/account?renewBookingId=' + this.bookingId;
    return;
  }

  isLoggedIn(): boolean {
    this.currentMember = this.authService.getLoggedInMember();
    return !!this.currentMember;
  }

  ngOnDestroy(): void {
  }
}