<div class="modal-header">
  <h4 class="modal-title drop-modal-title" id="modal-basic-title">RESERVE SPOT</h4>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
  <div *ngIf="balance()==='0' && sessionDetails.Class.Cost > 0" class="no-more-credits mb-3">Purchase more credits in Drop app</div>
  <table class="spot-grid">
      <tbody>
        <tr *ngFor="let row of rows">
          <ng-container *ngFor="let col of cols">
            <td *ngIf="spots[row * cols.length + col] > 0" class="spot-cell">
              <a  href="javascript:void(0)" 
                  class="spot" 
                  [ngClass]="{ 'booked': booked.includes(spots[row * cols.length + col]), 'selected': selected.includes(spots[row * cols.length + col]) }"
                  (click)="selectToggle(spots[row * cols.length + col])"
              >
                  <span *ngIf="!isFriend(spots[row * cols.length + col])">{{ spots[row * cols.length + col] }}</span>
                  <img *ngIf="isFriend(spots[row * cols.length + col])" class="friend-avatar rounded-circle" [src]="getFriendAvatar(spots[row * cols.length + col])" />
              </a>
            </td>
            <td *ngIf="spots[row * cols.length + col] === 0" class="spot-cell">
              <a  href="javascript:void(0)">
                  &nbsp;
              </a>
            </td>
          </ng-container>
        </tr>
        <ng-container>
          <div class="instructor-layer" [ngStyle]="getInstructorLayerStyle()">
              <div class="instructor-wrapper">
                  <img [src]="sessionDetails.InstructorDefaultAvatar" [alt]="sessionDetails.InstructorName" [title]="sessionDetails.InstructorName" class="rounded-circle" />
                  <p>{{instructorFirstName}}</p>
              </div>
          </div>
        </ng-container>
      </tbody>
    </table>
</div>
<div class="modal-footer">
  <span>{{creditsRemaining()}}</span>
  <button type="button" class="btn btn-dark reserve-spot-button" [disabled]="submitted || selected.length === 0 || (balance()===undefined || balance() === '0') && sessionDetails.Class.Cost > 0" (click)="confirmSpot()">
      <div *ngIf="submitted" class="spinner-border" role="status">
          <span class="visually-hidden">Confirming Spot...</span>
        </div>
        <span *ngIf="!submitted">CONFIRM SPOT</span>
  </button>
</div>
