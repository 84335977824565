<div class="instagram-area">
    <div class="container-fluid p-0">
        <div class="instagram-slides">
            <owl-carousel-o [options]="instagramSlides">
                <ng-template carouselSlide>
                    <div class="single-instagram-item">
                        <img src="https://scontent-lga3-1.cdninstagram.com/v/t51.29350-15/309329192_637787384458516_9009758721271891565_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=l1yQwSQhlpoAX-sUAWC&_nc_ht=scontent-lga3-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT_CQ01PziCXnN8QVrY5tSCaxgTI3wAq2AL4uiJJzx0KaQ&oe=633AC1D3" alt="instagram-image">
                        <a href="#" target="_blank" class="link-btn"></a>
                        <i class="fa-brands fa-instagram"></i>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-instagram-item">
                        <img src="https://scontent-lga3-1.cdninstagram.com/v/t51.29350-15/308323854_1191842651382570_8751148536641394144_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=MtfbAWSojhEAX-XxJhz&_nc_ht=scontent-lga3-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT_89nwxtfavOtjx-uleHdVPyNEZ5kFxlOZAMCMD3ttHtg&oe=633B66F4" alt="instagram-image">
                        <a href="#" target="_blank" class="link-btn"></a>
                        <i class="fa-brands fa-instagram"></i>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-instagram-item">
                        <img src="assets/images/instagram/instagram3.jpg" alt="instagram-image">
                        <a href="#" target="_blank" class="link-btn"></a>
                        <i class="fa-brands fa-instagram"></i>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-instagram-item">
                        <img src="assets/images/instagram/instagram4.jpg" alt="instagram-image">
                        <a href="#" target="_blank" class="link-btn"></a>
                        <i class="fa-brands fa-instagram"></i>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-instagram-item">
                        <img src="assets/images/instagram/instagram5.jpg" alt="instagram-image">
                        <a href="#" target="_blank" class="link-btn"></a>
                        <i class="fa-brands fa-instagram"></i>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-instagram-item">
                        <img src="assets/images/instagram/instagram6.jpg" alt="instagram-image">
                        <a href="#" target="_blank" class="link-btn"></a>
                        <i class="fa-brands fa-instagram"></i>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-instagram-item">
                        <img src="assets/images/instagram/instagram7.jpg" alt="instagram-image">
                        <a href="#" target="_blank" class="link-btn"></a>
                        <i class="fa-brands fa-instagram"></i>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>