<div class="home-slides">
    <owl-carousel-o #carousel [options]="homeSlides">
        <ng-template carouselSlide>
            <div class="hero-banner hero-bg1">
                <div class="container">
                    <div class="hero-banner-content">
                        <h1>A new fitness experience</h1>
                        <p>Join Drop today and get your first week free.</p>
                        <a href="/get-started" class="btn default-btn">
                            FREE 7-DAY TRIAL
                        </a>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="hero-banner hero-bg2">
                <div class="container">
                    <div class="hero-banner-content">
                        <h1>Download the app to join</h1>
                        <p>Joining a luxury gym has never been easier.</p>
                        <a href="/get-started" class="btn default-btn">
                            FREE 7-DAY TRIAL
                        </a>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="hero-banner hero-bg3">
                <div class="container">
                    <div class="hero-banner-content">
                        <h1>Your First Week is Free</h1>
                        <p>Get 7 days of unlimited classes and workouts for free when you download the Drop app.</p>
                        <a href="/get-started" class="btn default-btn">
                            FREE 7-DAY TRIAL
                        </a>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>