<div class="page-support-request-wrapper container">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 mb-4 mt-4 text-center">
        <h1>Contact Us</h1>
      </div>
      <div class="col-lg-12 col-sm-12 mb-5">
        <form (ngSubmit)="onSubmit()" [formGroup]="supportForm" novalidate enctype="multipart/form-data" class="supportForm">
          
          <div class="form-floating mb-3">
            <input type="text" id="name" name="name" formControlName="name" class="form-control" placeholder="Enter your name" required>
            <label class="form-label" for="name">Name</label>
            <div *ngIf="isInvalid('name')" class="invalid-feedback">
              Name is required
            </div>
          </div>

          <div class="form-floating mb-3">
            <input type="email" id="email" name="email" formControlName="email" class="form-control" placeholder="Enter your email" required>
            <label class="form-label" for="email">Email</label>
            <div *ngIf="isInvalid('email')" class="invalid-feedback">
              Email is required
            </div>
          </div>

          <div class="form-floating mb-3">
            <textarea type="message" id="message" rows="5" name="message" formControlName="message" class="form-control textarea" placeholder="Enter your message" required></textarea>
            <label class="form-label" for="message">Message</label>
            <div *ngIf="isInvalid('message')" class="invalid-feedback">
              Message is required
            </div>
          </div>

          <div class="form-field mb-3">
            <label class="form-label" for="attachment">Attachment</label>
            <input type="file" class="form-control" id="attachment" name="attachment" formControlName="attachment" (change)="onFileSelected($event)">
          </div>

          <div class="captcha-wrapper">
            <ngx-recaptcha2
              formControlName="captchaResponseControl"
              [siteKey]="siteKey"
              (ngxCaptchaCallback)="handleSuccess($event)"
              (ngxCaptchaExpired)="handleExpire()"
              (ngxCaptchaLoad)="handleLoad()"
            ></ngx-recaptcha2>
            <!-- Show a button to reset the captcha -->
            <a href="javascript:void(0)" (click)="resetCaptcha()">
              reset
            </a>
          </div>
          <div *ngIf="isInvalid('captchaResponseControl')" class="invalid-feedback">
            Captcha is required
          </div>
          

          <button type="submit" class="btn btn-primary" [disabled]="submitted" style="min-width: 100px;">
            <div *ngIf="submitted" class="spinner-border" role="status">
              <span class="visually-hidden">Submitting...</span>
            </div>
            <span *ngIf="!submitted">Submit</span>
          </button>
          
          <div *ngIf="success" class="alert alert-success mt-3">
            Form submitted successfully
          </div>

          <div *ngIf="error" class="alert alert-danger mt-3">
            {{ error }}
          </div>
        </form>
      </div>
    </div>
  </div>
</div>