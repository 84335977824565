import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, catchError, takeUntil, tap } from 'rxjs';
import parsePhoneNumber from 'libphonenumber-js';
import * as moment from 'moment';

import { AuthService } from 'src/app/services/auth.service';
import { MetaService } from 'src/app/services/meta.service';
import { UserService } from 'src/app/services/user.service';
import { FacilityService } from 'src/app/services/facility.service';
import { CurrencyService } from 'src/app/services/currency.service';
import { AlertService } from 'src/app/services/alert.service';

import { ClassesTab } from 'src/app/models/meta';
import { Utils } from 'src/app/services/common/utils';
import { ResponseWrapper } from 'src/app/models/common-models';
import { Booking, MemberCurrency, MemberMemberApp } from 'src/app/models/member';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'df-my-drop',
  templateUrl: './my-drop.component.html',
  styleUrls: ['./my-drop.component.scss']
})
export class MyDropComponent implements OnInit, OnDestroy {

  private readonly _unsubscribe: Subject<any> = new Subject();
  name: string = '';
  dob: string = '';
  phoneNumber: string = '';
  email: string = '';
  subscriptions: any[] = [];
  packs: any[] = [];
  classesTab: ClassesTab = <ClassesTab>{};
  bookedClasses: any[] = [];
  isLoading = false;
  renewBookingId: number = 0;

  constructor(
    private _user: UserService,
    private _auth: AuthService,
    private activeRoute: ActivatedRoute,
    private _metaService: MetaService,
    private facilityService: FacilityService,
    private currencyService: CurrencyService,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {

    this.renewBookingId = this.activeRoute.snapshot.queryParams['renewBookingId'];

    this.isLoading = true;

    this._user.fetchMemberAccount()?.pipe(
      takeUntil(this._unsubscribe),
      tap((memberAccount: MemberMemberApp) => {
        this.subscriptions = this._user.getMemberSubscriptions(memberAccount);
        this.packs = this._user.getMemberPacks(memberAccount);
        let user = this._user.getUser();
        this.name = `${user.FirstName} ${user.LastName}`;
        this.dob = moment(user.BirthDate).format('MM/DD/YYYY');
        this.phoneNumber = parsePhoneNumber(user.DialCode + user.PhoneNumber)?.formatNational() || '';
        this.email = user.Email;
        this._metaService.getClassesMeta().pipe(
          takeUntil(this._unsubscribe),
          tap((classesTab: ClassesTab) => {
            this.classesTab = classesTab;
            this.bookedClasses = (memberAccount.Upcoming.ClassBookings || []).filter((cb: any) => {
              return cb && cb.MemberId === memberAccount.Id && this.isInFuture(cb.StartDate, cb.StartTime);
            });
            this.isLoading = false;
          }),
          catchError((err: any) => {
            this.isLoading = false;
            return err;
          })
        ).subscribe();
      }),
      catchError((err: any) => {
        this.isLoading = false;
        return err;
      })
    )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
  }

  isInFuture(startDate: string, startTime: string): boolean {
    var sessionStartTimestamp: number = Utils.getTimestampFromDateTimeUTC(new Date(startDate), startTime);
    if(sessionStartTimestamp < this.facilityService.utcTime.TimeStamp)
      return false;
    return true;
  }

  daysLeftSub(sub: Booking): string {
    if (this._user.isFreeTrial(sub) && !sub.IsStarted) {
      return 'Not started.';
    }
    let today = Date.now();
    let days = Math.floor((new Date(sub.NextChargableDate).getTime() - today) / (24 * 60 * 60 * 1000));
    
    let classesRemaining = '';
    if (sub.CurrencyId === 1)
      classesRemaining = `${this._user.classesRemainingSub(sub)} classes remaining.`;
    return `${new Date(sub.NextChargableDate).getTime() > Date.now() ? 'Renews' : 'Ends'} in ${days} days. ${classesRemaining}`;
  }

  daysLeftPack(pack: MemberCurrency): number {
    let endAt = new Date(pack.EndAt).getTime();
    let today = Date.now();
    return Math.floor((endAt - today) / (24 * 60 * 60 * 1000));
  }

  renewAvailable(subscription: Booking) : boolean {
    if(subscription.Id == 0)
      return false;

    if(this.renewBookingId == subscription.Id)
      return true;
    
    var checkDate : Date = Utils.nextDate(new Date(), 1, Utils.getPreviousUnit(subscription.TermUnit));
    if(checkDate >= new Date(subscription.NextChargableDate) )
    {
      return true;
    }
    return false;
  }

  renew(subscription: Booking): void {
    if (confirm("Do you want to renew the subscription?") == true) {
      this.isLoading = true;
      this.currencyService.renew(subscription.MemberId, subscription.Id).subscribe((result: boolean | string) => {
        this.isLoading = false;
        if(typeof(result) === 'string')
        {
          this.alertService.error(result, true, true);
          window.location.href = '/account';
          return;
        } 
        else if(typeof(result) === 'object')
        {
          if(result == null)
          {
            this.alertService.error("Something went wrong. Please try again later.", true, true);
            window.location.href = '/account?renewBookingId=' + subscription.Id;
            return;
          }
          var result1 = result as ResponseWrapper;
          if(result1.StatusCode != 200)
          {
            this.alertService.error(result1.Message, true, true);
            window.location.href = '/account';
            return;
          } else {
            this.alertService.success('Renewed successfully.', true, true);
            window.location.href = '/account';
            return;
          }
        } else {
          this.alertService.success('Renewed successfully.', true, true);
          window.location.href = '/account';
          return;
        }
      });
    } 
    return;
  }

  subDescription(sub: any) {
    switch (sub.PackId) {
      case 7:
        return '4 classes / mo. and unlimited gym access';
      case 8:
        return '8 classes / mo. and unlimited gym access';
      case 9:
        if (this._user.isFreeTrial(sub)) return 'Trial classes and trial gym access';
        return 'Unlimited classes and unlimited gym access';
      case 19:
      case 22:
          return 'Unlimited gym access. Classes not included.';
    }
    return '';
  }

  descriptionPack(p: any): string {
    return p.Comment; //`${p.Balance} class${p.Balance > 1 ? 'es' : ''}`;
  }

  nextCycleDates(sub: Booking) : string
  {
    let cycle = this._user.getLastCycle(sub);
    if (cycle && !cycle.IsCancelled) {
      let startAt = new Date(cycle.EndAt).getTime();
      let nextEndAt: Date = Utils.nextDate(new Date(cycle.EndAt), sub.TermDuration, sub.TermUnit);
      return `${moment(startAt).format("M/D/YYYY")} - ${moment(nextEndAt).format("M/D/YYYY")}`
    }
    return "";
  }

  packBalance(p: any) {
    if(p.CurrencyId == 7)
      return `Expires in ${this.daysLeftPack(p)} days. Credits remaining: $${p.Balance / 100}.`;
    let trainingPacks = [2, 10, 11, 12, 13, 14, 15, 16, 17, 18, 27];
    return `Expires in ${this.daysLeftPack(p)} days. Credits remaining: ${trainingPacks.indexOf(p.PackId) >= 0 ? p.Balance / 60 : p.Balance}.`
  }

  classesRemaining(): string {
    return this._user.classesRemaining(this.subscriptions, this.packs);
  }

  imageForPackId(id: number): string {
    const ids = [4, 5, 6, 7, 8, 9, 20, 22];
    if (ids.indexOf(id) >= 0)
      return `${id}.jpg`;
    return '0.svg';
  }
  
}
