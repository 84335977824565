<div class="modal-header">
    <h4 class="modal-title drop-modal-title" id="modal-basic-title">{{toUpperCase(selectedMenuPack?.Name)}}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal(false)"></button>
</div>
<div class="modal-body" [style.height.px]="modalBodyHeight">
    <div class="pack-image" [style.background-image]="'url(' + pack?.Image +')'">
        <img [src]="pack?.Image" />
    </div>
    <p class="text">Choose your plan</p>
    <div class="plans" *ngIf="initialized">
        <ng-container *ngFor="let menuPack of planMenuPacks">
            <div class="plan" [class.selected]="selectedMenuPackId === menuPack.Id" id="plan-{{menuPack.Id}}" (click)="changePlan(menuPack.Id)">
                <input type="radio" name="menu_pack" class="plan-input" [(ngModel)]="selectedMenuPackIdStr" value="{{menuPack.Id}}"/>
                <p class="term-duration">{{getTermUnitText(menuPack.Id)}}</p>
                <p class="price"><span class="decoy-price" *ngIf="menuPack.DecoyPrice > 0 && menuPack.DecoyPrice != menuPack.Price">${{menuPack.DecoyPrice/100}}</span><span *ngIf="menuPack.DecoyPrice > 0 && menuPack.DecoyPrice != menuPack.Price">&nbsp; | &nbsp;</span><span class="actual-price">${{menuPack.Price/100}}</span> {{getTermDuration(menuPack.Id)}}</p>
                <p *ngIf="menuPack.DecoyPrice > 0 && menuPack.DecoyPrice != menuPack.Price" class="tag-wrapper"><span class="tag">Save ${{(menuPack.DecoyPrice - menuPack.Price)/100}}</span></p>
                <p class="upgrade-description">{{menuPack.UpgradeDescription}}</p>
            </div>
        </ng-container>
    </div>
</div>
<div class="modal-footer">
    <div class="row">
        <div class="col-lg-12">
            <button type="button" class="btn btn-dark checkout-button" [disabled]="submitted" (click)="openNextPopup()">
                <div *ngIf="submitted" class="spinner-border" role="status">
                    <span class="visually-hidden">Checking Out...</span>
                    </div>
                    <span *ngIf="!submitted">CHECKOUT</span>
            </button>
        </div>
    </div>
</div>
  