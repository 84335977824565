import { Component, ViewChild, Injectable, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReCaptcha2Component } from 'ngx-captcha';

@Injectable({
  providedIn: 'root'
})

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-support-form',
  templateUrl: './support-form.component.html',
  styleUrls: ['./support-form.component.scss']
})

export class SupportFormComponent implements OnInit {
  @ViewChild(ReCaptcha2Component) captchaComponent!: ReCaptcha2Component;
  supportForm : FormGroup;
  selectedFile!: File;
  submitted: boolean = false;
  success: boolean = false;
  error: string = '';
  siteKey: string = environment.facility[environment.submerchant].recaptcha.siteKey;
  captchaIsLoaded = false;
  captchaSuccess = false;
  captchaIsExpired = false;
  captchaResponse?: string;
  hasQueryParams: boolean = false;

  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.supportForm = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$')]),
      message: new FormControl('', Validators.required),
      attachment: new FormControl(null),
      captchaResponseControl: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const name = params['name'];
      const email = params['email'];

      if (name) {
        this.supportForm.controls['name'].setValue(name);
        this.supportForm.controls['name'].disable();
        this.hasQueryParams = true;
      }

      if (email) {
        this.supportForm.controls['email'].setValue(email);
        if(this.isValidEmail(email))
        {
          this.supportForm.controls['email'].disable();
        }
        this.hasQueryParams = true;
      }
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.success = false;
    this.error = '';

    if (this.supportForm.valid) {

      // Send form data to server or perform other actions here
      const formData = new FormData();
      formData.append('name', this.supportForm.controls['name'].value);
      formData.append('email', this.supportForm.controls['email'].value);
      formData.append('message', this.supportForm.controls['message'].value);
      formData.append('attachment', this.selectedFile);

      this.http.post(`${environment.facility[environment.submerchant].facilityUrl}api/v1/Facility/SupportRequest`, formData).subscribe({
        next: () => {
          this.success = true;
          this.submitted = false;
          if(!this.hasQueryParams)
          {
            this.supportForm.reset();
            this.resetCaptcha();
          }
          else 
          {
            this.supportForm.controls['message'].reset();
            this.supportForm.controls['attachment'].reset();
            this.resetCaptcha();
          }
        },
        error: (err) => {
          console.error('Error submitting form data:', err);
          this.error = err;
          this.submitted = false;
          this.resetCaptcha();
        }
      });
    } else {
      // Mark all fields as touched to display validation errors
      Object.values(this.supportForm.controls).forEach(control => control.markAsTouched());
      this.submitted = false;
    }
  }

  handleSuccess(event: any) {
    const target = event.target as HTMLInputElement;
    this.captchaResponse = target.value;
    this.captchaSuccess = true;
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
  }

  handleExpire(): void {
    this.captchaIsExpired = true;
  }

  resetCaptcha(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = false;
    this.captchaResponse = undefined;
    this.captchaComponent.resetCaptcha();
  }

  isValidEmail(email: string): boolean {
    // Regular expression pattern for email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
    // Test if the email matches the pattern
    return emailRegex.test(email);
  }

  isInvalid(controlName: string): boolean {
    const control = this.supportForm.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  onFileSelected(event: any) {
    if (event.target != null) {
      this.selectedFile = event.target.files[0];
    }
  }

  get attachmentControl(): AbstractControl<{ attachment: File }, { attachment: File }> | null {
    return this.supportForm.get('attachment');
  }
}
