import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

export class Alert {
  public id: string = "";
  public message: string = "";
  public type: AlertType = AlertType.Info;
  public autoDisposable: boolean = true;
  public persist: boolean = false;

  constructor(
    public message1: string,
    public type1: AlertType = AlertType.Info,
    public autoDisposable1: boolean = true,
    public persist1: boolean = false
  ) {
    this.id = uuidv4();
    this.message = message1;
    this.type = type1;
    this.autoDisposable = autoDisposable1;
    this.persist = persist1;
  }
}

export enum AlertType {
  Success,
  Error,
  Info,
  Warning
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  public static readonly ALERTS_KEY = 'alerts';
  public alerts: Alert[] = [];
  public alertSubject = new Subject<Alert[]>();

  constructor() {
    
  }

  readPersistentAlerts() : void
  {
    // Retrieve alerts from localStorage
    const savedAlerts = localStorage.getItem(AlertService.ALERTS_KEY);
    if (savedAlerts) {
      window.alert(savedAlerts);
      this.alerts = JSON.parse(savedAlerts);
      this.alertSubject.next(this.alerts);
      localStorage.removeItem(AlertService.ALERTS_KEY);
    }
  }

  success(message: string, autoDisposable = true, persist = false) {
    this.alert(AlertType.Success, message, autoDisposable, persist);
  }

  error(message: string, autoDisposable = true, persist = false) {
    this.alert(AlertType.Error, message, autoDisposable, persist);
  }

  info(message: string, autoDisposable = true, persist = false) {
    this.alert(AlertType.Info, message, autoDisposable, persist);
  }

  warning(message: string, autoDisposable = true, persist = false) {
    this.alert(AlertType.Warning, message, autoDisposable, persist);
  }

  private alert(
    type: AlertType,
    message: string,
    autoDisposable: boolean,
    persist: boolean
  ) {
    const alert = new Alert(message, type, autoDisposable, persist);
    this.alerts.push(alert);
    this.alertSubject.next(this.alerts);
    if (persist) {
      // Save alerts to localStorage
      localStorage.setItem(AlertService.ALERTS_KEY, JSON.stringify(this.alerts));
    }
    if (autoDisposable) {
      setTimeout(() => this.removeAlert(alert.id), 5000);
    }
  }

  removeAlert(id: string) {
    this.alerts = this.alerts.filter(alert => alert.id !== id);
    this.alertSubject.next(this.alerts);
    localStorage.setItem(AlertService.ALERTS_KEY, JSON.stringify(this.alerts));
  }

  removeAll() {
    localStorage.removeItem(AlertService.ALERTS_KEY);
  }
}