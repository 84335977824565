declare var $: any;
//system
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Title, Meta} from "@angular/platform-browser";

//third-party
import * as moment from 'moment';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

//services
import { LogService } from 'src/app/services/common/log.service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { StudioService } from 'src/app/services/studio.service';
import { FacilityService } from 'src/app/services/facility.service';
import { MetaService } from 'src/app/services/meta.service';

//interfaces
import { Studio} from 'src/app/models/studio/session-details';
import { StudioDetails, InstructorDetails, ClassesTab } from 'src/app/models/meta';
import { MemberModel } from 'src/app/models/member';

//components
import { AuthBaseComponent } from 'src/app/components/auth-base/auth-base.component';

//utils
import { Utils } from 'src/app/services/common/utils';
import { UserState } from 'src/app/models/enums';

@Component({
  selector: 'app-studio-details',
  templateUrl: './studio-details.component.html',
  styleUrls: ['./studio-details.component.scss']
})

export class StudioDetailsComponent extends AuthBaseComponent implements OnInit, OnDestroy {
  // Declare the slickCarousel property
  @ViewChild(SlickCarouselComponent)
  studioCarousel!: SlickCarouselComponent;

  @ViewChild(SlickCarouselComponent)
  instructorCarousel!: SlickCarouselComponent;

  currentSlide: number = 0;
  studioId: number = 0;
  studioName: string = "";
  studioNameSlug: string = "";
  studios!: Studio[] | undefined;
  instructors!: InstructorDetails[] | undefined;
  studioImagesSlides: any;
  instructorImagesSlides: any;
  dateStr: string = moment().format("YYYY-MM-DD");
  classesTab!: ClassesTab;

  currentMember!: MemberModel;

  instagramLink: string = "";
  facebookLink: string = "";
  tiktokLink: string = "";

  private studioSubscription!: Subscription;
  private instructorSubscription!: Subscription;
  private studioDetailsSubscription!: Subscription;

  studioDetails!: StudioDetails;
  upcomingClassesCount: number = 0;

  studioImagesSlideConfig = { slidesToShow: 1, slidesToScroll: 1, dots: true, infinite: true, autoplay: true, arrows: true, 
    responsive: [ { breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1, } } ] };
  
  instructorImagesSlideConfig = { slidesToShow: 3, slidesToScroll: 3, dots: true, infinite: false, autoplay: false, arrows: true, 
      responsive: [ { breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1, } } ] };

  constructor(
    private router: Router
    , private titleService:Title
    , private meta: Meta
    , private logService: LogService
    , private alertService: AlertService
    , private authService: AuthService
    , private facilityService: FacilityService
    , private modalService: NgbModal
    , private activeRoute: ActivatedRoute
    , private studioService: StudioService
    , private metaService: MetaService
  ) 
  { 
    super(router, logService, alertService, facilityService);
    this.titleService.setTitle("Studio - Drop Fitness");
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.currentMember = this.authService.getLoggedInMember();
      this.logService.debug("this.authService.getLoggedInMember()",this.authService.getLoggedInMember());

    this.dateStr = moment.unix(this.facilityService.utcTime.TimeStamp).format("YYYY-MM-DD");
    let studioSlug: string = this.activeRoute.snapshot.params['studio'] || '';
    this.studioName = Utils.reverseSlug(studioSlug);
    this.titleService.setTitle(this.studioName + " - Drop Fitness");
    this.studioSubscription = this.metaService.getClassesMeta().subscribe((data: ClassesTab) => {
      this.classesTab = data;
      this.studios = this.classesTab.Studios;
      this.instructors = this.classesTab.Instructors;
      this.logService.debug("this.studios",this.studios);
      this.logService.debug("this.instructors",this.instructors);
      if(this.studios)
      {
        var found: boolean = false;
        this.studioId = parseInt(this.studioName);
        if(Number.isNaN(this.studioId))
        {
          this.studios.forEach(element => {
            if(element.Name == this.studioName)
            {
              this.studioId = element.Id;
              this.titleService.setTitle(element.Name + " - Drop Fitness");
              this.meta.updateTag({ name: 'description', content: element.Description });
              found = true;
            }
          });
        } else if(this.studioId > 0) {
          this.studios.forEach(element => {
            if(element.Id == this.studioId)
            {
              this.studioId = element.Id;
              this.titleService.setTitle(element.Name + " - Drop Fitness");
              this.meta.updateTag({ name: 'description', content: element.Description });
              found = true;
            }
          });
          
        } else {
          this.studioId = 0;
        }
      }
      
      if(this.studioId == 0 )
      {
        this.router.navigate(['404']);
        return;
      }

      if(this.instructors)
      {
        this.instructorImagesSlides = [];
        this.instructors.forEach(element => {
          if(element.State == UserState.Active)
          {
            this.instructorImagesSlides.push(element);
          }
        });
      }

      //Fetched session details from backend.
      this.studioDetailsSubscription = this.studioService.getStudioDetails(this.studioId).subscribe((data: StudioDetails) => {
        this.studioDetails = data;
        this.logService.debug("this.studioDetails", this.studioDetails);
        this.studioImagesSlides = this.studioDetails.Images;
        this.studioNameSlug = Utils.createSlug(this.studioDetails.Name);
        this.studioDetails.Settings.forEach(setting => {
          switch(setting.Key)
          {
            case 'facebook':
              this.facebookLink = setting.Value;
              break;
            case 'instagram':
              this.instagramLink = setting.Value;
              break;
            case 'tiktok':
              this.tiktokLink = setting.Value;
              break;
          }
        });
      });
    });

    
  }

  handleDataChanged(upcomingClassesCount: number) {
    this.upcomingClassesCount = upcomingClassesCount;
  }

  goToClassSchedule() {
    const queryParams = { studio: this.studioNameSlug };
    this.router.navigate(['class-schedule'], { queryParams });
  }

  ngOnDestroy() {
    if (this.studioSubscription) {
      this.studioSubscription.unsubscribe();
    }
    if (this.instructorSubscription) {
      this.instructorSubscription.unsubscribe();
    }
    if (this.studioDetailsSubscription) {
      this.studioDetailsSubscription.unsubscribe();
    }
  }
}