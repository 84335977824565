//system
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService, HttpServiceConfig } from './common/http.service';

//services
import { LogService } from './common/log.service';

//environment
import { environment } from 'src/environments/environment';

//models
import { SessionWaitlist } from 'src/app/models/studio/session-details';

@Injectable({
  providedIn: 'root'
})
export class SessionWaitlistService {

  constructor(private logService: LogService, private httpService: HttpService) { 
    const config: HttpServiceConfig = {
      baseUrl: `${environment.facility[environment.submerchant].facilityUrl}api/v${environment.facility[environment.submerchant].facilityApiVersion}`
    };
    this.httpService.configure(config);
  }

  get(sessionId: number): Promise<SessionWaitlist[]> { 
    return this.httpService.getPromise<SessionWaitlist[]>(`SessionWaitlist/BySession/${sessionId}`);
  }

  add(sessionWaitlist: Partial<SessionWaitlist>): Observable<boolean> {
    return this.httpService.post<boolean>(`SessionWaitlist`, sessionWaitlist);
  }

  remove(sessionWaitlist: Partial<SessionWaitlist>): Observable<boolean> {
    return this.httpService.delete<boolean>(`SessionWaitlist`, sessionWaitlist);
  }
}