declare var $: any;
//system
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

//third-party
import { createEvent, DateArray, EventAttributes } from 'ics';
import { v4 as uuidv4 } from 'uuid';

//services
import { LogService } from 'src/app/services/common/log.service';
import { StudioService } from 'src/app/services/studio.service';
import { UserService } from 'src/app/services/user.service';

//interfaces
import { SessionDetails, ClassMember } from 'src/app/models/studio/session-details';
import { ClassMemberModel, CancelBookingRequest } from 'src/app/models/studio/class-booking';

//environment
import { environment } from 'src/environments/environment';

//utils
import { Utils } from 'src/app/services/common/utils';

declare const google: any;

@Component({
  selector: 'app-cancel-reservation',
  templateUrl: './cancel-reservation.component.html',
  styleUrls: ['./cancel-reservation.component.scss']
})
export class CancelReservationComponent implements OnInit {
  address :string = '';
  directionUrl :string = '';
  sessionTiming :string = '';
  @Input() public sessionDetails! : SessionDetails;
  @Input() public classMember! : ClassMember;
  @Output() passEntry: EventEmitter<boolean> = new EventEmitter();

  submitted:              boolean  = false;

  constructor(
    public activeModal: NgbActiveModal
    , private router: Router
    , private location: Location
    , private logService: LogService
    , private studioService: StudioService
    , private userService: UserService
  ) 
  {
    
  }

  async ngOnInit() {
    this.address = await this.getAddress();
    this.directionUrl = this.getDirectionUrl();

    var localSessionTimestamp: number = Utils.getTimestampFromDateTime(this.sessionDetails.StartDate, this.sessionDetails.StartTime);
    var localSessionDateTime: Date = new Date(localSessionTimestamp);
    this.sessionTiming = Utils.formatDate(localSessionDateTime, "dddd, MMM d") + " at " + Utils.formatDate(localSessionDateTime, "H:mm X");
  }

  closeModal() {
    this.passEntry.emit(false);
    this.activeModal.close(false);
  }

  cancelSpot() {
    this.logService.debug("cancelling class member id :" + this.classMember.Id);
    this.submitted = true;
    var member: any = this.userService.getMember();
    const cancelBookingRequest: CancelBookingRequest = {
      ClassMemberId: this.classMember.Id,
      StripeCustomerId: "", //@todo: add it from backend.
      StripeCardId: "", //@todo: add it from backend.
      ReceiptEmail: ""  //@todo: add it from backend.
    };

    this.studioService.cancelSpot(cancelBookingRequest).subscribe((data: void) => {
      this.logService.debug("You have successfully cancelled spot " + this.classMember.Spot);
      this.submitted = false;
      this.passEntry.emit(true);
      this.activeModal.close(true);
    });
  }

  getDirectionUrl(): string {
    return `https://www.google.com/maps/dir/?api=1&destination=${environment.facility[environment.submerchant].googleMap.latitude},${environment.facility[environment.submerchant].googleMap.longitude}`;
  }

  generateICS() {
    var localSessionStartTimestamp: number = Utils.getTimestampFromDateTime(this.sessionDetails.StartDate, this.sessionDetails.StartTime);
    var localSessionEndTimestamp: number = Utils.getTimestampFromDateTime(this.sessionDetails.StartDate, this.sessionDetails.EndTime);

    var date = new Date(localSessionStartTimestamp);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var minute = date.getMinutes();
    const startDateTimeArray: DateArray = [year, month, day, hour, minute];

    date = new Date(localSessionEndTimestamp);
    year = date.getFullYear();
    month = date.getMonth();
    day = date.getDate();
    hour = date.getHours();
    minute = date.getMinutes();
    const endDateTimeArray: DateArray = [year, month, day, hour, minute];

    const event: EventAttributes = {
      start: startDateTimeArray, // year, month (0-11), day, hour, minute
      end: endDateTimeArray,
      title: this.sessionDetails.Class.Name + " at " + this.sessionDetails.Studio.Name,
      description: this.sessionDetails.Class.Name + " at " + this.sessionDetails.Studio.Name,
      location: this.address,
      url: window.location.origin + this.router.url,
      status: 'CONFIRMED',
      organizer: {
        name: this.sessionDetails.InstructorName,
        email: environment.facility[environment.submerchant].supportEmail
      }
    };
    
    createEvent(event, (error, value) => {
      if (error) {
        this.logService.error(error);
        return;
      }
  
      const blob = new Blob([value], { type: 'text/calendar' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'df-event-' + uuidv4() + '.ics';
      link.click();
    });
  }
  
  
  

  async getAddress(): Promise<string> {
    var address : string = "";
    const geocoder = new google.maps.Geocoder();
    const latLng = new google.maps.LatLng(environment.facility[environment.submerchant].googleMap.latitude, environment.facility[environment.submerchant].googleMap.longitude);

    return new Promise<string>((resolve, reject) => {
      geocoder.geocode({ location: latLng }, (results: any, status: any) => {
        if (status === "OK") {
          if (results[0]) {
            const address = results[0].formatted_address.replace("Inside ", "");
            resolve(address);
          } else {
            this.logService.debug("Geocoder: No results found");
            reject("No results found");
          }
        } else {
          this.logService.debug("Geocoder failed due to: " + status);
          reject(status);
        }
      });
    });
  }
}
