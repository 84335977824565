<div *ngIf="currentMember">
  <app-navbar style="display:block;height:0px;"></app-navbar>
  <div class="container account-container">
    <div class="sidebar">
      <df-sidebar></df-sidebar>
    </div>
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<app-alert></app-alert>