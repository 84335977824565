//system
import { Injectable } from '@angular/core';
import { HttpService, HttpServiceConfig } from './common/http.service';

//services
import { LogService } from './common/log.service';

//environment
import { environment } from 'src/environments/environment';

//models
import { ShortLink } from 'src/app/models/invite-code';

@Injectable({
  providedIn: 'root'
})
export class ShortLinkService {

  constructor(private logService: LogService, private httpService: HttpService) { 
    const config: HttpServiceConfig = {
      baseUrl: `${environment.facility[environment.submerchant].facilityUrl}api/v${environment.facility[environment.submerchant].facilityApiVersion}`
    };
    this.httpService.configure(config);
  }

  async getShortLinkDetailsByCode(code: string): Promise<ShortLink> {
    return await this.httpService.getPromise<ShortLink>(`ShortLink/ByCode/${code}`);
  }
}
