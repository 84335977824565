<div class="banner"></div>
<div class="container">
  <h1>How to update payment method</h1>
  <p>
    To update your default payment method:
  </p>
  <ul>
    <li>Open Drop Fitness mobile app</li>
    <li>Tap on your avatar and then Edit Profile</li>
    <li>Tap on Payment Methods</li>
    <li>Add New Card and make it default</li>
  </ul>
</div>