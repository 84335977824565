import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { ClassScheduleComponent } from './pages/class-schedule/class-schedule.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ProjectsDetailsComponent } from './pages/projects-details/projects-details.component';
import { ServicesDetailsComponent } from './pages/services-details/services-details.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { PricesComponent } from './pages/pricing/prices.component';
import { GetStartedComponent } from './pages/get-started/get-started.component';
import { RedirectToStoreComponent } from './pages/redirect-to-store/redirect-to-store.component';
import { UpdatePaymentInfoComponent } from './pages/update-payment-info/update-payment-info.component';
import { CafeMenuComponent } from './pages/cafe-menu/cafe-menu.component';
import { PaymentLinkComponent } from './pages/payment-link/payment-link.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { AccountComponent } from './pages/account/account.component';

import { FaqsComponent } from './components/faqs/faqs.component';
import { SupportFormComponent } from './components/support-form/support-form.component';
import { FaqsPageComponent } from './pages/faqs-page/faqs-page.component';
import { SupportRequestPageComponent } from './pages/support-request-page/support-request-page.component';
import { ClassDetailsComponent } from './pages/studio/class-details/class-details.component';
import { StudioDetailsComponent } from './pages/studio/studio-details/studio-details.component';
import { InstructorDetailsComponent } from './pages/studio/instructor-details/instructor-details.component';
import { PersonalInfoComponent } from './pages/account/personal-info/personal-info.component';
import { MyDropComponent } from './pages/account/my-drop/my-drop.component';
import { PaymentMethodsComponent } from './pages/account/payment-methods/payment-methods.component';
import { PurchaseComponent } from './pages/purchase/purchase.component';
import { TodaysScheduleComponent } from './pages/todays-schedule/todays-schedule.component';
import { RenewLinkComponent } from './pages/renew-link/renew-link.component';
import { DeleteComponent } from './pages/account/delete/delete.component';
import { MountOliveWebpageComponent } from './pages/fitnessfactory/mount-olive/mount-olive-webpage.component';
import { FramesGuard } from './guards/frames.guard';
import { BuyComponent } from './pages/buy/buy.component';


const routes: Routes = [
    // No layout pages
    { path: 'payment-link', component: PaymentLinkComponent },
    { path: 'renew-link', component: RenewLinkComponent },
    { path: 'faq-raw', component: FaqsComponent },
    { path: 'support-raw', component: SupportFormComponent },
    {path: 'todays-schedule', component: TodaysScheduleComponent},
    {path: 'buy/:locationId', component: BuyComponent},
    {path: 'fitnessfactory/mount-olive', component: MountOliveWebpageComponent},
    //Studio Tab related pages
    { 
        path: 'studio',
        component: AppLayoutComponent, 
        children: [
            {path: ':studio', component: StudioDetailsComponent},
            {path: 'class-details/:sessionId', component: ClassDetailsComponent},
        ]
    },
    //Account Tab related pages
    { 
        path: 'account',
        component: AccountComponent, 
        children: [
            {path: '', component: MyDropComponent},
            {path: 'my-drop', component: MyDropComponent},
            {path: 'personal-info', component: PersonalInfoComponent},
            {path: 'payment-methods', component: PaymentMethodsComponent},
            {path: 'delete', component: DeleteComponent},
        ]
    },
    // App layout pages
    { 
        path: '',
        component: AppLayoutComponent,
        canActivate: [FramesGuard],
        children: [
            {path: '', component: HomeComponent},
            {path: 'home', component: HomeComponent},
            {path: 'class-schedule', component: ClassScheduleComponent},
            // {path: 'privacy', component: PrivacyPolicyComponent},
            // {path: 'terms', component: TermsConditionsComponent},
            {path: 'faq', component: FaqsPageComponent},
            {path: 'support', component: SupportRequestPageComponent},
            {path: 'services-details', component: ServicesDetailsComponent},
            {path: 'projects-details', component: ProjectsDetailsComponent},
            {path: 'blog-details', component: BlogDetailsComponent},
            {path: 'instructor/:instructor', component: InstructorDetailsComponent},
            // Here add new pages component
            {path: 'pricing', redirectTo: '/purchase'},
            {path: 'purchase', component: PurchaseComponent},
            {path: 'get-started', component: GetStartedComponent},
            {path: 'app-store', component: RedirectToStoreComponent},
            {path: 'update-payment-info', component: UpdatePaymentInfoComponent},
            {path: 'zest-menu', redirectTo: 'cafe'},
            {path: 'cafe', component: CafeMenuComponent},

            {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }