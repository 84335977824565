import { Injectable } from '@angular/core';
import { Stripe, StripeCardElement, StripeElements, StripeCardElementOptions, Token, StripeError } from '@stripe/stripe-js';

//environment
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StripeClientService {
  private stripePromise: Promise<Stripe>;

  constructor() {
    this.stripePromise = this.loadStripe(environment.facility[environment.submerchant].stripe.publishedKey, environment.facility[environment.submerchant].stripe.accountId);
  }

  private loadStripe(publishableKey: string, accountId: string): Promise<Stripe> {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.onload = () => {
        resolve((window as any).Stripe(publishableKey, {
          stripeAccount: accountId
        }));
      };
      document.body.appendChild(script);
    });
  }

  elements(options?: StripeCardElementOptions): Promise<StripeElements> {
    return this.stripePromise.then((stripe) => stripe.elements());
  }

  createToken(card: StripeCardElement): Promise<{ token?: Token; error?: StripeError }> {
    return this.stripePromise.then((stripe) => stripe.createToken(card));
  }
}
