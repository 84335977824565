<div class="modal-header">
    <h4 class="modal-title drop-modal-title" id="modal-basic-title">{{toUpperCase(selectedMenuPack?.Name)}}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal(false)"></button>
</div>
<div class="modal-body" [style.height.px]="modalBodyHeight">
    <div class="pack-image" [style.background-image]="'url(' + pack?.Image +')'">
        <img [src]="pack?.Image" />
    </div>
    <div class="benefit-items" *ngIf="initialized">
        <ng-container *ngFor="let benefit of benefits">
            <div class="benefit-item">
                <div class="row">
                    <div class="col-3 item-image-wrapper">
                        <div class="item-image subscription" [style.background-image]="'url(/assets/images/icon/' + benefit.Icon +')'"></div>
                    </div>
                    <div class="col-9">
                        <div class="item-details">
                            <h4 class="title">
                                {{benefit.Name}}
                            </h4>
                            <p class="description">{{benefit.Description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div class="modal-footer">
    <div class="row">
        <div class="col-lg-12">
            <button type="button" class="btn btn-dark choose-plan-button" [disabled]="submitted" (click)="openChoosePlan()">
                <div *ngIf="submitted" class="spinner-border" role="status">
                    <span class="visually-hidden">Choosing Plan...</span>
                    </div>
                    <span *ngIf="!submitted">CHOOSE PLAN</span>
            </button>
        </div>
    </div>
</div>
  