//system
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//services
import { LogService } from 'src/app/services/common/log.service';
import { FacilityService } from 'src/app/services/facility.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-base',
  template: ''
})
export class AuthBaseComponent implements OnInit {
  private isClockSync: boolean = true;
  constructor(
    private routerBase: Router
    , private logServiceBase: LogService
    , private alertServiceBase: AlertService
    , private facilityServiceBase: FacilityService
  ) 
  {

  }

  async ngOnInit() {
    this.isClockSync = await this.isClockSynced();
    
    if(!this.isClockSync){
      this.alertServiceBase.error('System clock is not synced.', true, true);
      this.routerBase.navigate(['/']);
    }
  }

  async isClockSynced(): Promise<boolean>
  {
    this.isClockSync = await this.facilityServiceBase.isClockSynced();
    this.logServiceBase.debug("isClockSynced", this.isClockSync);
    return this.isClockSync;
  }
}