declare var $: any;
//system
import { Component, ViewChild, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Title, Meta} from "@angular/platform-browser";
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';

//services
import { LogService } from 'src/app/services/common/log.service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { StudioService } from 'src/app/services/studio.service';
import { FacilityService } from 'src/app/services/facility.service';
import { MemberService } from 'src/app/services/member.service';
import { InviteCodeService } from 'src/app/services/invite-code.service';

//interfaces
import { SessionDetails, ClassMember, SessionWaitlist} from 'src/app/models/studio/session-details';
import { ClassMemberModel } from 'src/app/models/studio/class-booking';
import { UtcTimeModel } from 'src/app/models/common-models';
import { MemberBasicInfo } from 'src/app/models/member';
import { Action, InviteCodeIntent, UserType } from 'src/app/models/enums';
import { InviteCode, InviteCodeGenerateRequest, InviteCodeRedeemRequest, InviteDialogModel } from 'src/app/models/invite-code';

//components
import { AuthBaseComponent } from 'src/app/components/auth-base/auth-base.component';
import { SpotGridComponent } from 'src/app/components/studio/spot-grid/spot-grid.component';
import { CancelReservationComponent } from 'src/app/components/studio/cancel-reservation/cancel-reservation.component';
import { InviteComponent } from 'src/app/components/invite/invite.component';
import { LoginComponent } from 'src/app/components/df-login/df-login.component';

//environment
import { environment } from 'src/environments/environment';

//utils
import { Utils } from 'src/app/services/common/utils';
import { DownloadAppComponent } from 'src/app/components/download-app/download-app.component';
import { SessionWaitlistService } from 'src/app/services/session-waitlist.service';
import { SessionWaitlistAddedComponent } from 'src/app/components/popups/session-waitlist-added/session-waitlist-added.component';

@Component({
  selector: 'app-class-details',
  templateUrl: './class-details.component.html',
  styleUrls: ['./class-details.component.scss']
})

export class ClassDetailsComponent extends AuthBaseComponent implements OnInit, OnDestroy {
  @ViewChild('login') loginPopup!: LoginComponent;
  @ViewChild('download') downloadPopup!: DownloadAppComponent;
  sessionId: number = 0;
  dayOfWeek: string = "";
  directionUrl: string = '';
  month: string = "";
  dayOfMonth: string = "";
  startTime: string = "";
  endTime: string = "";
  utcTime!: UtcTimeModel;
  currentMember!: any;
  classMember!: ClassMember;
  inviteCode!: InviteCode;
  inviteCodeStr: string = "";
  locationId: number = 1;
  friendsInfo!: MemberBasicInfo[];
  friendIds: number[] = [];
  isSessionBooked: boolean = false;
  sessionWaitlist: SessionWaitlist[] = [];
  isInWaitlist: boolean = false;

  private sessionSubscription!: Subscription;
  private inviteCodeSubscription!: Subscription;
  private facilitySubscription!: Subscription;

  sessionDetails!: SessionDetails;
  spotsLeft: number = 0;

  hideTimeout: number = 0;

  constructor(
    private router: Router
    , private logService: LogService
    , private alertService: AlertService
    , private titleService: Title
    , private metaService: Meta
    , private authService: AuthService
    , private facilityService: FacilityService
    , private modalService: NgbModal
    , private activeRoute: ActivatedRoute
    , private studioService: StudioService
    , private memberService: MemberService
    , private inviteCodeService: InviteCodeService
    , private sessionWaitlistService: SessionWaitlistService
    , private location: Location
  ) 
  { 
    super(router, logService, alertService, facilityService);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.locationId = this.activeRoute.snapshot.queryParams['location_id'];
    this.inviteCodeStr = this.activeRoute.snapshot.queryParams['invite_code'];

    this.activeRoute.params.subscribe(params => {
      this.sessionId = +params['sessionId'];
    });
    this.currentMember = this.authService.getLoggedInMember();
    this.logService.debug("this.currentMember", this.currentMember);

    //Fetched session details from backend.
    this.sessionSubscription = this.studioService.getClassDetails(this.sessionId).subscribe(async (data: SessionDetails) => {
      this.sessionDetails = data;
      this.directionUrl = this.getDirectionUrl();
      this.setMetaTags();
      this.spotsLeft = this.sessionDetails.SpotConfig.TotalSpots - this.sessionDetails.ClassMembers.length;
      this.spotsLeft = this.spotsLeft < 0 ? 0 : this.spotsLeft;

      this.sessionDetails.StartDate = new Date(this.sessionDetails.StartDate);
      const startDateStr = this.sessionDetails.StartDate.toISOString().split('T')[0];
      const startDateTime: Date = new Date(`${startDateStr}T${this.sessionDetails.StartTime}`);
      const endDateTime: Date = new Date(`${startDateStr}T${this.sessionDetails.EndTime}`);

      [this.dayOfWeek, this.month, this.dayOfMonth] = startDateTime.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' }).split(', ');
      [this.startTime, this.endTime] = [startDateTime, endDateTime].map(dt => dt.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }));
      this.startTime = this.startTime.replace(" AM", "").replace(" PM", "");

      this.sessionDetails.ClassMembers.forEach(classMember => {
        if(this.currentMember != null && classMember.MemberId == this.currentMember.Id)
        {
          this.isSessionBooked = true;
          this.classMember = classMember;

          if (this.currentMember.OnboardedAt === '0001-01-01T00:00:00') {
            window.setTimeout(() => this.downloadPopup.open(), 100);
            this.hideTimeout = window.setTimeout(() => this.downloadPopup.dismiss(), 10000);
          }
        }
      });

      if(this.spotsLeft === 0)
      {
        this.sessionWaitlist = await this.sessionWaitlistService.get(this.sessionId);
        this.sessionWaitlist.forEach(row => {
          if(row.MemberId == this.currentMember.Id)
          {
            this.isInWaitlist = true;
          }
        });
      }
    });    
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event : any) {
    Utils.makeElementFloating('.footer-area', '.floating-footer');
  }

  getDirectionUrl(): string {
    return `https://www.google.com/maps/dir/?api=1&destination=${this.sessionDetails.Class.Latitude},${this.sessionDetails.Class.Longitude}`;
  }

  isLoggedIn(): boolean {
    this.currentMember = this.authService.getLoggedInMember();
    return !!this.currentMember;
  }

  isInFuture(): boolean {
    var sessionStartTimestamp: number = Utils.getTimestampFromDateTime(this.sessionDetails.StartDate, this.sessionDetails.StartTime);
    if (sessionStartTimestamp < this.facilityService.utcTime.TimeStamp) {
      console.log('Session in the past', sessionStartTimestamp, this.facilityService.utcTime.TimeStamp);
      return false;
    }
    return true;
  }

  openSpotMapClicked() {
    if(this.isLoggedIn())
    {
      this.currentMember = this.authService.getLoggedInMember();
      this.sessionDetails.ClassMembers.forEach(classMember => {
        if(this.currentMember != null && classMember.MemberId == this.currentMember.Id)
        {
          this.isSessionBooked = true;
        }
      });

      if(this.inviteCodeStr && this.currentMember)
      {
        const redeemRequest : InviteCodeRedeemRequest = {
          MemberId : this.currentMember.Id,
          Code: this.inviteCodeStr
        };

        if(!this.isSessionBooked)
        {
          this.inviteCodeService.redeemInviteCode(redeemRequest).subscribe((data: InviteCode | string) => {
            if(typeof(data) === 'string')
            {
              this.alertService.error("You do not have enough credits to book a class.");
              return;
            } else {
              this.inviteCode = data;
              this.logService.debug("this.inviteCode", this.inviteCode);
            }
            this.memberService.getMutual(this.currentMember.Id).subscribe((data: MemberBasicInfo[]) => {
              this.friendsInfo = data;
              this.friendIds = this.friendsInfo.map(c => c.Id);
              this.logService.debug("this.friendIds",this.friendIds);
              this.openSpotMap();
            });
          });
        }
      } else {
        if(!this.isSessionBooked)
        {
          this.openSpotMap();
        }
      }
     } else {
      this.loginPopup.open(this.sessionDetails.Id);
     }
  }

  openSpotMap(delay: number = 0) {
    setTimeout(() => {
      //open class map popup
      const modalRef = this.modalService.open(SpotGridComponent, {
        ariaLabelledBy: 'modal-basic-title',
        // size: 'lg',
        centered: true,
        fullscreen: window.innerWidth <= 768,
        // windowClass: 'custom-class'
      });

      //Passing sessionDetails to popup
      modalRef.componentInstance.sessionDetails = this.sessionDetails;
      modalRef.componentInstance.friendIds = this.friendIds;
  
      //Retrieving ClassMember from popup
      modalRef.result.then((result: ClassMemberModel) => {
        if (result) {
          this.logService.debug("class booking details:", result);
          location.reload();
        }
      });
    }, delay);
  }

  cancelReservation(classMemberId: number) {
    this.logService.debug("Cancel class member id:", classMemberId);

    //open class map popup
    const modalRef =this.modalService.open(CancelReservationComponent, {
      ariaLabelledBy: 'modal-basic-title', 
      // size: 'lg',
      centered: true,
      fullscreen: window.innerWidth <= 768,
      // windowClass: 'custom-class'
    });

    //Passing sessionDetails & classMember to popup
    modalRef.componentInstance.sessionDetails = this.sessionDetails;
    modalRef.componentInstance.classMember = this.classMember;

    //Retrieving ClassMember from popup
    modalRef.result.then((result: boolean) => {
      if (result) {
        this.logService.debug("class booking details:", result);
        location.reload();
      }
    });
  }

  setMetaTags() {
    const [hours, minutes, seconds] = this.sessionDetails.StartTime.split(":").map(Number);
    this.sessionDetails.StartDate = new Date(this.sessionDetails.StartDate);
    const StartDateTime = new Date(this.sessionDetails.StartDate.getTime());
    StartDateTime.setHours(hours);
    StartDateTime.setMinutes(minutes);
    StartDateTime.setSeconds(seconds);
    var formttedStartDate = Utils.formatDate(StartDateTime, "MMMM dy");
    var formttedStartTime = Utils.formatDate(StartDateTime, "h:mm X");
    
    var title = this.sessionDetails.Studio.Name + " - "+ this.sessionDetails.Class.Name + " at " + environment.facility[environment.submerchant].name + " " + formttedStartDate + " at " + formttedStartTime;
    var url = environment.facility[environment.submerchant].links.website.url + "studio/class-details/" + this.sessionDetails.Id;
    
    //title
    this.titleService.setTitle(title);
    
    //description
    this.metaService.updateTag({ name: 'description', content: this.sessionDetails.Class.Description });

    //og tags
    this.metaService.updateTag({ property: "og:title", content: title});
    this.metaService.updateTag({ property: "og:description", content: this.sessionDetails.Class.Description });
    this.metaService.updateTag({ property: "og:image", content: this.sessionDetails.ClassPhoto });
    this.metaService.updateTag({ property: "og:url", content: url });

    //twitter tags
    this.metaService.updateTag({ name: "twitter:title", content: title });
    this.metaService.updateTag({ name: "twitter:description", content: this.sessionDetails.Class.Description });
    this.metaService.updateTag({ name: "twitter:image", content: this.sessionDetails.ClassPhoto });
    this.metaService.updateTag({ name: "twitter:url", content: url });
  }

  ngOnDestroy() {
    if (this.sessionSubscription) {
      this.sessionSubscription.unsubscribe();
    }

    if (this.facilitySubscription) {
      this.facilitySubscription.unsubscribe();
    }

    if (this.inviteCodeSubscription) {
      this.inviteCodeSubscription.unsubscribe();
    }
  }

  loggedIn(isLoggedIn: boolean) {
    if (isLoggedIn)
    {
      this.currentMember = this.authService.getLoggedInMember();
      this.sessionDetails.ClassMembers.forEach(classMember => {
        if(this.currentMember != null && classMember.MemberId == this.currentMember.Id)
        {
          this.isSessionBooked = true;
        }
      });
      if(!this.isSessionBooked)
      {
        this.openSpotMap(500);
      }
    }
  }

  inviteFriends() {
    this.inviteCodeService.generateInviteCode(new InviteCodeGenerateRequest(
      UserType.Member,
      this.currentMember.Id,
      InviteCodeIntent.ClassSession,
      this.sessionDetails.Id,
      -1
    )).subscribe(async (data : InviteCode) => {
      this.inviteCode = data;

      let openGraphMetadata = await this.inviteCodeService.getOpenGraphMetadata(this.inviteCode.Code);

      //open invite friends popup
      const modalRef =this.modalService.open(InviteComponent, {
        ariaLabelledBy: 'modal-basic-title', 
        // size: 'lg',
        centered: true,
        fullscreen: window.innerWidth <= 768,
        // windowClass: 'custom-class'
      });

      var localSessionTimestamp: number = Utils.getTimestampFromDateTime(this.sessionDetails.StartDate, this.sessionDetails.StartTime);
      var localSessionDateTime: Date = new Date(localSessionTimestamp);
      var title = this.sessionDetails.Studio.Name + " - " + this.sessionDetails.Class.Name + " at " + environment.facility[environment.submerchant].name + " " + Utils.formatDate(localSessionDateTime, "dddd, MMM d") + " at " + Utils.formatDate(localSessionDateTime, "h:mm X");
      var emailTitle = 'Hey! Join me at ' + this.sessionDetails.Studio.Name + '!🙌';
      var emailBody = 'Hey! Join me for ' + this.sessionDetails.Class.Name + ' at ' + this.sessionDetails.Studio.Name + ' on ' + Utils.formatDate(localSessionDateTime, "dddd, MMM d") + ' at ' + Utils.formatDate(localSessionDateTime, "h:mm X") + ' inside ' + environment.facility[environment.submerchant].name + '. Use the link below to book your spot! ' + this.getSharerUrl();
      //Passing InviteCode and InviteDialogModel to popup
      modalRef.componentInstance.inviteCode = this.inviteCode;
      modalRef.componentInstance.inviteDialogModel = new InviteDialogModel(
        openGraphMetadata.Title,
        openGraphMetadata.Description,
        openGraphMetadata.ImageUrl,
        openGraphMetadata.Url,
        emailTitle,
        emailBody
      );

      //Retrieving result from popup
      modalRef.result.then((result: void) => {
          this.logService.debug("invite popup closed:");
      });
    });
  }

  goBack() : void {
    this.location.back();
  }

  getSharerUrl() : string
  {
    return environment.facility[environment.submerchant].sharerBaseUrl + "share?invite_code=" + this.inviteCode.Code + "&location_id=" + 1
  }

  spotsLeftText(): string {
    return `${this.spotsLeft} spot${this.spotsLeft !== 1 ? 's' : ''} left`;
  }

  dlPopupClicked() {
      if (this.hideTimeout) {
          window.clearTimeout(this.hideTimeout);
          this.hideTimeout = 0;
      }
  }

  JoinWaitlistClicked() {
    if(this.isLoggedIn())
      {
        this.currentMember = this.authService.getLoggedInMember();
        if(this.currentMember)
        {
          const request : Partial<SessionWaitlist> = {
            MemberId : this.currentMember.Id,
            SessionId: this.sessionId,
            Action: Action.Add
          };
  
          if(!this.isSessionBooked)
          {
            this.inviteCodeSubscription = this.sessionWaitlistService.add(request).subscribe((result: boolean) => {
              if(result)
              {
                this.isInWaitlist = true;
                //open filter popup
                const modalRef = this.modalService.open(SessionWaitlistAddedComponent, {
                  ariaLabelledBy: 'modal-basic-title',
                  // size: 'lg',
                  centered: true,
                  fullscreen: window.innerWidth <= 768,
                  // windowClass: 'custom-class'
                });

                modalRef.componentInstance.classPhoto = this.sessionDetails?.ClassPhoto;
                modalRef.componentInstance.heading = "You're on the Waitlist!";
                modalRef.componentInstance.subHeading = "We will notify everyone on the waitlist as soon as a spot becomes available.";
                //Retrieving data from popup
                modalRef.result.then((result: boolean) => {
                  
                });
              }
              
            });
          }
        }
       } else {
        this.loginPopup.open(this.sessionDetails.Id);
       }
  }

  CancelWaitlistClicked() {
    if(this.isLoggedIn())
      {
        this.currentMember = this.authService.getLoggedInMember();
        if(this.currentMember)
        {
          this.isInWaitlist = false;
          const request : Partial<SessionWaitlist> = {
            MemberId : this.currentMember.Id,
            SessionId: this.sessionId,
            Action: Action.Remove
          };
  
          if(!this.isSessionBooked)
          {
            this.inviteCodeSubscription = this.sessionWaitlistService.remove(request).subscribe((result: boolean) => {
              if(result)
              {
                //open filter popup
                const modalRef = this.modalService.open(SessionWaitlistAddedComponent, {
                  ariaLabelledBy: 'modal-basic-title',
                  // size: 'lg',
                  centered: true,
                  fullscreen: window.innerWidth <= 768,
                  // windowClass: 'custom-class'
                });

                modalRef.componentInstance.classPhoto = this.sessionDetails?.ClassPhoto;
                modalRef.componentInstance.heading = "You're removed from the Waitlist!";
                modalRef.componentInstance.subHeading = "";
                //Retrieving data from popup
                modalRef.result.then((result: boolean) => {
                  this.isInWaitlist = false;
                });
              }
              
            });
          }
        }
       } else {
        this.loginPopup.open(this.sessionDetails.Id);
       }
  }
}