<div class="modal-header">
    <h4 class="modal-title drop-modal-title" id="modal-basic-title">INVITE FRIENDS</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
    <div class="content-wrapper">
        <div class="heading-wrapper">
            <h4 class="class-name-title">Bring a friend!</h4>
        </div>
        <div class="row">
            <div class="col-sm-12 info-wrapper">
                <div class="share-details-wrapper">
                    <div class="share-photo-wrapper" [ngStyle]="{'background-image': 'url(' + inviteDialogModel.ImageUrl + ')'}" [title]="inviteDialogModel.Title">
                        
                    </div>
                    <div class="share-details">
                        <p class="share-heading">{{inviteDialogModel.Title}}</p>
                        <!-- <p class="share-description">{{inviteDialogModel.Description}}</p> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-6 col-sm-6 info-wrapper">
                <a href="javascript:void(0)" (click)="copyLink()">
                    <div class="share-item">
                        <img src="/assets/images/svg/copy.svg" alt="Copy Link" />
                        <!-- <i class="bi bi-files"></i> -->
                        <span class="info-title">Copy Link</span>
                    </div>
                </a>
            </div>
            <div class="col-6 col-sm-6 info-wrapper">
                <a href="javascript:void(0)" (click)="shareOnEmail()">
                    <div class="share-item">
                        <img src="/assets/images/svg/email.svg" alt="Email" />
                        <!-- <i class="bi bi-envelope"></i> -->
                        <span class="info-title">Email</span>
                    </div>
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-sm-6 info-wrapper" *ngIf="isMobileBrowser()">
                <a href="javascript:void(0)" (click)="shareOnMessages()">
                    <div class="share-item">
                        <img src="/assets/images/svg/messages.svg" alt="Messages" />
                        <!-- <i class="bi bi-chat"></i> -->
                        <span class="info-title">Messages</span>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
