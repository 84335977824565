<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="content">
            <h1>Blog Details</h1>
            <a routerLink="/" class="link-btn">
                Back to Home
            </a>
        </div>
    </div>
</div>

<!-- Blog Details -->
<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="post-thumb">
                        <img src="assets/images/blog/blog1.jpg" alt="blog-details">
                    </div>
                    <h3>Unpatched virtual machine takeover bug affects compute engine</h3>
                    <div class="post-meta">
                        <ul>
                            <li><i class="fa-regular fa-calendar"></i> June 30, 2022</li>
                            <li><i class="fa-regular fa-comment-dots"></i> <a routerLink="/blog-details">4 Comments</a></li>
                        </ul>
                    </div>
                    <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur <strong>adipisicing</strong> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea <a routerLink="/">commodo</a> consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat.</p>
                    <ol>
                        <li>Nunc, mauris ut in vestibulum. Consectetur phasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam in </li>
                        <li>Most of the designer are very creative to do something , mauris ut in vestibulum. Consectetur phasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam in lobortis </li>
                        <li>There are two thing is very important in Consectetur phasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam.</li>
                        <li>Web design and development very creative to do something , mauris ut in vestibulum. Consectetur phasellus ultrices fusce nibh  venenatis, amet to all design and development.</li>
                    </ol>
                    <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>
                    <div class="row">
                        <div class="col-4">
                            <img src="assets/images/blog/blog5.jpg" alt="image">
                        </div>
                        <div class="col-4">
                            <img src="assets/images/blog/blog6.jpg" alt="image">
                        </div>
                        <div class="col-4">
                            <img src="assets/images/blog/blog7.jpg" alt="image">
                        </div>
                    </div>
                    <h3>Four major elements that we offer:</h3>
                    <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>
                    <ul>
                        <li>Scientific skills for getting a better result</li>
                        <li>Communication skills to getting in touch</li>
                        <li>A career overview opportunity available</li>
                        <li>A good work environment for work</li>
                    </ul>
                    <h3>Setting the mood with incense</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat.</p>
                    <blockquote class="wp-block-quote">
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        <cite>Tom Cruise</cite>
                    </blockquote>
                    <h3>The rise of marketing and why you need it</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur <strong>adipisicing</strong> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea <a routerLink="/">commodo</a> consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat.</p>
                    <div class="post-footer">
                        <div class="post-tags">
                            <span class="sub">Tags:</span>
                            <ul>
                                <li><a routerLink="/blog-grid" rel="tag">Fashion</a></li>
                                <li><a routerLink="/blog-grid" rel="tag">Design</a></li>
                            </ul>
                        </div>
                        <div class="article-share">
                            <ul class="social">
                                <li><span>Share:</span></li>
                                <li>
                                    <a href="#" class="facebook" target="_blank">
                                        <i class="fa-brands fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank">
                                        <i class="fa-brands fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank">
                                        <i class="fa-brands fa-linkedin-in"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="instagram" target="_blank">
                                        <i class="fa-brands fa-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="comments-area" id="comments">
                    <h3 class="comments-title">2 Comments:</h3>
                    <ol class="comment-list">
                        <li class="comment">
                            <div class="comment-body">
                                <footer class="comment-meta">
                                    <div class="comment-author vcard">
                                        <img src="assets/images/user/user1.jpg" class="avatar" alt="user">
                                        <b class="fn">John Jones</b>
                                    </div>
                                    <div class="comment-metadata">
                                        <span>January 01, 2021 at 10:59 am</span>
                                    </div>
                                </footer>
                                <div class="comment-content">
                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                </div>
                                <div class="reply">
                                    <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                </div>
                            </div>
                            <ol class="children">
                                <li class="comment">
                                    <div class="comment-body">
                                        <footer class="comment-meta">
                                            <div class="comment-author vcard">
                                                <img src="assets/images/user/user2.jpg" class="avatar" alt="user">
                                                <b class="fn">Steven Smith</b>
                                            </div>
                                            <div class="comment-metadata">
                                                <span>January 02, 2021 at 21:59 am</span>
                                            </div>
                                        </footer>
                                        <div class="comment-content">
                                            <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                        </div>
                                        <div class="reply">
                                            <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                        </div>
                                    </div>
                                    <ol class="children">
                                        <li class="comment">
                                            <div class="comment-body">
                                                <footer class="comment-meta">
                                                    <div class="comment-author vcard">
                                                        <img src="assets/images/user/user3.jpg" class="avatar" alt="user">
                                                        <b class="fn">Sarah Taylor</b>
                                                    </div>
                                                    <div class="comment-metadata">
                                                        <span>January 03, 2021 at 05:59 am</span>
                                                    </div>
                                                </footer>
                                                <div class="comment-content">
                                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                </div>
                                                <div class="reply">
                                                    <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                                </div>
                                            </div>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li class="comment">
                            <div class="comment-body">
                                <footer class="comment-meta">
                                    <div class="comment-author vcard">
                                        <img src="assets/images/user/user4.jpg" class="avatar" alt="user">
                                        <b class="fn">John Doe</b>
                                    </div>
                                    <div class="comment-metadata">
                                        <span>January 04, 2021 at 05:59 am</span>
                                    </div>
                                </footer>
                                <div class="comment-content">
                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                </div>
                                <div class="reply">
                                    <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                </div>
                            </div>
                            <ol class="children">
                                <li class="comment">
                                    <div class="comment-body">
                                        <footer class="comment-meta">
                                            <div class="comment-author vcard">
                                                <img src="assets/images/user/user1.jpg" class="avatar" alt="user">
                                                <b class="fn">James Anderson</b>
                                            </div>
                                            <div class="comment-metadata">
                                                <span>January 05, 2021 at 04:59 am</span>
                                            </div>
                                        </footer>
                                        <div class="comment-content">
                                            <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                        </div>
                                        <div class="reply">
                                            <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                        </div>
                                    </div>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <div class="comment-respond">
                        <h3 class="comment-reply-title">Leave A Reply</h3>
                        <p class="comment-notes">
                            <span id="email-notes">Your email address will not be published.</span>
                            Required fields are marked <span class="required">*</span>
                        </p>
                        <form class="comment-form">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input type="text" placeholder="Your Name*" name="author">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input type="email" placeholder="Your Email*" name="email">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <input type="url" placeholder="Website" name="url">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <textarea name="comment" cols="45" placeholder="Your Comment..." rows="5"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn">
                                        Post A Comment
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>