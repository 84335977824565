declare var $: any;
//system
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';

//services
import { LogService } from 'src/app/services/common/log.service';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from 'src/app/services/user.service';
import { InviteCodeService } from 'src/app/services/invite-code.service';

//interfaces
import { InviteCode, InviteDialogModel } from 'src/app/models/invite-code';

import { AppModule } from 'src/app/app.module';

//environment
import { environment } from 'src/environments/environment';

//utils
import { Utils } from 'src/app/services/common/utils';

declare const google: any;

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {
  submitted       : boolean  = false;
  person          : any;

  @Input() public inviteCode! : InviteCode;
  @Input() public inviteDialogModel! : InviteDialogModel;
  
  constructor(
    public activeModal: NgbActiveModal
    , private router: Router
    , private logService: LogService
    , private alertService: AlertService
    , private userService: UserService
    , private inviteCodeService: InviteCodeService
    , private clipboardService: ClipboardService
  ) 
  {
    
  }

  async ngOnInit() {
    this.person = this.userService.getUser();
    
    
  }

  closeModal() {
    this.activeModal.close();
  }

  copyLink() {
    this.logService.debug("copy link button clicked.");
    this.clipboardService.copyFromContent(this.getSharerUrl());
    this.alertService.info('Link copied to clipboard.', true, true);
  }

  getSharerUrl() : string
  {
    return environment.facility[environment.submerchant].sharerBaseUrl + "share?invite_code=" + this.inviteCode.Code + "&location_id=" + 1
  }

  async shareOnMessages() {
    this.logService.debug("share on messages button clicked.");

    const userAgent = navigator.userAgent;
    const shareContent = {
      text: this.inviteDialogModel.Title,
      url: this.getSharerUrl(),
    };

    if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {

      try {
        await navigator.share(shareContent);
        this.logService.debug('Content shared successfully on ios.');
      } catch (error) {
        this.logService.error('Error sharing content:', error);
      }

    } else if (userAgent.includes('Android')) {

      const encodedText = encodeURIComponent(shareContent.text);
      const encodedUrl = encodeURIComponent(shareContent.url);
      const shareUrl = `sms:?body=${encodedText}%20${encodedUrl}`;

      window.open(shareUrl);
      this.logService.debug('Content shared successfully on android.');

    } else {

      // User is on a platform other than iOS or Android
      // Handle accordingly or display a message that the feature is not supported
      this.logService.debug('User is on a platform other than iOS or Android.');
    }
  }

  shareOnEmail() {
    this.logService.debug("share on email button clicked.");

    const email = '';
    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(this.inviteDialogModel.EmailTitle)}&body=${encodeURIComponent(this.inviteDialogModel.EmailBody)}`;

    window.location.href = mailtoUrl;
  }

  shareOnInstagram() {
    this.logService.debug("share on instagram button clicked.");

    const url = this.inviteDialogModel.ImageUrl;
    const caption = this.inviteDialogModel.EmailBody;

    // Generate the Instagram share URL with the image URL and caption
    const instagramUrl = `instagram://library?AssetPath=${encodeURIComponent(url)}&InstagramCaption=${encodeURIComponent(caption)}`;

    // Open the Instagram app if available, or fallback to the web version
    window.location.href = instagramUrl;

    this.logService.debug('Content shared successfully on instagram.');
  }

  isMobileBrowser(): boolean {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      return true;
    }
    return false;
  }
}
