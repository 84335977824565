declare var $: any;

import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Title} from "@angular/platform-browser";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Stripe } from '@stripe/stripe-js';

import { LogService } from 'src/app/services/common/log.service';
import { AlertService } from 'src/app/services/alert.service';
import { FacilityService } from 'src/app/services/facility.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { MemberService } from 'src/app/services/member.service';
import { StripeService } from 'src/app/services/stripe.service';

import { MemberModel, MemberSetting } from 'src/app/models/member';

//environment
import { environment } from 'src/environments/environment';

import { AuthBaseComponent } from 'src/app/components/auth-base/auth-base.component';
import { Card } from 'src/app/models/common-models';
import { AddCardComponent } from 'src/app/components/stripe/add-card/add-card.component';

@Component({
  selector: 'df-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent extends AuthBaseComponent implements OnInit {
  @ViewChild('addCard') addCardPopup!: AddCardComponent;

  private stripePromise: Promise<Stripe | null>;

  submitted: boolean = false;
  memberInfo!: MemberModel;
  cards!: Card[];

  isLoading = false;

  context: any = undefined;

  constructor(private router: Router
    , private logService: LogService
    , private alertService: AlertService
    , private facilityService: FacilityService
    , private _authService: AuthService
    , private _userService: UserService
    , private _memberService: MemberService
    , private _stripeService: StripeService
    , private modalService: NgbModal
    , private titleService: Title
  ) {
    super(router, logService, alertService, facilityService);
    this.stripePromise = this.loadStripe();
  }

  override async ngOnInit() {
    await super.ngOnInit();
    //title
    this.titleService.setTitle(environment.facility[environment.submerchant].name + " Account -> Payment Methods");

    this.isLoading = true;
    this.cards = [];
    this.memberInfo = await this._memberService.getMember();
    this.cards = await this._stripeService.getCustomerCards(this.memberInfo.Id);
    this.logService.debug("this.cards",this.cards);
    this.isLoading = false;
  }

  async loadStripe(): Promise<Stripe | null> {
    try {
      const stripe = await import('@stripe/stripe-js');
      return stripe.loadStripe(environment.facility[environment.submerchant].stripe.publishedKey);
    } catch (error) {
      console.error('Failed to load Stripe:', error);
      return null;
    }
  }

  cardImage(brand: string) : string {
    var imageName: string = brand.toLowerCase().replace(/ /g, '_');
    var imagePath: string = `assets/images/svg/cards/${imageName}.svg`;
    const isAvailable = this.isImageAvailable(imageName);
    if(!isAvailable)
      imagePath = `assets/images/svg/cards/card.svg`
    return imagePath;
  }

  isImageAvailable(imageName: string): boolean {
    const availableImages = ['visa', 'american_express', 'discover', 'mastercard'];
    return availableImages.includes(imageName);
  }

  setCardDefault(cardId: string): void {
    this.isLoading = true;
    this._stripeService.setCardDefault(this.memberInfo.Id, cardId).subscribe((status: boolean) => {
      window.location.reload();
    });
  }

  removeCard(cardId: string): void {
    this.isLoading = true;
    this._stripeService.removeCard(this.memberInfo.Id, cardId).subscribe((status: boolean) => {
      window.location.reload();
    });
  }
  
  toggleMenu(event: Event) {
    const menu = $(event.target).siblings('.dropdown-menu');
    $('.dropdown-menu').not(menu).hide();
    menu.toggle();
  }

  openAddCardPopup(delay: number = 0) {
    setTimeout(() => {
      //open class map popup
      const modalRef = this.modalService.open(AddCardComponent, {
        ariaLabelledBy: 'modal-basic-title',
        // size: 'lg',
        centered: true,
        fullscreen: window.innerWidth <= 768,
        // windowClass: 'custom-class'
      });

      //Passing data to popup
      modalRef.componentInstance.memberId = this.memberInfo.Id;
  
      //Retrieving info from popup
      modalRef.result.then((result: boolean) => {
        if (result) {
          this.logService.debug("Card added successfully. ", result);
          //location.reload();
        }
      });
    }, delay);
  }
}
