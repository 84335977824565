declare var $: any;
//system
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Title, Meta} from "@angular/platform-browser";

//third-party
import * as moment from 'moment';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

//services
import { LogService } from 'src/app/services/common/log.service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { StudioService } from 'src/app/services/studio.service';
import { FacilityService } from 'src/app/services/facility.service';
import { MetaService } from 'src/app/services/meta.service';

//interfaces
import { Instructor} from 'src/app/models/studio/session-details';
import { InstructorDetails, ClassesTab } from 'src/app/models/meta';
import { MemberModel } from 'src/app/models/member';

//components
import { AuthBaseComponent } from 'src/app/components/auth-base/auth-base.component';

//utils
import { Utils } from 'src/app/services/common/utils';

@Component({
  selector: 'app-instructor-details',
  templateUrl: './instructor-details.component.html',
  styleUrls: ['./instructor-details.component.scss']
})

export class InstructorDetailsComponent extends AuthBaseComponent implements OnInit, OnDestroy {
  // Declare the slickCarousel property
  @ViewChild(SlickCarouselComponent)
  instructorCarousel!: SlickCarouselComponent;

  instructorId: number = 0;
  instructorName: string = "";
  instructorSlug: string = "";
  studioId: number = 0;
  instructors!: InstructorDetails[] | undefined;
  dateStr: string = moment().format("YYYY-MM-DD");

  currentMember!: MemberModel;

  instagramLink: string = "";
  facebookLink: string = "";
  tiktokLink: string = "";
  instructorDescription: string = "";
  instructorImagesSlides: any;
  classesTab!: ClassesTab;

  private instructorSubscription!: Subscription;
  private instructorAllSubscription!: Subscription;

  instructorDetails!: InstructorDetails;
  upcomingClassesCount: number = 0;

  instructorImagesSlideConfig = { slidesToShow: 1, slidesToScroll: 1, dots: true, infinite: true, autoplay: true, arrows: true, 
    responsive: [ { breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1, } } ] };

  constructor(
    private router: Router
    , private titleService:Title
    , private logService: LogService
    , private alertService: AlertService
    , private authService: AuthService
    , private facilityService: FacilityService
    , private modalService: NgbModal
    , private activeRoute: ActivatedRoute
    , private studioService: StudioService
    , private metaService: MetaService
  ) 
  { 
    super(router, logService, alertService, facilityService);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.dateStr = moment.unix(this.facilityService.utcTime.TimeStamp).format("YYYY-MM-DD");
    this.instructorSlug = this.activeRoute.snapshot.params['instructor'] || '';
    this.instructorName = Utils.reverseSlug(this.instructorSlug);

    this.currentMember = this.authService.getLoggedInMember();
    this.logService.debug("this.authService.getLoggedInMember()",this.authService.getLoggedInMember());

    this.instructorAllSubscription = this.metaService.getClassesMeta().subscribe((data: ClassesTab) => {
      this.classesTab = data;
      this.instructors = this.classesTab.Instructors;
      if(this.instructors)
      {
        var found: boolean = false;
        this.instructorId = parseInt(this.instructorName);
        if(Number.isNaN(this.instructorId))
        {
          this.instructors.forEach(element => {
            if(element.Person.FullName == this.instructorName)
            {
              this.instructorId = element.Id;
              this.titleService.setTitle(element.Person.FullName + " - Drop Fitness");
              found = true;
            }
          });
        } else if(this.instructorId > 0) {
          this.instructors.forEach(element => {
            if(element.Id == this.instructorId)
            {
              this.instructorId = element.Id;
              this.titleService.setTitle(element.Person.FullName + " - Drop Fitness");
              found = true;
            }
          });
          
        } else {
          this.instructorId = 0;
        }
      }
      
      if(this.instructorId == 0 )
      {
        this.router.navigate(['404']);
        return;
      }
      //Fetched instructor details from backend.
      this.instructorSubscription = this.studioService.getInstructorDetails(this.instructorId).subscribe((data: InstructorDetails) => {
        this.instructorDetails = data;
        this.instructorImagesSlides = this.instructorDetails.Images;
        this.logService.debug("this.instructorDetails", this.instructorDetails);
        this.instructorDetails.Settings.forEach(setting => {
          switch(setting.Key)
          {
            case 'facebook':
              this.facebookLink = setting.Value;
              break;
            case 'instagram':
              this.instagramLink = setting.Value;
              break;
            case 'tiktok':
              this.tiktokLink = setting.Value;
              break;
            case 'about':
                this.instructorDescription = setting.Value;
                break;
          }
        });
      });
    });
  }

  handleDataChanged(upcomingClassesCount: number) {
    this.upcomingClassesCount = upcomingClassesCount;
  }

  goToClassSchedule() {
    const queryParams = { studioId: this.studioId, instructorId: this.instructorId };
    this.router.navigate(['class-schedule'], { queryParams });
  }

  ngOnDestroy() {
    if (this.instructorAllSubscription) {
      this.instructorAllSubscription.unsubscribe();
    }
    if (this.instructorSubscription) {
      this.instructorSubscription.unsubscribe();
    }
  }
}