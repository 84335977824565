declare var $: any;
//System
import { Component, HostListener, ElementRef, Renderer2, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { catchError, Subject, takeUntil, tap, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import {Title, Meta} from "@angular/platform-browser";
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//Third Party
import * as moment from 'moment';

//Components
import { AuthBaseComponent } from 'src/app/components/auth-base/auth-base.component';

//Services
import { LogService } from 'src/app/services/common/log.service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import { ClassesService } from 'src/app/services/classes.service';
import { FeatureSupportService } from 'src/app/services/feature-support.service';
import { UserService } from 'src/app/services/user.service';
import { InviteCodeService } from 'src/app/services/invite-code.service';
import { MetaService } from 'src/app/services/meta.service';

//Models
import { MemberModel } from 'src/app/models/member';
import { InviteCode, InviteCodeRedeemRequest } from 'src/app/models/invite-code';
import { InstructorDetails, ClassesTab } from 'src/app/models/meta';
import { Studio, Session, SessionWaitlist} from 'src/app/models/studio/session-details';
import { FilterModel } from 'src/app/models/studio/classes';

//Utils
import { Utils } from 'src/app/services/common/utils';
import { SessionFilterComponent } from 'src/app/components/popups/session-filter/session-filter.component';
import { Action } from 'src/app/models/enums';
import { SessionWaitlistService } from 'src/app/services/session-waitlist.service';

@Component({
  selector: 'class-schedule',
  templateUrl: './class-schedule.component.html',
  styleUrls: ['./class-schedule.component.scss']
})

export class ClassScheduleComponent extends AuthBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('slickCarousel', { static: false }) slickCarousel!: SlickCarouselComponent;
  @ViewChild('sessionsWrapper', { static: false }) sessionsWrapperDiv!: ElementRef;

  date: string = moment().format("YYYY-MM-DD");
  currentMember!: MemberModel;

  studioIds: number[] = [];
  instructorIds: number[] = [];

  //Pagination
  slides : any = [];
  sessions : Session[] = [];
  firstVisibleSession! : Session;
  
  inviteCode!: InviteCode;
  inviteCodeStr: string = "";
  locationId: number = 1;

  slideStartDate = "";
  slideEndDate = "";
  rangeStart = "";
  rangeEnd = "";
  currentDate = new Date();
  currentDateYYYYMMDD = "";
  currentDateStr = "";
  disableScrollEvent = false;
  sessionLimit = 40;
  showLoadMore = true;
  classesTab!: ClassesTab;
  studios!: Studio[] | undefined;
  instructors!: InstructorDetails[] | undefined;
  filterCount: number = 0;

  slideConfig = {"infinite": false, "slidesToShow": 7, "slidesToScroll": 7, "arrows": false};

  //Sessions
  private readonly _unsubscribe: Subject<any> = new Subject();

  filteredSessions!: Session[] | null;
  bookings!: any[] | null;
  template: string = 'scheduleView';  //Passing itemIds to popup
  filterModel: FilterModel = {
    StudioIds: [],
    InstructorIds: [],
    StartTime: 5,
    EndTime: 23
  };


  private studioSubscription!: Subscription;

  constructor(private titleService:Title
    , private logService: LogService
    , private activeRoute: ActivatedRoute
    , private router: Router
    , private alertService: AlertService
    , private facilityService: FacilityService
    , private modalService: NgbModal
    , private _classesService: ClassesService
    , private _user: UserService
    , private authService: AuthService
    , private inviteCodeService: InviteCodeService
    , private _featureSupport: FeatureSupportService
    , private metaService: MetaService
    , private sessionWaitlistService: SessionWaitlistService
    , private renderer: Renderer2
    , private el: ElementRef
    ) {
    super(router, logService, alertService, facilityService);
    this.titleService.setTitle("Class Schedules - Drop Fitness");
    // this.meta.updateTag({ name: 'description', content: "Class schedules" })
    $("body").attr("id","class-schedule");
    // $(".navbar.navbar-area").removeClass("bg-light").addClass("bg-dark");
  }

  override async ngOnInit() {
    this.filteredSessions = null;
    await super.ngOnInit();

    this.locationId = this.activeRoute.snapshot.queryParams['location_id'];
    this.inviteCodeStr = this.activeRoute.snapshot.queryParams['invite_code'];

    this.currentMember = this.authService.getLoggedInMember();

    if(this.inviteCodeStr && this.currentMember)
    {
      const redeemRequest : InviteCodeRedeemRequest = {
        MemberId : this.currentMember.Id,
        Code: this.inviteCodeStr
      };

      this.inviteCodeService.redeemInviteCode(redeemRequest).subscribe((data: InviteCode | string) => {
        if(typeof(data) === 'string')
        {
          this.alertService.error(data);
          return;
        } else {
          this.inviteCode = data;
          this.alertService.info("Code has been redeemed successfully.");
          this.logService.debug("this.inviteCode", this.inviteCode);
        }
      });
    }

    var studioNameSlug : string = this.activeRoute.snapshot.queryParams['studio'] || '';
    var studioNameSlugArray : string[] = studioNameSlug.split(',');
    var instructorNameSlug : string = this.activeRoute.snapshot.queryParams['instructor'] || '';
    var instructorNameSlugArray : string[] = instructorNameSlug.split(',');
    var timeRangeStr : string = this.activeRoute.snapshot.queryParams['time_range'] || '';
    var timeRangeArray : string[] = timeRangeStr.split(',');

    if(timeRangeArray.length >= 2)
    {
      var startTime : number = parseInt(timeRangeArray[0]);
      if(Number.isNaN(startTime)){
        this.filterModel.StartTime = 5;
      } else {
        this.filterModel.StartTime = startTime;
      }

      var endTime : number = parseInt(timeRangeArray[1]);
      if(Number.isNaN(endTime)){
        this.filterModel.EndTime = 23;
      } else {
        this.filterModel.EndTime = endTime;
      }
    }

    this.studioSubscription = this.metaService.getClassesMeta().subscribe((data: ClassesTab) => {
      this.classesTab = data;
      this.studios = this.classesTab.Studios;
      this.instructors = this.classesTab.Instructors;
      this.logService.debug("this.studios",this.studios);
      this.logService.debug("this.instructors",this.instructors);
      if(this.studios)
      {
        for (const studioNameSlug of studioNameSlugArray) {
          var found: boolean = false;
          
          var studioIdTemp : number = parseInt(studioNameSlug);
          if(Number.isNaN(studioIdTemp))
          {
            var studioName : string = Utils.reverseSlug(studioNameSlug);
            this.studios.forEach(element => {
              if(element.Name == studioName)
              {
                this.filterModel.StudioIds.push(element.Id);
                found = true;
              }
            });
          }
          else if(studioIdTemp > 0)
          {
            this.studios.forEach(element => {
              if(element.Id == studioIdTemp)
              {
                this.filterModel.StudioIds.push(element.Id);
                found = true;
              }
            });
          }
        }
      }

      if(this.instructors)
      {
        for (const instructorNameSlug of instructorNameSlugArray) {
          var found: boolean = false;
          
          var instructorIdTemp : number = parseInt(instructorNameSlug);
          if(Number.isNaN(instructorIdTemp))
          {
            var instructorName : string = Utils.reverseSlug(instructorNameSlug);
            this.instructors.forEach(element => {
              if(element.Person.FullName == instructorName)
              {
                this.filterModel.InstructorIds.push(element.Id);
                found = true;
              }
            });
          }
          else if(instructorIdTemp > 0)
          {
            this.instructors.forEach(element => {
              if(element.Id == instructorIdTemp)
              {
                this.filterModel.InstructorIds.push(element.Id);
                found = true;
              }
            });
          }
        }
      }

      this.logService.debug("this.filterModel.StudioIds", this.filterModel.StudioIds);
      this.logService.debug("this.filterModel.InstructorIds", this.filterModel.InstructorIds);

      let todaysDate : string = moment().format("YYYY-MM-DD");

      this.setCurrentDate(todaysDate);
      
      this.fetchSessions().then(() => {
        this.getSlides();
        var filteredSessions : Session[] = this.sessions.slice(0, this.sessionLimit).filter(session => this.isInFuture(session));
        const startTimeRange = new Date(`1970-01-01T${this.getTwoDigitString(this.filterModel.StartTime)}:00:00`);
        const endTimeRange = new Date(`1970-01-01T${this.getTwoDigitString(this.filterModel.EndTime)}:00:00`);
        this.filteredSessions = filteredSessions.filter(session => {
          const sessionTime = new Date(`1970-01-01T${session.StartTime}`);
          return sessionTime >= startTimeRange && sessionTime <= endTimeRange;
        });
        this.addDayRows();
        this.jumpToDateInUI();
        this.calculateFilterCount();
      });
    });
  }

  jumpToDateInUI()
  {
    if(this.filteredSessions && this.filteredSessions.length > 0)
    {
      this.firstVisibleSession = this.filteredSessions[0];
      let currentMomentDate: moment.Moment = moment(this.firstVisibleSession.StartDate);
      this.currentDateYYYYMMDD = currentMomentDate.format("YYYY-MM-DD");
      this.currentDateStr = currentMomentDate.format("dddd, MMMM DD");
      this.setPaginationToDate(this.currentDateYYYYMMDD);

      var slideIndexToSet = 1; // The index of the slide you want to set
      this.slides.forEach((slide: any, i: number) => {
        if(slide.DateTime == this.currentDateYYYYMMDD)
        {
          slide.IsCurrent = true;
          slideIndexToSet = i;
          // Set the active slide
          if(this.slickCarousel)
            this.slickCarousel.slickGoTo(slideIndexToSet);
        } else {
          slide.IsCurrent = false;
        }
      });

      var scrollToSelector = `.session-${this.currentDateYYYYMMDD}`;
      const element = document.querySelector(scrollToSelector) as HTMLElement;
      const element1 = document.querySelector(scrollToSelector);
      if (element && element1) {
        const elementHeight = element.offsetHeight;
        const dataIndex = parseInt(element1.getAttribute('data-index')!);
        $('#sessionsWrapper').animate({
          scrollTop: (dataIndex * elementHeight)
        }, { 
          duration : 1000,
          complete: function() {
            this.disableScrollEvent = false;
          }
        });
      }
    }
  }

  //Pagination
  onDateSelect(dateTime: string) {
    let startIndex = -1;
    this.setCurrentDate(dateTime);

    this.disableScrollEvent = true;
    $(window).scrollTop(0);

    for (let i = 0; i < this.sessions.length; i++) {
      if ((moment(this.sessions[i].StartDate).format("YYYY-MM-DD") == dateTime) || (startIndex == -1 && moment(this.sessions[i].StartDate).isAfter(moment(dateTime)))) {
        startIndex = i;
        break;
      }
      if(startIndex == -1 && moment(this.sessions[i].StartDate).isAfter(moment(dateTime)))
      {
        startIndex = i;
        break;
      }
    }
    
    if (startIndex !== -1) {
      var nextSessions: Session[] = this.sessions.slice(startIndex, startIndex + (this.sessionLimit + 1));
      if(this.filteredSessions)
      {
        var filteredSessions : Session[] = nextSessions.filter(session => this.isInFuture(session));
        const startTimeRange = new Date(`1970-01-01T${this.getTwoDigitString(this.filterModel.StartTime)}:00:00`);
        const endTimeRange = new Date(`1970-01-01T${this.getTwoDigitString(this.filterModel.EndTime)}:00:00`);
        this.filteredSessions = filteredSessions.filter(session => {
          const sessionTime = new Date(`1970-01-01T${session.StartTime}`);
          return sessionTime >= startTimeRange && sessionTime <= endTimeRange;
        });
      }
      this.addDayRows();
      if(nextSessions.length < this.sessionLimit)
        this.showLoadMore = false;
    } else {
      this.showLoadMore = false;
    }

    this.jumpToDateInUI();
    
    this.disableScrollEvent = false;
  }

  calculateFilterCount()
  {
    this.filterCount = 0;
    if(this.filterModel.InstructorIds.length > 0)
      this.filterCount++;
    if(this.filterModel.StudioIds.length > 0)
      this.filterCount++;
    if(this.filterModel.StartTime != 5 || this.filterModel.EndTime != 23)
      this.filterCount++;
  }

  setPaginationToDate(dateTime: string) {
    this.setCurrentDate(dateTime);
    $(".date.current").removeClass("current");
    $(".date-paginator-slider").find("[data-datetime='" + dateTime + "']").addClass("current");
  }

  slickInit(e : any) {
    setTimeout(() => {
      this.setRange();
    }, 100);
  }
  
  afterChange(e : any) {
    this.setRange();
  }

  setCurrentDate(dateTime: string){
    this.currentDate = new Date(dateTime);
    this.date = dateTime;
    let currentMomentDate: moment.Moment = moment(dateTime);
    this.currentDateYYYYMMDD = currentMomentDate.format("YYYY-MM-DD");
    this.currentDateStr = currentMomentDate.format("dddd, MMMM DD");
  }

  setRange() {
    this.rangeStart = $(".slide.slick-active:first").data("month") + " " + $(".slide.slick-active:first").data("date");
    this.rangeEnd = $(".slide.slick-active:last").data("month") + " " + $(".slide.slick-active:last").data("date");
  }

  getMonth(dateTime: string) {
    let momentDate: moment.Moment = moment(dateTime);
    return momentDate.format("MMM");
  }

  getDate(dateTime: string) {
    let momentDate: moment.Moment = moment(dateTime);
    return momentDate.format("D");
  }

  getDay(dateTime: string) {
    let momentDate: moment.Moment = moment(dateTime);
    return momentDate.format("dd");
  }

  getSlides() {
    if(this.sessions && this.sessions.length > 0)
    {
      const minStartDate = this.sessions.reduce((min, session) => {
        const startDate = new Date(session.StartDate);
        return startDate < min ? startDate : min;
      }, new Date(this.sessions[0].StartDate));
      
      const maxStartDate = this.sessions.reduce((max, session) => {
        const startDate = new Date(session.StartDate);
        return startDate > max ? startDate : max;
      }, new Date(this.sessions[0].StartDate));

      this.slides = [];
      moment.updateLocale('en', {
          week : {
              dow :0  // 0 to 6 sunday to saturday
          }
      });
      let fromDate : moment.Moment = moment(minStartDate).startOf('week');
      let fromDateStr : string = fromDate.format("YYYY-MM-DD");
      let toDate : moment.Moment = moment(maxStartDate).endOf('week');
      let currentDate : moment.Moment = moment(moment().format("YYYY-MM-DD"));
      let type : moment.unitOfTime.Diff = "days";
      let diff = toDate.diff(fromDate, type);
      for (let i = 0; i <= diff; i++) {
        let date = moment(fromDateStr).add(i, type);
        let dateStr = date.format("YYYY-MM-DD");
        this.slides.push(
          {
            "DateTime": dateStr,
            "IsDisabled": date.valueOf() < currentDate.valueOf() ? true : false, 
            "IsCurrent": date.format("YYYY-MM-DD") == currentDate.format("YYYY-MM-DD") ? true : false
          }
        );
      }
      this.setCurrentDate(currentDate.format("YYYY-MM-DD"));
    }
  }

  //Sessions
  ngAfterViewInit(): void {
    // Access the slick instance after the view has been initialized
    const slickInstance = this.slickCarousel;
    this._user.fetchMemberAccount(false)?.pipe(
      takeUntil(this._unsubscribe),
      tap((memberAccount: any) => {
        this.bookings = (memberAccount.Upcoming.ClassBookings || []).filter((cb: any) => {
          return cb.MemberId === memberAccount.Id;
        });
      }),
      catchError((error: any) => {
        this.logService.error("MemberAccount error", error);
        return error;
      })
    ).subscribe();
  }

  isSupported(): boolean {
    return this._featureSupport.isSupported('reserve');
  }

  isInFuture(session: Session): boolean {
    var sessionStartTimestamp: number = Utils.getTimestampFromDateTime(session.StartDate, session.StartTime);
    if(sessionStartTimestamp < Date.now())
      return false;
    return true;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    if(this.disableScrollEvent)
      return;
    
    var currentYCoordinate = window.scrollY;
    const container = document.querySelector('#sessionsWrapper');
    const paginatorContainer = document.querySelector('.page-paginator-wrapper');
    const footer = document.querySelector('.footer-area');
    const nav = document.querySelector('.navbar-area');
    const filterButton = this.el.nativeElement.querySelector('.filter-button');
    
    const listItems = document.querySelectorAll('.sessions-wrapper .session-wrapper');
    if(container && paginatorContainer && footer && nav)
    {
      var height = container.getBoundingClientRect().height + paginatorContainer.getBoundingClientRect().height + nav.getBoundingClientRect().height - (footer.getBoundingClientRect().height * 2);
      if(currentYCoordinate > height)
      {
        this.renderer.setStyle(filterButton, 'position', 'absolute');
      } else {
        this.renderer.setStyle(filterButton, 'position', 'fixed');
      }
    }



    if (container && paginatorContainer && listItems.length > 0) {
      const containerTop = paginatorContainer.getBoundingClientRect().bottom;//280;//container.getBoundingClientRect().top;
      let firstVisibleIndex = -1;

      for (let i = 0; i < listItems.length; i++) {
        const listItemTop = listItems[i].getBoundingClientRect().top;
        if (listItemTop >= containerTop) {
          firstVisibleIndex = i - 1;
          break;
        }
      }

      if (firstVisibleIndex >= 0 && this.filteredSessions) {
        this.firstVisibleSession = this.filteredSessions[firstVisibleIndex];
        let currentMomentDate: moment.Moment = moment(this.firstVisibleSession.StartDate);
        this.currentDateYYYYMMDD = currentMomentDate.format("YYYY-MM-DD");
        this.currentDateStr = currentMomentDate.format("dddd, MMMM DD");
        this.setPaginationToDate(this.currentDateYYYYMMDD);

        var slideIndexToSet = 1; // The index of the slide you want to set
        this.slides.forEach((slide: any, i: number) => {
          if(slide.DateTime == this.currentDateYYYYMMDD)
          {
            slide.IsCurrent = true;
            slideIndexToSet = i;
            // Set the active slide
            if(this.slickCarousel)
              this.slickCarousel.slickGoTo(slideIndexToSet);
          } else {
            slide.IsCurrent = false;
          }
        });
      }
    }
  }

  onLoadMore() {
    this.disableScrollEvent = true;
    var lastSession: Session;
    if (this.filteredSessions && this.filteredSessions.length > 0) {
      lastSession = this.filteredSessions[this.filteredSessions.length - 1];

      const startIndex = this.sessions.findIndex(session => session.Id === lastSession.Id);

      if (startIndex !== -1) {
        var nextSessions: Session[] = this.sessions.slice(startIndex + 1, startIndex + (this.sessionLimit + 1));

        var filteredSessions : Session[] = this.filteredSessions.concat(nextSessions);
        const startTimeRange = new Date(`1970-01-01T${this.getTwoDigitString(this.filterModel.StartTime)}:00:00`);
        const endTimeRange = new Date(`1970-01-01T${this.getTwoDigitString(this.filterModel.EndTime)}:00:00`);
        this.filteredSessions = filteredSessions.filter(session => {
          const sessionTime = new Date(`1970-01-01T${session.StartTime}`);
          return sessionTime >= startTimeRange && sessionTime <= endTimeRange;
        });
        this.addDayRows();
        if(nextSessions.length < this.sessionLimit)
          this.showLoadMore = false;
      } else {
        this.showLoadMore = false;
      }
    }
    this.disableScrollEvent = false;
  }

  addDayRows() {
    var updatedSessions : Session[] = [];
    var currentDate: Date;
    if (this.filteredSessions && this.filteredSessions.length > 0) {
      this.filteredSessions.forEach((session: Session, i: number) => {
        if(currentDate)
        {
          if(currentDate != session.StartDate) {
            if(session.Id > 0)
              updatedSessions.push(this.getEmptySession(session.StartDate));
            currentDate = session.StartDate;
          }
        } else {
          currentDate = session.StartDate;
        }
        updatedSessions.push(session);
      });
    }
    this.filteredSessions = updatedSessions;
  }

  getEmptySession(date: Date): Session
  {
    var session: Session = {
      Id: 0,
      StudioName: '',
      StudioImageUrl: '',
      ClassName: '',
      InstructorName: '',
      StartDate: date,
      StartTime: '',
      EndTime: '',
      Duration: 0,
      TotalSpots: 0,
      SpotsBooked: 0,
      SpotsLeft: 0,
    }
    return session;
  }

  getTimeText(time: string)
  {
    return moment("0001-01-01 " + time).format("h:mm A");
  }

  getDateText(date: Date)
  {
    return moment(date).format("dddd, MMMM DD");
  }

  getMilitaryDateText(date: Date)
  {
    return moment(date).format("YYYY-MM-DD");
  }

  getDayText(date: Date)
  {
    return moment(date).format("ddd").toUpperCase();
  }

  goToSession(sessionId: number): void {
    if(sessionId <= 0)
      return;
    if (!this.isSupported()) return;
    this.logService.debug("goToSession", sessionId);
    this.router.navigate(['studio', 'class-details', sessionId]);
  }

  isLoggedIn(): boolean {
    this.currentMember = this.authService.getLoggedInMember();
    return !!this.currentMember;
  }

  /**
   * Handle the error
   * @param {*} error
   */
   _handleError(error: any) {
    // tslint:disable-next-line: prefer-const
    var errorMessage = error.message;
    return errorMessage;
  }

  fetchSessions(): Promise<Session[]> {
    return new Promise((resolve, reject) => {
      this._classesService.getPublicSessions(this.filterModel.StudioIds, this.filterModel.InstructorIds)
        .pipe(
          takeUntil(this._unsubscribe),
          tap(sessions => {
            this.sessions = (sessions as Session[]);
            if(this.sessions && this.sessions.length > 0)
            {
              this.firstVisibleSession = this.sessions[0];
            }
            resolve(this.sessions);
          }),
          catchError((error: any) => {
            this.logService.error("ClassSessions error", error);
            reject(this._handleError(error));
            return error;
          })
        )
        .subscribe();
    });
  }

  isReserved(session: Session): boolean {
    if (this.bookings && this.bookings.length) {
      return !!this.bookings.find((b: any) => b.SessionId === session.Id);
    }
    return false;
  }

  screenAdoptedName(name: string): string {
    if (window.screen.availWidth < 768) {
      return name.split(' ')[0];
    }
    return name;
  }

  spotsLeftText(session: Session): string {
    return `${session.SpotsLeft} spot${session.SpotsLeft !== 1 ? 's' : ''} left`;
  }

  filterSessions() : void {
    //open filter popup
    const modalRef = this.modalService.open(SessionFilterComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      centered: false,
      windowClass: 'filter-popup-wrapper',
      modalDialogClass: 'filter-popup',
      fullscreen: window.innerWidth <= 768,
    });

    modalRef.componentInstance.filterModel = this.filterModel;
    modalRef.componentInstance.instructors = this.instructors;
    modalRef.componentInstance.studios = this.studios;
    //Retrieving data from popup
    modalRef.result.then((result: FilterModel|boolean) => {
      if(typeof result != "boolean")
      {
        this.logService.debug("data:", result);
        this.filterModel = result;
        this.calculateFilterCount();
        window.location.href = this.generateUrl();
      }
    });
  }

  generateUrl() : string
  {
    var studioSlugs : string[] = [];
    var instructorSlugs : string[] = [];

    for (var studioId of this.filterModel.StudioIds) {
      this.studios?.forEach(element => {
        if(element.Id == studioId)
        {
          studioSlugs.push(Utils.createSlug(element.Name));
        }
      });
    }
    for (var instructorId of this.filterModel.InstructorIds) {
      this.instructors?.forEach(element => {
        if(element.Id == instructorId)
        {
          instructorSlugs.push(Utils.createSlug(element.Person.FullName));
        }
      });
    }
    // Create an array to hold the query parameters
    const queryParams: string[] = [];

    // Add the instructor query parameter
    if (instructorSlugs.length > 0) {
        queryParams.push(`instructor=${instructorSlugs.join(',')}`);
    }

    // Add the studio query parameter
    if (studioSlugs.length > 0) {
      queryParams.push(`studio=${studioSlugs.join(',')}`);
    }

    // Conditionally add the time_range query parameter
    if (this.filterModel.StartTime !== 5 || this.filterModel.EndTime !== 23) {
        queryParams.push(`time_range=${this.filterModel.StartTime},${this.filterModel.EndTime}`);
    }

    if(this.locationId > 0)
    {
      queryParams.push(`location_id=${this.locationId}`);
    }

    if(this.inviteCodeStr != null && this.inviteCodeStr !== "")
    {
      queryParams.push(`invite_code=${this.inviteCodeStr}`);
    }

    // Join the query parameters with '&' and create the final query string
    const queryString: string = "/class-schedule?" + queryParams.join('&');

    return queryString;
  }


  getTwoDigitString(num: number): string {
    // Convert the number to a string
    let numStr = num.toString();

    // If the number is less than 10, prepend '0' to it
    if (num < 10) {
        numStr = '0' + numStr;
    }

    return numStr;
}

  ngOnDestroy() {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
    
    if (this.studioSubscription) {
      this.studioSubscription.unsubscribe();
    }
  }
}