<df-promo-banner *ngIf="!isLoggedIn() && bannerObj" id="promoBanner" [banner]="bannerObj"></df-promo-banner>

<app-alert></app-alert>

<app-banner></app-banner>

<app-pricing></app-pricing>

<app-classes></app-classes>

<app-training></app-training>

<app-wellness></app-wellness>

<!-- <app-social-media></app-social-media> -->

<app-location></app-location>

<df-download-app #download (click)="dlPopupClicked()"></df-download-app>
