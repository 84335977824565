import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

constructor(private http: HttpClient) { }

  sendPhoneLinkSms = (phone: string) => {
    if (!phone) return;

    let phoneNumber = phone.replace(/\D/g, "");
    if (phoneNumber.length != 10) return;

    let platform = this.getMobileOperatingSystem();
    return this.http.post(`${environment.facility[environment.submerchant].facilityUrl}api/v1/Public/PhoneLink?phone=${phoneNumber}&platform=${platform}`, {});
  }

  getMobileOperatingSystem() {
    return getMobilePlatform();
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error: any) {
      // tslint:disable-next-line: prefer-const
      var errorMessage = error.message;
      return errorMessage;
  }

}

export function getMobilePlatform() {
  var userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
      return "android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod|Macintosh/.test(userAgent)) {
      return "ios";
  }

  return "unknown";
};
