<ng-template #login let-modal>
  <div class="modal-header">
    <div class="modal-title fs-5">LOG IN / SIGN UP</div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body text-center">

    <div *ngIf="loginStep==='phone'" class="container show">
      <div class="row mb-4 mt-4">
        <div class="col"><img class="logo-img-gray"></div>
      </div>
      <div class="row">
        <div class="col">
          <h4>{{title}}</h4>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">{{loginText}}</div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <form #f="ngForm" class="form-floating form-phone-number needs-validation" (keypress)="phoneKeyPress($event)" autocomplete="off" [formGroup]="phoneForm" novalidate (submit)="enterPhone()">
            <div class="input-group has-validation">
              <span class="input-group-text" (click)="country.open(countryCode)">
                <img src="{{flag()}}" class="flag">
                <span class="px-1">{{dialCode}}</span>
              </span>
              <div class="form-floating">
                <input #phone type="tel" name="phone" formControlName="Phone" class="form-control" id="phone" placeholder="Mobile Number" required autofocus (input)="phoneChange(phone)">
                <label for="phone">Mobile Number</label>
              </div>
              <div class="invalid-feedback">
                Please enter a phone number.
              </div>
            </div>

            <button type="submit" class="btn btn-dark mt-4 w-100" [disabled]="submitted || !hasPhone()">
              <div *ngIf="submitted" class="spinner-border" role="status">
                <span class="visually-hidden">Submitting...</span>
              </div>
              <span *ngIf="!submitted">Confirm</span>
            </button>
          </form>
        </div>
      </div>
    </div>

    <div *ngIf="loginStep==='verify'" class="container">
      <div class="row mb-4 mt-4">
        <div class="col"><img class="logo-img-gray"></div>
      </div>
      <div class="row">
        <div class="col">
          <h4>Confirm Log In</h4>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          We sent a 6-digit confirmation code to {{formattedPhoneNumber()}}. Didn't receive the code? Resend
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <form class="form-floating" autocomplete="off">
            <input #code type="number" class="form-control" id="codeValue" placeholder="Enter Code" value="" (keypress)="codeKeyPress($event)" (input)="codeValue = code.value;">
            <label for="phoneValue">Enter Code</label>

            <button type="button" class="btn btn-dark mt-4 w-100" [disabled]="submitted || !codeValue || codeValue.length != 6" (click)="enterCode()">
              <div *ngIf="submitted" class="spinner-border" role="status">
                <span class="visually-hidden">Submitting...</span>
              </div>
              <span *ngIf="!submitted">Confirm</span>
            </button>
          </form>
        </div>
      </div>
    </div>

    <div *ngIf="loginStep==='register'" class="container">
      <form class="form-floating profileForm" (ngSubmit)="registerMember()" [formGroup]="profileForm" novalidate >
        <div class="row">
          <div class="col">
            <h4 *ngIf="!showGetStarted">Confirm your booking</h4>
            <h4 *ngIf="showGetStarted">Create your account</h4>
          </div>
        </div>
        <div class="row">
          <div class="col" *ngIf="!showGetStarted">
            Please enter your contact information to complete your booking and unlock a free week of classes.
          </div>
          <div class="col" *ngIf="showGetStarted">
            Please enter your contact information to create your account and unlock a free 7 day trial.
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <!-- <form class="form-floating"> -->
              <div class="container">
                <div class="row mt-3">
                  <div class="col-6">
                    <div class="form-floating">
                      <input type="text" id="FirstName" name="firstName" formControlName="FirstName" [ngClass]="{'form-control':true, 'is-invalid':isInvalid('FirstName')}" placeholder="First Name" required>
                      <label class="form-label" for="FirstName">First Name</label>
                      <div *ngIf="isInvalid('FirstName')" class="invalid-feedback">
                        First name is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-floating">
                      <input type="text" id="LastName" name="lastName" formControlName="LastName" [ngClass]="{'form-control':true, 'is-invalid':isInvalid('LastName')}" placeholder="Last Name" required>
                      <label for="LastName">Last Name</label>
                      <div *ngIf="isInvalid('LastName')" class="invalid-feedback">
                        Last name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <div class="form-floating">
                      <input type="email" id="Email" name="email" formControlName="Email" [ngClass]="{'form-control':true, 'is-invalid':isInvalid('Email')}" placeholder="Enter your email" required>
                      <label class="form-label" for="Email">Email</label>
                      <div *ngIf="isInvalid('Email')" class="invalid-feedback">
                        Email is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-6">
                    <div class="form-floating">
                      <input type="date" id="BirthDate" name="birthDate" formControlName="BirthDate" [ngClass]="{'form-control':true, 'is-invalid':isInvalid('BirthDate')}" placeholder="Date of Birth" required min="{{minDOB()}}" max="{{maxDOB()}}">
                      <label class="form-label" for="dobValue">Date of Birth</label>
                      <div *ngIf="isInvalid('BirthDate')" class="invalid-feedback">
                        Birth date is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-floating">
                      <select #gender id="Gender" name="gender" formControlName="Gender" [ngClass]="{'form-select':true, 'is-invalid':isInvalid('Gender')}" placeholder="Gender" required>
                        <option selected disabled value="">Choose...</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Non-binary">Non-binary</option>
                        <option value="Other">Other</option>
                      </select>
                      <label class="form-label" for="Gender">Gender</label>
                      <div *ngIf="isInvalid('Gender')" class="invalid-feedback">
                        Gender is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-6">
                    <div class="form-floating">
                      <input type="text" id="ZipCode" name="zipCode" type="number" maxlength="5" minlength="5" formControlName="ZipCode" [ngClass]="{'form-control':true, 'is-invalid':isInvalid('ZipCode')}" placeholder="Zip Code">
                      <label class="form-label" for="ZipCode">Zip Code</label>
                      <div *ngIf="isInvalid('ZipCode')" class="invalid-feedback">
                        Zip code is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <button type="submit" class="btn btn-dark mt-4 w-100" [disabled]="submitted">
              <div *ngIf="submitted" class="spinner-border" role="status">
                <span class="visually-hidden">Submitting...</span>
              </div>
              <span *ngIf="!submitted">Confirm</span>
            </button>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="loginStep==='welcome'" class="container">
      <div class="row">
        <div class="col">
          <h4>Logging in...</h4>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <div class="loader"></div>
          <!-- <button type="button" class="btn btn-dark mt-4 w-100" (click)="modal.dismiss('Success')">Close</button> -->
        </div>
      </div>
    </div>

    <div *ngIf="loginStep==='error'" class="container">
      <div class="row">
        <div class="col">
          <h4>An error has occured. Please try again.</h4>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <button type="button" class="btn btn-dark mt-4 w-100" (click)="modal.dismiss('Error')">Close</button>
        </div>
      </div>
    </div>

    <div *ngIf="loginStep==='get-started'" class="container">
      <div class="row mb-4 mt-4">
        <div class="col"><img class="logo-img-gray"></div>
      </div>
      <div class="row">
        <div class="col">
          <h4>Welcome to Drop!</h4>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          Your free trial will start on your first workout! Feel free to book a class or visit us for a workout to start!
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <button type="button" class="btn btn-dark mt-4 w-100" (click)="viewSchedule()">VIEW CLASS SCHEDULE</button>
        </div>
      </div>
    </div>

  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="modal.close('Save click')">Close</button>
    <button type="button" class="btn btn-primary">Login</button>
  </div> -->

  <df-country-select #country (select)="changeCountry($event)"></df-country-select>
</ng-template>