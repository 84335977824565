<div id="wellness" class="works-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <h2>Wellness & Recovery</h2>
            <p>Drop Fitness offers luxe amenties and products to help balance your mind, body, and spirit. </p>
        </div>
        <div class="faq-accordion">
            <accordion [closeOthers]="false">
                <accordion-group heading="Luxury Locker Rooms" [isOpened]="true">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <img [src]="'/assets/images/df/accordion/Accordion_01.jpg'" alt="IMG" class="mb-3" />
                            </div>
                            <div class="col-lg-6 col-sm-12 d-flex flex-column justify-content-center">
                                <div class="faq-title">Renew. Restore. Revive.</div>
                                Our locker rooms are designed elegantly so that you can be focused on recovery to sustain your fitness training while restoring your energy.
                            </div>
                        </div>
                    </div>
                </accordion-group>
                <accordion-group heading="Sauna">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <img [src]="'/assets/images/df/accordion/Accordion_02.jpg'" alt="IMG" class="mb-3" />
                            </div>
                            <div class="col-lg-6 col-sm-12 d-flex flex-column justify-content-center">
                                <div class="faq-title">Relax. Cleanse. Rejuvenate.</div>
                                Our luxury saunas aren’t just relaxing to use, they are beneficial to the body whether you just worked out or are just about to workout. 
                            </div>
                        </div>
                    </div>
                </accordion-group>
                <accordion-group heading="Cold Plunge">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <img [src]="'/assets/images/df/accordion/Accordion_03.jpg'" alt="IMG" class="mb-3" />
                            </div>
                            <div class="col-lg-6 col-sm-12 d-flex flex-column justify-content-center">
                                <div class="faq-title">Boost your energy.</div>
                                Try combining cold water therapy of our cold plunge shower with heat exposure from the sauna to boost post-workout recovery.
                            </div>
                        </div>
                    </div>
                </accordion-group>
                <accordion-group heading="Wellness Café">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <img [src]="'/assets/images/df/accordion/Accordion_04.jpg'" alt="IMG" class="mb-3" />
                            </div>
                            <div class="col-lg-6 col-sm-12 d-flex flex-column justify-content-center">
                                <div class="faq-title">Healthy, Nutritious Eats.</div>
                                Life changing nutrition. Our curated menus are designed to give your body what it needs to thrive.
                            </div>
                        </div>
                    </div>
                </accordion-group>
                <accordion-group heading="Kids Club">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <img [src]="'/assets/images/df/accordion/Accordion_05.jpg'" alt="IMG" class="mb-3" />
                            </div>
                            <div class="col-lg-6 col-sm-12 d-flex flex-column justify-content-center">
                                <div class="faq-title">Complimentary kids club.</div>
                                Our day care services are provided by an experienced team of certified sitters. Ages 6 mo. - 8 yrs.
                            </div>
                        </div>
                    </div>
                </accordion-group>
                <accordion-group heading="The Shop">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <img [src]="'/assets/images/df/accordion/Accordion_06.jpg'" alt="IMG" class="mb-3" />
                            </div>
                            <div class="col-lg-6 col-sm-12 d-flex flex-column justify-content-center">
                                <div class="faq-title">Shop Premium Brands.</div>
                                We curate the best fitness, health, beauty, and wellness products and activewear.
                            </div>
                        </div>
                    </div>
                </accordion-group>
            </accordion>
        </div>
    </div>
</div>