<div
    class="work-process-area pt-100 pb-70"
    [ngClass]="{'pt-0': router?.url === '/business-agency'}"
>
    <div class="container">
        <div class="section-title">
            <h2>Our Work Process</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="work-process">
            <img src="assets/images/man-with-mobile.png" alt="logo">
            <div class="work-process-list">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="fa-solid fa-compass-drafting"></i>
                    </div>
                    <h3>Surface</h3>
                    <span>Visual Design</span>
                </div>
                <div class="single-work-process">
                    <div class="icon">
                        <i class="fa-brands fa-sketch"></i>
                    </div>
                    <h3>Skeleton</h3>
                    <span>Wireframes</span>
                </div>
                <div class="single-work-process">
                    <div class="icon">
                        <i class="fa-solid fa-chart-bar"></i>
                    </div>
                    <h3>Structure</h3>
                    <span>Screen Flow</span>
                </div>
                <div class="single-work-process">
                    <div class="icon">
                        <i class="fa-solid fa-code-pull-request"></i>
                    </div>
                    <h3>Scope</h3>
                    <span>Requirements</span>
                </div>
                <div class="single-work-process">
                    <div class="icon">
                        <i class="fa-brands fa-searchengin"></i>
                    </div>
                    <h3>User Research</h3>
                    <span>User Research</span>
                </div>
                <div class="single-work-process">
                    <div class="icon">
                        <i class="fa-regular fa-lightbulb"></i>
                    </div>
                    <h3>Implement</h3>
                    <span>Lunch/Analyse</span>
                </div>
            </div>
            <img src="assets/images/circle.png" class="rotateme circle-image" alt="image">
        </div>
    </div>
</div>