//system
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

//services
import { Alert, AlertType, AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  alerts: Alert[] = [];
  subscription: Subscription | undefined;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    // Retrieve alerts from localStorage
    const savedAlerts = localStorage.getItem(AlertService.ALERTS_KEY);
    if (savedAlerts) {
      this.alerts = JSON.parse(savedAlerts);
    }
    this.alertService.alertSubject.subscribe((alerts) => {
      this.alerts = alerts;
    });
    //removing from persistent storage.
    this.alertService.removeAll();
  }

  dismiss(alertId: string) {
    this.alertService.removeAlert(alertId);
  }

  ngOnDestroy() {
    
    this.subscription?.unsubscribe();
  }

  getClass(type: AlertType) {
    switch (type) {
      case AlertType.Success:
        return 'text-bg-success';
      case AlertType.Info:
        return 'text-bg-info';
      case AlertType.Warning:
        return 'text-bg-warning';
      case AlertType.Error:
        return 'text-bg-danger';
      default:
        return 'text-bg-info';
    }
  }
}
