<div class="sessions-wrapper">
    <ng-container *ngIf="!filteredSessions">
        <div class="ph-item">
            <div class="ph-col-12">
                <div class="ph-row">
                    <div class="ph-col-2 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-6 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-2 big"></div>
                    <div class="ph-col-2"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-6"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-2"></div>
                    <div class="ph-col-2 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-6 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-2 big"></div>
                    <div class="ph-col-2"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-6"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-2"></div>
                </div>
            </div>
        </div>
        <div class="ph-item">
            <div class="ph-col-12">
                <div class="ph-row">
                    <div class="ph-col-2 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-6 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-2 big"></div>
                    <div class="ph-col-2"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-6"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-2"></div>
                    <div class="ph-col-2 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-6 big"></div> <div class="ph-col-1 empty big"></div> <div class="ph-col-2 big"></div>
                    <div class="ph-col-2"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-6"></div> <div class="ph-col-1 empty"></div> <div class="ph-col-2"></div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="filteredSessions && template === 'scheduleView'">
        <div *ngFor='let session of filteredSessions' class="row g-0 session-wrapper justify-content-center" (click)="goToSession(session.Id)">
            <div class="col-3 time-slot">
                <p class="session-text-big">{{getTime(session.StartTime)}}</p>
                <p class="session-text">{{session.Duration}} min</p>
            </div>
            <div class="col-9">
                <img src="{{session.StudioImageUrl}}" alt="{{session.StudioName}}" class="session-studio-image"/>
                <p class="session-text">{{session.ClassName}}</p>
                <p class="session-text">{{screenAdoptedName(session.InstructorName)}}</p>
                <button *ngIf="isSupported() && isInFuture(session) && !isReserved(session)"
                        [ngClass]="{'btn':true, 'btn-dark': session.SpotsLeft!==0, 'btn-outline-dark': session.SpotsLeft===0, 'class-book-button':true}"
                        (click)="goToSession(session.Id)">
                    {{session.SpotsLeft === 0 ? 'class full' : 'reserve'}}
                </button>
                <button *ngIf="isReserved(session)"  class="btn btn-outline-dark class-book-button" (click)="goToSession(session.Id)">
                    cancel
                </button>
                <p *ngIf="session.SpotsLeft > 0 && session.SpotsLeft <= 5" class="spots-left-text">{{spotsLeftText(session)}}</p>
            </div>
        </div>
        <div *ngIf="filteredSessions && !filteredSessions.length" class="no-more-classes">No more classes</div>
    </ng-container>

    <ng-container *ngIf="filteredSessions && (template === 'studioView' || template === 'instructorView')">
        <div *ngFor='let session of filteredSessions' class="row g-0 session-wrapper justify-content-center" (click)="goToSession(session.Id)">
            <div class="col-3">
                <p class="session-text">{{getDay(session.StartDate)}}</p>
                <p class="session-text">{{getDate(session.StartDate)}}</p>
                <p class="session-text">{{getTime(session.StartTime)}}</p>
                <p class="session-text">{{session.Duration}} min</p>
            </div>
            <div class="col-9">
                <img src="{{session.StudioImageUrl}}" alt="{{session.StudioName}}" class="session-studio-image"/>
                <p class="session-text">{{session.ClassName}}</p>
                <p class="session-text">{{session.InstructorName}}</p>
                <button *ngIf="isSupported() && isInFuture(session)"  class="btn btn-dark class-book-button">
                    reserve
                </button>
            </div>
        </div>
        <div *ngIf="filteredSessions && !filteredSessions.length" class="no-more-classes">No more classes</div>
    </ng-container>
</div>

<!-- <df-login #login title="Confirm your booking" (loggedIn)="loggedIn($event)"></df-login> -->