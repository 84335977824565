<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="content">
            <h1>Services Details</h1>
            <a routerLink="/" class="link-btn">
                Back to Home
            </a>
        </div>
    </div>
</div>

<!-- Services Details -->
<div class="services-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="services-details-desc">
                    <h2>We Provide The Best Customer Support Of The World</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas facilisis metus vitae mattis velit ac amet, mattis aenean. Quam eu aliquam <a routerLink="/">quisque</a> commodo feugiat placerat elit. Eget mi, morbi tincidunt dolor. Placerat enim rid iculus id feugiat faucibus non pulvinar tincidunt. Vulputate tincidunt sed interdum interdum porta enim.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas <strong>facilisis</strong> metus vitae mattis velit ac amet, mattis aenean. Quam eu aliquam quisque commodo feugiat placerat elit. Eget mi, morbi tincidunt dolor.</p>
                    <img src="assets/images/services/services2.jpg" alt="services-image">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas facilisis metus vitae mattis velit ac amet, mattis aenean. Quam eu aliquam quisque commodo feugiat placerat elit. Eget mi, morbi tincidunt dolor. Placerat enim rid iculus id feugiat faucibus non pulvinar tincidunt. Vulputate tincidunt sed interdum interdum porta enim.</p>
                    <h2>Increase Your Brainstorm Ideas With Luaz Docs management</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipis elit. Nul imperdiet nulla duis ac. Id massa scelerisque ven enatis, massac gravida donec orci.Nulla imperdiet nulla duis ac.Id massa scelerisque ven enatis.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipis elit. Diet nulla duis ac. Id massa scelerisque ven. Consectetur adipistui elit. Nul imperdiet nulla duis ac id mass.</p>
                    <ul>
                        <li>
                            <i class="fa-regular fa-circle-check"></i>
                            Don’t try to create a project without proper research.
                        </li>
                        <li>
                            <i class="fa-regular fa-circle-check"></i>
                            Create appropriate Timeframe for the Task
                        </li>
                        <li>
                            <i class="fa-regular fa-circle-check"></i>
                            Get notified when task completed &amp; handover the project in time.
                        </li>
                    </ul>
                    <h2>Most Asked Quetions By Others</h2>
                    <div class="app-faq-accordion">
                        <accordion>
                            <accordion-group heading="How many team members can I invite?">
                                <p>Lorem Ipsum is simply dummy text of the printing and <strong>typesetting</strong> industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p>
                            </accordion-group>
                            <accordion-group heading="What is the maximum file upload size?">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p>
                            </accordion-group>
                            <accordion-group heading="How do I reset my password?">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p>
                            </accordion-group>
                            <accordion-group heading="Can I cancel my subscription?">
                                <p>Lorem Ipsum is simply dummy text of the printing and <strong>typesetting</strong> industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p>
                            </accordion-group>
                            <accordion-group heading="Can I pay via an invoice?">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p>
                            </accordion-group>
                            <accordion-group heading="Can I cancel my subscription?">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p>
                            </accordion-group>
                        </accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>