<div class="modal-header">
    <h4 class="modal-title drop-modal-title" id="modal-basic-title">{{getTotalItems()}}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal(false)"></button>
</div>
<div class="modal-body">
    <div class="checkout-items" *ngIf="initialized">
        <ng-container *ngFor="let item of purchase.Items">
            <div class="checkout-item">
                <div class="row">
                    <div class="col-3">
                        <div class="item-image pack" *ngIf="item.Intent === 'Pack'">
                            <div class="quantity">
                                {{getPackQuantity(item.Id)}}
                            </div>
                            <div class="unit">
                                {{getPackUnit(item.Id)}}
                            </div>
                        </div>
                        <div class="item-image subscription" [style.background-image]="'url(' + item.Image +')'" *ngIf="item.Intent === 'Subscription'"></div>
                    </div>
                    <div class="col-9">
                        <div class="item-details">
                            <h4 class="title">
                                {{item.Name}}
                                <span class="tag" *ngIf="hasDiscountLabel(item.Id)">
                                    {{getDiscountLabel(item.Id)}}
                                </span>
                            </h4>
                            <p class="price"><span class="decoy-price" *ngIf="item.DecoyPrice > 0 && item.DecoyPrice != item.Price">${{item.DecoyPrice/100}}</span><span *ngIf="item.DecoyPrice > 0 && item.DecoyPrice != item.Price">&nbsp; | &nbsp;</span><span class="actual-price">${{item.Price/100}}</span> <span class="term">&nbsp;{{getTermDuration(item.Id)}}</span></p>
                            <a href="javascript:void(0)" class="remove-item" (click)="removeItem(item.Id, item.Intent)">remove</a>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div class="modal-footer">
    <div class="total-wrapper">
        <div class="content" *ngIf="cards.length > 0">
            <div class="row">
                <div class="col-6">Payment Method</div>
                <div class="col-6">
                    <select name="card-select" class="card-select" [(ngModel)]="selectedCardId">
                        <option *ngFor="let card of cards" [value]="card.id">**** {{card.last4}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="content" *ngIf="cards.length == 0">
            <div class="row">
                <div class="col-6">Payment Method</div>
                <div class="col-6">
                    <a href="javascript:void(0)" class="add-card-btn" (click)="openAddCardPopup()">Add card</a>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="row faded">
                <div class="col-6">Subtotal</div>
                <div class="col-6">
                    <p class="numeric">${{getFormattedPrice(purchase.Subtotal)}}</p>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="row faded">
                <div class="col-6">Sales Tax ({{getTax()}}%)</div>
                <div class="col-6">
                    <p class="numeric">${{getFormattedPrice(purchase.SalesTax)}}</p>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="row">
                <div class="col-6">Total</div>
                <div class="col-6">
                    <p class="numeric">${{getFormattedPrice(purchase.Total)}}</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <button type="button" class="btn btn-dark checkout-button" [disabled]="submitted || cards.length == 0" (click)="checkout()">
                    <div *ngIf="submitted" class="spinner-border" role="status">
                        <span class="visually-hidden">Checking Out...</span>
                        </div>
                        <span *ngIf="!submitted">CHECKOUT</span>
                </button>
            </div>
        </div>
    </div>
</div>
<df-login #login title="Confirm your purchase" (loggedIn)="loggedIn($event)"></df-login>