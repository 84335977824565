import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeatureSupportService {

  constructor() { }

  isSupported(featureName: string): boolean {
    if (
      // false
      featureName === 'pi'
      // featureName === 'login'
      // || featureName === 'reserve'
    ) {
      return false;
    }
    return true;
  }
}
