<div class="modal-header">
    <h4 class="modal-title drop-modal-title" id="modal-basic-title">ADD PAYMENT METHOD</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
    <h3 class="mb-3 mt-3 section-heading">Add credit or debit card</h3>

    <!-- This is the container for the Stripe card element -->
    <div class="stripeElement" #cardElement></div>

    <label class="make-default">
        <input type="checkbox" [(ngModel)]="makeDefault" />
        Make this my default payment method
    </label>
    
    <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
    <div *ngIf="successMessage" class="success-message">{{ successMessage }}</div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-dark reserve-spot-button" [disabled]="submitted" (click)="onSubmit()">
      <div *ngIf="submitted" class="spinner-border" role="status">
          <span class="visually-hidden">Confirming...</span>
        </div>
        <span *ngIf="!submitted">CONFIRM</span>
  </button>
</div>
