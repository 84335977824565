import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {Title} from "@angular/platform-browser";

//services
import { AuthService } from 'src/app/services/auth.service';
import { LogService } from 'src/app/services/common/log.service';
import { AlertService } from 'src/app/services/alert.service';
import { FacilityService } from 'src/app/services/facility.service';

//interfaces
import { MemberModel } from 'src/app/models/member';

//components
import { AuthBaseComponent } from 'src/app/components/auth-base/auth-base.component';

//environment
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent extends AuthBaseComponent implements OnInit, OnDestroy {
  currentMember!: MemberModel;

  constructor(
    private router: Router
    , private logService: LogService
    , private alertService: AlertService
    , private titleService: Title
    , private authService: AuthService
    , private facilityService: FacilityService
    
  ) 
  { 
    super(router, logService, alertService, facilityService);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    if(!this.isLoggedIn())
    {
      this.alertService.error("Login to access account section.", true, true);
      location.href = "/";
    }

    //title
    this.titleService.setTitle(environment.facility[environment.submerchant].name + " - Account");
  }

  isLoggedIn(): boolean {
    this.currentMember = this.authService.getLoggedInMember();
    return !!this.currentMember;
  }

  ngOnDestroy() {
    
  }
}
