declare var $: any;
//System
import { Component, ElementRef, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { catchError, Subject, takeUntil, tap, Subscription } from 'rxjs';
import {Title} from "@angular/platform-browser";
import { SlickCarouselComponent } from 'ngx-slick-carousel';

//Third Party
import * as moment from 'moment';

//Services
import { LogService } from 'src/app/services/common/log.service';
import { ClassesService } from 'src/app/services/classes.service';
import { MetaService } from 'src/app/services/meta.service';

//Models
import { InstructorDetails, ClassesTab } from 'src/app/models/meta';
import { Studio, Session} from 'src/app/models/studio/session-details';

//Utils
import { Utils } from 'src/app/services/common/utils';

@Component({
  selector: 'df-todays-schedule',
  templateUrl: './todays-schedule.component.html',
  styleUrls: ['./todays-schedule.component.scss']
})

export class TodaysScheduleComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('slickCarousel', { static: false }) slickCarousel!: SlickCarouselComponent;
  @ViewChild('sessionsWrapper', { static: false }) sessionsWrapperDiv!: ElementRef;

  date: string = moment().format("YYYY-MM-DD");
  
  studioIds: number[] = [];
  instructorIds: number[] = [];

  liveSessionIds: number[] = [];

  sessions : Session[] = [];
  firstVisibleSession! : Session;

  currentDate = new Date();
  currentDateYYYYMMDD = "";
  currentDateStr = "";
  
  classesTab!: ClassesTab;
  studios!: Studio[] | undefined;
  instructors!: InstructorDetails[] | undefined;

  classPointerUpdateInterval: number = 1 * 10 * 1000;
  updateMetaInterval: number = 15 * 60 * 1000;

  //Sessions
  private readonly _unsubscribe: Subject<any> = new Subject();

  filteredSessions!: Session[] | null;
  template: string = 'scheduleView';  //Passing itemIds to popup

  private studioSubscription!: Subscription;

  constructor(private titleService:Title
    , private logService: LogService
    , private _classesService: ClassesService
    , private metaService: MetaService
    ) {
    this.titleService.setTitle("Today's Class Schedules - Drop Fitness");
    $("body").attr("id","todays-class-schedule");
  }

  async ngOnInit() {
    this.filteredSessions = null;
    this.updateMeta();

    setInterval(() => {this.updateClassPointer();}, this.classPointerUpdateInterval);
    setInterval(() => {this.updateMeta();}, this.updateMetaInterval);
  }

  updateClassPointer() {
    this.filteredSessions = this.sessions.filter(session => this.isToday(session));
    this.liveSessionIds = [];
    this.filteredSessions.forEach(session => {
      const startDateTime = moment(`${session.StartDate} ${session.StartTime}`, "YYYY-MM-DD HH:mm:ss");
      const endDateTime = moment(`${session.StartDate} ${session.EndTime}`, "YYYY-MM-DD HH:mm:ss");
      if(moment().isBetween(startDateTime,endDateTime))
      {
        this.liveSessionIds.push(session.Id);
      }
    });
    if(this.liveSessionIds.length > 0)
    {
      var scrollToSelector = `#sessionId-${this.liveSessionIds[0]}`;
      const element = document.querySelector(scrollToSelector) as HTMLElement;
      const element1 = document.querySelector(scrollToSelector);
      if (element && element1) {
        const elementHeight = element.offsetHeight;
        const dataIndex = parseInt(element1.getAttribute('data-index')!);
        $('#sessionsWrapper').animate({
          scrollTop: (dataIndex * elementHeight)
        }, { 
          duration : 1000,
          complete: function() {
            this.disableScrollEvent = false;
          }
        });
      }
    }
  }

  isLiveSession(sessionId: number)
  {
    return false;
    // return this.liveSessionIds.includes(sessionId);
  }

  getTwoDigitString(num: number): string {
    // Convert the number to a string
    let numStr = num.toString();

    // If the number is less than 10, prepend '0' to it
    if (num < 10) {
        numStr = '0' + numStr;
    }

    return numStr;
  }

  updateMeta() {
    this.studioSubscription = this.metaService.getClassesMeta().subscribe((data: ClassesTab) => {
      this.classesTab = data;
      this.studios = this.classesTab.Studios;
      this.instructors = this.classesTab.Instructors;
      this.logService.debug("this.studios",this.studios);
      this.logService.debug("this.instructors",this.instructors);
      
      let todaysDate : string = moment().format("YYYY-MM-DD");

      this.setCurrentDate(todaysDate);
      
      this.fetchSessions().then(() => {
        this.filteredSessions = this.sessions.filter(session => this.isToday(session));
      });
      this.updateClassPointer();
    });
  }

  setCurrentDate(dateTime: string){
    this.currentDate = new Date(dateTime);
    this.date = dateTime;
    let currentMomentDate: moment.Moment = moment(dateTime);
    this.currentDateYYYYMMDD = currentMomentDate.format("YYYY-MM-DD");
    this.currentDateStr = currentMomentDate.format("dddd, MMMM DD");
  }

  getMonth(dateTime: string) {
    let momentDate: moment.Moment = moment(dateTime);
    return momentDate.format("MMM");
  }

  getDate(dateTime: string) {
    let momentDate: moment.Moment = moment(dateTime);
    return momentDate.format("D");
  }

  getDay(dateTime: string) {
    let momentDate: moment.Moment = moment(dateTime);
    return momentDate.format("dd");
  }

  //Sessions
  ngAfterViewInit(): void {
    
  }

  isInFuture(session: Session): boolean {
    var sessionStartTimestamp: number = Utils.getTimestampFromDateTime(session.StartDate, session.StartTime);
    if(sessionStartTimestamp < Date.now())
      return false;
    return true;
  }

  isToday(session: Session): boolean {
    var sessionEndTimestamp: number = Utils.getTimestampFromDateTime(session.StartDate, session.EndTime);
    if(moment(session.StartDate).format("YYYY-MM-DD") == moment(Date.now()).format("YYYY-MM-DD") && sessionEndTimestamp > Date.now())
      return true;
    return false;
  }

  getEmptySession(date: Date): Session
  {
    var session: Session = {
      Id: 0,
      StudioName: '',
      StudioImageUrl: '',
      ClassName: '',
      InstructorName: '',
      StartDate: date,
      StartTime: '',
      EndTime: '',
      Duration: 0,
      TotalSpots: 0,
      SpotsBooked: 0,
      SpotsLeft: 0,
    }
    return session;
  }

  getTimeText(time: string)
  {
    return moment("0001-01-01 " + time).format("h:mm A");
  }

  getDateText(date: Date)
  {
    return moment(date).format("dddd, MMMM DD");
  }

  getMilitaryDateText(date: Date)
  {
    return moment(date).format("YYYY-MM-DD");
  }

  getDayText(date: Date)
  {
    return moment(date).format("ddd").toUpperCase();
  }

  /**
   * Handle the error
   * @param {*} error
   */
   _handleError(error: any) {
    // tslint:disable-next-line: prefer-const
    var errorMessage = error.message;
    return errorMessage;
  }

  fetchSessions(): Promise<Session[]> {
    return new Promise((resolve, reject) => {
      this._classesService.getPublicSessions([], [])
        .pipe(
          takeUntil(this._unsubscribe),
          tap(sessions => {
            this.sessions = (sessions as Session[]);
            if(this.sessions && this.sessions.length > 0)
            {
              this.firstVisibleSession = this.sessions[0];
            }
            resolve(this.sessions);
          }),
          catchError((error: any) => {
            this.logService.error("ClassSessions error", error);
            reject(this._handleError(error));
            return error;
          })
        )
        .subscribe();
    });
  }

  screenAdoptedName(name: string): string {
    if (window.screen.availWidth < 768) {
      return name.split(' ')[0];
    }
    return name;
  }

  spotsLeftText(session: Session): string {
    return `${session.SpotsLeft} spot${session.SpotsLeft !== 1 ? 's' : ''} left`;
  }

  ngOnDestroy() {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
    
    if (this.studioSubscription) {
      this.studioSubscription.unsubscribe();
    }
  }
}