import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

  aboutUs = [
    {
      title: 'How is Drop Fitness different than other gyms?',
      content: '<div>Drop Fitness puts you in control of your fitness journey. We do not have salespeople. Simply download the Drop Fitness app to become a member and enjoy one week free. From there, you can purchase any service either individually or as a subscription. Subscriptions can be turned on and off with a tap of a button.</div>',
    },
    {
      title: 'What does Drop Fitness offer?',
      content: '<div>Drop Fitness curates the best offerings in fitness and wellness. We have partnered with some of the most loved boutique fitness brands, personal trainers, equipment manufacturers, healthy food purveyors, and retail outlets to bring you our favorite products and services. Wish we carried a certain brand? Please let us know.</div>',
    },
    {
      title: 'What do I get with my first week free?',
      content: '<div>First week free includes unlimited classes and unlimited access to our cardio + weights floor.</div>',
    },
    {
      title: 'When does my first week free begin?',
      content: '<div>After you check in for the first time.</div>',
    },
    {
      title: 'How do I check in for the first time?',
      content: '<div>Please visit us at any time and our friendly front desk staff will be sure to get you checked in. First time check in requires a valid photo ID and a security photo. Collecting this information allows us to maintain the security of the club and enforce that our members treat the club and its members respectfully.</div> <div>&nbsp;</div><div>To check in for the first time and any time after, open the app and tap on the QR code on the right side of the home screen. Place the QR code underneath the scanner on the front desk to check in.</div>',
    },
    {
      title: 'How can I see your pricing menu?',
      content: '<div>To see prices for the services we offer, tap on your photo in top left corner to access the menu and then select "My Drop". From there, tap on any of our services to see pricing.</div><div>&nbsp;</div><div>Our prices are always accessible and transparent. Drop Fitness does not have any hidden fees.</div>',
    },
    {
      title: 'Do I need to be a member to make café or retail purchases?',
      content: '<div>No. The café and retail sections of Drop Fitness are located in front of the check in desk and are accessible to the public.</div>',
    },
    {
      title: 'How do I invite friends and family to become members of Drop Fitness?',
      content: '<div>Tap on the "Invite Friends &amp; Family" widget on the home tab.</div>',
    },
    {
      title: 'How do I cancel my membership?',
      content: '<p><span class="wysiwyg-color-black">At Drop Fitness, you\'re not locked into a traditional membership contract or required to pay monthly membership fees. Once you\'re registered as a member, you can come and go as you please, paying only for what you\'re using at the time.</span></p> <p><span class="wysiwyg-color-black">We value your privacy. If you need to permanently close your account and request that we delete the personal information associated with it (name, email, phone number, etc.), then you can do so from within the app. Simply tap on your photo, go into Edit Profile, open Account Status, and tap "Delete Account" to begin the process.</span></p> <p><span class="wysiwyg-color-black" style="font-family: -apple-system, BlinkMacSystemFont, \'Segoe UI\', Helvetica, Arial, sans-serif;">After submitting your request, you won\'t be able to access any of the services that you would normally enjoy as a Drop Fitness member. That includes checking in to any already-booked classes and training sessions, or using the open gym. </span><span class="wysiwyg-color-black">Any active subscriptions will be cancelled, so that you won\'t continue to be charged for them.</span></p> <p><span class="wysiwyg-color-black">Access to the Drop Café is and will continue to be available to you as a member of the general public.</span></p> <p><span class="wysiwyg-color-black">We will initiate account deletion 7 days after we receive your request. At that point, any unused credits will be permanently lost. We will send you a confirmation text message and an email, once this process is completed.</span></p> <p><span class="wysiwyg-color-black">If you decide to come back at any time after account deletion, then please contact us in advance so that we can help you get set up with a new account.</span></p> <p></p>',
    }
  ];

  studios = [
    {
      title: 'How are Drop Fitness Studios different than what I would find at other gyms?',
      content: '<div>Drop Fitness partners with the best boutique fitness studios and brings them to you all under one roof. Studio classes are offered à la carte or as a subscription.</div>',
    },
    {
      title: 'How are Drop Fitness Studios different than what I would find at other boutique studios?',
      content: '<div>Drop Fitness offers four boutique fitness studios under one roof and amenities not found at most other boutique studios such as cardio + free weights, kids club, café, and luxury locker rooms.</div>',
    },
    {
      title: 'How do I book a class?',
      content: '<div>In the studios tab, tap the "+" icon to see our schedule for that day. Tap on any class to initiate the booking process. Tap any date on the calendar to see the schedule for that day.</div>',
    },
    {
      title: 'Can class packages and subscriptions be used across all studios?',
      content: '<div>Yes.  All class packages and subscriptions can be used across all of our studios.</div>',
    },
    {
      title: 'Can I use the cardio machines and free weights on days I book a class?',
      content: '<div>Class subscriptions come with unlimited access to our cardio and free weights floor.</div><div>Class packages do <span class="wysiwyg-underline">not</span> come with access to the cardio and free weights floor.</div>',
    },
    {
      title: 'Can I use the kids club on days I book a class?',
      content: '<div>Yes. We are currently offering kids club complementary with all class package and subscription purchases. Kids club access is on a first come first served basis. Children can be left in our kids club for up to 1 hour.</div>',
    },
    {
      title: 'Can I invite my friends to join me in class?',
      content: '<div>Yes.  We love it when our members invite their friends to a class.  To invite a friend, tap on your upcoming class in the "up next" widget on the home screen.  From there you will see the link to "invite friends".</div>',
    },
    {
      title: 'Can I change my spot in class?',
      content: '<div>Once you arrive to the class the check in staff will be able to change your spot if an empty spot is available.</div>',
    },
    {
      title: 'What is the class cancellation policy?',
      content: '<div>If you need to cancel a class, please cancel 4 hours before your class begins to avoid losing a class credit or getting charged a no-show fee if you are an unlimited class subscriber.</div>',
    },
    {
      title: 'Can I arrive late to a class?',
      content: '<div>We encourage you to arrive 15 minutes prior to the start of class so you can check in and get ready. Our studios extend a 5 minute grace period to enter the studio after class has started.</div>',
    },
    {
      title: 'When do class packages expire?',
      content: '<div>Class packages expire 1 year from the purchase date.</div>',
    },
    {
      title: 'How do I cancel a subscription?',
      content: '<div>Tap your profile photo. Tap on "My Drop". Tap on the active subscription. Tap cancel. Drop Fitness will not charge you on the next billing cycle start date.</div>',
    }
  ];

  personalTraining = [
    {
      title: 'How is Drop Fitness Personal Training different than what I would find at other gyms?',
      content: '<div>We don\'t charge membership dues on top of personal personal training fees. We also have a dedicated personal training area with top of the line equipment so you never have to wait for a piece of equipment being used by someone who isn\'t personal training.</div>',
    },
    {
      title: 'How do I get started with Personal Training?',
      content: '<p>Please go to the trainer tab in the app and choose which trainer is right for you. Our personal trainer will be in touch in the next 24 hours to schedule your complimentary needs assessment appointment.</p>',
    },
    {
      title: 'Do you offer an initial personal training assessment?',
      content: '<div>Yes. Your first session is a complementary needs assessment appointment that a trainer can use to learn more about your training goals. During the assessment, you and your trainer will discuss your goals and set a plan of action.</div>',
    },
    {
      title: 'How long is a training session?',
      content: '<div>A training session is 60 minutes.</div>',
    },
    {
      title: 'Where do I check in to a personal training session?',
      content: '<div>Please meet your trainer in the private training area by the benches.</div>',
    }
  ];

  cardioWeights = [
    {
      title: 'How do I access cardio machines and free weights?',
      content: '<div>Members have two options to access our cardio and free weights floor:</div> <div> <br>Option 1: We offer a cardio + weights subscription that gives members monthly access to cardio and free weights.</div> <div></div> <div>Option 2: Class subscriptions come with unlimited access to our cardio and free weights floor.</div>',
    },
    {
      title: 'Does a Cardio + Weights subscription give me access to Studios or Personal Training?',
      content: '<div>No. If you like to use cardio machines and free weights on your own, then a Cardio + Weights subscription may be for you. If you like classes, personal training, or our other services then we offer those independent of a Cardio + Weights subscription.</div>',
    }
  ];

  kidsClub = [
    {
      title: 'Where is your kids club located?',
      content: '<div>The kids club is located behind the front desk.</div>',
    },
    {
      title: 'How do I access the kids club?',
      content: '<div>Please check in at the front desk who will direct you to the kids club. Parents can drop off up to 1 child at the kids club on a first come first served basis.  Parents are required to sign a waiver the first time they drop a child off at the kids club. Every child must be signed in to the kids club on every visit.</div>',
    },
    {
      title: 'How do I access the kids club?',
      content: '<div>Please check in at the front desk who will direct you to the kids club. Parents can drop off up to 1 child at the kids club on a first come first served basis.  Parents are required to sign a waiver the first time they drop a child off at the kids club. Every child must be signed in to the kids club on every visit.</div>',
    },
    {
      title: 'Is the kids club free if I have a scheduled class or personal training session on that day?',
      content: '<div>For now we are offering kids club as a complementary service if you have purchased a package or subscription.</div>',
    },
    {
      title: 'How long can I leave my child at the kids club?',
      content: '<div>We ask that you pick your child up at the kids club after an hour of working out so we can provide this service for all of our members.</div>',
    },
    {
      title: 'What happens if my child needs me when I\'m working out?',
      content: '<p>When checking your child into kids club you will receive a buzzer. If your child needs you we will send you a buzz. In an emergency, we will also come find you.</p>',
    }
  ];
  expandedIndex = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
