<div class="col-sm-12">
  <div class="row g-0 mb-3 upcoming" (click)="goToSession()">
    <div class="col-6">
      <img [src]="studioLogo" class="studio-logo" [alt]="studioName">
      <p class=""><small class="text-body-secondary text-muted">{{className}}</small></p>
    </div>
    <div class="col-6">
      <div class="">{{classStartDate()}}</div>
      <div class="">{{classStartTime()}}</div>
    </div>
  </div>
</div>