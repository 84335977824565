import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, catchError, takeUntil, tap } from 'rxjs';

import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';

@Component({
  selector: 'df-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  
  private readonly _unsubscribe: Subject<any> = new Subject();

  isLoading = false;

  constructor(
    private _user: UserService,
    private _auth: AuthService,
    private facilityService: FacilityService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;

    this.facilityService.isClockSynced();

    this._user.fetchMemberAccount()?.pipe(
      takeUntil(this._unsubscribe),
      tap((memberAccount: any) => {
        let user = this._user.getUser();
        this.isLoading = false;
      }),
      catchError((err: any) => {
        this.isLoading = false;
        return err;
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
  }

  avatar() {
    return this._user.getPhotoUrl();
  }

  personalInfo() {
    window.location.href = '/account/personal-info';
  }

  logout() {
    this._auth.logout();
    // window.setTimeout(() => this._auth.logout(), 500);
    window.location.href = '/';
  }
}