<div>
  <div class="col-sm-10 d-none d-md-flex download-input mx-auto" [formGroup]="phoneForm">
    <input name="phone" formControlName="phone" autocomplete="off" type="text"
      pattern="[0-9]*" inputmode="numeric" inputPhoneMask class="download-phone"
      placeholder="Enter your phone number" (keyup)="onPhoneEnter($event)" (change)="inputChanged($event)" />
    <img class="message" [ngClass]="{'message-disabled': isDisabled}" [src]="isSent ? '/assets/images/svg/check.svg' : '/assets/images/svg/arrow.svg'" (click)="onPhoneEnter({code: 'Enter'})" />
    <!-- <i class="message fa-solid" [ngClass]="{'message-disabled': isDisabled, 'fa-circle-arrow-right': !isSent, 'fa-circle-check': isSent}" (click)="onPhoneEnter({code: 'Enter'})"></i> -->
  </div>
  <hr class="d-none d-md-block" style="margin-top: 2rem;"/>
  <div class="d-none d-md-block download-divider text-muted">OR</div>
  <div class="mt-4"></div>
  <div class="row">
    <div class="col-6">
      <a href="/app-store?store=apple" target="_blank"><img class="download-store" src="/assets/images/appstore.png" alt="IMG"></a>
    </div>
    <div class="col-6">
      <a href="/app-store?store=google" target="_blank"><img class="download-store" src="/assets/images/playstore.png" alt="IMG"></a>
    </div>
  </div>
</div>