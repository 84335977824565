<!-- Page Title -->
<!-- <div class="page-title-area bg-f9f9f9">
  <div class="container">
      <div class="content text-center"> -->
        <!-- <img [src]="'/assets/images/df/prices/prices01.jpg'" width="100%"> -->
      <!-- </div>
  </div>
</div> -->

<!-- Privacy Policy -->
<div class="prices-area pt-4 pb-4 ptb-lg-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-sm-12 p-lg-5 mb-5">
        <h1>Simple, Transparent Pricing</h1>
        We offer something for everyone with flexible pricing and no hidden fees.
      </div>
      <div class="col-lg-6 col-sm-12 p-lg-5">
        <ul class="points">
          <li>
            <div class="points-h">No Membership Fees</div>
            <div class="points-t">Download the Drop app to become a member of our community and get one week of free access to all of our services and continued access to free fitness and wellness events.</div>
          </li>
          <li>
            <div class="points-h">No Strings Attached</div>
            <div class="points-t">Subscribe or pay as you go.  The choice is yours.  Turn subscriptions on and off with the tap of a button in the Drop app.</div>
          </li>
          <li>
            <div class="points-h">First Week Free </div>
            <div class="points-t">Enjoy 7 days of unlimited classes, open gym access, and 1 free fitness assessment when you download the Drop app.</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="our-pricing-area bg-f9f9f9 pt-4 pb-4 ptb-lg-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 mb-2 text-center pb-70">
        <h1>Our Pricing</h1>
        <p>Drop Fitness puts you in control.  Only pay for what you value.</p>
      </div>
      <div class="col-lg-12 col-sm-12 mb-5">
        <div class="prices-accordion">
            <accordion [closeOthers]="false">
                <accordion-group heading="Studio Classes" [isOpened]="true">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 col-sm-12">
                        <div class="p-3 border-1 mb-4 prices-box">
                          <div class="row">
                            <div class="col-6">Class Subscriptions</div>
                            <div class="col-6 text-end">New Member Discount</div>
                          </div>
                          <hr/>
                          <div class="text-smaller">
                            Includes access to all four studios, amenities, and unlimited cardio + weights. Renews monthly.
                            <br/><br/>
                            <div class="row">
                              <div class="col-7">4 classes / mo.</div>
                              <div class="col-5 text-end"><span class="strike">$119</span> <strong>$99</strong></div>
                            </div>
                            <div class="row">
                              <div class="col-7">8 classes / mo.</div>
                              <div class="col-5 text-end"><span class="strike">$149</span> <strong>$119</strong></div>
                            </div>
                            <div class="row">
                              <div class="col-7">Unlimited classes / mo.</div>
                              <div class="col-5 text-end"><span class="strike">$199</span> <strong>$149</strong></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-sm-12">
                        <div class="p-3 border-1 prices-box">
                          <div class="row">
                            <div class="col-6">Class Packages</div>
                            <div class="col-6 text-end">New Member Discount</div>
                          </div>
                          <hr/>
                          <div class="text-smaller">
                            Includes access to all four studios and amenities.  No subscription required.  Expires 12 months from purchase.
                            <br/><br/>
                            <div class="row">
                              <div class="col-7">Single class</div>
                              <div class="col-5 text-end"><span class="strike">$28</span> <strong>$25</strong></div>
                            </div>
                            <div class="row">
                              <div class="col-7">5 classes</div>
                              <div class="col-5 text-end"><span class="strike">$119</span> <strong>$99</strong></div>
                            </div>
                            <div class="row">
                              <div class="col-7">10 classes</div>
                              <div class="col-5 text-end"><span class="strike">$179</span> <strong>$149</strong></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </accordion-group>
                <accordion-group heading="Private Training">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 col-sm-12 mx-auto p-3 border-1 mb-4 prices-box">
                        <div class="row">
                          <div class="col-6">Private Sessions</div>
                          <div class="col-6 text-end">New Member Discount</div>
                        </div>
                        <hr/>
                        <div class="text-smaller">
                          1 hour private session.  No subscription required.  Expires 12 months from purchase.
                          <br/><br/>
                          <div class="row">
                            <div class="col-7">Single session</div>
                            <div class="col-5 text-end"><span class="strike">$99</span> <strong>$95</strong></div>
                          </div>
                          <div class="row">
                            <div class="col-7">5 sessions</div>
                            <div class="col-5 text-end"><span class="strike">$475</span> <strong>$449</strong></div>
                          </div>
                          <div class="row">
                            <div class="col-7">10 sessions</div>
                            <div class="col-5 text-end"><span class="strike">$899</span> <strong>$849</strong></div>
                          </div>
                          <div class="row">
                            <div class="col-7">20 sessions</div>
                            <div class="col-5 text-end"><span class="strike">$1,699</span> <strong>$1,599</strong></div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-lg-6 col-sm-12">
                        <div class="p-3 border-1 prices-box">
                          Group Sessions
                          <hr/>
                          <div class="text-smaller">
                            1 hour group session with up to three of your friends.  No subscription required.  Expires 12 months from purchase.  Session purchase required for all participants.
                            <br/><br/>
                            <div class="row">
                              <div class="col-7">10 group sessions</div>
                              <div class="col-5 text-end"><span class="strike">$800</span> <strong>$750</strong></div>
                            </div>
                            <div class="row">
                              <div class="col-7">20 group sessions</div>
                              <div class="col-5 text-end"><span class="strike">$1,500</span> <strong>$1,400</strong></div>
                            </div>
                            <div class="row">
                              <div class="col-7">&nbsp;</div>
                              <div class="col-5 text-end"></div>
                            </div>
                            <div class="row">
                              <div class="col-7">&nbsp;</div>
                              <div class="col-5 text-end"></div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </accordion-group>
                <accordion-group heading="Cardio + Weights">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12 p-3 border-1 mb-4 mx-auto prices-box">
                              <div class="row">
                                <div class="col-6">Monthly Subscription</div>
                                <div class="col-6 text-end">New Member Discount</div>
                              </div>
                              <hr/>
                              <div class="text-smaller">
                                Purchase a Monthly Subscription and enjoy unlimited access to cardio + weights and amenities.
                                <br/><br/>
                                <div class="row">
                                  <div class="col-7">Monthly unlimited access</div>
                                  <div class="col-5 text-end"><span class="strike">$89</span> <strong>$79</strong></div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </accordion-group>
            </accordion>
        </div>
      </div>
    </div>
  </div>
</div>