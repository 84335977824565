//system
import { Component, ViewChild, ElementRef, HostListener, OnInit, OnDestroy, Renderer2, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta, DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//services
import { LogService } from 'src/app/services/common/log.service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import { MetaService } from 'src/app/services/meta.service';
import { InviteCodeService } from 'src/app/services/invite-code.service';
import { ShortLinkService } from 'src/app/services/short-link.service';
import { MemberService } from 'src/app/services/member.service';

//interfaces
import { MyDrop, Purchase } from 'src/app/models/member';
import { CurrencyCategoryType, TermUnit, InviteCodeType } from 'src/app/models/enums';
import { CategoryMenuPack, CurrencyMeta, MenuPack } from 'src/app/models/meta';
import { Banner } from "src/app/models/common-models";
import { InviteCode, InviteCodeRedeemRequest, ShortLink, ShortLinkMeta } from 'src/app/models/invite-code';

//components
import { AuthBaseComponent } from 'src/app/components/auth-base/auth-base.component';
import { LoginComponent } from 'src/app/components/df-login/df-login.component';
import { CheckoutComponent } from 'src/app/components/popups/my-drop/checkout/checkout.component';
import { BenefitsComponent } from 'src/app/components/popups/my-drop/benefits/benefits.component';
import { ChoosePlanComponent } from 'src/app/components/popups/my-drop/choose-plan/choose-plan.component';

//environment
import { environment } from 'src/environments/environment';

//utils
import { Utils } from 'src/app/services/common/utils';
import { Location } from 'src/app/models/common-models';
import { StripeCheckoutComponent } from 'src/app/components/popups/my-drop/stripe-checkout/stripe-checkout.component';

@Component({
  selector: 'df-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent extends AuthBaseComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('login') loginPopup!: LoginComponent;
  bannerObj!: Banner;
  currentMember!: any;
  memberId: number = 0;
  activeScrollCategoryId: number = 1;
  selected: boolean = false;

  currencyMeta!: CurrencyMeta;
  myDrop!: MyDrop;
  subscriptionMenuIds!: number[];
  
  filteredMenuPacks!: MenuPack[];

  categoryMenuPacks!: CategoryMenuPack[];
  locations: Location[] = [];

  inviteCode!: InviteCode;
  inviteCodeStr: string = "";
  shortCode: string = "";
  shortLink!: ShortLink;
  locationId: number = 1;
  loginTitle: string = "Log into your account";
  loginDescription: string = "Please enter your mobile number to verify or create an account.";

  bodyHeight: number = 500;
  constructor(
    private router: Router
    , private renderer: Renderer2
    , private logService: LogService
    , private alertService: AlertService
    , private metaService: MetaService
    , private activeRoute: ActivatedRoute
    , private titleService: Title
    , private meta: Meta
    , private authService: AuthService
    , private facilityService: FacilityService
    , private modalService: NgbModal
    , private sanitizer: DomSanitizer
    , private el: ElementRef
    , private inviteCodeService: InviteCodeService
    , private shortLinkService: ShortLinkService
    , private memberService: MemberService
  ) 
  { 
    super(router, logService, alertService, facilityService);
  }

    sendBodyHeight() {
    var bodyH = this.el.nativeElement.ownerDocument.body.offsetHeight;
    if(this.bodyHeight != bodyH)
    {
      this.bodyHeight = bodyH;
      window.parent.postMessage({ frameHeight: this.bodyHeight }, '*');
    }
  }

  ngAfterViewInit(): void {
    // Send message to parent frame with iframe height
    window.parent.postMessage({ frameHeight: this.sendBodyHeight() }, '*');
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.locationId = this.activeRoute.snapshot.params['locationId'] || 0;
    this.inviteCodeStr = this.activeRoute.snapshot.queryParams['invite_code'];
    this.shortCode = this.activeRoute.snapshot.queryParams['short_code'];

    this.currentMember = this.authService.getLoggedInMember();
    this.memberId = this.currentMember?.Id || 0;

    if(this.memberId == 0 && this.locationId == 0)
    {
      this.bannerObj = {
        Text : 'Take advantage of our new member pricing when you purchase during your 7-day free trial! 💪',
        LinkText : '',
        Link : ''
      };

      if(!Utils.isNullOrEmpty(this.inviteCodeStr))
      {
        this.inviteCode = await this.inviteCodeService.getInviteCode(this.inviteCodeStr);
        if(this.inviteCode.CodeType == InviteCodeType.ReEngagementFreeCredit)
        {
          this.loginTitle = "Free Credits!";
          this.loginDescription = "Please login to apply it to your account.";
        }
        this.loginPopup.open(null, '');
      }

      if(!Utils.isNullOrEmpty(this.shortCode))
      {
        this.loginPopup.open(null, '');
      }
    } else {
      this.locations = await this.facilityService.getLocations();

      //fetch member's subscription details.
      if(this.memberId > 0)
      {
        this.myDrop = await this.memberService.getMyDrop(this.memberId);
      }
    }

    if(this.memberId > 0 && !Utils.isNullOrEmpty(this.inviteCodeStr))
    {
      const redeemRequest : InviteCodeRedeemRequest = {
        MemberId : this.currentMember.Id,
        Code: this.inviteCodeStr
      };

      this.inviteCodeService.redeemInviteCode(redeemRequest).subscribe((data: InviteCode | string) => {
        if(typeof(data) === 'string')
        {
          this.alertService.error(data);
          return;
        } else {
          this.inviteCode = data;
          this.alertService.info("Code has been redeemed successfully.");
          this.logService.debug("this.inviteCode", this.inviteCode);
        }
      });

      this.inviteCode = await this.inviteCodeService.getInviteCode(this.inviteCodeStr);
      if(this.inviteCode != null && this.inviteCode.CodeType == InviteCodeType.PaymentLink)
      {
        this.selected = true;
        setTimeout(() => {
          this.openCheckoutPopup(this.inviteCode.IntentId);
          return;
        }, 2000);
      }
    }

    if(this.memberId > 0 && !Utils.isNullOrEmpty(this.shortCode))
    {
      this.shortLink = await this.shortLinkService.getShortLinkDetailsByCode(this.shortCode);
      if(this.shortLink != null && typeof(this.shortLink) != "undefined")
      {
        var metaData: ShortLinkMeta | undefined = this.getShortLinkMetaDataByKey(this.shortLink, "MenuPackId");
        if(metaData != null && typeof(metaData) != "undefined")
        {
          this.selected = true;
          
          setTimeout(() =>  {
            var itemId : number = parseInt(metaData?.Value ?? "0");
            this.openCheckoutPopup(itemId);
            return;
          }, 2000);
        }
        
      }
    }

    if(this.locationId > 0 && this.memberId == 0)
    {
      this.currencyMeta = await this.metaService.getCurrencyMetaByLocation(this.locationId);
    } else {
      this.currencyMeta = await this.metaService.getCurrencyMetaByMember(this.memberId);
    }
    this.currencyMeta.Categories.sort((a, b) => a.Order - b.Order);
    this.currencyMeta.Categories = this.currencyMeta.Categories.filter(m => (m.Slug != "kids_club" && m.Slug != "store_credits"));
    
    const filteredMenuPackIds = this.currencyMeta.DiscountMenuPacks.map(dmp => dmp.MenuPackId);

    this.subscriptionMenuIds = this.currencyMeta.Menus.filter(m => m.IsSubscription).map(m => m.Id);

    this.filteredMenuPacks = [];
    
    for (const menu of this.currencyMeta.Menus) {
      for (const menuPack of menu.MenuPacks) {
        if (filteredMenuPackIds.includes(menuPack.Id) && menuPack.ParentId == 0) {
          this.filteredMenuPacks.push(menuPack);
        }
      }
    }

    this.categoryMenuPacks = [];
    var isFirst: boolean = true;
    for (const menuPack of this.filteredMenuPacks) {
      var discountMenuPack = this.currencyMeta.DiscountMenuPacks.find(c => c.MenuPackId === menuPack.Id);
      if(discountMenuPack)
      {
        menuPack.Order = discountMenuPack.Order;
      }

      const isSubscription = this.subscriptionMenuIds.includes(menuPack.MenuId);
      const currency = this.currencyMeta.Currencies.find(curr => curr.Id === menuPack.CurrencyId);
      
      if (currency) {
        const categoryCurrencies = this.currencyMeta.CategoryCurrencies.filter(cc => cc.CurrencyId === currency.Id);
        for (const categoryCurrency of categoryCurrencies) {
          if(isFirst)
          {
            this.activeScrollCategoryId = categoryCurrency.CategoryId;
            isFirst = false;
          }
          const categories = this.currencyMeta.Categories.filter(cat =>
            cat.Id === categoryCurrency.CategoryId &&
            ((isSubscription && [CurrencyCategoryType.Subscriptions, CurrencyCategoryType.PackSubscriptions].includes(cat.Type)) ||
            (!isSubscription && [CurrencyCategoryType.Packs, CurrencyCategoryType.PackSubscriptions].includes(cat.Type)))
          );
          
          for (const category of categories) {
            let foundCategoryMenuPack = this.categoryMenuPacks.find(cmp => cmp.Category.Id === category.Id);
            
            if (!foundCategoryMenuPack) {
              foundCategoryMenuPack = { Category: category, MenuPacks: [] };
              this.categoryMenuPacks.push(foundCategoryMenuPack);
            }
            
            const existingMenuPack = foundCategoryMenuPack.MenuPacks.find(mp => mp.Id === menuPack.Id);
            
            if (!existingMenuPack) {
              if ((isSubscription && [CurrencyCategoryType.Subscriptions, CurrencyCategoryType.PackSubscriptions].includes(foundCategoryMenuPack.Category.Type)) ||
                  (!isSubscription && [CurrencyCategoryType.Packs, CurrencyCategoryType.PackSubscriptions].includes(foundCategoryMenuPack.Category.Type))) {
                foundCategoryMenuPack.MenuPacks.push(menuPack);
              }
            }
          }
        }
      }
    }
    this.categoryMenuPacks.sort((a, b) => a.Category.Order - b.Category.Order);

    for (const category of this.categoryMenuPacks) {
      category.MenuPacks.sort((a, b) => a.Order - b.Order);
    }
    setTimeout(() => {
      this.sendBodyHeight();
    }, 1000);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event) {
    this.sendBodyHeight();
    var scrollPosition: number = window.scrollY || window.pageYOffset;
    scrollPosition = scrollPosition + 65;
    const sections = document.querySelectorAll('.category-wraper');
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i] as HTMLElement;
      const sectionTop = section.offsetTop;
      const sectionBottom = sectionTop + section.clientHeight;

      if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
        this.activeScrollCategoryId = parseInt(section.id.replace("category-", ""));
        break;
      }
    }
  }

  goToCategory(categoryId: number)
  {
    const element = document.getElementById("category-"+categoryId);
    if (element) {
      const elementOffsetTop = element.offsetTop;
      console.log(element.offsetTop);
      window.scrollTo({
        top: elementOffsetTop - 65,
        behavior: 'smooth', // Add smooth scrolling behavior
      });
      this.activeScrollCategoryId = categoryId;
    }
  }

  openStripeCheckoutPopup(itemId: number = 0) {
    this.selected = true;
    if(itemId == 0)
      return;

    const menuPack = this.filteredMenuPacks.find(mp => mp.Id === itemId);
    if (menuPack) {
      setTimeout(() => {
        //open class map popup
        const modalRef = this.modalService.open(StripeCheckoutComponent, {
          ariaLabelledBy: 'modal-basic-title',
          size: 'xl',
          centered: true,
          // windowClass: 'checkout-popup-wrapper',
          // modalDialogClass: 'checkout-popup',
          fullscreen: window.innerWidth <= 768,
        });

        modalRef.componentInstance.productName = menuPack.Name;
        modalRef.componentInstance.productDescription = menuPack.UpgradeDescription;
        modalRef.componentInstance.productImage = this.getPackImage(itemId);
        modalRef.componentInstance.productPrice = menuPack.Price;
  
        modalRef.result.then((result: boolean) => {
          if (result) {
            this.logService.debug("purchase details:", result);
            // location.href = "/buy/1";
          }
        });
        this.selected = false;
      });
    }
  }

  openBenefitsPopup(itemId: number = 0) {

    if(this.memberId == 0)
    {
      this.loginPopup.open(null, '');
      return;
    }

    this.selected = true;
    if(itemId == 0)
      return;
    var intent: string = "Pack";

    const menuPack = this.filteredMenuPacks.find(mp => mp.Id === itemId);
    if(menuPack)
    {
      const isSubscription = this.subscriptionMenuIds.includes(menuPack.MenuId);
      if(isSubscription)
      {
        intent = "Subscription"
      }

      const pack = this.currencyMeta.Packs.find(mp => mp.Id === menuPack.PackId);
      if(pack)
      {
        if(pack.Benefits.length == 0)
        {
          if(isSubscription)
          {
            const menuPacks = this.filteredMenuPacks.filter(cc => cc.ParentId === menuPack.Id);
            if(menuPack.ParentId > 0 || menuPacks.length > 0)
              this.openChoosePlanPopup(itemId);
          } else {
            this.openCheckoutPopup(itemId);
          }
        } else {
          setTimeout(() => {
            //open class map popup
            const modalRef = this.modalService.open(BenefitsComponent, {
              ariaLabelledBy: 'modal-basic-title',
              size: 'lg',
              centered: false,
              windowClass: 'checkout-popup-wrapper',
              modalDialogClass: 'checkout-popup',
              fullscreen: window.innerWidth <= 768,
            });
      
            var purchase : Purchase = {
              MemberId: this.memberId,
              Items:[
               {
                  Id: itemId,
                  Intent: intent,
                  Image: "",
                  Name: "",
                  Price: 0,
                  DecoyPrice: 0,
                  TermDuration: 0,
                  TermUnit: TermUnit.Month,
                  Tax: 0,
                  TaxAmount: 0,
                  StartAt: new Date()
               }
              ],
              Subtotal: 0,
              SalesTax: 0,
              Total: 0
            }
      
            //Passing itemIds to popup
            modalRef.componentInstance.purchase = purchase;
            modalRef.componentInstance.currencyMeta = this.currencyMeta;
            modalRef.componentInstance.myDrop = this.myDrop;
        
            //Retrieving MemberCurrency from popup
            modalRef.result.then((result: boolean) => {
              if (result) {
                this.logService.debug("purchase details:", result);
                location.href = "/purchase";
              }
            });
            this.selected = false;
          }, 1000);
        }
      }
    }
  }

  openChoosePlanPopup(itemId: number = 0) {
    this.selected = true;
    if(itemId == 0)
      return;
    var intent: string = "Pack";

    const menuPack = this.filteredMenuPacks.find(mp => mp.Id === itemId);
    if(menuPack)
    {
      const isSubscription = this.subscriptionMenuIds.includes(menuPack.MenuId);
      if(isSubscription)
      {
        intent = "Subscription"
      }
    }

    setTimeout(() => {
      //open class map popup
      const modalRef = this.modalService.open(ChoosePlanComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        centered: false,
        windowClass: 'checkout-popup-wrapper',
        modalDialogClass: 'checkout-popup',
        fullscreen: window.innerWidth <= 768,
      });

      var purchase : Purchase = {
        MemberId: this.memberId,
        Items:[
         {
            Id: itemId,
            Intent: intent,
            Image: "",
            Name: "",
            Price: 0,
            DecoyPrice: 0,
            TermDuration: 0,
            TermUnit: TermUnit.Month,
            Tax: 0,
            TaxAmount: 0,
            StartAt: new Date()
         }
        ],
        Subtotal: 0,
        SalesTax: 0,
        Total: 0
      }

      //Passing itemIds to popup
      modalRef.componentInstance.purchase = purchase;
      modalRef.componentInstance.currencyMeta = this.currencyMeta;
      modalRef.componentInstance.myDrop = this.myDrop;
  
      //Retrieving MemberCurrency from popup
      modalRef.result.then((result: boolean) => {
        if (result) {
          this.logService.debug("purchase details:", result);
          location.href = "/purchase";
        }
      });
      this.selected = false;
    }, 1000);
  }

  openCheckoutPopup(itemId: number = 0) {
    this.selected = true;
    if(itemId == 0)
      return;
    var intent: string = "Pack";

    const menuPack = this.filteredMenuPacks.find(mp => mp.Id === itemId);
    if(menuPack)
    {
      const isSubscription = this.subscriptionMenuIds.includes(menuPack.MenuId);
      if(isSubscription)
      {
        intent = "Subscription"
      }
    }
    // if(this.memberId == 0) {
    //   alert("Login is required.");
    //   return;
    // }

    setTimeout(() => {
      //open class map popup
      const modalRef = this.modalService.open(CheckoutComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        centered: false,
        windowClass: 'checkout-popup-wrapper',
        modalDialogClass: 'checkout-popup',
        fullscreen: window.innerWidth <= 768,
      });

      var purchase : Purchase = {
        MemberId: this.memberId,
        Items:[
         {
            Id: itemId,
            Intent: intent,
            Image: "",
            Name: "",
            Price: 0,
            DecoyPrice: 0,
            TermDuration: 0,
            TermUnit: TermUnit.Month,
            Tax: 0,
            TaxAmount: 0,
            StartAt: new Date()
         }
        ],
        Subtotal: 0,
        SalesTax: 0,
        Total: 0
      }

      //Passing itemIds to popup
      modalRef.componentInstance.purchase = purchase;
      modalRef.componentInstance.currencyMeta = this.currencyMeta;
      modalRef.componentInstance.myDrop = this.myDrop;
  
      //Retrieving MemberCurrency from popup
      modalRef.result.then((result: boolean) => {
        if (result) {
          this.logService.debug("purchase details:", result);
          location.href = "/purchase";
        }
      });
      this.selected = false;
    }, 1000);
  }

  getMenuPackNameById(menuPackId: number)
  {
    const menuPack = this.filteredMenuPacks.find(mp => mp.Id === menuPackId);
    if(menuPack)
    {
      const formattedText = Utils.newLineToBr(menuPack.Name, this.sanitizer);
      return formattedText;
    }
    return "";
  }

  getPackDescriptionById(packId: number)
  {
    const pack = this.currencyMeta.Packs.find(p => p.Id === packId);
    if(pack)
    {
      const formattedText = Utils.newLineToBr(pack.Description, this.sanitizer);
      return formattedText;
    }
    return "";
  }

  getPackExpiryLabel(menuPackId: number)
  {
    const menuPack = this.filteredMenuPacks.find(mp => mp.Id === menuPackId);
    if(menuPack)
    {
      return "Expires in " + menuPack.TermDuration + " " + Utils.getEnumNameFromValue(TermUnit, menuPack.TermUnit) + (menuPack.TermDuration > 1 ? "s" : "");
    }
    return "";
  }

  getPackImage(menuPackId: number)
  {
    const menuPack = this.filteredMenuPacks.find(mp => mp.Id === menuPackId);
    if(menuPack)
    {
      const pack = this.currencyMeta.Packs.find(p => p.Id === menuPack.PackId);
      return pack?.Image;
    }
    return "";
  }

  getTermDuration(menuPackId: number) {
    const menuPack = this.filteredMenuPacks.find(mp => mp.Id === menuPackId);
    if(menuPack)
    {
      return "per " + Utils.getEnumNameFromValue(TermUnit, menuPack.TermUnit).toLowerCase();
    }
    return "";
  }

  async onLocationChange(event: any) {
    console.log("onLocationChange", event.value);
  }

  ngOnDestroy() {
    
  }

  loggedIn(isLoggedIn: boolean) {
    if (isLoggedIn)
    {
      window.location.reload();
      //this.currentMember = this.authService.getLoggedInMember();
    }
  }

  getShortLinkMetaDataByKey(shortLink: ShortLink, key: string): ShortLinkMeta | undefined {
    const metaData = shortLink.MetaData.find(meta => meta.Key === key);
    return metaData;
  }
}
