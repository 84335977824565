//System
import { Component, ViewChild, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, Subject, takeUntil, tap } from 'rxjs';

//Third Party
import * as moment from 'moment';

//Interfaces & Models
import { environment } from 'src/environments/environment';
import { Session} from 'src/app/models/studio/session-details';

//Components
import { LoginComponent } from 'src/app/components/df-login/df-login.component';
import { AuthBaseComponent } from 'src/app/components/auth-base/auth-base.component';

//Services
import { LogService } from 'src/app/services/common/log.service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import { ClassesService } from 'src/app/services/classes.service';
import { FeatureSupportService } from 'src/app/services/feature-support.service';

//Utils
import { Utils } from 'src/app/services/common/utils';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'cs-sessions-list',
  templateUrl: './cs-sessions-list.component.html',
  styleUrls: ['./cs-sessions-list.component.scss']
})
export class CsSessionsListComponent extends AuthBaseComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('login') loginPopup!: LoginComponent;
  public sessions : any = [];
  private readonly _unsubscribe: Subject<any> = new Subject();

  public filteredSessions!: Session[] | null;
  public bookings!: any[] | null;

  @Input()
  public filterDate: string = '';
  @Input()
  public filterStudio: number = 0;
  @Input()
  public filterInstructor: number = 0;
  @Input()
  public template: string = 'segmentedView'; 
  //scheduleView: Shown with date selector on class schedule page.
  //studioView: Shown on studio page.
  //instructorView: Shown on instructor page.

  @Output() onDataChanged: EventEmitter<number> = new EventEmitter<number>();

  constructor(private _http: HttpClient,
    private router: Router
    , private logService: LogService
    , private alertService: AlertService
    , private facilityService: FacilityService
    , private _classesService: ClassesService
    , private _auth: AuthService
    , private _user: UserService
    , private _featureSupport: FeatureSupportService
  ) { 
    super(router, logService, alertService, facilityService);
  }

  override async ngOnInit() {
    this.filteredSessions = null;
    await super.ngOnInit();
    
    this.fetchSessions().then(() => {
      this.filteredSessions = this.getFilteredSession(moment(this.facilityService.utcTime.TimeStamp).format("YYYY-MM-DD"));
      this.onDataChanged.emit(this.filteredSessions.length);
    });
  }
  
  ngAfterViewInit(): void {
    this._user.fetchMemberAccount(false)?.pipe(
      takeUntil(this._unsubscribe),
      tap((memberAccount: any) => {
        this.bookings = (memberAccount.Upcoming.ClassBookings || []).filter((cb: any) => {
          return cb.MemberId === memberAccount.Id;
        });
      }),
      catchError((error: any) => {
        console.log("MemberAccount error", error);
        return error;
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
  }

  isSupported(): boolean {
    return this._featureSupport.isSupported('reserve');
  }

  isInFuture(session: Session): boolean {
    var sessionStartTimestamp: number = Utils.getTimestampFromDateTime(session.StartDate, session.StartTime);
    if(sessionStartTimestamp < Date.now())
      return false;
    return true;
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filterDate'] !== undefined) {
      this.onDateChange(this.filterDate);
    }
  }

  onDateChange(filterDate: string) {
    this.filteredSessions = this.getFilteredSession(filterDate);
  }

  getFilteredSession(filterDate: string) {
      let tempfilteredSessions : Session[] = [];
      this.sessions.forEach((session: Session) => {
        let sessionDate: string = moment(session.StartDate).format("YYYY-MM-DD");
        if(filterDate == sessionDate && this.isInFuture(session))
        {
          tempfilteredSessions.push(session);
        }
      });
      return tempfilteredSessions;
  }

  getTime(time: string)
  {
    return moment("0001-01-01 " + time).format("h:mm A");
  }

  getDate(date: Date)
  {
    return moment(date).format("MM.DD");
  }

  getDay(date: Date)
  {
    return moment(date).format("ddd").toUpperCase();
  }

  goToSession(sessionId: number): void {
    if (!this.isSupported()) return;
    this.logService.debug("goToSession", sessionId);
    this.router.navigate(['studio', 'class-details', sessionId]);
  }

  /**
   * Handle the error
   * @param {*} error
   */
   _handleError(error: any) {
    // tslint:disable-next-line: prefer-const
    var errorMessage = error.message;
    return errorMessage;
  }

  fetchSessions(): Promise<Session[]> {
    return new Promise((resolve, reject) => {
      this._classesService.getPublicSessions([this.filterStudio], [this.filterInstructor])
        .pipe(
          takeUntil(this._unsubscribe),
          tap(sessions => {
            this.sessions = (sessions as Session[]);
            resolve(this.sessions);
          }),
          catchError((error: any) => {
            console.log("ClassSessions error", error);
            reject(this._handleError(error));
            return error;
          })
        )
        .subscribe();
    });
  }

  isReserved(session: Session): boolean {
    if (this.bookings && this.bookings.length) {
      return !!this.bookings.find((b: any) => b.SessionId === session.Id);
    }
    return false;
  }

  screenAdoptedName(name: string): string {
    if (window.screen.availWidth < 768) {
      return name.split(' ')[0];
    }
    return name;
  }

  spotsLeftText(session: Session): string {
    return `${session.SpotsLeft} spot${session.SpotsLeft !== 1 ? 's' : ''} left`;
  }
}
