<!-- Default Footer -->
<footer
    class="footer-area"
    [ngClass]="{'bg-black': true}"
>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-12">
                <div class="single-footer-widget">
                    <div
                        class="logo"
                        [ngClass]="{'d-none': true}"
                    >
                        <a routerLink="/">
                            <img src="assets/images/logo.png" alt="logo">
                        </a>
                    </div>
                    <div
                        class="logo d-none"
                        [ngClass]="{'d-inline-block': true}"
                    >
                        <a routerLink="/">
                            <img src="assets/images/logo-white.png" alt="white-logo" class="footer-logo">
                        </a>
                    </div>
                    <p>Drop Fitness is a fair, fun, and modern approach to fitness and wellness.</p>
                    <ul class="social-links">
                        <li>
                            <a href="https://www.instagram.com/dropfitness" target="_blank">
                                <i class="fa-brands fa-instagram"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/dropfitnessmontvale" target="_blank">
                                <i class="fa-brands fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.tiktok.com/@dropfitnessnj" target="_blank">
                                <i class="fa-brands fa-tiktok"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div class="col-lg-3 col-sm-6 col-6">
                <div class="single-footer-widget pl-4">
                    <h3>Company</h3>
                    <ul class="list">
                        <li>
                            <a routerLink="/about">About Drop</a>
                        </li>
                        <li>
                            <a routerLink="/press">Press</a>
                        </li>
                        <li>
                            <a routerLink="/member-policy">Member Policy</a>
                        </li>
                        <li>
                            <a routerLink="/investment">Investment</a>
                        </li>
                        <li>
                            <a routerLink="/careers">Careers</a>
                        </li>
                        <li>
                            <a routerLink="/franchise">Franchise</a>
                        </li>
                    </ul>
                </div>
            </div> -->
            <div class="col-lg-4 col-6">
                <div class="single-footer-widget">
                    <h3>Legal</h3>
                    <ul class="list">
                        <li>
                            <a href="/privacy" target="_blank">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/terms" target="_blank">Terms & Conditions</a>
                        </li>
                        <!-- <li>
                            <a routerLink="/member-agreements">Member Agreement</a>
                        </li>
                        <li>
                            <a routerLink="/safety-waiver">Safety Waiver</a>
                        </li>
                        <li>
                            <a routerLink="/guardian-consent">Guardian Consent</a>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-6">
                <div class="single-footer-widget">
                    <h3>Help</h3>
                    <ul class="list">
                        <li>
                            <a href="/faq">FAQ</a>
                        </li>
                        <li>
                            <a href="/support">Contact Us</a>
                        </li>
                        <!-- <li>
                            <a href="https://dropfitness.zendesk.com/hc/en-us/requests/new" target="_blank">Help Desk</a>
                        </li>
                        <li>
                            <a href="mailto:hello@dropfitness.com" target="_blank">Contact Us</a>
                        </li> -->
                    </ul>
                </div>
                
                <small class="footer-badge">This site is protected by reCAPTCHA and the Google 
                    <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
                    <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
                </small>
            </div>
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <p>© {{currentYear()}} Drop Fitness</p>
        </div>
    </div>
</footer>
