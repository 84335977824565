<div class="get-started-area text-center">
  <div class="row g-0">
    <div class="col-12 col-lg-6 g-0">
      <div class="get-started-bg-position get-started-bg" id="get-started-left">
      </div>
    </div>
    <div class="col-12 col-lg-6 g-0">
      <!-- auth page content -->
      <div class="get-started-page-content">
        <div class="phone-wrapper">
            <div class="row text-start g-0">
                <div class="col-md-12 col-lg-10 col-xl-10">
                    <div class="card mt-4">
                        <div class="card-body px-3 px-md-5">
                            <div>
                                <div><img src="../../../assets/images/svg/icon_location_black.svg" alt="nav" style="width: 11px;"/>&nbsp;&nbsp;Drop Fitness - Montvale</div>
                            </div>
                            <div class="mt-3">
                                <h1>First Week Free</h1>
                                <p class="text-muted mt-4">Enjoy 7 days of unlimited class booking, unlimited open gym access, and a free 30 minute fitness assessment when you create an account. Your free trial starts on your first check-in.</p>
                            </div>
                            <div class="mt-4">
                              <div class="col-sm-10 d-flex download-input" [formGroup]="phoneForm">
                                <input name="phone" formControlName="phone" autocomplete="off" type="text"
                                  pattern="[0-9]*" inputmode="numeric" inputPhoneMask class="download-phone"
                                  placeholder="Enter your phone number" (keyup)="onPhoneEnter($event)" (change)="inputChanged($event)" />
                                <img class="message" [ngClass]="{'message-disabled': isDisabled}" [src]="isSent ? '/assets/images/svg/check.svg' : '/assets/images/svg/arrow.svg'" (click)="onPhoneEnter({code: 'Enter'})" />
                                <!-- <i class="message fa-solid fa-circle-arrow-right" [ngClass]="{'message-disabled': isDisabled}" (click)="onPhoneEnter({code: 'Enter'})"></i> -->
                              </div>
                              <div class="col-sm-2"></div>
                            </div>
                            <hr class="mt-4"/>
                            <div class="row">
                              <div class="col">&nbsp;</div>
                              <div class="col-1 download-divider text-muted">OR</div>
                              <div class="col">&nbsp;</div>
                            </div>
                            <div class="row">
                              <div class="col-5">
                                <a href="/app-store?store=apple" target="_blank"><img class="download-store" src="/assets/images/appstore.png" alt="IMG"></a>
                              </div>
                              <div class="col-5">
                                <a href="/app-store?store=google" target="_blank"><img class="download-store" src="/assets/images/playstore.png" alt="IMG"></a>
                              </div>
                            </div>
                        </div>
                        <!-- end card body -->
                    </div>
                    <!-- end card -->
                </div>
            </div>
        <!-- end row -->
        </div>
        <!-- end container -->
    </div>
    <!-- end auth page content -->
    </div>
  </div>
</div>

<df-login #login title="Log into your account"></df-login>