import { Component } from '@angular/core';

@Component({
  selector: 'app-faqs-page',
  templateUrl: './faqs-page.component.html',
  styleUrls: ['./faqs-page.component.scss']
})
export class FaqsPageComponent {

}
