import { AfterViewInit, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CountryOptions } from '../../models/country-codes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'df-country-select',
  templateUrl: './df-country-select.component.html',
  styleUrls: ['./df-country-select.component.scss']
})
export class CountrySelectComponent implements OnInit, AfterViewInit {
  options: any = CountryOptions;
  countries: any[] = CountryOptions.countries;
  selectedCountry: string = 'us';

  @ViewChild('country', { read: TemplateRef }) country: TemplateRef<any> | undefined;

  @Output() select: EventEmitter<any> = new EventEmitter();

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  public open(selectedCountry: string = 'us') {
    this.selectedCountry = selectedCountry;
    this.openModal(this.country);
  }

  public openModal(modal: any): void {
    let modalRef = this.modalService.open(modal, {
      ariaLabelledBy: 'modal-basic-title', 
      // size: 'lg',
      centered: true,
      fullscreen: window.innerWidth <= 768,
      // windowClass: 'custom-class'
    });
    modalRef.shown.subscribe(() => {
      this.search('');
      setTimeout(() => this.scrollTo(this.selectedCountry));
    });
  }

  flag(item: any): string {
    return item.flag_4x3;
  }

  scrollTo(value: string) {
    document.querySelector(`#ctry-${value}`)?.scrollIntoView();
  }

  search(value: string) {
    if (!value) {
      this.countries = CountryOptions.countries;
      setTimeout(() => this.scrollTo(this.selectedCountry));
      return;
    }
    value = value.toLowerCase();
    this.countries = CountryOptions.countries.filter(c => c.code.startsWith(value) || c.name.toLowerCase().startsWith(value));
  }

  itemClass(item: any): any {
    let classes: any = {};

    if (item.code === this.selectedCountry) {
      classes['active'] = true;
    }
    
    return classes;
  }

  selectedCountryName(): string {
    let ctry = this.countries.find(c => c.code === this.selectedCountry);
    if (ctry) {
      return ctry.name;
    }
    return '';
  }

  phoneCode(): string {
    let ctry = this.countries.find(c => c.code === this.selectedCountry);
    if (ctry) {
      return ctry.dialCode;
    }
    return '';
  }

  selectItem(item: any) {
    this.selectedCountry = item.code;
  }

  closeSuccess() {
    this.select.emit({
      country: this.selectedCountry,
      phoneCode: this.phoneCode()
    });
  }
}
