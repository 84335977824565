import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SmsService } from 'src/app/pages/get-started/sms.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'df-download-options',
  templateUrl: './download-options.component.html',
  styleUrls: ['./download-options.component.scss']
})
export class DownloadOptionsComponent {
  phoneForm: FormGroup<{ phone: FormControl<any>; }>;
  isSent: boolean = false;
  isDisabled: boolean = true;

  constructor(private sms: SmsService, private alertService: AlertService) {
    this.phoneForm = new FormGroup({
      phone: new FormControl()
    });
  }

  onPhoneEnter(e: any) {
    let phone = this.phoneForm?.get('phone')?.value;
    if (!phone || phone.length < 14) {
      this.isDisabled = true;
      this.isSent = false;
      return;
    }
    this.isDisabled = false;
    if (e.code === 'Enter') {
      this.isSent = true;
      this.sms.sendPhoneLinkSms(phone)?.pipe().subscribe(() => {
        this.alertService.info('A link to download the app has been sent.', true, true);
      });
    }
  }

  inputChanged(evt: any) {
    // if auto-fill happened and value didn't get entered
    window.setTimeout(() => {
      if (this.phoneForm?.get('phone') && this.phoneForm.get('phone')?.value !== evt.target.value) {
        this.phoneForm.get('phone')?.setValue(evt.target.value);
        this.isDisabled = false;
      }
    }, 100);
  }
}
