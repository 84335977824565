<div class="funfacts-area ptb-100 bg-fcfbfb">
    <div class="container">
        <div class="section-title">
            <h2>We always try to understand users expectation</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="funfact">
                    <div class="icon">
                        <i class="fa-regular fa-pen-to-square"></i>
                    </div>
                    <h3>980+</h3>
                    <p>Completed Projects</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="funfact">
                    <div class="icon">
                        <i class="fa-solid fa-heart"></i>
                    </div>
                    <h3>950+</h3>
                    <p>Happy Clients</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="funfact">
                    <div class="icon">
                        <i class="fa-solid fa-person-running"></i>
                    </div>
                    <h3>780+</h3>
                    <p>Ongoing Projects</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="funfact">
                    <div class="icon">
                        <i class="fa-solid fa-trophy"></i>
                    </div>
                    <h3>70+</h3>
                    <p>Winning Awards</p>
                </div>
            </div>
        </div>
        <div class="contact-cta-box">
            <h3>Have any questions about us?</h3>
            <p>Don't hesitate to contact us</p>
            <button (click)="onClick('contact')" class="default-btn">
                Contact Us
            </button>
        </div>
        <div class="map-bg">
            <img src="assets/images/map.png" alt="map">
        </div>
    </div>
</div>