<div id="classes" class="about-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="row">
        <div class="section-title col-lg-4 col-sm-12 text-left d-flex align-items-center ml-0">
            <div class="mb-4">
                <h2>Four Studios,<br/>One Address</h2>
                <p>Discover our studio partners. Purchase class packages or subscriptions to use across all 4 studios.</p>
                <button (click)="onClick('')" class="btn btn-primary rounded-pill px-4 py-2 rounded-pill-btn">
                    CLASS SCHEDULE
                </button>
            </div>
        </div>
        <div class="section-title col-lg-8 col-sm-12">
            <div class="d-flex justify-content-center">
                <div class="d-flex flex-column about">
                    <div class="about-image about-humming-puppy">
                        <button class="video-btn" (click)="ngxSmartModalService.getModal('hummingPuppy').open()">
                            <i class="fa-solid fa-play"></i>
                        </button>
                        <div class="logo"></div>
                    </div>
                    <div class="about-descr">
                        <div class="about-title">Yoga that resonates</div>
                        Humming Puppy embraces the origins of hot yoga from the motherland of India.
                    </div>
                    <div class="about-image about-physique-57">
                        <button class="video-btn" (click)="ngxSmartModalService.getModal('physique57').open()">
                            <i class="fa-solid fa-play"></i>
                        </button>
                        <div class="logo"></div>
                    </div>
                    <div class="about-descr">
                        <div class="about-title">Cardio & Strength Barre</div>
                        Our method embraces a more intimate, dynamic workout environment.
                    </div>
                    <div class="about-space"></div>
                </div>
                <div class="about-spacer">&nbsp;</div>
                <div class="d-flex flex-column about">
                    <div class="about-space"></div>
                    <div class="about-image about-fhitting-room">
                        <button class="video-btn" (click)="ngxSmartModalService.getModal('fhittingRoom').open()">
                            <i class="fa-solid fa-play"></i>
                        </button>
                        <div class="logo"></div>
                    </div>
                    <div class="about-descr">
                        <div class="about-title">Group-based HIIT</div>
                        Our movements are functional and work multiple muscle groups simultaneously.
                    </div>
                    <div class="about-image about-mhrc">
                        <button class="video-btn" (click)="ngxSmartModalService.getModal('mhrc').open()">
                            <i class="fa-solid fa-play"></i>
                        </button>
                        <div class="logo"></div>
                    </div>
                    <div class="about-descr">
                        <div class="about-title">Interval-based Running</div>
                        If you run, you are a runner. Mile High is built for you and together, we’ll hit the ground running.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Video Popup -->
<div class="video-popup">
    <ngx-smart-modal #hummingPuppy [identifier]="'hummingPuppy'" (onOpen)="hpVideoOpen=true" (onClose)="hpVideoOpen=false">
        <video *ngIf="hpVideoOpen" controls autoplay>
            <source src="https://dropfitness-static.nyc3.digitaloceanspaces.com/videos/HP.mp4" type="video/mp4">
        </video>
    </ngx-smart-modal>
    <ngx-smart-modal #physique57 [identifier]="'physique57'" (onOpen)="p57VideoOpen=true" (onClose)="p57VideoOpen=false">
        <video *ngIf="p57VideoOpen" controls autoplay>
            <source src="https://dropfitness-static.nyc3.digitaloceanspaces.com/videos/P57.mp4" type="video/mp4">
        </video>
    </ngx-smart-modal>
    <ngx-smart-modal #fhittingRoom [identifier]="'fhittingRoom'" (onOpen)="frVideoOpen=true" (onClose)="frVideoOpen=false">
        <video *ngIf="frVideoOpen" controls autoplay>
            <source src="https://dropfitness-static.nyc3.digitaloceanspaces.com/videos/FR.mp4" type="video/mp4">
        </video>
    </ngx-smart-modal>
    <ngx-smart-modal #mhrc [identifier]="'mhrc'" (onOpen)="mhrcVideoOpen=true" (onClose)="mhrcVideoOpen=false">
        <video *ngIf="mhrcVideoOpen" controls autoplay>
            <source src="https://dropfitness-static.nyc3.digitaloceanspaces.com/videos/MHRC.mp4" type="video/mp4">
        </video>
    </ngx-smart-modal>
</div>