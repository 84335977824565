<div *ngIf="isLoading" class="loader my-5"></div>

<div *ngIf="!isLoading" class="classes-area pt-4 ptb-lg-100">
	<div class="container">
		<h5 class="account-heading">Payment Methods</h5>
        <div class="row">
            <div class="col-lg-8 col-sm-12">
                <ul class="payment-method-list">
                    <li class="payment-method-list-item" *ngFor="let card of cards">
                        <div class="card mb-3 py-0">
                            <div class="row g-0 d-table">
                              <div class="col-2 d-table-cell">
                                <img [src]="cardImage(card.brand)" [alt]="card.brand" class="card-image"/>
                              </div>
                              <div class="col-9 d-table-cell">
                                <div class="card-body">
                                    <span class="card-text">Card ending in {{card.last4}}
                                        <span class="default-card-tag" *ngIf="card.is_default">Default</span>
                                    </span>
                                </div>
                              </div>
                              <div class="col-1 d-table-cell align-middle h-align-right">
                                <i class="bi bi-three-dots-vertical three-dots"  *ngIf="!card.is_default" (click)="toggleMenu($event)"></i>
                                <div class="dropdown-menu">
                                    <ul>
                                      <li (click)="setCardDefault(card.id)">Set Default</li>
                                      <li (click)="removeCard(card.id)">Remove</li>
                                    </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a href="javascript:void(0)" (click)="openAddCardPopup()" class="add-card-link">+ Add a new card</a>
            </div>
        </div>
	</div>
</div>