<div
    class="partner-area pt-100 pb-70"
    [ngClass]="{'style-2': router?.url === '/business-agency'}"
>
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Partners</span>
            <h2>Trusted by World Class Companies</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div
            class="row align-items-center justify-content-center"
            [ngClass]="{'border': router?.url === '/marketing-agency'}"
        >
            <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                <div class="partner-item">
                    <a href="#" target="_blank">
                        <img src="assets/images/partners/partner1.png" alt="partner-image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                <div class="partner-item">
                    <a href="#" target="_blank">
                        <img src="assets/images/partners/partner2.png" alt="partner-image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                <div class="partner-item">
                    <a href="#" target="_blank">
                        <img src="assets/images/partners/partner3.png" alt="partner-image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                <div class="partner-item">
                    <a href="#" target="_blank">
                        <img src="assets/images/partners/partner4.png" alt="partner-image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                <div class="partner-item">
                    <a href="#" target="_blank">
                        <img src="assets/images/partners/partner5.png" alt="partner-image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                <div class="partner-item">
                    <a href="#" target="_blank">
                        <img src="assets/images/partners/partner6.png" alt="partner-image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                <div class="partner-item">
                    <a href="#" target="_blank">
                        <img src="assets/images/partners/partner7.png" alt="partner-image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                <div class="partner-item">
                    <a href="#" target="_blank">
                        <img src="assets/images/partners/partner8.png" alt="partner-image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                <div class="partner-item">
                    <a href="#" target="_blank">
                        <img src="assets/images/partners/partner9.png" alt="partner-image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                <div class="partner-item">
                    <a href="#" target="_blank">
                        <img src="assets/images/partners/partner10.png" alt="partner-image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                <div class="partner-item">
                    <a href="#" target="_blank">
                        <img src="assets/images/partners/partner11.png" alt="partner-image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                <div class="partner-item">
                    <a href="#" target="_blank">
                        <img src="assets/images/partners/partner12.png" alt="partner-image">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>