import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TrainingService } from 'src/app/services/training.service';

class TrainerInfo {
  Id: number = 0;
  FirstName: string = '';
  LastName: string = '';
  ImageUrl: string = '';
}

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit, OnDestroy {

  carouselSlides: OwlOptions = {
    // items: 1,
    // nav: true,
    // margin: 10,
    loop: true,
    dots: false,
    autoplay: true,
    // smartSpeed: 500,
    // animateIn: 'fadeIn',
    // animateOut: 'fadeOut',
    autoplayHoverPause: true,
    // navText: [
    //   "<i class='fa-solid fa-chevron-left'></i>",
    //   "<i class='fa-solid fa-chevron-right'></i>"
    // ],
    responsive: {
      0:{
          items:1
      },
      600:{
          items:2
      },
      1000:{
          items:5
      }
    }
  };

  carouselSource: TrainerInfo[] = [];
  private readonly _unsubscribe: Subject<any> = new Subject();

  constructor(private _trainingService: TrainingService) { }

  ngOnInit(): void {
    this._trainingService.getTrainers()
      .pipe(
        takeUntil(this._unsubscribe),
        tap(trainers => {
          this.carouselSource = trainers as TrainerInfo[];
          this.carouselSource = this.shuffle(this.carouselSource);
        })
      )
      .subscribe();
  }

  shuffle(array: any[]): any[] {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  ngOnDestroy(): void {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
  }

}
