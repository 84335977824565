declare var $: any;
//system
import { Component, Input, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'df-session-waitlist-added',
  templateUrl: './session-waitlist-added.component.html',
  styleUrls: ['./session-waitlist-added.component.scss']
})
export class SessionWaitlistAddedComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public classPhoto : string = "";
  @Input() public heading : string = "";
  @Input() public subHeading : string = "";

  classPhotoStr: string = "";
  headingStr: string = "You're on the Waitlist!";
  subHeadingStr: string = "We will notify everyone on the waitlist as soon as a spot becomes available.";
  constructor(
    public activeModal: NgbActiveModal
  ) {
    
  }

  ngOnInit() {
    this.classPhotoStr = this.classPhoto;
    this.headingStr = this.heading;
    this.subHeadingStr = this.subHeading;
  }

  ngAfterViewInit() {

  }

  closeModal() {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
  }
}

