import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, catchError, takeUntil, tap } from 'rxjs';
import { ClassesTab } from 'src/app/models/meta';
import { ClassesService } from 'src/app/services/classes.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'df-booked-class',
  templateUrl: './booked-class.component.html',
  styleUrls: ['./booked-class.component.scss']
})
export class BookedClassComponent implements OnInit, OnDestroy {

  private readonly _unsubscribe: Subject<any> = new Subject();
  @Input()
  source: any = {};
  @Input()
  classesTab: ClassesTab = <ClassesTab>{};

  studioName: string = '';
  className: string = '';
  studioLogo: string = '';

  startDate: string = '';
  startTime: string = '';

  constructor(private _classesService: ClassesService, private router: Router) {}

  ngOnInit(): void {
    let session: any = this.classesTab.Sessions.find(s => s.Id === this.source.SessionId);
    if (session) {
      let cl: any = this.classesTab.Classes.find(c => c.Id === session.ClassId);
      let st: any = this.classesTab.Studios.find(s => s.Id === session.StudioId);

      if (cl && st) {
        this.className = cl.Name;
        this.studioName = st.Name;
        this.studioLogo = st.Image.UploadedUrl;
        this.startDate = moment(new Date(session.StartDate)).format('MMMM DD, YYYY');
        this.startTime = moment(session.StartTime, 'H:m').format('h:mm a');
      }
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
  }

  classStartDate(): string {
    return this.startDate;
  }

  classStartTime(): string {
    return this.startTime;
  }

  goToSession() {
    this.router.navigate(['studio', 'class-details', this.source.SessionId]);
  }

}
