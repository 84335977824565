<div id="training" class="team pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <h2>Private Personal Training</h2>
            <p>Book personal trainers directly and never double pay for a gym membership and personal training again.</p>
        </div>
        <div class="justify-content-center">
            <df-carousel>
                <div *ngFor="let item of carouselSource" class="img-slide">
                    <div class="team-card d-flex flex-column">
                        <div class="img-container" [style.background-image]="'url(' + item.ImageUrl + ')'">
                        </div>
                        <div class="team-caption">
                            <div class="team-member-name">{{item.FirstName}}</div>
                        </div>
                    </div>
                </div>
            </df-carousel>
        </div>
    </div>
</div>