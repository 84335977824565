//system
import { Injectable } from '@angular/core';
import { HttpService, HttpServiceConfig } from './common/http.service';

//services
import { LogService } from './common/log.service';

//environment
import { environment } from 'src/environments/environment';

//models
import { Card } from 'src/app/models/common-models';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  
  constructor(private logService: LogService, private httpService: HttpService) { 
    const config: HttpServiceConfig = {
      baseUrl: `${environment.facility[environment.submerchant].facilityUrl}api/v${environment.facility[environment.submerchant].facilityApiVersion}`
    };
    this.httpService.configure(config);
  }

  getCustomerCards(memberId: number): Promise<Card[]> {
    return this.httpService.getPromise<Card[]>(`Stripe/Cards/${memberId}`);
  }

  addCard(memberId: number, token: string, makeDefault: boolean): Observable<boolean | string> {
    return this.httpService.post<boolean>(`Stripe/AddCard/${memberId}/${token}/${makeDefault}`, null, new HttpHeaders(), true);
  }

  setCardDefault(memberId: number, cardId: string): Observable<boolean> {
    return this.httpService.put<boolean>(`Stripe/SetCardDefault/${memberId}/${cardId}`, null);
  }

  removeCard(memberId: number, cardId: string): Observable<boolean> {
    return this.httpService.put<boolean>(`Stripe/Card/${memberId}/${cardId}`, null);
  }
}