declare var $: any;
//system
import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//services
import { LogService } from 'src/app/services/common/log.service';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { FacilityService } from 'src/app/services/facility.service';
import { AuthService } from 'src/app/services/auth.service';
import { CurrencyService } from 'src/app/services/currency.service';
import { MetaService } from 'src/app/services/meta.service';
import { StripeService } from 'src/app/services/stripe.service';
import { MemberService } from 'src/app/services/member.service';

//components
import { AuthBaseComponent } from 'src/app/components/auth-base/auth-base.component';
import { LoginComponent } from 'src/app/components/df-login/df-login.component';

//interfaces
import { CurrencyMeta, MenuPack } from 'src/app/models/meta';
import { MemberCurrency, Purchase, Item, MemberModel, MemberSetting, MyDrop } from 'src/app/models/member';
import { TermUnit } from 'src/app/models/enums';
import { Card, Person } from 'src/app/models/common-models';
import { PurchaseRequest } from 'src/app/models/currency';

//utils
import { Utils } from 'src/app/services/common/utils';

//environment
import { environment } from 'src/environments/environment';
import { AddCardComponent } from 'src/app/components/stripe/add-card/add-card.component';

@Component({
  selector: 'df-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent extends AuthBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('login') loginPopup!: LoginComponent;
  private readonly _unsubscribe: Subject<any> = new Subject();
  @Input() public currencyMeta! : CurrencyMeta;
  @Input() public purchase! : Purchase;
  @Input() public myDrop! : MyDrop;
  @Output() passEntry:      EventEmitter<boolean> = new EventEmitter();
  submitted:                boolean  = false;
  initialized:              boolean  = false;
  memberCurrency!:          MemberCurrency;
  menuPacks!:               MenuPack[];
  subscriptionMenuIds!:     number[];
  cards:                    Card[] = [];
  memberInfo!:              MemberModel;
  personInfo!:              Person;
  selectedCardId:           string = "";
  currentMember!:           any;
  memberId:                 number = 0;
  memberStripeCustomerId:   string = "";
  stripeCustomerIdSetting!: MemberSetting;
  purchaseRequest!:         PurchaseRequest;
  receiptEmail:             string = "";

  constructor(
    private router: Router
    , private logService: LogService
    , private alertService: AlertService
    , private facilityService: FacilityService
    , private metaService: MetaService
    , public activeModal: NgbActiveModal
    , private _memberService: MemberService
    , private _currencyService: CurrencyService
    , private authService: AuthService
    , private _stripeService: StripeService
    , private modalService: NgbModal
    , private userService: UserService
  ) {
    super(router, logService, alertService, facilityService);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.cards = [];
  }

  async ngAfterViewInit() {
    if(!this.isLoggedIn())
    {
      this.loginPopup.open();
    } else {
      this.memberInfo = await this._memberService.getMember();
      if(this.memberInfo)
      {
        this.personInfo = await this._memberService.getPerson();
        if(this.personInfo)
        {
          this.receiptEmail = this.personInfo.Email;
        }

        this.stripeCustomerIdSetting = await this._memberService.getMemberSetting(this.memberInfo.Id, ('stripe_customer_id' + (environment.facility[environment.submerchant].stripe.isSandbox ? '_test' : '')));
        if(this.stripeCustomerIdSetting && this.stripeCustomerIdSetting.Id > 0)
        {
          this.memberStripeCustomerId = this.stripeCustomerIdSetting.Value;
        }

        this.cards = await this._stripeService.getCustomerCards(this.memberInfo.Id);
        const defaultCard = this.cards.find(card => card.is_default);
        this.selectedCardId = defaultCard ? defaultCard.id : "";
      }

      if(this.purchase && this.purchase.Items.length > 0)
      {
        this.subscriptionMenuIds = this.currencyMeta.Menus.filter(m => m.IsSubscription).map(m => m.Id);
        this.menuPacks = [];
      
        for (const menu of this.currencyMeta.Menus) {
          for (const menuPack of menu.MenuPacks) {
              this.menuPacks.push(menuPack);
          }
        }

        var i: number = 0;
        for(const item of this.purchase.Items)
        {
          if(item.Intent == "Pack" || item.Intent == "Subscription")
          {
            this.purchase.Items[i] = this.fillMenuPackDetails(item);
          }
          i++;
        }
        this.calculateTotal();
      }
      this.initialized = true;
    }
  }

  fillMenuPackDetails(item: Item) : Item
  {
    const menuPack = this.menuPacks.find(mp => mp.Id === item.Id);
    if(menuPack)
    {
      const pack = this.currencyMeta.Packs.find(p => p.Id === menuPack?.PackId);
      item.Image = pack?.Image;
      item.Name = menuPack.Name;
      item.Price = menuPack.Price;
      item.DecoyPrice = menuPack.DecoyPrice;
      item.TermDuration = menuPack.TermDuration;
      item.TermUnit = menuPack.TermUnit;
      item.Tax = pack?.IsTaxable ? 6.625 : 0; //Todo: get it from global app config.
      item.TaxAmount = pack?.IsTaxable ? (menuPack.Price * 6.625) / 100 : 0;
    } else {
      this.purchase.Items = this.purchase.Items.filter(i => i.Id !== item.Id);
    }

    return item;
  }

  calculateTotal()
  {
    this.purchase.Subtotal = this.purchase.SalesTax =  this.purchase.Total = 0;

    for (const item of this.purchase.Items) {
      this.purchase.Subtotal += item.Price;
      this.purchase.SalesTax += item.TaxAmount;
      this.purchase.Total += item.Price + item.TaxAmount;
    }

    this.purchase.Subtotal = Number((this.purchase.Subtotal/100).toFixed(2));
    this.purchase.SalesTax = Number((this.purchase.SalesTax/100).toFixed(2));
    this.purchase.Total = Number((this.purchase.Total/100).toFixed(2));
  }

  getFormattedPrice(price: number)
  {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formatter.format(price);
  }

  getTermDuration(menuPackId: number) {
    const menuPack = this.menuPacks.find(mp => mp.Id === menuPackId);
    if(menuPack)
    {
      const isSubscription = this.subscriptionMenuIds.includes(menuPack.MenuId);
      if(isSubscription)
        return "per " + Utils.getEnumNameFromValue(TermUnit, menuPack.TermUnit).toLowerCase();
    }
    return "";
  }

  getPackQuantity(menuPackId: number): number {
    const menuPack = this.menuPacks.find(mp => mp.Id === menuPackId);
    if(menuPack)
    {
      const pack = this.currencyMeta.Packs.find(p => p.Id === menuPack.PackId);
      if(pack)
      {
        return pack.Quantity;
      }
    }
    return 0;
  }

  getPackUnit(menuPackId: number): string {
    const qty = this.getPackQuantity(menuPackId);
    const menuPack = this.menuPacks.find(mp => mp.Id === menuPackId);
    if(menuPack)
    {
      const currency = this.currencyMeta.Currencies.find(p => p.Id === menuPack.CurrencyId);
      if(currency)
      {
        if(currency.Unit.toLowerCase() == "class" || currency.Unit.toLowerCase() == "drop-pass")
          return currency.Unit + (qty > 1 ? "es" : "");
        return currency.Unit + (qty > 1 ? "s" : "");
      }
    }
    return "Unit" + (qty > 1 ? "s" : "");
  }

  hasDiscountLabel(menuPackId: number): boolean {
    const menuPack = this.menuPacks.find(mp => mp.Id === menuPackId);
    if(menuPack)
    {
      const save = menuPack.DecoyPrice - menuPack.Price;
      if(save > 0)
      {
        return true;
      }
    }
    return false;
  }

  getDiscountLabel(menuPackId: number): string {
    const menuPack = this.menuPacks.find(mp => mp.Id === menuPackId);
    if(menuPack)
    {
      const save = menuPack.DecoyPrice - menuPack.Price;
      if(save > 0)
      {
        return "Save $" + (save / 100);
      }
    }
    return "";
  }

  getTax(): number {
    return this.purchase.Items[0].Tax;
  }

  removeItem(itemId: number, itemIntent: string)
  {
    this.purchase.Items = this.purchase.Items.filter(i => i.Id !== itemId && i.Intent !== itemIntent);
    if(this.purchase.Items.length === 0)
      this.closeModal(true);
  }
  
  getTotalItems()
  {
    return this.purchase.Items.length + (this.purchase.Items.length > 1 ? " Items" : " Item");
  }

  checkout() {
    this.purchaseRequest = {
      MemberId: this.memberInfo.Id,
      MenuPackId: this.purchase.Items[0].Id,
      StripeCustomerId: this.memberStripeCustomerId,
      StripeCardId: this.selectedCardId,
      ReceiptEmail: this.receiptEmail,
      StartAt: this.purchase.Items[0].StartAt
    }

    this._currencyService.purchase(this.purchaseRequest).subscribe((result: boolean | string) => {
      if(typeof(result) === 'string')
      {
        this.alertService.error(result);
        window.alert(result);
        this.closeModal(false);
        return;
      } else {
        window.alert('Purchase successful.');
        this.closeModal(true);
      }
    });
  }

  closeModal(result: boolean) {
    this.passEntry.emit(result);
    this.activeModal.close(result);
  }

  isLoggedIn(): boolean {
    this.currentMember = this.authService.getLoggedInMember();
    this.memberId = this.currentMember?.Id || 0;
    return !!this.currentMember && this.currentMember.Id > 0;
  }

  loggedIn(isLoggedIn: boolean) {
    if (isLoggedIn)
    {
      if(this.isLoggedIn())
      {
        
      }
    }
  }

  openAddCardPopup() {
    setTimeout(() => {
      //open class map popup
      const modalRef = this.modalService.open(AddCardComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        centered: true,
        fullscreen: window.innerWidth <= 768,
      });

      //Passing itemIds to popup
      modalRef.componentInstance.memberId = this.memberId;
  
      //Retrieving boolean from popup
      modalRef.result.then(async (result: boolean) => {
        this.logService.debug("card added?:", result);
        if(result)
        {
          this.cards = await this._stripeService.getCustomerCards(this.memberInfo.Id);
          const defaultCard = this.cards.find(card => card.is_default);
          this.selectedCardId = defaultCard ? defaultCard.id : "";
        }
      });
    }, 500);
  }

  ngOnDestroy(): void {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
  }
}

