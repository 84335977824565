import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'df-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent {
  @ViewChild('download', { read: TemplateRef }) download: TemplateRef<any> | undefined;
  slideClass: any = { 'slide-1': true };
  rightPaneClass: any = { 'pt-0': false, 'pt-5': true, 'pb-5': false, 'pb-0': true };
  rightPaneClassTop: any = { 'pt-0': false, 'pt-4': true, 'pb-4': false, 'pb-0': true };
  slideNo: number = 0;
  
  @Output()
  click: EventEmitter<any> = new EventEmitter<any>();

  downloadSlides: OwlOptions = {
		items: 1,
		nav: true,
		loop: true,
		autoWidth: true,
		responsive: {},
		dots: true,
		autoplay: false,
		smartSpeed: 500,
		animateIn: 'fadeIn',
		animateOut: 'fadeOut',
    autoplayHoverPause: true,
    mouseDrag: true,
    navText: [
      '<img src="/assets/images/svg/arrow_left.svg" alt="" />',
			'<img src="/assets/images/svg/arrow_right.svg" alt="" />'
			// "<i class='fa-solid fa-chevron-left'></i>",
			// "<i class='fa-solid fa-chevron-right'></i>"
		]
	}

  constructor(private modalService: NgbModal) {
  }

  public open() {
    this.openModal(this.download);
  }

  public dismiss() {
    this.modalService.dismissAll();
  }

  public openModal(modal: any): void {
    let modalRef = this.modalService.open(modal, {
      ariaLabelledBy: 'modal-basic-title', 
      size: 'lg',
      centered: true,
      // fullscreen: window.innerWidth <= 768,
      windowClass: 'margined-modal'
    });
    modalRef.shown.subscribe(() => {
    });
  }

  slideChanged(e: any) {
    this.slideNo = e.startPosition;

    this.slideClass = {};
    this.slideClass[`slide-${this.slideNo}`] = true;

    if (this.slideNo === 1) {
      this.rightPaneClass = { 'pt-0': true, 'pt-5': false, 'pb-5': true, 'pb-0': false };
      this.rightPaneClassTop = { 'pt-0': true, 'pt-4': false, 'pb-4': true, 'pb-0': false };
    }
    else {
      this.rightPaneClass = { 'pt-0': false, 'pt-5': true, 'pb-5': false, 'pb-0': true };
      this.rightPaneClassTop = { 'pt-0': false, 'pt-4': true, 'pb-4': false, 'pb-0': true };
    }
  }

  clicked(e: any) {
    this.click.emit(e); 
  }
}
