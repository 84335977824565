//system
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';

//environment
import { environment } from 'src/environments/environment';

//models
import { Booking, MemberCurrency, MemberMemberApp, MemberModel } from 'src/app/models/member';

//services
import { HttpService, HttpServiceConfig } from './common/http.service';
import { AuthService } from './auth.service';
import { LogService } from './common/log.service';
import { MembershipAction, TermUnit } from '../models/enums';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  freeTrialDesc: string = 'Free trial';
  cachedMemberAccount$!: Observable<any> | null;

  constructor(private _auth: AuthService, 
    private _http: HttpClient,
    private logService: LogService, 
    private httpService: HttpService) { }

  fetchUser() {
    if (this._auth.isLoggedIn()) {
      return this._http.get(environment.facility[environment.submerchant].corporateUrl + 'api/v1/Person/me');
    }
    return null;
  }

  fetchMember() {
    if (this._auth.isLoggedIn()) {
      return this._http.get(environment.facility[environment.submerchant].facilityUrl + 'api/v1/Member/Info');
    }
    return null;
  }

  fetchAvatar(memberId: number) {
    if (this._auth.isLoggedIn()) {
      return this._http.get(environment.facility[environment.submerchant].facilityUrl + `api/v1/Upload/ByIntent/1/${memberId}/member_default_photo`);
    }
    return null;
  }

  getUser() {
    return this._auth.getProp('user');
  }

  getMember() {
    return this._auth.getProp('member');
  }

  getPhotoUrl() {
    if (this._auth.isLoggedIn()) {
      let avatar = this._auth.getProp('avatar');
      if (avatar) {
        return avatar.UploadedUrl;
      }
      let user = this._auth.getProp('user');
      return `${user.FirstInitial}${user.LastInitial}`;
      // return '/assets/images/df/profile.png';
    }
    return null;
  }

  fetchMemberAccount(force: boolean = true) {
    if (this._auth.isLoggedIn()) {
      let member = this._auth.getProp('member');
      if (member?.Id) {
        if (!this.cachedMemberAccount$ || force) {
          this.cachedMemberAccount$ = this._http.get(`${environment.facility[environment.submerchant].facilityUrl}api/v1/Member/MemberApp/${member.Id}`).pipe(
            shareReplay({ bufferSize: 1, refCount: true })
          );
        }
        return this.cachedMemberAccount$;
      }
    }
    return null;
  }

  getCurrentCycle(s: any) {
    let today = new Date();
    if (s.Cycles && s.Cycles.length) {
      for (let i in s.Cycles) {
        let cycle = s.Cycles[i];
        if (cycle && new Date(cycle.StartAt) <= today && new Date(cycle.EndAt) >= today) {
          return cycle;
        }
      }
    }
    return null;
  }

  getLastCycle(s: any) {
    if (s.Cycles && s.Cycles.length > 0) {
      let cycle = s.Cycles[1];
      for (let i in s.Cycles) {
        cycle = s.Cycles[i];
      }
      return cycle;
    }
    return null;
  }

  isCancelled(s: any) {
    let cycle = this.getCurrentCycle(s);
    if (cycle) return cycle.IsCancelled;
      return true;
  }

  isFreeTrial(s: any) {
    return s.Description === this.freeTrialDesc;
  }

  getMemberSubscriptions(memberAccount: MemberMemberApp): Booking[] {
    let myDrop = memberAccount.MyDrop;
    let subscriptions = (myDrop?.Subscriptions || []).filter((s: any) => {
      return !this.isCancelled(s);
    });
    if (!subscriptions.length) {
      let member = this.getMember();
      let today = Date.now();
      if (new Date(member.OnboardedAt).getTime() < 0 || today <= new Date(member.FreeTrialEndsAt).getTime()) {
        subscriptions.push({
          Id: 0,
          MemberId: memberAccount.Id,
          ParentId: 0,
          StripeCustomerId: "",
          StripeCardId: "",
          ReceiptEmail: "",
          Amount: 0,
          TermDuration: 1,
          TermUnit: TermUnit.Week,
          IsSandbox: false,
          NextChargableDate: member.FreeTrialEndsAt,
          FreeResumableTill: "",
          MembershipStartAt: member.FreeTrialStartsAt,
          ModifiedAt: "",
          Action: MembershipAction.Add,
          Comment: "",

          CurrencyId: 1,
          PackId: 9,
          MenuPackId: 47,
          Description: this.freeTrialDesc,
          IsStarted: new Date(member.FreeTrialStartsAt).getTime() > 0,
          Cycles: [{
            IsCancelled: false,
            StartAt: member.FreeTrialStartsAt,
            EndAt: member.FreeTrialEndsAt
          }]
        });
      }
    }
    return subscriptions;
  }

  getMemberPacks(memberAccount: MemberMemberApp): MemberCurrency[] {
    let myDrop = memberAccount.MyDrop;
    let packs = (myDrop?.Packs || []).filter((p: MemberCurrency) => {
      return p.Balance > 0 && new Date(p.EndAt).getTime() > Date.now();
    });
    return packs;
  }

  classesRemainingSub(s: any): string {
    let cycle = this.getCurrentCycle(s);
    if (cycle && s.PackId != 9) return cycle.Balance;
    if (cycle && s.PackId == 9) return 'Unlimited';
    return '0';
  }

  classesRemainingPack(p: any): string {
    return p.Balance;
  }

  classesRemaining(subscriptions: any[], packs: any[]): string {
    let balance = 0;
    for (let i in subscriptions) {
      let sub = subscriptions[i];
      if (sub.CurrencyId !== 1) continue;
      if (sub.Description === this.freeTrialDesc) return 'Free Trial';
      let b = this.classesRemainingSub(sub);
      if (b === 'Unlimited') return b;
      balance += parseInt(b, 10);
    }
    for (let i in packs) {
      let pack = packs[i];
      if (pack.CurrencyId !== 1) continue;
      balance += pack.Balance;
    }
    return balance.toString();
  }
}
