<div class="row">
    <div class="col-sm-12 avatar-area">
        <div class="account-menu mt-5 mb-5">
            <img *ngIf="avatar() && avatar().length != 2" [src]="avatar()" alt="" class="avatar">
            <div *ngIf="avatar() && avatar().length == 2" alt="" class="avatar">{{avatar()}}</div>
        </div>
        <div [routerLink]="['/account/my-drop']" routerLinkActive="active-link" class="account-menu mb-2 clickable">Account</div>
        <div [routerLink]="['/account/personal-info']" routerLinkActive="active-link" class="account-menu mb-2 clickable">Personal Information</div>
        <div [routerLink]="['/account/payment-methods']" routerLinkActive="active-link" class="account-menu mb-2 clickable">Payment Methods</div>
        <div class="account-menu mb-5 clickable" (click)="logout()">Logout</div>
    </div>
</div>