import { Component, OnInit, Input } from '@angular/core';

declare const google: any;

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent {
  @Input() public latitude : number = 0;
  @Input() public longitude : number = 0;
  @Input() public details : string = "";
  map!: any;

  constructor() { }

  ngOnInit(): void {
    
    if(this.latitude == null)
    {
      this.latitude = environment.facility[environment.submerchant].googleMap.latitude;
    }

    if(this.longitude == null)
    {
      this.longitude = environment.facility[environment.submerchant].googleMap.longitude;
    }

    const geocoder = new google.maps.Geocoder();
    const latLng = new google.maps.LatLng(this.latitude, this.longitude);
    if(this.details == null || this.details == "")
    {
      geocoder.geocode({ location: latLng }, (results: any, status: any) => {
        if (status === "OK") {
          if (results[0]) {
              this.details = results[0].formatted_address.replace("Inside ", "");
          } else {
            console.log("Geocoder: No results found");
          }
        } else {
          console.log("Geocoder failed due to: " + status);
        }
      });
    }
  }

  ngAfterViewInit() {
    const mapProperties = {
      center: new google.maps.LatLng(this.latitude, this.longitude),
      zoom: 17,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
    };
    this.map = new google.maps.Map(document.getElementById("map"), mapProperties);

    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(this.latitude, this.longitude),
      label: {
        text: "Drop Fitness",
        color: "black",
        fontSize: "16px",
        fontWeight: "bold"
      },
    });
    marker.setMap(this.map);
  }
}
